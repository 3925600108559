import { Injectable } from '@angular/core';
import { Document } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcNotificationService } from '@tc/core';
import { saveAs } from 'file-saver';
import { take } from 'rxjs/operators';

import { getAuthenticationToken } from '../../modules/auth/store/auth.selectors';
import { AuthState } from '../../modules/auth/store/auth.state';
import { ConfigKeys } from '../../modules/shared/interfaces/config.interface';
import { ConfigService } from '../../modules/shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

  constructor(
    private readonly config: ConfigService,
    private readonly store$: Store<AuthState>,
    private readonly notification: TcNotificationService,
  ) { }

  public async download(doc: Document) {
    const token = await this.store$.select(getAuthenticationToken).pipe(take(1)).toPromise();
    const uri = `${this.config.get(ConfigKeys.API_BASE_PATH)}/storage/${doc.fileIdentifier}/download`;

    const a = await fetch(uri, { headers: { 'Authorization': `Bearer ${token}` } });

    if (!a.ok) {
      this.notification.error('File not found');
      return;
    }

    saveAs(await a.blob(), doc.fileName);
  }

}