import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild, ViewEncapsulation, Input } from "@angular/core";
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { fromEvent, Subscription, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { FiltersService } from '../../../../services/business-services/filters-service';
import * as moment from 'moment';
import { UserRoleEnum } from '@match-fix/shared';
@Component({
  selector: 'expert-match-filter',
  templateUrl: './expert-match-filter.component.html',
  styleUrls: ['./expert-match-filter.styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpertMatchFilterComponent extends TcFormComponent<any> implements AfterViewInit, OnDestroy {

  private subscription: Subscription;
  private searchValue: string;

  private initialFilter = {
    competitionId: null,
    date: null,
    clubACode: null,
    clubBCode: null,
    supervisorId: null,
    operator1Id: null,
    operator2Id: null,
    expertId: null,
    principalReferee: null,
    matchState: null,
    matchAnalysisState: null,
    matchStatus: null,
  };

  private _userRole: UserRoleEnum;
  @Input() set userRole(role: UserRoleEnum) {
    this._userRole = role;
  }

  @Output() filterChange = new EventEmitter<any>();

  @ViewChild('inputRef') private inputRef: any;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService,
    private readonly filtersService: FiltersService,
  ) {
    super(translate, elem);

    const canSeeMore = this._userRole === UserRoleEnum.Administrator || this._userRole === UserRoleEnum.Expert;

    this.fields = [
      this.selectField('competitionId', []),
      this.selectField('date', [], false),
      this.selectField('clubACode', []),
      this.selectField('clubBCode', []),
      ...(canSeeMore
        ? [
          this.selectField('supervisorId', []),
          this.selectField('operator1Id', []),
          this.selectField('operator2Id', []),
          this.selectField('expertId', []),
          this.selectField('principalReferee', [], false),
        ]
        : []),
      this.selectField('matchState', []),
      this.selectField('matchAnalysisState', []),
      this.selectField('matchStatus', []),
      this.buttonField('clear', true, () => this.resetModel()),
    ];

    this.model = { ...this.initialFilter };
  }

  ngAfterViewInit() {
    this.initFields();

    this.subscription = fromEvent(this.inputRef.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        map((event: any) => event.target.value),
      )
      .subscribe(search => this.search(search));
  }

  private async initFields() {
    const canSeeMore = this._userRole === UserRoleEnum.Administrator || this._userRole === UserRoleEnum.Expert;
    const filter = await this.filtersService.getAnalysisFilters();

    this.fields = [
      this.selectField('competitionId', filter?.competitions || []),
      this.selectField('date', (filter?.dates || []).map(date => ({ value: date.value.toString(), label: moment(date.value).format('DD/MM/YYYY') })), false),
      this.selectField('clubACode', filter?.teamA || []),
      this.selectField('clubBCode', filter?.teamB || []),
      ...(canSeeMore
        ? [
          this.selectField('supervisorId', filter?.supervisors || []),
          this.selectField('operator1Id', filter?.operator1 || []),
          this.selectField('operator2Id', filter?.operator2 || []),
          this.selectField('expertId', filter?.experts || []),
          this.selectField('principalReferee', filter?.referees || [], false),
        ]
        : []),
      this.selectField('matchState', filter?.matchStates || []),
      this.selectField('matchAnalysisState', filter?.matchAnalysisStates || []),
      this.selectField('matchStatus', filter?.matchStatuses || []),
      this.buttonField('clear', true, () => this.resetModel()),
    ];
  }

  private resetModel() {
    this.model = { ...this.initialFilter };

    this.emitFilter();
  }

  private search(value: string) {
    this.searchValue = value;

    this.emitFilter();
  }

  private emitFilter(e = null) {
    const filter = this.buildFilter();

    this.filterChange.emit(filter);
  }

  private buildFilter() {

    const data = {
      anyFieldContains: this.searchValue || ''
    };

    for (const key of Object.keys(this.model)) {
      if (key === 'date') {
        if (!this.model[key]) { continue; }

        data[key] = { value: this.model[key], filterType: 'ExactDateFilter' };
      } else if (key === 'principalReferee') {
        if (!this.model[key]) { continue; }

        data[key] = { value: this.model[key], filterType: 'Like' };
      } else {
        if (!this.model[key]?.length) { continue; }

        data[key] = { value: this.model[key].join(','), filterType: 'In' };
      }
    }

    if (!data.anyFieldContains) {
      delete data.anyFieldContains;
    }

    if (!Object.keys(data).length) {
      return null;
    }

    return data;
  }

  private selectField(key: string, options: { value: any, label: any }[], multiple = true): FormlyFieldConfig {
    const label = this.translate.instant(`expert-match-list.filter.${key}`);

    return {
      key,
      type: 'select',
      templateOptions: {
        label,
        options,
        multiple,
        change: () => this.emitFilter(),
      },
    };
  }

  private buttonField(key: string, outline: boolean, action: () => any): FormlyFieldConfig {
    const label = this.translate.instant(`expert-match-list.filter.${key}`);

    return ({
      key: key,
      type: 'button-formly',
      templateOptions: {
        outline,
        label,
        onClick: action
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}