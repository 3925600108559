import { BaseCustomPlayerModel, Club, ClubPlayerModel, Player } from '@match-fix/shared';
import { createAction, props } from '@ngrx/store';
import { TcAction } from '@tc/core';

export enum ClubActionTypes {
  CREATE_CLUB = '[Clubs] Create club',
  UPDATE_CLUB = '[Clubs] Update club',
  ADD_PLAYER = '[Clubs] Add player',
  UPDATE_CLUB_SUCCESS = '[Clubs] Update club success',
};

export class CreateClub implements TcAction {
  readonly type = ClubActionTypes.CREATE_CLUB;

  constructor(public payload: Club) { }
}

export class UpdateClub implements TcAction {
  readonly type = ClubActionTypes.UPDATE_CLUB;

  constructor(public payload: Club) { }
}

export class UpdateClubSuccess implements TcAction {
  readonly type = ClubActionTypes.UPDATE_CLUB_SUCCESS;
}

export class AddPlayer implements TcAction {
  readonly type = ClubActionTypes.ADD_PLAYER;

  constructor(public payload: BaseCustomPlayerModel) { }
}

export const upsertClub = createAction(
  '[Clubs] Upsert Club',
  props<{ club?: Club }>()
);

export const addPlayerToClub = createAction(
  '[Clubs] Add Player To Club',
  props<{ payload: Player }>()
);

export const loadClub = createAction(
  '[Clubs] Load Club',
  props<{ id?: number }>()
);

export const loadClubSuccess = createAction(
  '[Clubs] Load Club Success',
  props<{ payload?: any }>()
);

export const clubFilter = createAction(
  '[Clubs] Club Filter',
  props<{ payload?: string }>()
);

export const confirmTransfer = createAction(
  '[Clubs] Club Transfer',
  props<{ payload?: string }>()
);
export const upsertClubSuccess = createAction(
  '[Clubs] Upsert Success Club',
  props<{ club?: Club }>()
);
export const saveClubInStore = createAction(
  '[Clubs] Save club in store',
  props<{ club: Club }>(),
);
export const resetClubState = createAction(
  '[Clubs] Reset club state',
  props(),
);
export const clearClubPlayers = createAction(
  '[Clubs] Clear club players',
  props(),
);

export type ClubStoreActions =
  | CreateClub
  | UpdateClub
  | UpdateClubSuccess
  | AddPlayer;