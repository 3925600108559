import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation, UploadTypeEnum, User, UserRoleEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import * as moment from 'moment';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Subscription, timer } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { OrganisationUserService } from '../../../../../services/business-services/organisation-user.service';
import { OrganisationsService } from '../../../../../services/business-services/organisations-service';
import { UsersService } from '../../../../../services/business-services/users-service';
import { SelectValues } from '../../../../main/enums/select-values';
import { upsertOrganisation } from '../../../../organisations/store/organisations.actions';
import { UploadService } from '../../../../shared/services/upload.service';
import { saveOrganisations } from '../../../store/users.actions';
import { getOrganisations } from '../../../store/users.selectors';
import UsersState from '../../../store/users.state';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailComponent
  extends TcGenericFormComponent<User & { organisations: Organisation[] }>
  implements OnInit, AfterViewInit, OnDestroy {

  private orgs: Array<Organisation & { add?: boolean }> = [];
  private isCreating = false;
  private invalidEmail = false;

  private orgsSubscription: Subscription;

  constructor(
    private readonly uploadService: UploadService,
    private readonly usersService: UsersService,
    private readonly organisationsService: OrganisationsService,
    private readonly organisationUserService: OrganisationUserService,
    private readonly store$: Store<UsersState>,
    private readonly notification: TcNotificationService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private dialogRef: MatDialogRef<UserDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: User,
    elem: ElementRef
  ) {
    super('Users', entityCollectionServiceFactory, translate, elem);

    this.initFields(
      store$.select(getOrganisations).pipe(take(1)).toPromise(),
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.isCreating = !this.data;

    if (!this.isCreating) {
      const { password, birthDate, ...data } = this.data;
      this.model = {
        ...data,
        birthDate: this.data?.birthDate ? new Date(Number(this.data.birthDate)) : null,
      } as any;
    }

    this.orgsSubscription = this.store$
      .select(getOrganisations)
      .pipe(skip(1))
      .subscribe(orgs => this.checkOrganisations(orgs));
  }

  async ngAfterViewInit() {
    if (this.data?.id) {
      const current = await this.organisationUserService.getByUser(this.data.id);

      const organisations = (current || []).map(org => ({
        id: org.organisationId,
        name: (org as any).name
      })) as any;

      this.model = {
        ...this.model,
        organisations
      };
    }

    this.removePrefilledValues();
  }

  addOrg() {
    this.store$.dispatch(upsertOrganisation({}));
  }

  private async checkOrganisations(orgs: (Organisation & { add?: boolean; })[]) {
    this.orgs = orgs;
    const added = orgs.find(o => o.add);

    const apiOrgs = await this.organisationsService.getAll();
    const lastAdded = apiOrgs.reverse().find(org => org.code === added?.code && org.name === added?.name);

    if (lastAdded) {
      this.model = {
        ...this.model,
        organisations: [...(this.model.organisations || []), lastAdded],
      };

      this.store$.dispatch(
        saveOrganisations({ orgs: apiOrgs.map(({ id, name }) => ({ id, name })) as any }),
      );
    }
  }

  private isRequired(form: FormGroup) {
    return (control) => {
      return this.invalidEmail ? { emailTaken: true } : null;
    };
  }

  public async submit() {
    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.model.email);

    if (!isValidEmail) {
      this.form.controls.email.setErrors({ invalid: true });
    }

    if (this.form.invalid) {
      return;
    }

    if (!!this.model.signatureFileIdentifier && typeof this.model.signatureFileIdentifier === 'object') {
      const fileRes = await this.uploadService.upload(this.model.signatureFileIdentifier, (this.model.signatureFileIdentifier as any).name, UploadTypeEnum.Image).toPromise();

      this.model.signatureFileIdentifier = (fileRes as any)?.body?.id;

      if (!(fileRes as any)?.body?.id) {
        this.notification.error('Failed to upload');
        return;
      }
    }

    let user: User;

    if (!this.data) {
      user = await this.create(user);

    } else {
      user = await this.update(user);
    }

    if (!user) {
      this.invalidEmail = true;
      this.form.controls.email.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    } else {
      this.dialogRef.close(user);
    }
  }

  private async update(user: User) {
    const { password, ...data } = this.data;
    const { organisations, organisationNames, ...final } = { ...data, ...this.model } as any;

    const birthDate = moment(final.birthDate).utc(true).toISOString();
    const signatureFileIdentifier = this.model.role === UserRoleEnum.Expert ? this.model.signatureFileIdentifier : null;

    if (!final.password) {
      delete final.password;
    }

    user = await this.usersService.update({ ...final, birthDate, signatureFileIdentifier });

    if (!user) { return; }

    await this.organisationUserService.setForUser(
      user.id,
      (this.model?.organisations || []).map(({ id }) => ({ id }))
    );

    return user;
  }

  private async create(user: User) {
    const { organisations, organisationNames, ...data } = this.model as any;
    const birthDate = moment(data.birthDate).utc(true).toISOString();

    user = await this.usersService.create({ ...data, birthDate });

    if (!user) { return; }

    await this.organisationUserService.setForUser(
      user.id,
      (this.model?.organisations || []).map(({ id }) => ({ id }))
    );

    return user;
  }

  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }

  private async initFields(organisations: Promise<Organisation[]>) {
    this.orgs = await organisations;

    this.fields = [
      {
        fieldGroupClassName: 'form-display-columns',
        fieldGroup: [
          {
            key: 'code',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          },
          {
            key: 'firstName',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              maxLength: 100
            }
          },
          {
            key: 'lastName',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              maxLength: 100
            }
          },
          {
            key: 'birthDate',
            type: 'datepicker',
            templateOptions: {
              type: 'text',
              datepickerPopup: 'dd-MMMM-yyyy',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss'
              }
            },
            validators: {
              birthDate: {
                expression: (c) => !c.value || c.value <= new Date(),
                message: (error, field: FormlyFieldConfig) => `${field.templateOptions.label} ${this.translate.instant(`user-detail.validations.birthDate`)}`,
              },
            },
          },
          {
            key: 'email',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 255,
              ['emailTaken']: this.translate.instant('user-detail.errors.emailTaken'),
              ['invalid']: this.translate.instant('user-detail.errors.invalidEmail'),
              keypress: () => {
                this.invalidEmail = false;
                this.form.controls.email.updateValueAndValidity({ onlySelf: false, emitEvent: true });
              },
            },
            validators: {
              validation: this.isRequired(this.form),
            }
          },
          {
            key: 'password',
            type: 'input',
            className: 'columns-fields',
            defaultValue: null,
            templateOptions: {
              type: 'password',
              maxLength: 100,
              required: !Boolean(this.data),
            },
          },
          {
            key: 'identityDocumentType',
            type: 'select',
            className: 'columns-fields',
            templateOptions: {
              options: new SelectValues().userIdentityDocumentType(),
              required: true
            }
          },
          {
            key: 'identityDocumentNumber',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              maxLength: 100
            }
          },
          {
            key: 'role',
            type: 'select',
            className: 'columns-fields',
            templateOptions: {
              options: new SelectValues().userProfil(),
              required: true
            }
          },
          {
            key: 'phone',
            type: 'input',
            className: 'columns-fields',
            templateOptions: {
              type: 'text',
              maxLength: 50
            }
          },
          {
            key: 'active',
            type: 'checkbox',
            className: 'columns-fields',
            templateOptions: {
              type: 'boolean'
            }
          },
          {
            key: 'organisations',
            type: 'multi-select',
            className: 'columns-fields',
            name: 'organisations',
            templateOptions: {
              filter: () => (this.orgs || []).map(({ id, name }) => ({ id, name })),
              display: (item) => item.name
            }
          },
          {
            key: 'signatureFileIdentifier',
            type: 'upload-image',
            hideExpression: (model) => model?.role !== UserRoleEnum.Expert,
            className: 'columns-fields',
            templateOptions: {
              required: true,
            },
          },
        ]
      }
    ];
  }

  private async removePrefilledValues() {
    const emailChange = this.form.controls.email.valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.form.controls.email.setValue(null);
        this.form.controls.email.setErrors(null);
      });

    const pwdChange = this.form.controls.password.valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.form.controls.password.setValue(null);
        this.form.controls.password.setErrors(null);
      });


    await timer(1000).toPromise();

    emailChange.unsubscribe();
    pwdChange.unsubscribe();
  }

  ngOnDestroy() {
    if (this.orgsSubscription) {
      this.orgsSubscription.unsubscribe();
    }
  }

}
