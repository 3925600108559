import { CompetitionType, entityMetadata, OrganisationType, StadiumType, typesConfig } from '@match-fix/shared';

import { CustomClubType } from './modules/graphQlTypes/CustomClubType';
import { CustomMatchType } from './modules/graphQlTypes/CustomMatchType';
import { CustomPlayerType } from './modules/graphQlTypes/CustomPlayerType';
import { CustomStadiumsType } from './modules/graphQlTypes/CustomStadiumType';
import { CustomUserType } from './modules/graphQlTypes/CustomuserType';
import { MatchWithAnalystType } from './modules/graphQlTypes/MatchWithAnalystType';
import { PlayerWithClubType } from './modules/graphQlTypes/PlayerWithClubType';
import { RefereeWithMatchType } from './modules/graphQlTypes/RefereeWithMatchType';
import { QlServiceFactoryConfig } from './services/core/ql-service-factory-config';
import { CustomOrganisationType } from './modules/graphQlTypes/CustomOrganisationType';
import { CustomCompetitionType } from './modules/graphQlTypes/CustomCompetitionType';


OrganisationType.gqlColumns.push(`
country {
    _id,
    code
}`);

CompetitionType.gqlColumns.push(`
organisation {
    _id,
    name,
    country{
      code
    }
}`);

StadiumType.gqlColumns.push(`
country {
    _id,
    code
}`);

entityMetadata.RefereeWithMatch = {};
typesConfig['RefereeWithMatch'] = RefereeWithMatchType;

entityMetadata.MatchWithAnalyst = {};
typesConfig['MatchWithAnalyst'] = MatchWithAnalystType;

entityMetadata.PlayerWithClub = {};
typesConfig['PlayerWithClub'] = PlayerWithClubType;

entityMetadata.CustomPlayers = {};
typesConfig['CustomPlayers'] = CustomPlayerType;

entityMetadata.CustomUsers = {};
typesConfig['CustomUsers'] = CustomUserType;

entityMetadata.CustomClubs = {};
typesConfig['CustomClubs'] = CustomClubType;

entityMetadata.CustomOrganisation = {};
typesConfig['CustomOrganisation'] = CustomOrganisationType;

entityMetadata.CustomMatches = {};
typesConfig['CustomMatches'] = CustomMatchType;

entityMetadata.CustomStadiums = {};
typesConfig['CustomStadiums'] = CustomStadiumsType;

entityMetadata.CustomCompetition = {};
typesConfig['CustomCompetition'] = CustomCompetitionType;

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames
};

export const qlServiceFactoryConfig: QlServiceFactoryConfig = {
  typesConfig,
};
