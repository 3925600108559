import { Injectable } from '@angular/core';
import { UserModel } from '@match-fix/shared';
import { appPermissions, PermissionEnum, PermissionItem } from './types';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    private readonly permissions: PermissionItem[] = appPermissions;

    private user: UserModel;

    public init(user: UserModel) {
        this.user = user;
    }

    public hasAccess(permission: PermissionEnum, customPermission?: (...args) => boolean): boolean {
        const genericAccess = this.checkGenericAccess(permission);

        return genericAccess && customPermission ? customPermission() : genericAccess;
    }

    private checkGenericAccess(key: PermissionEnum): boolean {
        const permission: PermissionItem = this.permissions.find(item => item.key === key);

        return permission.roles.includes(this.user.role);
    }

}
