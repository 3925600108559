import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState, TcPage } from '@tc/core';
import { take } from 'rxjs/operators';
import { getReportMatchId } from '../../modules/expert-analysis/store/expert-analysis.selectors';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { ConfigKeys } from '../../modules/shared/interfaces/config.interface';
import { ConfigService } from '../../modules/shared/services/config.service';
import { ReportsService } from '../../services/business-services/reports-service';
import { UsersService } from '../../services/business-services/users-service';

@Component({
  selector: 'app-report-view-page',
  templateUrl: './report-view-page.component.html',
  styleUrls: ['./report-view-page.component.scss']
})
export class ReportViewPageComponent extends TcPage implements OnInit {

  public pdfSrc = null;

  constructor(
    public readonly store: Store<TcAppState>,
    private readonly config: ConfigService,
    private readonly usersService: UsersService,
    private readonly reportsService: ReportsService,
  ) {
    super(store);
  }

  async ngOnInit() {
    this.store.dispatch(getBreadcrumb({ route: RouteEnum.ReportView }));

    const matchId = await this.store.select(getReportMatchId).pipe(take(1)).toPromise();
    const report = await this.reportsService.getMatchReport(matchId);
    const token = await this.usersService.getAuthToken();

    this.pdfSrc = {
      url: `${this.config.get(ConfigKeys.API_BASE_PATH)}/storage/${report.fileIdentifier}/download`,
      httpHeaders: { 'Authorization': `Bearer ${token}` },
    }
  }

}
