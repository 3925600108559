import { Stadium } from '..';

export class StadiumType {
  static className = 'Stadium';
  static gqlRootQueryName = 'stadium';
  static gqlColumns: string[] = [
      '_id',
      'countryId',
      'city',
      'fieldLength',
      'fieldWidth',
      'name',
      'clubId',
  ];

  static gqlNodeMapper = (node: any): Stadium => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Stadium;
    }

}
