import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ButtonFromlyComponent } from '../main/button.component';
import { ChronoInputComponent } from '../main/components/smart/chrono-input/chrono-input.component';
import { NgxInputFromlyComponent } from '../main/ngx-input.component';
import { SharedModule } from '../shared/shared.module';
import { DeficienciesListComponent } from './components/deficiencies-list/deficiencies-list.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { KeyPointDetailsComponent } from './components/key-point-details/key-point-details.component';
import { KeyPointNavigationComponent } from './components/key-point-navigation/key-point-navigation.component';
import { KeyPointsComponent } from './components/key-points/key-points.component';
import { MatchMilestonesComponent } from './components/match-milestones/match-milestones.component';
import { RealignComponent } from './components/realign-popup/realign-popup.component';
import { AnalysisService } from './services/analysis.service';
import { RealignService } from './services/realign.service';
import { TeamService } from './services/team.service';
import { AnalysisStoreEffects } from './store/analysis.effects';
import { analysisStoreReducer } from './store/analysis.reducers';
import { ChronoStoreEffects } from './store/chrono.effects';
import { chronoStoreReducer } from './store/chrono.reducers';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    KeyPointsComponent,
    MatchMilestonesComponent,
    KeyPointDetailsComponent,
    RealignComponent,
    EventListComponent,
    DeficienciesListComponent,
    NgxInputFromlyComponent,
    ChronoInputComponent,
    ButtonFromlyComponent,
    KeyPointNavigationComponent,
  ],
  exports: [
    KeyPointsComponent,
    MatchMilestonesComponent,
    KeyPointDetailsComponent,
    RealignComponent,
    EventListComponent,
    DeficienciesListComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(options),
    CommonModule,
    TcCoreModule,
    SharedModule,
    FormsModule,
    StoreModule.forFeature('analysis', analysisStoreReducer),
    StoreModule.forFeature('chrono', chronoStoreReducer),
    EffectsModule.forFeature([AnalysisStoreEffects]),
    EffectsModule.forFeature([ChronoStoreEffects]),
  ],
  providers: [
    RealignService,
    AnalysisService,
    TeamService
  ]
})
export class MatchAnalysisModule { }
