import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState, TcPage } from '@tc/core';
import { login, loginFailure, resetErrorMessage } from '../../modules/auth/store/auth.actions';
import { LoginService } from '../../services/business-services/login.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends TcPage implements OnInit {

  public step = 0;
  public mfaOptions = null;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly loginService: LoginService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store$.dispatch(resetErrorMessage());
  }

  public async onLogin(data) {
    let response;
    try {
      response = await this.loginService.login(data.username, data.password);
    } catch (error) {
      this.store$.dispatch(loginFailure({ error }))
      return;
    }

    this.mfaOptions = response;
    this.step = 1
  }

  public onVerify(data) {
    this.store$.dispatch(login(data))
  }

}
