import { Subscription } from 'rxjs';
import {
  EventListItem,
  MatchTime,
  MatchTimeChrono
} from './../store/analysis.interfaces';
import { TcVideoPlayerService } from './../../../services/tc-video-player.service';
import { Injectable, OnDestroy } from '@angular/core';
import { EventTypeEnum, ChronoItem } from '@match-fix/shared';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { convertToReadableFormat } from '../../../utils/milisecondsToReadableFormat';
import { Store } from '@ngrx/store';
import { getEvents } from '../store/analysis.selectors';
import { UpdateVideoTime } from '../store/chrono.actions';

@Injectable({
  providedIn: 'root'
})
export class ChronoService implements OnDestroy {
  constructor(private videoPlayer: TcVideoPlayerService, private store: Store) {
    // Subscription to the store events
    this.eventsSubscription = this.store
      .select(getEvents)
      .subscribe(data => (this.events = data));
  }

  /**
   * Events list from the store
   */
  private events: EventListItem[];

  /**
   * Event subscription
   */
  private eventsSubscription: Subscription;

  /**
   * Destroyer to clear the subscription of the store
   */
  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  /**
   * Pause the video
   */
  public pause() {
    this.videoPlayer.pause();
  }

  /**
   * Play the video
   */
  public play() {
    this.videoPlayer.play();
  }

  /**
   * Update match time in the chrono store
   */
  public UpdateMatchTime(videoTime: number) {
    this.store.dispatch(new UpdateVideoTime({ videoTime: videoTime }));
  }

  /**
   * Get the time of the video
   */
  public getVideoTime(): number | null {
    const videoTime = this.videoPlayer.getTime();
    return videoTime;
  }

  /**
   * Return the current match time from the video time in milliseconds if it can be calculated. Return null if the time cannot be calculated.
   */
  public getMatchTime(videoTime: number): MatchTime | null {
    // Says if the match currently playing or not
    let playing = true;
    // Default return value
    let result = null;
    // Get the current time of video
    const currentVideoTime = videoTime;
    // Get the last event in the store from videoTime
    const lastEvent: ChronoItem = this.getLastEvent(currentVideoTime);
    // If last event is not empty, calculate the difference between two video times
    // At last, match start must be in the store to begin calculation
    if (lastEvent !== null) {
      // Différence between the two video time
      const difference = currentVideoTime - lastEvent.videoTime;
      // Adding the difference to the match time
      let matchTime = lastEvent.matchTime + difference;

      // Check if the match playing phase has ended
      let endingEventType: EventTypeEnum = null;
      switch (lastEvent.round) {
        case 1:
          endingEventType = EventTypeEnum.FirstHalfEnd;
          break;
        case 2:
          endingEventType = EventTypeEnum.SecondHalfEnd;
          break;
        case 3:
          endingEventType = EventTypeEnum.FirstExtraEnd;
          break;
        case 4:
          endingEventType = EventTypeEnum.SecondExtraEnd;
          break;
      }

    // Check if the chrono has ended for the round. If matchtime is >= to end time, then, we get end time : playing phase has stopped.
    if (endingEventType !== null) {
      const endingChrono = this.getMatchEvent(endingEventType);
      if (endingChrono !== null && matchTime >= endingChrono.matchTime) {
        matchTime = endingChrono.matchTime;
        playing = false;
      }
    }

    // Check if match has finished
    if (playing === true) {
      const endingMatchChrono = this.getMatchEvent(EventTypeEnum.MatchEnd);
      if (
        endingMatchChrono !== null &&
        matchTime >= endingMatchChrono.matchTime
      ) {
        matchTime = endingMatchChrono.matchTime;
        playing = false;
      }
    }

    // Set matchTime result
    result = {
        videoTime: currentVideoTime,
        matchTime: matchTime,
        lastEvent: lastEvent,
        playing: playing
      };
    }

    return result;
  }

  /**
   * Return match event from the store. Beware, this function is expecting event to be unique.
   * Return null if the event is not found.
   * @param eventType
   */
  public getMatchEvent(eventType: EventTypeEnum): ChronoItem | null {
    const events = this.events.filter(item => {
      if (item.event && item.event.eventType === eventType) {
        return true;
      }
      return false;
    });
    if (events.length === 0) {
      return null;
    } else if (events.length > 1) {
      throw new Error(
        'Duplicate event in store for event "' + eventType + '".'
      );
    } else {
      return events[0].event;
    }
  }

  /**
   * Return the last event occuring before the video time
   */
  public getLastEvent(videoTime: number): ChronoItem | null {
    const eventsMatchingTime = this.events.filter(item => {
      if (item.event && item.event.videoTime <= videoTime) {
        return true;
      }
      return false;
    });
    if (eventsMatchingTime.length === 0) {
      // If nothing, returns null. No events found.
      return null;
    } else {
      // Returns the last array value. The store is already sorted by videoTime.
      // So the last entry in the array should be the newest one.
      return eventsMatchingTime[eventsMatchingTime.length - 1].event;
    }
  }

  /**
   * Convert match time to human readable format.
   * Return an object with roundTime and additionalTime as strings to be display in the video chrono
   */
  public diplayMatchTime(chrono: ChronoItem): MatchTimeChrono {
    // If time has not been calculated, return empty chrono
    if (chrono === null) {
      return { time: '--:--' };
    }
    // Handle zero display at the beginning of the match
    if (chrono.matchTime === 0 && chrono.round === 1) {
      return { time: '00:00' };
    }

    // We need to know when the additionnal time has started for current round
    let startOfAdditionalTime: number | null = null;
    switch (chrono.round) {
      case 1:
        // 45 min
        startOfAdditionalTime = 2700000;
        break;
      case 2:
        // 90 min
        startOfAdditionalTime = 5400000;
        break;
      case 3:
        // 105 min (90 + 15)
        startOfAdditionalTime = 6300000;
        break;
      case 4:
        // 120 min (105 + 15)
        startOfAdditionalTime = 7200000;
        break;
    }

    // If we are in additionnal time and it's not the declaring event, we get the start of the additionnal time and substract to it the current time
    // to get the chrono from the moment of the start of the additionnal time.
    if (
      startOfAdditionalTime !== null && chrono.matchTime > startOfAdditionalTime
    ) {
      const additionnalTime = chrono.matchTime - startOfAdditionalTime;
      return {
        time: convertToReadableFormat(startOfAdditionalTime, 'mm:ss'),
        additionalTime: convertToReadableFormat(additionnalTime, 'm:ss')
      };
    } else {
      // No additional time, just convert the current time match
      return { time: convertToReadableFormat(chrono.matchTime, 'mm:ss') };
    }
  }

  /**
   * Get the current match time for a event. Some events implies that match time is not set by the video.
   */
  public getEventMatchTime(
    eventType: EventTypeEnum,
    matchTime?: MatchTime
  ): number {
    switch (eventType) {
      case EventTypeEnum.MatchStart:
        return 0;
      case EventTypeEnum.Match45:
        // 45 min
        return 2700000;
      case EventTypeEnum.FirstHalfEnd:
        // If there additionnal time after 45 min, use it
        if (matchTime.matchTime > 2700000) {
          return matchTime.matchTime;
        } else {
          // End of time should be at least 45 min
          return 2700000;
        }
      case EventTypeEnum.SecondHalfStart:
        // 45 min + 1 millisecond
        return 2700001;
      case EventTypeEnum.Match90:
        // 90 min
        return 5400000;
      case EventTypeEnum.SecondHalfEnd:
        // If there additionnal time after 90 min, use it
        if (matchTime.matchTime > 5400000) {
          return matchTime.matchTime;
        } else {
          // End of time should be at least 90 min
          return 5400000;
        }
      case EventTypeEnum.FirstExtraStart:
        // 90 min + 1 millisecond
        return 5400001;
      case EventTypeEnum.Extra15:
        // 105 min (90 + 15)
        return 6300000;
      case EventTypeEnum.FirstExtraEnd:
        // If there additionnal time after 105 min, use it
        if (matchTime.matchTime > 6300000) {
          return matchTime.matchTime;
        } else {
          // End of time should be at least 105 min
          return 6300000;
        }
      case EventTypeEnum.SecondExtraStart:
        // 105 min + 1 millisecond
        return 6300001;
      case EventTypeEnum.Extra30:
        // 120 min (105 + 15)
        return 7200000;
      case EventTypeEnum.SecondExtraEnd:
        // If there additionnal time after 120 min, use it
        if (matchTime.matchTime > 7200000) {
          return matchTime.matchTime;
        } else {
          // End of time should be at least 120 min
          return 7200000;
        }
      case EventTypeEnum.Penalty:
        // Always 120 min
        return 7200000;
      case EventTypeEnum.MatchEnd:
      default:
        return matchTime.matchTime;
    }
  }

  /**
   * Get the current round by eventType. If event type is not able to determine it, it uses the match time data.
   */
  public getRound(eventType: EventTypeEnum, matchTime: MatchTime): number {
    switch (eventType) {
      // First round
      case EventTypeEnum.MatchStart:
      case EventTypeEnum.Match45:
      case EventTypeEnum.FirstHalfEnd:
        return 1;
      // Second round
      case EventTypeEnum.SecondHalfStart:
      case EventTypeEnum.Match90:
      case EventTypeEnum.SecondHalfEnd:
        return 2;
      // First extra round
      case EventTypeEnum.FirstExtraStart:
      case EventTypeEnum.Extra15:
      case EventTypeEnum.FirstExtraEnd:
        return 3;
      // Second extra round
      case EventTypeEnum.SecondExtraStart:
      case EventTypeEnum.Extra30:
      case EventTypeEnum.SecondExtraEnd:
        return 4;
      // Penalty shoot-out
      case EventTypeEnum.Penalty:
        return 5;
      // We don't know, so it will be the values of the last event.
      case EventTypeEnum.MatchEnd:
      case EventTypeEnum.Realign:
        return matchTime.lastEvent.round;
    }
  }

  /**
   * Get the additionnal time by eventType. If event type is not able to determine it, it uses the match time data.
   */
  public getAdditionalTime(
    eventType: EventTypeEnum,
    matchTime: MatchTime
  ): boolean {
    switch (eventType) {
      case EventTypeEnum.MatchStart:
      case EventTypeEnum.FirstHalfEnd:
      case EventTypeEnum.SecondHalfStart:
      case EventTypeEnum.SecondHalfEnd:
      case EventTypeEnum.FirstExtraEnd:
      case EventTypeEnum.SecondExtraEnd:
      case EventTypeEnum.MatchEnd:
      case EventTypeEnum.FirstExtraStart:
      case EventTypeEnum.SecondExtraStart:
      case EventTypeEnum.Penalty:
        return false;
      case EventTypeEnum.Match45:
      case EventTypeEnum.Match90:
      case EventTypeEnum.Extra15:
      case EventTypeEnum.Extra30:
        return true;
      case EventTypeEnum.Realign:
        return matchTime.lastEvent.isAdditionalTime;
    }
  }
}
