import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KeyPointLabel, keyPoints } from '@match-fix/shared';

@Component({
  selector: 'app-key-point-type',
  templateUrl: './key-point-type.component.html',
  styleUrls: ['./key-point-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyPointTypeComponent {

  @Input() public set type(type: string) {
    this.keyPoint = keyPoints.find(({ value }) => value === type);
  }

  public keyPoint: KeyPointLabel;

}
