import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AnalysisState, KeyPointNavigationState } from '../../store/analysis.interfaces';
import { getNavigationState } from '../../store/analysis.selectors';
import { LoadKeyPoint } from '../../store/analysis.actions';

@Component({
  selector: 'key-point-navigation',
  templateUrl: './key-point-navigation.component.html',
  styleUrls: ['./key-point-navigation.styles.scss']
})
export class KeyPointNavigationComponent implements OnInit, OnDestroy {

  data: KeyPointNavigationState = { next: null, prev: null };

  private subscription: Subscription;

  constructor(
    private readonly store$: Store<AnalysisState>,
  ) { }

  ngOnInit() {
    this.subscription = this.store$.select(getNavigationState)
      .subscribe(data => this.data = data);
  }

  navigateKeyPoint(id: number) {
    if (!id) { return; }

    this.store$.dispatch(new LoadKeyPoint({ eventListId: id }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
