import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CompetitionDetailComponent } from '../components/smart/competition-detail/competition-detail.component';
import { deleteCompetitionSuccess, upsertCompetition, upsertCompetitionSuccess } from './competitions.actions';
import { TcNotificationService, TcTranslateService } from '@tc/core';

@Injectable()
export class CompetitionsEffects {

    constructor(
        private actions$: Actions,
        private dialog: MatDialog,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService
    ) { }

    upsertCompetition = createEffect(() => this.actions$.pipe(
        ofType(upsertCompetition),
        tap(({ competition }) => {
            this.dialog.open(CompetitionDetailComponent, {
                height: '450px',
                width: '414px',
                data: competition,
                // position: { top: '17%', left: '40%' }
            });
        })),
        { dispatch: false }
    );

    upsertCompetitionSuccess = createEffect(() => this.actions$.pipe(
        ofType(upsertCompetitionSuccess),
        tap(({ competition }) => {
            if (competition.id) {
                this.notificationService.success(this.translateService.instant('competition-detail.alerts.save-success'));
            } else {
                this.notificationService.success(this.translateService.instant('competition-detail.alerts.edit-success'));
            }
        })),
        { dispatch: false }
    );

    deleteCompetitionSuccess = createEffect(() => this.actions$.pipe(
        ofType(deleteCompetitionSuccess),
        tap(() => {
            this.notificationService.success(this.translateService.instant('competitions-list.alerts.delete-success'));
        })),
        { dispatch: false }
    );
}
