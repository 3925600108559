import { Injectable } from '@angular/core';
import { Chrono } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { skipWhile, take } from 'rxjs/operators';
import { getMatchVideos } from '../modules/match/store/matchs.selectors';
import { ConfigKeys } from '../modules/shared/interfaces/config.interface';
import { ConfigService } from '../modules/shared/services/config.service';

export interface VideoLocal {
  src?: string;
  isPrimary: boolean;
  startChrono: Partial<Chrono>;
  endChrono: Partial<Chrono>;
  videoTime: number;
  fileIdentifier: string;
}

@Injectable({
  providedIn: 'root'
})
export class MatchVideosService {

  private readonly urlByFileIdentifier = {
    "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa": "https://matchfix.tanacorp.com/videos/1.mp4",
    "bbbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb": "https://matchfix.tanacorp.com/videos/2.mp4",
    "cccccccc-cccc-cccc-cccc-cccccccccccc": "https://matchfix.tanacorp.com/videos/3.mp4",
    "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx": "https://matchfix.tanacorp.com/videos/output.mkv",
  };

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly config: ConfigService,
  ) { }

  public async getVideosFromStore(): Promise<VideoLocal[]> {
    const videos = await this.store$
      .select(getMatchVideos)
      .pipe(
        skipWhile(v => !v || !v.length),
        take(1),
      ).toPromise();

    return videos.map(v => ({
      src: this.getVideoUrl(v.fileIdentifier),
      isPrimary: v.isPrimary,
      startChrono: v.startChrono,
      endChrono: v.endChrono,
      videoTime: 0,
      fileIdentifier: v.fileIdentifier
    }));
  }

  public getVideoUrl(identifier: string): string {
    // TODO: remove hardocoded values once storage will be persistent
    if (this.urlByFileIdentifier[identifier]) {
      return this.urlByFileIdentifier[identifier];
    }

    const base = `${this.config.get(ConfigKeys.API_BASE_PATH)}/storage`;
    return `${base}/${identifier}`;
  }

}
