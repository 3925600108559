import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { StadiumListComponent } from './components/smart/stadium-list/stadium-list.component';
import { StadiumDetailComponent } from './components/smart/stadium-detail/stadium-detail.component';
import { EffectsModule } from '@ngrx/effects';
import { StadiumsEffects } from './store/stadiums.effects';

const smartComponents = [StadiumListComponent, StadiumDetailComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([StadiumsEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [StadiumDetailComponent]
})
export class StadiumsModule { }
