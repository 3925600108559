import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { TcGenericListComponent, TcListRowActionButtonsPosition, TcSmartComponent, TcListSortType, TcListFilterType } from '@tc/core';
import { Store } from '@ngrx/store';
import { EntitiesEnum, Organisation, CustomEntitiesEnum } from '@match-fix/shared';
import { upsertOrganisation, deleteOrganisationSuccess } from '../../../store/organisations.actions';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(
    private store: Store<any>,
    private readonly refreshService: ListRefreshService,
  ) {
    super();
  }

  organisationsList: TcGenericListComponent<any>;
  @ViewChild('organisationsList', { static: true }) set setOrganisationsList(values: TcGenericListComponent<Organisation>) {
    this.organisationsList = values;
    this.organisationsList.entityName = CustomEntitiesEnum.CustomOrganisation;
  }
  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.refresh();
  }

  ngOnInit() {
    this.organisationsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.organisationsList.showTotalInActionsHeader = false;

    this.organisationsList.sortType = TcListSortType.Server;
    this.organisationsList.filterType = TcListFilterType.Server;
    this.organisationsList.hasFixedHeader = true;

    this.organisationsList.hasAddButton = false;
    this.organisationsList.addItemWhenKeyPresed = true;

    this.organisationsList.hasActionsLabel = true;
    this.organisationsList.isPaged = false;

    this.organisationsList.onScrollDown = () => {
      this.organisationsList.service.getAll();
    };

    this.organisationsList.columns = [
      {
        propertyName: 'code',
        visible: true
      },
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'countryName',
        visible: true
      },
      // {
      //   propertyName: 'organisationCode',
      //   visible: true
      // }
    ];

    this.organisationsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.organisationsList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editOrganisation(row);
      }
    };

    this.organisationsList.onRowClick = (row: any) => {
      this.editOrganisation(row);
    };

    (this.organisationsList as any).afterDeleteSuccess = (row: any) => {
      this.store.dispatch(deleteOrganisationSuccess({}));
    };

    this.refreshService.subject.pipe(
      filter(e => e === ListRefreshType.Organisations)
    )
    .subscribe(() => this.refresh());
  }

  addItem() {
    this.store.dispatch(upsertOrganisation({}));
  }

  public delete = (row: any) => {
    this.organisationsList.tcList.onDeleteAction(row);
  }

  private editOrganisation(row: any) {
    this.store.dispatch(upsertOrganisation({ organisation: row }));
  }

  private refresh() {
    this.organisationsList.service.clearCache();
    this.organisationsList.service.getWithQuery({ initial: 'true' });
  }

}
