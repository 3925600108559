import { Component, OnInit } from '@angular/core';
import { ConsultationStatusData, KeyPointConsultationItem } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState, TcSmartComponent } from '@tc/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { getExpertAnalysisConsultationMatchId } from '../../../expert-analysis/store/expert-analysis.selectors';
import { ExpertAnalysisConsultationService } from '../../services/expert-analysis-consultation.service';

@Component({
  selector: 'app-expert-analysis-consultation',
  templateUrl: './expert-analysis-consultation.component.html',
  styleUrls: ['./expert-analysis-consultation.component.scss']
})
export class ExpertAnalysisConsultationComponent extends TcSmartComponent implements OnInit {

  public readonly keypoints$ = new Subject<KeyPointConsultationItem[]>();
  public readonly statusList$ = new Subject<ConsultationStatusData[]>();
  public expertConclusion: string;
  public teamTitle: string;

  private matchId: number;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly expertAnalysisConsultationService: ExpertAnalysisConsultationService,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
  }

  public async refresh() {
    if (!this.matchId) {
      await this.initMatchId();
    }

    this.reloadListData();
  }

  private async reloadListData() {
    const items = await this.expertAnalysisConsultationService.getKeyPointList(this.matchId);
    const status = await this.expertAnalysisConsultationService.getStatus(this.matchId);

    this.expertConclusion = status[0]?.expertConclusion;
    this.teamTitle = `Team A: ${status[0].teamA} vs Team B: ${status[0].teamB}`;

    this.keypoints$.next(items || []);
    this.statusList$.next(status ? status : []);
  }

  private async initMatchId() {
    this.matchId = await this.store$.select(getExpertAnalysisConsultationMatchId).pipe(take(1)).toPromise();
  }

}
