import { Event } from '..';

export class EventType {
  static className = 'Event';
  static gqlRootQueryName = 'event';
  static gqlColumns: string[] = [
      '_id',
      'type',
  ];

  static gqlNodeMapper = (node: any): Event => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Event;
    }

}
