import { User } from '..';

export class UserType {
  static className = 'User';
  static gqlRootQueryName = 'user';
  static gqlColumns: string[] = [
      '_id',
      'role',
      'identityDocumentType',
      'active',
      'birthDate',
      'code',
      'email',
      'firstName',
      'identityDocumentNumber',
      'languageCode',
      'lastName',
      'password',
      'phone',
      'googleAuthSecret',
      'signatureFileIdentifier',
  ];

  static gqlNodeMapper = (node: any): User => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as User;
    }

}
