import { Component, OnInit, Optional, Inject, ElementRef } from '@angular/core';
import {
  TcGenericFormComponent,
  TcTranslateService,
  TcNotificationService
} from '@tc/core';
import { ListValue, Stadium, EntitiesEnum } from '@match-fix/shared';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Store } from '@ngrx/store';
import { FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StadiumDetailComponent } from '../../../../stadiums/components/smart/stadium-detail/stadium-detail.component';
import { upsertListValueSuccess } from '../../../store/configuration.actions';
import { ListValuesService } from './../../../../../services/business-services/list-values.service';

@Component({
  selector: 'app-configuration-detail',
  templateUrl: './configuration-detail.component.html',
  styleUrls: ['./configuration-detail.component.scss']
})
export class ConfigurationDetailComponent
  extends TcGenericFormComponent<ListValue>
  implements OnInit {
  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private notificationService: TcNotificationService,
    private listValuesService: ListValuesService,
    private store: Store<any>,
    private dialogRef: MatDialogRef<StadiumDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ListValue,
    elem: ElementRef
  ) {
    super(
      EntitiesEnum.ListValues,
      entityCollectionServiceFactory,
      translate,
      elem
    );

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'value',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
            modelOptions: {
              updateOn: 'blur'
            }
          }
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }

    this.afterSave = model => {
      this.store.dispatch(upsertListValueSuccess({ listValue: model }));
      this.onClose();
    };
  }

  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }

  async submit(model: ListValue) {
    if (!model.value) {
      return;
    }

    if (this.checkExtension()) {
      this.notificationService.error('An extension cannot contain period (.) character');
      return;
    }

    if (await this.alreadyExists()) {
      this.notificationService.error('Extension already exists. We dont allow duplicate extensions');
      return;
    }

    if (!(await this.validExtensionId())) {
      this.notificationService.error('Another row with key already exists!');
      return;
    }

    model.listTypeId = 8;
    model.value = model.value.toLowerCase();
    model.key = model.value;

    super.submit(model);
  }

  checkExtension = () => {
    return this.form.controls.value.value?.indexOf('.') > -1;
  };

  alreadyExists = async () => {
    const listValueEntity = await this.getListValueEntity(
      this.form.controls.value.value.toLowerCase()
    );

    return !!listValueEntity;
  };

  validExtensionId = async () => {
    if (this.model.id) {
      const listValueEntity = await this.getListValueEntity(
        this.form.controls.value.value.toLowerCase()
      );

      if (listValueEntity === null) {
        return true;
      }

      if (listValueEntity.id !== this.model.id) {
        this.notificationService.error('Extension already taken');

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  getListValueEntity = async (key: string) => {
    const listValueEntity = await this.listValuesService.getByKey(key);

    return listValueEntity;
  };
}
