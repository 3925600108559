import { UserModel } from '@match-fix/shared';
import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Authentication] Login',
  props<{ token: string; code: string }>()
);

export const loginSuccess = createAction(
  '[Authentication] Login Success',
  props<{ user: UserModel }>()
);

export const loginFailure = createAction(
  '[Authentication] Login Failure',
  props<{ error: string; }>()
);

export const logout = createAction(
  '[Authentication] Logout'
);

export const clearUser = createAction(
  '[Authentication] Remove user from store'
);

export const getTokenSuccess = createAction(
  '[Authentication] Get token success',
  props<{ token: string; }>()
);

export const resetErrorMessage = createAction(
  '[Authentication] reset error message'
);
