import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TcService } from '@tc/core';
import { ImagePreviewComponent } from '../../modules/main/components/dumb/image-preview/image-preview.component';

@Injectable({
    providedIn: 'root'
})
export class ImagePreviewService extends TcService {

    constructor(
        private readonly dialog: MatDialog,
    ) {
        super();
    }

    public open(data: any) {
        this.dialog.open(ImagePreviewComponent, {
            maxWidth: '95vw',
            maxHeight: '95vh',
            data: data,
        });
    }

}
