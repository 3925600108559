import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamPlayerDeficiencyCategoryEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcConfirmDialogComponent, TcListComponent, TcListFilterType, TcListSortType, TcSmartComponent } from '@tc/core';
import { of } from 'rxjs';
import { SaveCategorizations, loadKeyPointsClassification } from '../../store/expert-analysis.actions';
import { ExpertAnalysisState, PlayersCategorization, TeamsPlayersCategorization } from '../../store/expert-analysis.interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'players-categorization',
    templateUrl: './players-categorization.component.html',
    styleUrls: ['./players-categorization.styles.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlayersCategorizationComponent extends TcSmartComponent implements OnInit {

    values = {};

    disabled = true;
    options: { value: TeamPlayerDeficiencyCategoryEnum }[] = Object
        .keys(TeamPlayerDeficiencyCategoryEnum)
        .map(type => ({ value: TeamPlayerDeficiencyCategoryEnum[type] }))

    @ViewChild('listNumberRef', { static: true }) private listNumberRef: TemplateRef<any>;
    @ViewChild('listNameRef', { static: true }) private listNameRef: TemplateRef<any>;
    @ViewChild('categoryNameRef', { static: true }) private categoryNameRef: TemplateRef<any>;

    teamAList: TcListComponent;
    @ViewChild('teamAList', { static: true }) set setTeamAList(values: TcListComponent) {
        this.teamAList = values;
    }

    teamBList: TcListComponent;
    @ViewChild('teamBList', { static: true }) set setTeamBList(values: TcListComponent) {
        this.teamBList = values;
    }

    constructor(
        private readonly dialog: MatDialog,
        private readonly translate: TranslateService,
        private readonly store$: Store<ExpertAnalysisState>,
        private dialogRef: MatDialogRef<PlayersCategorizationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: TeamsPlayersCategorization,
    ) {
        super();
    }

    ngOnInit() {
        this.initList(this.teamAList, this.data.teamA);
        this.initList(this.teamBList, this.data.teamB);
    }

    onClose() {
        this.dialogRef.close();
    }

    save() {
        this.store$.dispatch(
            new SaveCategorizations(this.data),
        );

        this.store$.dispatch(loadKeyPointsClassification({}))
    }

    async select(row: any, value: TeamPlayerDeficiencyCategoryEnum) {
        this.values[row.teamPlayerId] = value;

        let res = true;

        const player = [...this.data.teamA, ...this.data.teamB]
            .find(pl => pl.teamPlayerId === row.teamPlayerId);

        if (!row.deficienciesCount && value === TeamPlayerDeficiencyCategoryEnum.Involved) {
            res = await this.confirmAction();
        }

        if (!res) {
            this.values[row.teamPlayerId] = null;
            player.deficiencyCategory = null;

            return;
        }

        player.deficiencyCategory = value;

        this.checkDisabled();
    }

    private confirmAction(): Promise<boolean> {
        return new Promise(async res => {
            const dialog = this.dialog.open(
                TcConfirmDialogComponent,
                {
                    data: {
                        noText: this.translate.instant('player-catgorization.confirmation.noText'),
                        title: this.translate.instant('player-catgorization.confirmation.title'),
                        yesText: this.translate.instant('player-catgorization.confirmation.yesText'),
                        message: this.translate.instant('player-catgorization.confirmation.message'),
                    },
                    panelClass: 'categorization-confirm-dialog',
                }
            );

            const result = await dialog.afterClosed().toPromise();

            res(result === 'yes');
        })
    }

    private checkDisabled() {
        this.disabled = [...this.data.teamA, ...this.data.teamB]
            .some(pl => !pl.deficiencyCategory);
    }

    private initList(component: TcListComponent, items: PlayersCategorization[]) {
        component.rows$ = of(items)
        component.isFiltrable = false;
        component.filterType = TcListFilterType.Disabled;
        component.sortType = TcListSortType.Disabled;
        component.isPaged = false;
        component.hasFixedHeader = true;
        component.hasAddButton = false;

        component.columns = [{
            propertyName: 'number',
            visible: true,
            htmlTemplate: this.listNumberRef,
        }, {
            propertyName: 'name',
            visible: true,
            htmlTemplate: this.listNameRef,
        }, {
            propertyName: 'deficienciesCount',
            visible: true,
        }, {
            propertyName: 'deficiencyCategory',
            visible: true,
            htmlTemplate: this.categoryNameRef,
        },];
    }

}
