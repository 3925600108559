import { Component, OnInit, Input } from '@angular/core';
import { TcVersionService } from '../../services/tc-version.service';

@Component({
  selector: 'tc-version',
  templateUrl: './tc-version.component.html',
  styleUrls: ['./tc-version.component.scss']
})
export class TcVersionComponent implements OnInit {
  version: string;

  constructor(private readonly versionService: TcVersionService) { }

  ngOnInit(): void {
    this.version = this.versionService.getVersion();

    //todo use logger
    console.log("Current app version: ", this.version);
  }

}
