import { Injectable, Optional, Inject } from '@angular/core';
import { ListValue, EntitiesEnum } from '@match-fix/shared';
import { CrudService } from '../crud.service';
import { HttpClient } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ListValuesService extends CrudService<ListValue> {
  constructor(
    public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(EntitiesEnum.ListValues, httpClient, basePath, configuration);
  }

  async getByKey(key: string): Promise<ListValue> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<ListValue>(`${url}/key/${key}`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      })
      .toPromise();
  }

  async getListValuesByListTypeId(listTypeId: number): Promise<ListValue[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<ListValue[]>(`${url}/list-type/${listTypeId}`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      })
      .toPromise();
  }
}
