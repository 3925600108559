import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadTypeEnum } from '@match-fix/shared';
import { ConfigKeys } from '../interfaces/config.interface';
import { UploadResponse } from '../interfaces/upload.interface';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
  ) { }

  public upload(file: File, path: string, type: UploadTypeEnum) {
    const formData = new FormData()
    formData.append('file', file, path)

    const url = `${this.config.get(ConfigKeys.API_BASE_PATH)}/file?type=${type}`;
    return this.http.request<UploadResponse>(
      new HttpRequest('POST', url, formData, { reportProgress: true })
    )
  }
}
