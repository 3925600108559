import { Document } from '..';

export class DocumentType {
  static className = 'Document';
  static gqlRootQueryName = 'document';
  static gqlColumns: string[] = [
      '_id',
      'matchId',
      'fileIdentifier',
      'fileName',
      'report',
  ];

  static gqlNodeMapper = (node: any): Document => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Document;
    }

}
