import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { take } from 'rxjs/operators';
import { getChronoTime } from '../../../../utils/getChronoTime';
import { isChronoValide } from '../../../../utils/validateChrono';
import { ExpertAnalysisService } from '../../services/expert-analysis.service';
import { getExpertAnalysisMatchStatus } from '../../store/expert-analysis.selectors';

@Component({
  selector: 'app-note-key-point-filters',
  templateUrl: './note-key-point-filters.component.html',
  styleUrls: ['./note-key-point-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NoteKeyPointFiltersComponent extends TcFormComponent<any> implements OnInit {

  @Output() public filtersChange: EventEmitter<any> = new EventEmitter();

  private initialModel = {
    startPeriod: '00:00',
    endPeriod: '00:00',
    teams: [],
    players: [],
    referees: [],
    statuses: [],
    implications: [],
    keyPointType: [],
    deficiencies: [],
  };
  private filters;

  constructor(
    public readonly elem: ElementRef,
    public readonly store: Store<any>,
    public readonly translate: TcTranslateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly expertAnalysisService: ExpertAnalysisService,
  ) {
    super(translate, elem);

    this.fields = [
      this.chronoInputField('startPeriod'),
      this.chronoInputField('endPeriod'),
      this.selectField('teams', 'id', []),
      this.selectField('players', 'id', []),
      this.selectField('referees', 'id', []),
      this.selectField('statuses', 'label', []),
      this.selectField('implications', 'label', []),
      this.selectField('keyPointType', 'value', []),
      this.selectField('deficiencies', 'value', []),
      this.buttonField('clear', true, () => this.resetModel()),
    ];

    this.getFiltersData();
  }

  ngOnInit() {
    this.resetModel(true);
  }

  private async getFiltersData() {
    const analysisStatus = await this.store.select(getExpertAnalysisMatchStatus).pipe(take(1)).toPromise();
    this.filters = await this.expertAnalysisService.getFilters(analysisStatus);

    this.fields = [
      this.chronoInputField('startPeriod'),
      this.chronoInputField('endPeriod'),
      this.selectField('teams', 'id', this.filters.teams.map(filter => ({ ...filter, value: filter.name, label: filter.name }))),
      this.selectField('players', 'id', this.filters.players.map(filter => ({ ...filter, value: filter.name, label: filter.name }))),
      this.selectField('referees', 'id', this.filters.referees.map(filter => ({ ...filter, value: filter.role, label: filter.role }))),
      this.selectField('statuses', 'label', this.filters.statuses.map(status => ({ label: status }))),
      this.selectField('implications', 'label', this.filters.implications.map(filter => ({ ...filter, label: filter.value }))),
      this.selectField('keyPointType', 'value', this.filters.keyPoints),
      this.selectField('deficiencies', 'value', this.filters.deficiencies),
      this.buttonField('clear', true, () => this.resetModel(true)),
    ];

    this.model = {
      ...this.model,
      endPeriod: this.filters.endPeriod,
    };

    this.cdr.detectChanges();
  }

  public resetModel(resetPeriod = false) {
    const model = { ...this.model, ...this.initialModel };

    this.model = resetPeriod && this.filters?.endPeriod ? { ...model, endPeriod: this.filters.endPeriod } : model;

    this.editFilterAndEmit();
    this.cdr.detectChanges();
  }

  private editFilterAndEmit() {
    const model = {
      ...this.model,
      startPeriod: getChronoTime(this.model.startPeriod).seconds * 1000,
      endPeriod: getChronoTime(this.model.endPeriod).seconds * 1000
    }

    this.filtersChange.emit(model);
  }

  private selectField(key: string, valueProp = 'id', options = []): FormlyFieldConfig {
    const label = this.translate.instant(`note-key-point-filters.labels.${key}`);

    return ({
      key: key,
      type: 'select',
      templateOptions: {
        label,
        valueProp,
        multiple: true,
        options: options,
        change: () => this.editFilterAndEmit()
      }
    });
  }

  private buttonField(key: string, outline: boolean, action): FormlyFieldConfig {
    const label = this.translate.instant(`note-key-point-filters.buttons.${key}`);

    return ({
      key: key,
      type: 'button-formly',
      templateOptions: {
        label,
        outline,
        onClick: action
      }
    });
  }

  private setPeriodValue(key: string) {
    const startPeriod = (this.model.startPeriod || '').replace(':', '');
    const endPeriod = (this.model.endPeriod || '').replace(':', '');

    const startValue = getChronoTime(startPeriod);
    const endValue = getChronoTime(endPeriod);

    if (startValue.seconds > endValue.seconds) {
      this.model = {
        ...this.model,
        startPeriod: startValue.mmss.split(':').join(''),
        endPeriod: endValue.mmss.split(':').join(''),
      }
    }

    this.editFilterAndEmit();
  }

  private chronoInputField(key: string): FormlyFieldConfig {
    const label = this.translate.instant(`note-key-point-filters.labels.${key}`);

    return ({
      key: key,
      type: 'chrono-input',
      className: `input-${key}`,
      templateOptions: {
        label,
        modelKey: key,
        setValue: (value) => {
          if (isChronoValide(value)) {
            this.setPeriodValue(key);
          }

          if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
          }
        },
      },
      validators: {
        [key]: {
          expression: (c) => isChronoValide(c.value),
        },
      },
    });
  }

}
