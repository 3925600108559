import { NoteKeyPointListItem, NoteKeyPointState } from '@match-fix/shared';
import {
  ExpertAnalysisActionTypes,
  ExpertAnalysisStoreActions
} from './expert-analysis.actions';
import { AssembleState, ExpertAnalysisState, UpdateNoteKeyPointSuccessPayload } from './expert-analysis.interfaces';

export const defaultExpertAnalysisState: ExpertAnalysisState = {
  noteMatchId: null, // Id of the match
  noteMatchBreadcrumbName: '',
  reportMatchId: '',
  noteKeypoint: null,
  assemble: null,
  noteKeyPointList: null,
  expertAnalysisConsultationMatchId: null,
  isReadOnly: false,
  matchAnalysisState: null,
  categorizations: null,
};

/**
 * Main reducer function
 */
export function expertAnalysisStoreReducer(
  state = defaultExpertAnalysisState,
  action: ExpertAnalysisStoreActions
) {
  switch (action.type) {
    case ExpertAnalysisActionTypes.CLEAR_EXPERT_KEY_POINT: {
      return clearExpertKeyPoint(state);
    }
    case ExpertAnalysisActionTypes.MANAGE_EXPERT_KEY_POINT: {
      return manageExpertKeyPoint(state, action.payload.noteKeyPoint);
    }
    case ExpertAnalysisActionTypes.CLEAR_ASSEMBLE: {
      return clearAssemble(state);
    }
    case ExpertAnalysisActionTypes.MANAGE_ASSEMBLE: {
      return manageAssemble(state, action.payload.assemble);
    }
    case ExpertAnalysisActionTypes.SET_NOTE_MATCH_ID: {
      return setNoteMatchId(state, action.payload.matchId, action.payload.matchBreadcrumbName);
    }
    case ExpertAnalysisActionTypes.SET_REPORT_MATCH_ID: {
      return setReportMatchId(state, action.payload.matchId);
    }
    case ExpertAnalysisActionTypes.SET_EXPERT_ANALYSIS_CONSULTATION_MATCH_ID: {
      return setExpertAnalysisConsulattionMatchId(state, action.payload.matchId);
    }
    case ExpertAnalysisActionTypes.GET_NOTE_KEY_POINT_SUCCESS: {
      return setNoteKeyPointList(state, action.payload.noteKeyPointList);
    }
    case ExpertAnalysisActionTypes.SET_EXPERT_ANALYSIS_READONLY: {
      return setExpertAnalysisReadonly(state, action.payload);
    }
    case ExpertAnalysisActionTypes.SET_MATCH_ANALYSIS_STATUS: {
      return {
        ...state,
        matchAnalysisState: action.payload,
      }
    }
    case ExpertAnalysisActionTypes.UPDATE_NOTE_KEY_POINT_SUCCESS: {
      return updateNoteKeyPointSuccess(state, action.payload);
    }
    case ExpertAnalysisActionTypes.SAVE_CATEGORIZATIONS: {
      return {
        ...state,
        categorizations: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export function clearExpertKeyPoint(state) {
  const newState = { ...state, noteKeypoint: null };
  return newState;
}

export function manageExpertKeyPoint(state, noteKeyPoint: NoteKeyPointState) {
  const newState = { ...state, noteKeypoint: noteKeyPoint };
  return newState;
}

export function clearAssemble(state) {
  const newState = { ...state, assemble: null };
  return newState;
}

export function manageAssemble(state, assemble: AssembleState) {
  const newState = { ...state, assemble: assemble };
  return newState;
}

export function setNoteMatchId(state, matchId: number, matchBreadcrumbName: string) {
  const newState = {
    ...state,
    noteMatchId: matchId,
    noteMatchBreadcrumbName: matchBreadcrumbName
  };
  return newState;
}

export function setReportMatchId(state, matchId: number) {
  const newState = {
    ...state,
    reportMatchId: matchId,
  };
  return newState;
}

export function setExpertAnalysisConsulattionMatchId(state, matchId: number) {
  return {
    ...state,
    expertAnalysisConsultationMatchId: matchId,
  };
}

export function setExpertAnalysisReadonly(state, isReadOnly) {
  return {
    ...state,
    isReadOnly: isReadOnly,
  };
}

export function setNoteKeyPointList(state, noteKeyPointList: NoteKeyPointListItem[]) {
  const newState = { ...state, noteKeyPointList };
  return newState;
}

export function updateNoteKeyPointSuccess(state: ExpertAnalysisState, payload: UpdateNoteKeyPointSuccessPayload): ExpertAnalysisState {
  return {
    ...state,
    noteKeyPointList: state.noteKeyPointList.map(noteKeyPoint => {
      if (noteKeyPoint.id !== payload.keyPointId) {
        return noteKeyPoint;
      }

      return {
        ...noteKeyPoint,
        invalidatedExpert: payload?.invalidatedExpert ?? noteKeyPoint.invalidatedExpert,
        status: payload?.keyPointAnalysisStatus ?? noteKeyPoint.status,
        var: payload?.var ?? noteKeyPoint.var,
      };
    }),
  };
}
