import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { take } from 'rxjs/operators';
import { InitExpertAnalysis } from '../../modules/expert-analysis/store/expert-analysis.actions';
import { getMatchBreadcrumbName, getNoteMatchId } from '../../modules/expert-analysis/store/expert-analysis.selectors';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';

@Component({
  selector: 'app-note-key-point-page',
  templateUrl: './note-key-point-page.component.html',
  styleUrls: ['./note-key-point-page.component.scss']
})
export class NoteKeyPointPageComponent extends TcPage implements OnInit {

  constructor(
    public readonly store: Store<any>,
  ) {
    super(store);
  }

  async ngOnInit() {
    const matchId = await this.store.select(getNoteMatchId).pipe(take(1)).toPromise();
    const matchBreadcrumbName = await this.store.select(getMatchBreadcrumbName).pipe(take(1)).toPromise();

    this.store.dispatch(new InitExpertAnalysis({ matchId, matchBreadcrumbName }));
    this.store.dispatch(getBreadcrumb({ route: RouteEnum.NoteKeyPoint, name: matchBreadcrumbName }));
  }

}
