import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ActorListTypeEnum,
  allDeficiencyStatusesList,
  allPlayerDeficiencyList,
  allRefereeDeficiencyList,
  ChronoItem,
  KeypointAnalysisStatusEnum,
  keyPoints,
  KeyPointTypeEnum,
  UserRoleEnum
} from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcFormComponent,
  TcNotificationService,
  TcTranslateService
} from '@tc/core';
import { get } from 'lodash';
import { Subscription, timer } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { getChronoTime } from '../../../../utils/getChronoTime';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import {
  normalizeTime,
  normalizeTimeMinutes
} from '../../../../utils/normalizeTimeFormat';
import { isChronoValide } from '../../../../utils/validateChrono';
import { AnalysisService } from '../../services/analysis.service';
import { ChronoService } from '../../services/chrono.service';
import { DeficiencyItem, KeyPointItem } from '../../store/analysis.interfaces';
import {
  getActors,
  getAnalysisStoreState,
  getTeams
} from '../../store/analysis.selectors';
import {
  CancelKeyPoint,
  ManageKeyPoint,
  SaveKeyPoint
} from './../../store/analysis.actions';

interface DeficiencyItemLocal extends Omit<DeficiencyItem, 'chrono'> {
  chrono: string;
  timeline: string;
}

@Component({
  selector: 'app-key-point-details',
  templateUrl: './key-point-details.component.html',
  styleUrls: ['./key-point-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyPointDetailsComponent extends TcFormComponent<any> implements OnInit, OnDestroy {

  @ViewChild('deficienciesList', { static: false }) deficienciesRef: ElementRef;

  public keypoint: KeyPointItem;
  public deficiencies: DeficiencyItemLocal[] = [];
  public startTime = 0;
  public canSave = true;
  public canNavigate = false;
  public canAddDeficiencies = false;
  public timelineDifference = 0;
  public dialogTitle = 'key-point-details.headers.main';

  private teamsSubscription: Subscription;
  private playersSubscription: Subscription;

  private changedTimeline: boolean;
  private changedChronoStart: boolean;
  private changedChronoEnd: boolean;

  private hasDeficiencyRole = true;

  constructor(
    public elem: ElementRef,
    public translate: TcTranslateService,
    private readonly store$: Store<any>,
    private readonly chronoService: ChronoService,
    private readonly analysisService: AnalysisService,
    private readonly notificationService: TcNotificationService,
    private readonly dialogRef: MatDialogRef<KeyPointDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(translate, elem);
    this.canNavigate = !!data?.payload?.keyPoint?.id;

    if (data?.payload?.keyPoint?.id) {
      this.dialogTitle = 'key-point-details.headers.edit';
    }

    this.initMainFields();
  }

  async ngOnInit() {
    super.ngOnInit();

    this.keypoint = JSON.parse(JSON.stringify(this.data.payload.keyPoint));

    this.model.type = this.keypoint.keyPointType;
    this.model.label = this.keypoint.keyPointTypeLabel;
    this.model.timeline = convertToReadableFormat(
      this.keypoint.startChrono.videoTime,
      'hh:mm:ss'
    );
    this.model.chronoStart = convertToReadableFormat(
      this.keypoint.startChrono.matchTime,
      'mm:ss'
    );
    this.model.chronoEnd = convertToReadableFormat(
      this.keypoint.endChrono.matchTime,
      'mm:ss'
    );
    this.model.var = this.keypoint?.keyPointAnalysis?.var;
    this.model.teamId = this.keypoint?.teamId;
    this.model.teamPlayerId = this.keypoint?.teamPlayerId;
    this.model.teamPlayerOutId = this.keypoint?.teamPlayerOutId;
    this.model.teamPlayerInId = this.keypoint?.teamPlayerInId;
    this.timelineDifference =
      this.keypoint.startChrono.videoTime - this.keypoint.startChrono.matchTime;

    this.startTime = (this.keypoint?.startChrono?.videoTime || 0);

    this.store$
      .select(getAnalysisStoreState)
      .pipe(
        skipWhile(() => !this.fields.length),
        take(1)
      )
      .subscribe(store => {
        if (store.analysisRole === UserRoleEnum.Operator) {
          this.disableFields(this.fields);
          this.manageEditingKeyPoint();
        }

        if (this.keypoint.keyPointAnalysis) {
          this.canAddDeficiencies =
            store.analysisRole === UserRoleEnum.Operator &&
            (store.userIdByAnalysisId[store.analysisId] === store.operator1Id ||
              store.userIdByAnalysisId[store.analysisId] === store.operator2Id);
        }
      });

    this.teamsSubscription = this.store$.select(getTeams).subscribe(teams => {
      const fieldIndex = this.fields.findIndex(f => f.key === 'teamId');
      if (fieldIndex >= 0) {
        this.fields[fieldIndex].templateOptions.options = teams.map(team => ({
          ...team,
          label: (team as any).code,
          value: team.id
        }));
      }
    });

    this.playersSubscription = this.store$
      .select(getActors)
      .subscribe(actors => {
        const fieldTeamPlayerIdIndex = this.fields.findIndex(
          f => f.key === 'teamPlayerId'
        );
        const fieldTeamPlayerOutIdIndex = this.fields.findIndex(
          f => f.key === 'teamPlayerOutId'
        );
        const fieldTeamPlayerInIdIndex = this.fields.findIndex(
          f => f.key === 'teamPlayerInId'
        );
        if (fieldTeamPlayerIdIndex >= 0) {
          this.fields[fieldTeamPlayerIdIndex].templateOptions.options = actors
            .filter(actor => actor?.type !== ActorListTypeEnum.referee)
            .map(actor => ({
              ...actor,
              label: this.analysisService.getActorLabel(actor),
              value: actor.id
            }));
        }
        // TODO find a better way
        if (fieldTeamPlayerOutIdIndex >= 0) {
          this.fields[fieldTeamPlayerOutIdIndex].templateOptions.options = actors
            .filter(actor => actor?.type !== ActorListTypeEnum.referee)
            .map(actor => ({
              ...actor,
              label: this.analysisService.getActorLabel(actor),
              value: actor.id
            }));
        }

        // TODO find a better way
        if (fieldTeamPlayerInIdIndex >= 0) {
          this.fields[fieldTeamPlayerInIdIndex].templateOptions.options = actors
            .filter(actor => actor?.type !== ActorListTypeEnum.referee)
            .map(actor => ({
              ...actor,
              label: this.analysisService.getActorLabel(actor),
              value: actor.id
            }));
        }
      });
  }

  private disableFields(fields: any[]) {
    fields.forEach(field => {
      if (field.templateOptions) {
        field.templateOptions.disabled = true;
      }
      if (field.fieldGroup) {
        this.disableFields(field.fieldGroup);
      }
    });
  }

  private initMainFields(disabled = false) {
    this.fields = [
      {
        fieldGroup: [
          {
            key: 'type',
            type: 'select',
            templateOptions: {
              disabled,
              required: true,
              options: keyPoints
            }
          },
          {
            key: 'timeline',
            type: 'ngx-input',
            templateOptions: {
              disabled,
              required: true,
              modelKey: 'timeline',
              setValue: value => {
                this.setStartTime(normalizeTime(value).hhmmss);
                this.changedTimeline = true;
                this.changedChronoStart = true;
                this.changedChronoEnd = true;
              }
            }
          }
        ]
      },
      {
        fieldGroup: [
          {
            key: 'chronoStart',
            type: 'chrono-input',
            className: 'chrono--first',
            templateOptions: {
              disabled,
              required: true,
              modelKey: 'chronoStart',
              setValue: value => {
                if (value.length === 6) {
                  this.model.chronoStart = getChronoTime(value).mmss;
                }

                this.changedChronoStart = true;
              }
            },
            validators: {
              chronoStart: {
                expression: (c) => isChronoValide(c.value),
              },
            },
          },
          {
            key: 'chronoEnd',
            type: 'chrono-input',
            className: 'chrono--second',
            templateOptions: {
              disabled,
              required: true,
              modelKey: 'chronoEnd',
              setValue: value => {
                if (value.length === 6) {
                  this.model.chronoEnd = getChronoTime(value).mmss;
                }

                this.changedChronoEnd = true;
              }
            },
            validators: {
              chronoEnd: {
                expression: (c) => isChronoValide(c.value),
              },
            },
          }
        ]
      },
      {
        fieldGroup: [
          {
            key: 'label',
            type: 'input',
            hideExpression: 'model.type !== "O"',
            templateOptions: {
              disabled,
              required: true,
              type: 'text'
            }
          }
        ]
      },
      {
        key: 'teamId',
        type: 'select',
        templateOptions: {
          disabled,
          required: true,
          label: this.translate.instant(
            'key-point-details.labels.teamId'
          ),
          options: []
        }
      },
      {
        key: 'teamPlayerId',
        type: 'select',
        templateOptions: {
          disabled,
          label: this.translate.instant(
            'key-point-details.labels.teamPlayerId'
          ),
          options: []
        }
      },
      {
        key: 'teamPlayerOutId',
        type: 'select',
        hideExpression: 'model.type !== "S"',
        templateOptions: {
          disabled,
          required: true,
          label: this.translate.instant(
            'key-point-details.labels.teamPlayerOutId'
          ),
          options: []
        }
      },
      {
        key: 'teamPlayerInId',
        type: 'select',
        hideExpression: 'model.type !== "S"',
        templateOptions: {
          disabled,
          required: true,
          label: this.translate.instant(
            'key-point-details.labels.teamPlayerInId'
          ),
          options: []
        }
      }
    ];
  }

  private manageEditingKeyPoint() {
    this.model.status = this.keypoint.keyPointAnalysis?.keyPointAnalysisStatus;
    this.deficiencies = (
      this.keypoint?.keyPointAnalysis?.deficiencies ?? []
    ).map(def => ({
      ...def,
      chrono: convertToReadableFormat(def.chrono.matchTime, 'mm:ss'),
      timeline: convertToReadableFormat(def.chrono.videoTime, 'hh:mm:ss'),
      deficiencyType: [...allPlayerDeficiencyList(this.translate), ...allRefereeDeficiencyList(this.translate)].find(deficiency => deficiency.value === def.deficiencyType).label,
      statut: [...allDeficiencyStatusesList(this.translate)].find(deficiency => deficiency.value === def.statut)?.label,
    }));

    this.fields.push({
      fieldGroup: [
        {
          key: 'status',
          type: 'radio',
          hideExpression: !this.hasDeficiencyRole, // TODO: display also based if edit mode not add
          className: 'status--container',
          defaultValue: KeypointAnalysisStatusEnum.Normal,
          templateOptions: {
            options: [
              { value: KeypointAnalysisStatusEnum.Normal, label: 'Normal' },
              { value: KeypointAnalysisStatusEnum.PotentiallyAbnormal, label: 'Potentially Abnormal' },
              { value: KeypointAnalysisStatusEnum.Abnormal, label: 'Abnormal' },
            ]
          }
        },
        {
          key: 'var',
          type: 'checkbox',
          className: 'var--container',
          templateOptions: {
            label: 'VAR'
          }
        }
      ]
    });
  }

  public cancelKeyPoint() {
    this.store$.dispatch(new CancelKeyPoint());
  }

  public async saveKeyPoint() {
    if (this.deficienciesRef) {
      await this.validateDeficiencies();
    }

    if (!this.canSave) {
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.store$.dispatch(new ManageKeyPoint({ keyPoint: this.toStoreModel() }));
    this.store$.dispatch(new SaveKeyPoint());

    if (this.keypoint.id) {
      this.notificationService.success(
        this.translate.instant('key-point-details.alerts.updated')
      );
    } else {
      this.notificationService.success(
        this.translate.instant('key-point-details.alerts.saved')
      );
    }

    this.dialogRef.close();
  }

  private async validateDeficiencies(): Promise<boolean> {
    this.canSave = this.deficiencies?.every(def => !!def.team && !!def.chrono && !!def.deficiencyType && !!def.statut && isChronoValide(def.chrono));

    await timer(100).toPromise();

    this.deficienciesRef['grid'].gridOptions.api.redrawRows();
    this.deficienciesRef['grid'].gridOptions.api.refreshCells();

    return this.canSave;
  }

  public onClose() {
    this.dialogRef.close(null);
    this.store$.dispatch(new CancelKeyPoint());
  }

  public updateDeficiencies(rows) {
    this.deficiencies = rows;
  }

  private setStartTime(value) {
    const values = value?.split(':').map(v => parseInt(v, 10));
    const start =
      values !== undefined
        ? (values[0] * 3600 + values[1] * 60 + values[2]) * 1000
        : 0;

    if (!start) {
      return;
    }

    const difference =
      this.keypoint.startChrono.videoTime - this.keypoint.startChrono.matchTime;
    this.startTime = start;

    this.model = {
      ...this.model,
      timeline: convertToReadableFormat(start, 'hh:mm:ss'),
      chronoStart: convertToReadableFormat(
        start - difference < 0 ? 0 : start - difference,
        'mm:ss'
      ),
      chronoEnd: convertToReadableFormat(
        (start - difference < 0 ? 0 : start - difference) + 30 * 1000,
        'mm:ss'
      )
    };
  }

  private toStoreModel(): KeyPointItem {
    const startChrono = (this.changedChronoStart || this.changedTimeline) ? { ...this.getChronoByTime(this.model.chronoStart), id: this.keypoint.startChrono.id, videoTime: this.getCurrentTimeline(), } : this.keypoint.startChrono;
    const endChrono = this.changedChronoEnd ? { ...this.getChronoByTime(this.model.chronoEnd), id: this.keypoint.endChrono.id } : this.keypoint.endChrono;

    let model = {
      ...this.keypoint,
      startChrono,
      endChrono,
      keyPointTypeLabel:
        this.model.type === KeyPointTypeEnum.Other ? this.model.label : null,
      keyPointType: this.model.type,
      teamId: this.model.teamId,
      teamPlayerId: this.model.teamPlayerId,
      teamPlayerOutId: this.model.type === KeyPointTypeEnum.Substitution ? this.model.teamPlayerOutId : null,
      teamPlayerInId: this.model.type === KeyPointTypeEnum.Substitution ? this.model.teamPlayerInId : null,
    };
    if (this.keypoint.keyPointAnalysis) {
      model = {
        ...model,
        keyPointAnalysis: {
          ...this.keypoint.keyPointAnalysis,
          keyPointAnalysisStatus: this.model.status,
          deficiencies: this.deficiencies.map(def => {
            return {
              ...def,
              chrono: { ...this.getChronoByTime(def.chrono) },
              deficiencyType: [...allPlayerDeficiencyList(this.translate), ...allRefereeDeficiencyList(this.translate)].find(deficiency => deficiency.label === def.deficiencyType).value,
              statut: [...allDeficiencyStatusesList(this.translate)].find(deficiency => deficiency.label === def.statut)?.value,
            };
          }),
          var: this.model.var,
          reviewed: true,
        }
      };
    }
    return model;
  }

  private getChronoByTime(time: string = '00:00'): ChronoItem {
    const value = time.replace(':', '');
    const miliseconds = getChronoTime(value).seconds * 1000;
    const matchTime = this.chronoService.getMatchTime(miliseconds + this.timelineDifference);

    return {
      videoTime: get(matchTime, 'videoTime', 0),
      matchTime: miliseconds,
      round: get(matchTime, 'lastEvent.round', 1),
      isAdditionalTime: get(matchTime, 'lastEvent.isAdditionalTime', false)
    };
  }

  private getCurrentTimeline() {
    return this.changedTimeline
      ? normalizeTime(this.model.timeline.split(':').join('')).seconds * 1000
      : this.keypoint.startChrono.videoTime;
  }

  ngOnDestroy() {
    if (this.playersSubscription) {
      this.playersSubscription.unsubscribe();
    }
    if (this.teamsSubscription) {
      this.teamsSubscription.unsubscribe();
    }
  }
}
