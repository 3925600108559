import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/core';
import { getAuthenticationError } from '../../../store/auth.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends TcSmartComponent implements OnInit {

  @Output() login: EventEmitter<any> = new EventEmitter();

  public errorMessage = ' ';
  form: FormGroup;
  usernameCtrl: FormControl;
  passwordCtrl: FormControl;

  ngOnInit() {
    this.usernameCtrl = new FormControl('', Validators.required);
    this.passwordCtrl = new FormControl('', Validators.required);

    this.form = new FormGroup({
      username: this.usernameCtrl,
      password: this.passwordCtrl
    });

    this.store.select(getAuthenticationError)
      .subscribe((error: string) => {
        this.errorMessage = error || ' ';
      });
  }

  constructor(
    private readonly store: Store<any>
  ) {
    super();
  }

  usernameError() {
    return this.usernameCtrl.hasError('required') ? 'Username is required ' : '';
  }

  passwordError() {
    return this.passwordCtrl.hasError('required') ? 'Password is required ' : '';
  }

  submit(): void {
    if (this.form.valid) {
      this.login.emit({ username: this.form.value.username, password: this.form.value.password });
    }
  }

}
