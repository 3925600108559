import { Injectable } from '@angular/core';
import { KeyPointConsultationItem, ConsultationStatusData } from '@match-fix/shared';
import { KeyPointAnalysisService } from '../../../services/business-services/key-point-analysis-service';
import { MatchsService } from '../../../services/business-services/matchs-service';

@Injectable()
export class ExpertAnalysisConsultationService {

  constructor(
    private readonly matchsService: MatchsService,
    private readonly keyPointAnalysisService: KeyPointAnalysisService,
  ) { }

  public async getKeyPointList(matchId: number): Promise<KeyPointConsultationItem[]> {
    const keyPoints = await this.keyPointAnalysisService.getKeyPointAnalysisConsultation(matchId);

    return keyPoints || [];
  }

  public async getStatus(matchId: number): Promise<ConsultationStatusData[]> {
    return await this.matchsService.getConsultationStatus(matchId);
  }

}
