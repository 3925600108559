import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomEntitiesEnum } from '@match-fix/shared';
import { LOGIN_INAVLID_USERNAME_OR_PASSWORD } from '../../modules/auth/auth.module';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CrudService {

  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(CustomEntitiesEnum.Auth, httpClient, basePath, configuration);
  }

  async verify(access_token: string, code: string): Promise<{ access_token: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<{ access_token: string }>(
      `${url}/verify`, {
      code,
      token: access_token,
    }, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<{ access_token: string }>();
  }

}

