import { EntityMetadataMap } from 'ngrx-data';
import { EntitiesEnum } from './EntitiesEnum';

import { AnalysisType } from './modelMetadata/AnalysisType';
import { ChronoType } from './modelMetadata/ChronoType';
import { ClubType } from './modelMetadata/ClubType';
import { ClubPlayerType } from './modelMetadata/ClubPlayerType';
import { CompetitionType } from './modelMetadata/CompetitionType';
import { CountryType } from './modelMetadata/CountryType';
import { DbVersionType } from './modelMetadata/DbVersionType';
import { DeficiencyType } from './modelMetadata/DeficiencyType';
import { DocumentType } from './modelMetadata/DocumentType';
import { EventType } from './modelMetadata/EventType';
import { KeyPointType } from './modelMetadata/KeyPointType';
import { KeyPointAnalysisType } from './modelMetadata/KeyPointAnalysisType';
import { ListTypeType } from './modelMetadata/ListTypeType';
import { ListValueType } from './modelMetadata/ListValueType';
import { MatchType } from './modelMetadata/MatchType';
import { MatchRefereeType } from './modelMetadata/MatchRefereeType';
import { OrganisationType } from './modelMetadata/OrganisationType';
import { OrganisationUserType } from './modelMetadata/OrganisationUserType';
import { PlayerType } from './modelMetadata/PlayerType';
import { RefereeType } from './modelMetadata/RefereeType';
import { StadiumType } from './modelMetadata/StadiumType';
import { TeamType } from './modelMetadata/TeamType';
import { TeamPlayerType } from './modelMetadata/TeamPlayerType';
import { UserType } from './modelMetadata/UserType';
import { VideoType } from './modelMetadata/VideoType';

export const entityMetadata: EntityMetadataMap = {
  Analysiss: {},
  Chronos: {},
  Clubs: {},
  ClubPlayers: {},
  Competitions: {},
  Countrys: {},
  DbVersions: {},
  Deficiencies: {},
  Documents: {},
  Events: {},
  KeyPoints: {},
  KeyPointAnalysiss: {},
  ListTypes: {},
  ListValues: {},
  Matchs: {},
  MatchReferees: {},
  Organisations: {},
  OrganisationUsers: {},
  Players: {},
  Referees: {},
  Stadiums: {},
  Teams: {},
  TeamPlayers: {},
  Users: {},
  Videos: {},
};


export const pluralNames = {
  Analysiss: 'analysiss',
  Chronos: 'chronos',
  Clubs: 'clubs',
  ClubPlayers: 'clubplayers',
  Competitions: 'competitions',
  Countrys: 'countrys',
  DbVersions: 'dbversions',
  Deficiencies: 'deficiencies',
  Documents: 'documents',
  Events: 'events',
  KeyPoints: 'keypoints',
  KeyPointAnalysiss: 'keypointanalysiss',
  ListTypes: 'listtypes',
  ListValues: 'listvalues',
  Matchs: 'matchs',
  MatchReferees: 'matchreferees',
  Organisations: 'organisations',
  OrganisationUsers: 'organisationusers',
  Players: 'players',
  Referees: 'referees',
  Stadiums: 'stadiums',
  Teams: 'teams',
  TeamPlayers: 'teamplayers',
  Users: 'users',
  Videos: 'videos',
};

export const typesConfig: { [key: string]: any } = { };
typesConfig[EntitiesEnum.Analysiss] = AnalysisType;
typesConfig[EntitiesEnum.Chronos] = ChronoType;
typesConfig[EntitiesEnum.Clubs] = ClubType;
typesConfig[EntitiesEnum.ClubPlayers] = ClubPlayerType;
typesConfig[EntitiesEnum.Competitions] = CompetitionType;
typesConfig[EntitiesEnum.Countrys] = CountryType;
typesConfig[EntitiesEnum.DbVersions] = DbVersionType;
typesConfig[EntitiesEnum.Deficiencies] = DeficiencyType;
typesConfig[EntitiesEnum.Documents] = DocumentType;
typesConfig[EntitiesEnum.Events] = EventType;
typesConfig[EntitiesEnum.KeyPoints] = KeyPointType;
typesConfig[EntitiesEnum.KeyPointAnalysiss] = KeyPointAnalysisType;
typesConfig[EntitiesEnum.ListTypes] = ListTypeType;
typesConfig[EntitiesEnum.ListValues] = ListValueType;
typesConfig[EntitiesEnum.Matchs] = MatchType;
typesConfig[EntitiesEnum.MatchReferees] = MatchRefereeType;
typesConfig[EntitiesEnum.Organisations] = OrganisationType;
typesConfig[EntitiesEnum.OrganisationUsers] = OrganisationUserType;
typesConfig[EntitiesEnum.Players] = PlayerType;
typesConfig[EntitiesEnum.Referees] = RefereeType;
typesConfig[EntitiesEnum.Stadiums] = StadiumType;
typesConfig[EntitiesEnum.Teams] = TeamType;
typesConfig[EntitiesEnum.TeamPlayers] = TeamPlayerType;
typesConfig[EntitiesEnum.Users] = UserType;
typesConfig[EntitiesEnum.Videos] = VideoType;
