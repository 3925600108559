import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatchFormAbstract } from '../../../abstract/match-form.abstract';
import { AddReferee } from '../../../store/matchs.actions';
import { MatchReferee, MatchState } from '../../../store/matchs.interfaces';
import { RemoveReferee, UpdateMatch } from './../../../store/matchs.actions';
import { getMatch, getReferees } from './../../../store/matchs.selectors';

@Component({
  selector: 'app-match-referee',
  templateUrl: './match-referee.component.html',
  styleUrls: ['./match-referee.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MatchRefereeComponent extends MatchFormAbstract implements OnInit, OnDestroy {
  public form: FormGroup;
  public referees = new Observable<MatchReferee[]>();
  public refereesSubscription: Subscription;
  refereeRole = new FormControl();

  roles: any[] = [];
  filteredRoles: Observable<any[]>;

  isVar = false;

  constructor(
    protected store: Store<MatchState>
  ) {
    super(store);
  }

  ngOnInit(): void {
    // Setup form and formControl for the component : add form fields of the component here
    this.form = new FormGroup({
      role: new FormControl(''),
      refereeId: new FormControl(''),
    });

    // Setup the form for the parent
    super.SetForm(this.form);

    // Subscribe to automatically show the new referees
    this.refereesSubscription = this.store.select(getReferees).subscribe(data => {
      //filter out referee with id = "CURRENT_REFEREE_ID"
      data = data.filter(r => r.id !== 'CURRENT_REFEREE_ID');
      this.referees = of(data);
    });

    // Call the parent to setup error system
    super.ngOnInit();

    this.getVarOption();
  }

  addReferee() {
    this.store.dispatch(new AddReferee());
  }

  removeReferee(id: string) {
    this.store.dispatch(new RemoveReferee({ id: id }));
  }

  checkVar() {
    this.store.dispatch(new UpdateMatch({ key: 'var', value: this.isVar }));
  }

  private async getVarOption() {
    const data = await this.store
      .select(getMatch)
      .pipe(take(1))
      .toPromise();

    this.isVar = data.var;
  }

  ngOnDestroy() {
    // Destroy current subscriptions
    if (this.refereesSubscription) {
      this.refereesSubscription.unsubscribe();
    }

    // Call the parent to destroy parent subscriptions
    super.ngOnDestroy();
  }

}
