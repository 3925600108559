import { ChronoState } from './chrono.interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getAnalysisStoreState = createFeatureSelector<ChronoState>(
    'chrono'
);

/**
 * Get matchTime
 */
export const getMatchTime = createSelector(
    getAnalysisStoreState,
    (state: ChronoState) => state.matchTime
);