import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { ResetUserGoogleAuthDialogComponent } from './components/smart/reset-user-google-auth-dialog/reset-user-google-auth-dialog.component';

import { UserDetailComponent } from './components/smart/user-detail/user-detail.component';
import { UserListComponent } from './components/smart/user-list/user-list.component';
import { UsersEffects } from './store/users.effects';
import { usersReducer } from './store/users.reducer';

const smartComponents = [UserDetailComponent, UserListComponent, ResetUserGoogleAuthDialogComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([UsersEffects]),
    StoreModule.forFeature('users', usersReducer)
  ],
  exports: [...smartComponents],
  entryComponents: [UserDetailComponent, ResetUserGoogleAuthDialogComponent]
})
export class UsersModule { }
