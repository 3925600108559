import { Club } from '@match-fix/shared';

export class CustomClubType {
  static className = 'CustomClub';
  static gqlRootQueryName = 'customclub';
  static gqlColumns: string[] = [
    '_id',
    'numberOfPlayers',
    'countryName',
    'numberOfMatches',
    'code',
    'name',
  ];

  static gqlNodeMapper = (node: any): Club => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Club;
  };
}
