import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chrono } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { UpdateMatchVideo } from '../../../store/matchs.actions';
import { VideoWithChrono } from '../../../store/matchs.interfaces';
import { timer } from 'rxjs';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditVideoComponent implements OnInit {

  public video: VideoWithChrono;
  public canSave = true;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly dialogRef: MatDialogRef<EditVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.video = JSON.parse(JSON.stringify(this.data.video));
  }

  public onChangeChrono(chronoKey: string, chrono: Chrono) {
    this.video[chronoKey] = this.video[chronoKey] ? { ...this.video[chronoKey], ...chrono } : chrono;

    this.validateValues();
  }

  public async onSave() {
    if (!this.validateValues()) {
      return;
    }

    this.store$.dispatch(new UpdateMatchVideo(this.video));

    this.onClose();
  }

  public onClose() {
    this.dialogRef.close();
  }

  private validateValues(): boolean {
    this.canSave = !(this.video.startChrono?.matchTime === null || this.video.endChrono?.matchTime === null);

    return this.canSave;
  }

}
