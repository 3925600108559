import { createAction, props } from '@ngrx/store';
import { ListValue } from '@match-fix/shared';

export const upsertListValue = createAction(
  '[Configuration] Upsert List Value',
  props<{ listValue?: ListValue }>()
);

export const upsertListValueSuccess = createAction(
  '[Configuration] Upsert Success List Value',
  props<{ listValue?: ListValue }>()
);
