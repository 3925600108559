import { NoteKeyPointState, MatchAnalysisStateEnum, TeamPlayerDeficiencyCategoryEnum } from '@match-fix/shared';
import { createAction, props } from '@ngrx/store';
import { TcAction } from '@tc/core';
import { AddMatchForMatchPayload, AssembleState, UpdateNoteKeyPointSuccessPayload, TeamsPlayersCategorization } from './expert-analysis.interfaces';

export const noteKeypoints = createAction(
  '[ExpertAnalysis] Note keypoints',
  props<{ matchId: number, matchBreadcrumbName: string }>()
);

export const viewReport = createAction(
  '[ExpertAnalysis] Open report page',
  props<{ matchId: number }>()
);

export const loadKeyPointsClassification = createAction(
  '[ExpertAnalysis] Load Key Points Classification',
  props<{}>()
);

export const loadChangeMatchStatus = createAction(
  '[ExpertAnalysis] Load change match status',
  props<{ analysis: any }>()
);

export enum ExpertAnalysisActionTypes {
  INIT = '[ExpertAnalysis] Init store for expert analysis', // Setup the store with required ids.
  GET_NOTE_KEY_POINT_SUCCESS = '[ExpertAnalysis] Get note key point success',
  UPDATE_NOTE_KEY_POINT_SUCCESS = '[ExpertAnalysis] update note key point success',

  SAVE_EXPERT_KEY_POINT = '[ExpertAnalysis] Save key point in database and close the popup',
  CLEAR_EXPERT_KEY_POINT = '[ExpertAnalysis] Key point removed from store',
  LOAD_EXPERT_KEY_POINT = '[ExpertAnalysis] Open popup for expert key point edit',
  GET_NOTE_KEY_POINT = '[ExpertAnalysis] Get note key point',
  MANAGE_EXPERT_KEY_POINT = '[ExpertAnalysis] Update expert key point in the store',

  FINALIZE_KEY_POINTS_CLASSIFICATION = '[ExpertAnalysis] Finalize Key Points Classification',

  SET_REPORT_MATCH_ID = '[ExpertAnalysis] Set report match id in the store',
  SET_NOTE_MATCH_ID = '[ExpertAnalysis] Set note match id in the store',
  SET_EXPERT_ANALYSIS_CONSULTATION_MATCH_ID = '[ExpertAnalysis] Set expert analysis consultation match id in the store',

  LOAD_ASSEMBLE = '[ExpertAnalysis] Open popup for key point assembling',
  MANAGE_ASSEMBLE = '[ExpertAnalysis] Update assemble in the store',
  CLEAR_ASSEMBLE = '[ExpertAnalysis] Assemble removed from store',
  EXPERT_ANALYSIS_CONSULTATION = '[ExpertAnalysis] Open expert analysis consultation page',

  ADD_DOCUMENT_FOR_MATCH = '[ExpertAnalysis] Add document for match',

  SET_EXPERT_ANALYSIS_READONLY = '[ExpertAnalysis] Set expert analysis readonly',

  SET_MATCH_ANALYSIS_STATUS = '[ExpertAnalysis] Set match analysis status',

  OPEN_PLAYERS_CATEGOTIZATIONS = '[ExpertAnalysis] Open players categorizations',
  SAVE_CATEGORIZATIONS = '[ExpertAnalysis] Save categorizations',
}

export class ExpertAnalysisConsultation implements TcAction {
  readonly type = ExpertAnalysisActionTypes.EXPERT_ANALYSIS_CONSULTATION;

  constructor(public payload: { matchId: number }) { }
}

export class InitExpertAnalysis implements TcAction {
  readonly type = ExpertAnalysisActionTypes.INIT;
  constructor(public payload: { matchId?: number; matchBreadcrumbName?: string }) { }
}

export class SetExpertAnalysisConsultationMatchId implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SET_EXPERT_ANALYSIS_CONSULTATION_MATCH_ID;
  constructor(public payload: { matchId: number }) { }
}

export class SetNoteMatchId implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SET_NOTE_MATCH_ID;
  constructor(public payload: { matchId: number; matchBreadcrumbName: string; }) { }
}

export class SetReportMatchId implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SET_REPORT_MATCH_ID;
  constructor(public payload: { matchId: number; }) { }
}

export class GetNoteKeyPointListSuccess implements TcAction {
  readonly type = ExpertAnalysisActionTypes.GET_NOTE_KEY_POINT_SUCCESS;
  constructor(public payload: { noteKeyPointList: any; }) { }
}

export class SaveExpertKeyPoint implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SAVE_EXPERT_KEY_POINT;

  constructor(public payload: { keyPointId: number, noteKeyPoint: NoteKeyPointState }) { }
}

export class ClearExpertKeyPoint implements TcAction {
  readonly type = ExpertAnalysisActionTypes.CLEAR_EXPERT_KEY_POINT;
}

export class LoadExpertKeyPoint implements TcAction {
  readonly type = ExpertAnalysisActionTypes.LOAD_EXPERT_KEY_POINT;

  constructor(public payload: { keyPointId: number }) { }
}

export class GetNoteKeyPoint implements TcAction {
  readonly type = ExpertAnalysisActionTypes.GET_NOTE_KEY_POINT;

  constructor(public payload: { keyPointId: number }) { }
}

export class LoadAssemble implements TcAction {
  readonly type = ExpertAnalysisActionTypes.LOAD_ASSEMBLE;

  constructor(public payload: { keyPointId: number, deficienyId1: number, deficienyId2: number, expertDeficiencyId?: number }) { }
}

export class ManageExpertKeyPoint implements TcAction {
  readonly type = ExpertAnalysisActionTypes.MANAGE_EXPERT_KEY_POINT;

  constructor(public payload: { noteKeyPoint: NoteKeyPointState }) { }
}

export class ManageAssemble implements TcAction {
  readonly type = ExpertAnalysisActionTypes.MANAGE_ASSEMBLE;

  constructor(public payload: { assemble: AssembleState }) { }
}

export class ClearAssemble implements TcAction {
  readonly type = ExpertAnalysisActionTypes.CLEAR_ASSEMBLE;
}

export class FinalizeKeyPointsClassification implements TcAction {
  readonly type = ExpertAnalysisActionTypes.FINALIZE_KEY_POINTS_CLASSIFICATION;

  constructor(
    public payload: { matchId: number, comment: string, players: { id: number, status: TeamPlayerDeficiencyCategoryEnum }[] }
  ) { }
}

export class AddDocumentForMatch implements TcAction {
  readonly type = ExpertAnalysisActionTypes.ADD_DOCUMENT_FOR_MATCH;
  constructor(public readonly payload: AddMatchForMatchPayload) { }
}


export class SetExpertAnalysisReadOnly implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SET_EXPERT_ANALYSIS_READONLY;
  constructor(public payload: boolean) { }
}

export class SetMatchAnalysisStatus implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SET_MATCH_ANALYSIS_STATUS;
  constructor(public payload: MatchAnalysisStateEnum) { }
}

export class UpdateNoteKeyPointSuccess implements TcAction {
  readonly type = ExpertAnalysisActionTypes.UPDATE_NOTE_KEY_POINT_SUCCESS;
  constructor(public readonly payload: UpdateNoteKeyPointSuccessPayload) { }
}

export class OpenPlayersCategorizations implements TcAction {
  readonly type = ExpertAnalysisActionTypes.OPEN_PLAYERS_CATEGOTIZATIONS;
}

export class SaveCategorizations implements TcAction {
  readonly type = ExpertAnalysisActionTypes.SAVE_CATEGORIZATIONS;

  constructor(
    public payload: TeamsPlayersCategorization,
  ) { }
}

export type ExpertAnalysisStoreActions =
  | SaveExpertKeyPoint
  | ClearExpertKeyPoint
  | ManageExpertKeyPoint
  | LoadExpertKeyPoint
  | LoadAssemble
  | ManageAssemble
  | ClearAssemble
  | SetNoteMatchId
  | GetNoteKeyPointListSuccess
  | GetNoteKeyPoint
  | ExpertAnalysisConsultation
  | SetExpertAnalysisConsultationMatchId
  | FinalizeKeyPointsClassification
  | AddDocumentForMatch
  | SetExpertAnalysisReadOnly
  | SetMatchAnalysisStatus
  | UpdateNoteKeyPointSuccess
  | SetReportMatchId
  | SaveCategorizations
  | OpenPlayersCategorizations;
