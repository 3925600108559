import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingComponent } from './components/smart/reporting/reporting.component';


@NgModule({
  declarations: [ReportingComponent],
  imports: [
    CommonModule
  ]
})
export class ReportingModule { }
