import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatDialog } from '@angular/material/dialog';
import { ListRefreshService } from '../../match/services/list-refresh.service';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { ConfigurationDetailComponent } from './../components/smart/configuration-detail/configuration-detail.component';
import {
  upsertListValue,
  upsertListValueSuccess
} from './configuration.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigurationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly listRefreshService: ListRefreshService,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService
  ) { }

  upsertListValue = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertListValue),
        tap(({ listValue }) => {
          this.dialog.open(ConfigurationDetailComponent, {
            height: '260px',
            width: '414px',
            data: listValue
          });
        })
      ),
    { dispatch: false }
  );

  upsertListValueSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertListValueSuccess),
        tap(({ listValue }) => {
          if (listValue.id) {
            this.notificationService.success(
              this.translateService.instant(
                'document-extension.alerts.edit-success'
              )
            );
          } else {
            this.notificationService.success(
              this.translateService.instant(
                'document-extension.alerts.save-success'
              )
            );
          }
        })
      ),
    { dispatch: false }
  );
}
