export enum KeyPointTypeEnum {
  Goal = 'G',
  Penalty = 'P',
  RedCard = 'R',
  FreeKick = 'F',
  YellowCard = 'Y',
  Offside = 'OS',
  CornerKick = 'C',
  GoalSituation = 'GS',
  CounterAttack = 'CA',
  Injury = 'I',
  Other = 'O',
  Substitution = 'S',
  FreeDeficiency = 'FD'
}

export const allKeyPoints = Object.keys(KeyPointTypeEnum);

export interface KeyPointLabel {
  value: KeyPointTypeEnum;
  label: string;
  icon: string;
}

export const specificKeyPointTypes = [
  KeyPointTypeEnum.GoalSituation,
  KeyPointTypeEnum.CounterAttack,
  KeyPointTypeEnum.Injury,
  KeyPointTypeEnum.FreeDeficiency,
  KeyPointTypeEnum.Other
];

export const keyPoints: KeyPointLabel[] = [
  {
    value: KeyPointTypeEnum.Goal,
    label: 'Goal',
    icon: 'assets/icons/icon-goal.svg'
  },
  {
    value: KeyPointTypeEnum.Penalty,
    label: 'Penalty',
    icon: 'assets/icons/icon-penalty.svg'
  },
  {
    value: KeyPointTypeEnum.RedCard,
    label: 'Red Card',
    icon: 'assets/icons/icon-red-card.svg'
  },
  {
    value: KeyPointTypeEnum.FreeKick,
    label: 'Free Kick (fault)',
    icon: 'assets/icons/icon-free-kick.svg'
  },
  {
    value: KeyPointTypeEnum.YellowCard,
    label: 'Yellow Card',
    icon: 'assets/icons/icon-yellow-card.svg'
  },
  {
    value: KeyPointTypeEnum.Offside,
    label: 'Offside',
    icon: 'assets/icons/icon-offside.svg'
  },
  {
    value: KeyPointTypeEnum.CornerKick,
    label: 'Corner Kick',
    icon: 'assets/icons/icon-corner-kick.svg'
  },
  {
    value: KeyPointTypeEnum.GoalSituation,
    label: 'Goal Situation',
    icon: 'assets/icons/icon-goal-situation.svg'
  },
  {
    value: KeyPointTypeEnum.CounterAttack,
    label: 'Counter Attack',
    icon: 'assets/icons/icon-counter-attack.svg'
  },
  {
    value: KeyPointTypeEnum.Injury,
    label: 'Injury',
    icon: 'assets/icons/icon-injury.svg'
  },
  {
    value: KeyPointTypeEnum.Substitution,
    label: 'Substitution',
    icon: 'assets/icons/substitution2.svg'
  },
  {
    value: KeyPointTypeEnum.FreeDeficiency,
    label: 'Free Deficiency',
    icon: 'assets/icons/free-deficiency.svg'
  },
  {
    value: KeyPointTypeEnum.Other,
    label: 'Other',
    icon: 'assets/icons/icon-other.svg'
  }
];
