import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserRoleEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { take } from 'rxjs/operators';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { InitAnalysis } from '../../modules/match-analysis/store/analysis.actions';
import { getAnalysisBreadcrumbName, getAnalysisStoreState } from '../../modules/match-analysis/store/analysis.selectors';

@Component({
  selector: 'app-match-analysis-page',
  templateUrl: './match-analysis-page.component.html',
  styleUrls: ['./match-analysis-page.component.scss']
})
export class MatchAnalysisPageComponent extends TcPage implements OnInit, AfterViewInit {

  public canAddEvents = false;

  constructor(
    protected store$: Store<any>
  ) {
    super();
  }

  async ngOnInit() {
    const breadcrumbName = await this.store$.select(getAnalysisBreadcrumbName).pipe(take(1)).toPromise();

    this.store$.dispatch(getBreadcrumb({ route: RouteEnum.MatchAnalysis, name: breadcrumbName }));

    this.checkCanAddEvents();
  }

  ngAfterViewInit(): void {
    this.store$.dispatch(new InitAnalysis());
  }

  private async checkCanAddEvents() {
    const analysisState = await this.store$.select(getAnalysisStoreState).pipe(take(1)).toPromise();

    this.canAddEvents = analysisState.userIdByAnalysisId[analysisState.analysisId] === analysisState.supervisorId
      && analysisState.analysisRole === UserRoleEnum.Supervisor;
  }

}
