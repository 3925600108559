export enum RouteEnum {
  Home = 'home',
  Matches = 'matches',
  Organisations = 'organisations',
  Competitions = 'competitions',
  Clubs = 'clubs',
  Players = 'players',
  Referees = 'referees',
  Stadiums = 'stadiums',
  ExpertAnalysis = 'expert-analysis',
  ExpertAnalysisConsultation = 'expert-analysis-consultation',
  MatchAnalysis = 'match-analysis',
  Users = 'users',
  NoteKeyPoint = 'note-key-point',
  ReportView = 'report-view',
  Configuration = 'configuration',
  Reporting = 'reporting'
}
