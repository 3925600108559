import { ActorListItem, ChronoItem, MatchStatusEnum, TeamListItem } from '@match-fix/shared';
import { AnalysisActionTypes, AnalysisStoreActions } from './analysis.actions';
import {
  AnalysisState,
  EventListItem,
  KeyPointItem
} from './analysis.interfaces';

/**
 * Default object to register in analysis store with empty values
 */
export const defaultAnalysisState: AnalysisState = {
  matchId: null,
  operator1Id: null,
  operator2Id: null,
  supervisorId: null,
  analysisId: null,
  eventsList: [],
  actorsList: [],
  analysisStatus: null,
  userIdByAnalysisId: null,
  navigationState: { prev: null, next: null },
  analysisRole: null,
  teams: [],
  analysisBreadcrumbName: null,
};

/**
 * Main reducer function
 */
export function analysisStoreReducer(
  state = defaultAnalysisState,
  action: AnalysisStoreActions
): AnalysisState {
  switch (action.type) {
    case AnalysisActionTypes.INIT_SUCCESS: {
      return init(state, action.payload.analysisState);
    }
    case AnalysisActionTypes.ADD_TO_EVENT_LIST: {
      return addToEventList(state, action.payload.eventListItem);
    }
    case AnalysisActionTypes.UPDATE_EVENT_LIST: {
      return updateEventList(state, action.payload.eventList);
    }
    case AnalysisActionTypes.UPDATE_ACTORS_LIST: {
      return updateActorsList(state, action.payload.actorsList);
    }
    case AnalysisActionTypes.UPDATE_TEAMS: {
      return updateTeams(state, action.payload.teams);
    }
    case AnalysisActionTypes.UPDATE_EVENT: {
      return updateEvent(state, action.payload.event);
    }
    case AnalysisActionTypes.MANAGE_KEY_POINT: {
      return manageKeyPoint(state, action.payload.keyPoint);
    }
    case AnalysisActionTypes.CLEAR_KEY_POINT: {
      return clearKeyPoint(state);
    }
    case AnalysisActionTypes.DELETE_KEY_POINT: {
      return deleteKeyPoint(state, action.payload.eventListId);
    }
    case AnalysisActionTypes.MANAGE_EVENT: {
      return manageEvent(state, action.payload.event);
    }
    case AnalysisActionTypes.CLEAR_EVENT: {
      return clearEvent(state);
    }
    case AnalysisActionTypes.SET_VIDEO_TIME: {
      return setVideoTime(state, action.payload.time);
    }
    case AnalysisActionTypes.SET_ANALYSIS_STATUS_SUCCESS: {
      return setAnalysisStatus(state, action.payload.status);
    }
    case AnalysisActionTypes.SET_MATCH_ANALYSIS: {
      const newState = {
        ...state,
        matchId: action.payload.matchId,
        analysisId: action.payload.analysisId,
        operator1Id: action.payload.operator1Id,
        operator2Id: action.payload.operator2Id,
        supervisorId: action.payload.supervisorId,
        analysisStatus: action.payload.analysisStatus,
        userIdByAnalysisId: action.payload.userIdByAnalysisId,
        analysisRole: action.payload.analysisRole,
        analysisBreadcrumbName: action.payload.analysisBreadcrumbName,
      };
      return newState;
    }
    case AnalysisActionTypes.CHECK_NAVIGATION_STATE: {
      return checkNavigationState(state, action.payload)
    }
    default: {
      return state;
    }
  }
}

/**
 * Init / Reset function for state
 */
export function init(state: AnalysisState, analysisState: AnalysisState) {
  return analysisState;
}

/**
 * Save analysis status
 */
export function setAnalysisStatus(
  state: AnalysisState,
  analysisStatus: MatchStatusEnum
) {
  return { ...state, analysisStatus };
}

/**
 * Add to event list
 */
export function addToEventList(
  state: AnalysisState,
  eventListItem: EventListItem
) {
  // Sort the array by video time
  const sortedEventList = sortEventArray([...state.eventsList, eventListItem]);
  return { ...state, eventsList: sortedEventList };
}

/**
 * Update the event list
 */
export function updateEventList(
  state: AnalysisState,
  eventList: EventListItem[]
) {
  // Sort the array by video time
  const sortedEventList = sortEventArray(eventList);
  return { ...state, eventsList: sortedEventList };
}

/**
 * Update the actors list
 */
export function updateActorsList(
  state: AnalysisState,
  actorsList: ActorListItem[]
) {
  return { ...state, actorsList: actorsList };
}

/**
 * Update teams
 */
export function updateTeams(
  state: AnalysisState,
  teams: TeamListItem[]
) {
  return { ...state, teams: teams };
}

/**
 * Update an event by id
 */
export function updateEvent(
  state: AnalysisState,
  event: EventListItem
) {
  // Search event  by id in the event list
  const eventIndex = state.eventsList.findIndex(({ id }) => id === event.id);
  const eventsList = [...state.eventsList];

  // Update event on founded index
  eventsList[eventIndex] = event;

  return { ...state, eventsList };
}

/**
 * Remove a key point from the event list
 */
export function deleteKeyPoint(state: AnalysisState, eventListId: number) {
  const newEventList = state.eventsList.filter(item => item?.keyPoint?.id !== eventListId);
  return { ...state, eventsList: newEventList };
}

/**
 * Manage key point
 */
export function manageKeyPoint(state: AnalysisState, keyPoint: KeyPointItem) {
  return { ...state, selectedKeyPoint: keyPoint };
}

/**
 * Manage events
 */
export function manageEvent(state: AnalysisState, event: ChronoItem) {
  return { ...state, selectedEvent: event };
}

/**
 * Clear key point
 */
export function clearKeyPoint(state: AnalysisState) {
  const newState = { ...state };
  delete newState.selectedKeyPoint;
  return newState;
}

/**
 * Clear event
 */
export function clearEvent(state: AnalysisState) {
  const newState = { ...state };
  delete newState.selectedEvent;
  return newState;
}
/**
 * Set video time
 */
export function setVideoTime(state: AnalysisState, time: number) {
  const newState = { ...state, videoTime: time };
  return newState;
}

/**
 * Sorting function for events
 */
export function sortEventArray(events: EventListItem[]) {
  return events.slice().sort((item1, item2) => {
    // Determine time to sort : if event is set, use it, else, use keypoint startChrono as reference.
    const videoTime1 = item1.event
      ? item1.event.videoTime
      : item1.keyPoint.startChrono.videoTime;
    const videoTime2 = item2.event
      ? item2.event.videoTime
      : item2.keyPoint.startChrono.videoTime;
    if (videoTime1 > videoTime2) {
      return 1;
    }
    if (videoTime1 < videoTime2) {
      return -1;
    }
    return 0;
  });
}

export function setMatchAnalysis(
  state: AnalysisState,
  matchId: number,
  analysisId: number
) {
  return { ...state, matchId: matchId, analysisId: analysisId };
}

export function checkNavigationState(state: AnalysisState, { id }: { id: number }): AnalysisState {
  const index = state.eventsList.findIndex(e => e.id === id);

  if (index === -1) {
    return {
      ...state,
      navigationState: {
        next: null,
        prev: null
      }
    }
  }

  const before = state.eventsList.slice(0, index).reverse();
  const after = state.eventsList.slice(index + 1);

  const prev = (before.find(item => !!item.keyPoint))?.id || null;
  const next = (after.find(item => !!item.keyPoint))?.id || null;

  return {
    ...state,
    navigationState: {
      prev, next,
    }
  }
}