import { Component, ViewEncapsulation, ViewChild, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Subscription, fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'match-list-header',
  templateUrl: './match-list-header.component.html',
  styleUrls: ['./match-list-header.styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MatchListHeaderComponent implements AfterViewInit, OnDestroy {

  private subscription: Subscription;

  @Output() searchChange = new EventEmitter<string>();
  @Output() toggleChange = new EventEmitter<boolean>();

  @ViewChild('inputRef') private inputRef: any;

  ngAfterViewInit() {
    this.subscription = fromEvent(this.inputRef.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        map((event: any) => event.target.value),
      )
      .subscribe(search => this.searchChange.emit(search));
  }

  toggleChanged(event: MatSlideToggleChange) {
    this.toggleChange.emit(event.checked);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}