import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImagePreviewComponent extends TcDumbComponent implements OnInit {

  public source: string;

  constructor(
    private dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.source = this.data;
  }

  onClose() {
    this.dialogRef.close();
  }
}
