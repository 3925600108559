import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TcVideoPlayerService {

  private videoPlayer: HTMLMediaElement;
  private isInit = false;

  public init(video: HTMLMediaElement) {
    this.videoPlayer = video;
    this.isInit = true;
  }

  public play() {
    // This method can be call before video player is loaded in DOM. We wait for it.
    if (this.isInit === true) {
        this.videoPlayer.play();
    } else {
      setTimeout(this.play.bind(this), 250);
    }
  }

  public pause() {
    this.videoPlayer.pause();
  }

  public getTime(): number {
    return parseInt(get(this.videoPlayer, 'currentTime', 0) * 1000 as any, 10);
  }

  public goTo(time: number) {
    // This method can be call before video player is loaded in DOM. We wait for it.
    if (this.isInit === true) {
      this.videoPlayer.currentTime = time;
    } else {
      setTimeout(this.goTo.bind(this, time), 250);
    }
  }
}
