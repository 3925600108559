import { createReducer, on } from '@ngrx/store';
import { clearUser, getTokenSuccess, loginFailure, loginSuccess, resetErrorMessage } from './auth.actions';
import { initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,
  on(getTokenSuccess, (state, { token }) => ({
    ...state,
    token,
  })),
  on(loginSuccess, (state, { user }) => ({
    ...state,
    isAuthenticated: true,
    user,
    errorMessage: null
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    errorMessage: error
  })),
  on(clearUser, (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    errorMessage: null
  })),
  on(resetErrorMessage, (state) => ({
    ...state,
    errorMessage: null
  })),
);

