import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'ngx-input-cell-editor',
  templateUrl: './ngx-input-cell-editor.component.html',
  styleUrls: ['./ngx-input-cell-editor.component.scss'],
})
export class NgxInputCellEditorComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  public value: string;
  public model: string;
  public params: ICellEditorParams;
  public mask = '00:00';

  ngAfterViewInit() {
    setTimeout(() => this.input.nativeElement.focus());
  }

  getValue() {
    return this.value;
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = params.data[params.colDef.field];
    this.model = params.data[params.colDef.field];
    this.mask = params.colDef.cellEditorParams(params).mask;
  }

  public onChange(value) {
    this.value = this.params.colDef.cellEditorParams(this.params).valueSetter(value);
  }
}