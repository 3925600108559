import { ConfigurationListComponent } from './components/smart/configuration-list/configuration-list.component';
import { ConfigurationDetailComponent } from './components/smart/configuration-detail/configuration-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { TcCoreModule } from '@tc/core';
import { ConfigurationEffects } from './store/configuration.effects';

const smartComponents = [
  ConfigurationListComponent,
  ConfigurationDetailComponent
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([ConfigurationEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [ConfigurationDetailComponent]
})
export class ConfigurationModule {}
