import { initializeState } from './users.state';
import { createReducer, on } from '@ngrx/store';
import { saveOrganisations } from './users.actions';

export const intialState = initializeState();

const reducer = createReducer(intialState,
  on(saveOrganisations, (state, { orgs }) => ({
    ...state,
    organisations: orgs
  })),
);

export function usersReducer(state, action) {
  return reducer(state, action);
}