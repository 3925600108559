import { UserRoleEnum } from '../typings';

export class UserModel {
  id: string | number;
  email: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  initiales?: string;
  role?: UserRoleEnum;
}
