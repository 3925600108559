import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcNotificationService } from '@tc/core';
import { tap } from 'rxjs/operators';
import { UsersService } from '../../../services/business-services/users-service';
import { ListRefreshService, ListRefreshType } from '../../match/services/list-refresh.service';
import { ResetUserGoogleAuthDialogComponent } from '../components/smart/reset-user-google-auth-dialog/reset-user-google-auth-dialog.component';
import { UserDetailComponent } from '../components/smart/user-detail/user-detail.component';
import { resetUserAuthKey, resetUserAuthKeySuccess, showResetUserAuthKeyDialog, upsertUser } from './users.actions';

@Injectable()
export class UsersEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly dialog: MatDialog,
        private readonly listRefreshService: ListRefreshService,
        private readonly usersService: UsersService,
        private readonly store: Store<any>,
        private readonly notificationService: TcNotificationService,
        private readonly translateService: TranslateService
    ) { }

    upsertUser = createEffect(() => this.actions$.pipe(
        ofType(upsertUser),
        tap(({ user }) => {
            const dialog = this.dialog.open(UserDetailComponent, {
                height: '740px',
                width: '500px',
                data: user,
            });

            dialog.afterClosed().subscribe((result) => {
                if (!result) {
                    return;
                }

                this.listRefreshService.emitRefresh(ListRefreshType.User);
            });
        })),
        { dispatch: false }
    );


    resetUserAuthKey = createEffect(() => this.actions$.pipe(
        ofType(resetUserAuthKey),
        tap(async ({ user }) => {

            await this.usersService.resetAuthKey(user).then(r => {
                this.store.dispatch(resetUserAuthKeySuccess({}));
            }
            );
        })),
        { dispatch: false }
    );

    resetUserAuthKeySuccess = createEffect(() => this.actions$.pipe(
        ofType(resetUserAuthKeySuccess),
        tap(({ }) => {
            this.notificationService.success(this.translateService.instant('reset-user-google-auth.alerts.reset-success'));
        })),
        { dispatch: false }
    );

    showResetUserAuthKeyDialog = createEffect(() => this.actions$.pipe(
        ofType(showResetUserAuthKeyDialog),
        tap(({ user }) => {
            this.dialog.open(ResetUserGoogleAuthDialogComponent, {
                data: user,
                disableClose: true,
            });
        })),
        { dispatch: false }
    );

}
