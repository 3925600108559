import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { UploadedFiles } from '../../../../shared/interfaces/upload.interface';
import { AddMatchVideo } from '../../../store/matchs.actions';

@Component({
  selector: 'app-upload-videos',
  templateUrl: './upload-videos.component.html',
  styleUrls: ['./upload-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadVideosComponent {

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly dialogRef: MatDialogRef<UploadVideosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  public onClose() {
    this.dialogRef.close();
  }

  public onFilesUpload(files: UploadedFiles[]) {
    this.insertVideos(files);
  }

  private async insertVideos(files: UploadedFiles[]) {
    for (const [index, file] of files.entries()) {
      this.store$.dispatch(new AddMatchVideo({
        file: file.identifier,
        isPrimary: this.data.setFirstPrimary && index === 0,
        matchId: this.data.matchId,
      }));
    }
  }

}
