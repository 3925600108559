import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getBreadcrumbList } from '../../../store/breadcrumb/breadcrumb.selectors';
import { BreadcrumbModel } from '../../../models/breadcrumb.model';
import { Router } from '@angular/router';
import { threadId } from 'worker_threads';
import { navigateToRoute } from '../../../store/main.actions';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbList$: Observable<BreadcrumbModel[]>;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.breadcrumbList$ = this.store.pipe(select(getBreadcrumbList));
  }

  naviteToRoute(breadcrumb: BreadcrumbModel) {
    this.store.dispatch(navigateToRoute({ route: breadcrumb.route }));
  }

}
