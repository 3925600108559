import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Document, EntitiesEnum } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends CrudService<Document> {
  constructor(
    public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    super(EntitiesEnum.Documents, httpClient, basePath, configuration);
  }
}
