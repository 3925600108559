import { Injectable } from '@angular/core';
import { Video } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { getMatchId } from '../../modules/match-analysis/store/analysis.selectors';
import { VideosService } from '../business-services/match-videos-service';

@Injectable({
    providedIn: 'root'
})
export class CustomPermissionsService {

    constructor(
        private readonly store$: Store<TcAppState>,
        private readonly videosService: VideosService,
    ) { }

    public async matchHasPrimaryVideo(): Promise<boolean> {
        const matchId = await this.getMatchId();
        const matchVideos = await this.getMatchVideos(matchId)

        return matchVideos.some(video => video.isPrimary);
    }

    private async getMatchId(): Promise<number> {
        return await this.store$.select(getMatchId).pipe(take(1)).toPromise();
    }

    private async getMatchVideos(matchId: number): Promise<Video[]> {
        return await this.videosService.getMatchVideos(matchId);
    }

}
