import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActorListItem, ActorListTypeEnum, allDeficiencyStatusesList, allPlayerDeficiencyList, allRefereeDeficiencyList } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { getChronoTime } from '../../../../utils/getChronoTime';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { normalizeTime } from '../../../../utils/normalizeTimeFormat';
import { isChronoValide } from '../../../../utils/validateChrono';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { AnalysisService } from '../../services/analysis.service';
import { ChronoService } from '../../services/chrono.service';
import { DeficiencyItem } from '../../store/analysis.interfaces';
import { getActors, getTeams } from '../../store/analysis.selectors';

@Component({
  selector: 'app-deficiencies-list',
  templateUrl: './deficiencies-list.component.html',
  styleUrls: ['./deficiencies-list.component.scss'],
})
export class DeficienciesListComponent implements OnInit {
  @Input() public set deficiencies(deficiencies: DeficiencyItem[]) {
    this.setRows(deficiencies || []);
  }
  @Input() public uncompleted = false;
  @Input() public timelineDifference = 0;

  @Output() updateDeficiencies = new EventEmitter();
  @ViewChild('grid') grid: GridComponent;

  public rows: any[] = [];
  public columns = [];

  private actors: ActorListItem[] = [];
  private teams: any[] = [];

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly translate: TranslateService,
    private readonly chronoService: ChronoService,
    private readonly analysisService: AnalysisService
  ) { }

  async ngOnInit() {
    await this.getActors();
    await this.getTeams();

    this.initColumns();
  }

  private async getActors() {
    const actors = await this.store$.select(getActors).pipe(take(1)).toPromise();
    this.actors = actors.map(actor => ({
      ...actor,
      label: this.analysisService.getActorLabel(actor),
      id: actor?.type === ActorListTypeEnum.referee ? actor['refereeId'] : actor.id,
    }))
  }

  private async getTeams() {
    const teams = await this.store$.select(getTeams).pipe(take(1)).toPromise();
    this.teams = teams.map(team => ({
      ...team,
      label: (team as any).code,
      id: team.id
    }))
  }

  private initColumns() {
    this.columns = [
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.actor'),
        field: 'actor',
        cellEditor: 'selectActorEditor',
        cellRenderer: (params) => params.data.actor?.label,
        cellEditorParams: (params) => {
          return {
            options: this.actors,
          };
        },
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.team'),
        field: 'team',
        cellEditor: 'selectActorEditor',
        cellRenderer: (params) => params.data.team?.label,
        cellEditorParams: (params) => {
          return {
            options: this.teams,
          };
        },
        cellClassRules: {
          'border-red': (params) => this.uncompleted && !params?.value?.label
        }
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.type'),
        field: 'deficiencyType',
        cellEditor: 'selectEditor',
        cellEditorParams: (params) => {
          const actor = this.actors.find(a => a['label'] === params.data?.actor?.label);

          let options = [
            ...allPlayerDeficiencyList(this.translate),
            ...allRefereeDeficiencyList(this.translate),
          ];

          if (actor?.type === ActorListTypeEnum.teamPlayer) {
            options = allPlayerDeficiencyList(this.translate);
          }

          if (actor?.type === ActorListTypeEnum.referee) {
            options = allRefereeDeficiencyList(this.translate);
          }

          return { options: options.map(option => ({ ...option, value: option.label })) };
        },
        cellClassRules: {
          'border-red': (params) => this.uncompleted && !params.value
        }
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.status'),
        field: 'statut',
        cellEditor: 'selectEditor',
        cellEditorParams: (params) => {

          const options = [
            ...allDeficiencyStatusesList(this.translate),
          ];

          return { options: options.map(option => ({ ...option, value: option.label })) };
        },
        cellClassRules: {
          'border-red': (params) => this.uncompleted && !params.value
        }
      },
      {
        headerName: this.translate.instant('deficiencies-list.columns.var'),
        field: 'var',
        cellRenderer: 'checkboxEditor',
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.chrono'),
        field: 'chrono',
        cellEditor: 'chronoInputEditor',
        cellEditorParams: (params) => {
          return {
            valueSetter: (value) => {
              if (isChronoValide(value)) {
                const miliseconds = getChronoTime(value).seconds * 1000;

                params.data.timeline = convertToReadableFormat(miliseconds + this.timelineDifference, 'hh:mm:ss');

                const format = value.replace(':', '').length === 5 ? 'mmm:ss' : 'mm:ss';
                return convertToReadableFormat(miliseconds, format);
              }

              return value;
            },
          }
        },
        cellClassRules: {
          'border-red': (params) => this.uncompleted && !isChronoValide(params.value),
        }
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.timeline'),
        field: 'timeline',
        cellEditor: 'ngxInputEditor',
        cellEditorParams: (params) => {
          return {
            mask: '00:00:00',
            valueSetter: (value) => {
              const miliseconds = normalizeTime(value).seconds * 1000;

              params.data.chrono = convertToReadableFormat(miliseconds - this.timelineDifference, 'mm:ss');

              return convertToReadableFormat(normalizeTime(value).seconds * 1000, 'hh:mm:ss');
            },
          }
        },
        cellClassRules: {
          'border-red': (params) => this.uncompleted && !params.value
        }
      },
      {
        editable: true,
        headerName: this.translate.instant('deficiencies-list.columns.comment'),
        field: 'comment',
        cellEditor: 'inputEditor',
      },
      {
        headerName: this.translate.instant('deficiencies-list.columns.actions'),
        field: 'actions',
        cellRenderer: 'buttonsRender',
        cellRendererParams: {
          buttons: [
            {
              icon: 'delete',
              action: (row) => {
                const index = this.rows.findIndex((r) => row.id ? row.id === r.id : row.uiId === r.uiId);

                this.rows.splice(index, 1);

                this.updateDeficiencies.emit(this.rows);
                this.grid.gridOptions.api.setRowData(this.rows);
              },
            },
          ]
        }
      }
    ];
  }

  public addDeficiency() {
    this.rows.push({
      uiId: new Date().valueOf(),
      keyPointAnalysisId: 42,
      actor: null,
      deficiencyType: null,
      var: false,
      comment: '',
      chrono: convertToReadableFormat(0, 'mm:ss'),
      timeline: convertToReadableFormat(this.timelineDifference, 'hh:mm:ss'),
      invalid: false,
      team: null,
      statut: null
    });

    this.updateDeficiencies.emit(this.rows);
    this.grid.gridOptions.api.setRowData(this.rows);
  }

  public rowUpdated(row) {
    const index = this.rows.findIndex((r) => row.id ? row.id === r.id : row.uiId === r.uiId);

    this.rows[index] = row;
    this.updateDeficiencies.emit(this.rows);

    setTimeout(() => {
      this.grid.gridOptions.api.refreshCells();
    });
  }

  private setRows(deficiences: DeficiencyItem[]) {
    if (this.rows.length) {
      return;
    }

    this.rows = (deficiences || []).map(def => ({
      ...def,
      actor: { ...def.actor, label: this.analysisService.getActorLabel(def.actor) },
      team: { ...def.team, label: (def.team as any)?.code }
    }));

    setTimeout(() => this.grid.gridOptions.api.setRowData(this.rows));
  }

}