import { createAction, props } from '@ngrx/store';
import { Player } from '@match-fix/shared';

export const upsertPlayer = createAction(
    '[Players] Upsert Player',
    props<{ player?: Player, isFromPlayerList?: boolean }>()
);

export const upsertPlayerSuccess = createAction(
    '[Players] Upsert Player Success',
    props<{ player?: Player }>()
);

export const deletePlayerSuccess = createAction(
    '[Organisations] Delete Player Success',
    props<{}>()
);
