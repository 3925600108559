import { User } from '@match-fix/shared';

export class CustomUserType {
  static className = 'CustomUser';
  static gqlRootQueryName = 'customuser';
  static gqlColumns: string[] = [
    '_id',
    'role',
    'identityDocumentType',
    'active',
    'birthDate',
    'code',
    'email',
    'firstName',
    'identityDocumentNumber',
    'languageCode',
    'lastName',
    'password',
    'phone',
    'organisationNames',
    'signatureFileIdentifier',
  ];

  static gqlNodeMapper = (node: any): User => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as User;
  };
}
