import { Analysis } from '..';

export class AnalysisType {
  static className = 'Analysis';
  static gqlRootQueryName = 'analysis';
  static gqlColumns: string[] = [
      '_id',
      'userId',
      'analystType',
      'matchId',
      'conclusion',
      'status',
      'isValidated',
  ];

  static gqlNodeMapper = (node: any): Analysis => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Analysis;
    }

}
