import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { resetUserAuthKey } from '../../../store/users.actions';

@Component({
  selector: 'app-reset-user-google-auth-dialog',
  templateUrl: './reset-user-google-auth-dialog.component.html',
  styleUrls: ['./reset-user-google-auth-dialog.component.scss']
})
export class ResetUserGoogleAuthDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ResetUserGoogleAuthDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  submit() {
    this.store.dispatch(resetUserAuthKey({ user: this.data}));
    this.dialogRef.close();
  }

}
