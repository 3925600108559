import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExpertAnalysisState } from './expert-analysis.interfaces';

const getExpertAnalysisStoreState = createFeatureSelector<any>(
    'expertAnalysis'
);

/**
 * Get Not key point loaded in store
 */
export const getNoteKeyPoint = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.noteKeypoint
);

/**
 * Get Note matchId loaded in store
 */
export const getNoteMatchId = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.noteMatchId
);

/**
 * Get expertAnalysisConsultationMatchId loaded in store
 */
export const getExpertAnalysisConsultationMatchId = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.expertAnalysisConsultationMatchId
);

export const getMatchBreadcrumbName = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.noteMatchBreadcrumbName
);

export const getReportMatchId = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.reportMatchId
);

/**
 * Get Note Key Point List loaded in store
 */
export const getNoteKeyPointList = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.noteKeyPointList
);

export const getExpertAnalysisReadonly = createSelector(
    getExpertAnalysisStoreState,
    (state) => state.isReadOnly
);

export const getExpertAnalysisMatchStatus = createSelector(
    getExpertAnalysisStoreState,
    (state: ExpertAnalysisState) => state.matchAnalysisState
);

export const getCategorizationData = createSelector(
    getExpertAnalysisStoreState,
    (state: ExpertAnalysisState) => state.categorizations
);
