import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ButtonCell } from '../../interfaces/grid.interface';

@Component({
  selector: 'buttons-cell-render',
  templateUrl: './buttons-cell-render.component.html',
  styleUrls: ['./buttons-cell-render.component.scss'],
})
export class ButtonsCellRenderComponent implements AgRendererComponent {
  public params: ICellRendererParams;
  public buttons: ButtonCell[];

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.buttons = this.params['buttons'];
  }

  refresh() {
    return false;
  }

  public onClick(button: ButtonCell) {
    button?.action(this.params.data);
  }
}