import { AnalysisState } from './analysis.interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getAnalysisStoreState = createFeatureSelector<AnalysisState>(
    'analysis'
);

/**
 * Get events
 */
export const getEvents = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.eventsList
);

/**
 * Get actors
 */
export const getActors = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.actorsList
);


/**
 * Get teams
 */
export const getTeams = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.teams
);

/**
 * Get matchId
 */
export const getMatchId = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.matchId
);

/**
 * Get analysisId
 */
export const getAnalysisId = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.analysisId
);

/**
 * Get selectedKeyPoint
 */
export const getSelectedKeyPoint = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.selectedKeyPoint
);

/**
 * Get selectedEvent
 */
export const getSelectedEvent = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.selectedEvent
);

/**
 * Get videoTime
 */
export const getVideoTime = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.videoTime
);

/**
 * Get analysisStatus
 */
export const getAnalysisStatus = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.analysisStatus
);

export const getNavigationState = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.navigationState
);

export const getAnalysisBreadcrumbName = createSelector(
    getAnalysisStoreState,
    (state: AnalysisState) => state.analysisBreadcrumbName
);