import { Team } from '..';

export class TeamType {
  static className = 'Team';
  static gqlRootQueryName = 'team';
  static gqlColumns: string[] = [
      '_id',
      'matchId',
      'clubId',
      'order',
      'teamStatus',
  ];

  static gqlNodeMapper = (node: any): Team => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Team;
    }

}
