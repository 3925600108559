import { MatchTimeChrono } from './analysis.interfaces';
import { ChronoStoreActions, ChronoActionTypes } from './chrono.actions';
import { ChronoState } from './chrono.interfaces';

/**
 * Default object to register in chrono store with empty values
 */
export const defaultChronoState: ChronoState = {
    matchId: null,
    videoTime: null,
    matchTime: {time: '--:--'}
};

/**
 * Main reducer function
 */
export function chronoStoreReducer(
  state = defaultChronoState,
  action: ChronoStoreActions
): ChronoState {
  switch (action.type) {
    case ChronoActionTypes.INIT_CHRONO: {
      return init(state, action.payload.matchId, action.payload.videoTime);
    }
    case ChronoActionTypes.UPDATE_VIDEO_TIME_SUCCESS: {
      return updateVideoTime(state, action.payload.videoTime, action.payload.matchTime);
    }
    default: {
      return state;
    }
  }
}

export function init(state: ChronoState, matchId: number, videoTime: number) {
  return {...state, matchId: matchId, videoTime: videoTime};
}

export function updateVideoTime(state, videoTime: number, matchTime: MatchTimeChrono) {
  return {...state, videoTime: videoTime, matchTime: matchTime}
}