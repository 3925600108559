import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState, TcPage } from '@tc/core';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';

@Component({
  selector: 'app-expert-analysis-consultation-page',
  templateUrl: './expert-analysis-consultation-page.component.html',
  styleUrls: ['./expert-analysis-consultation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertAnalysisConsultationPageComponent extends TcPage implements OnInit {

  constructor(
    public readonly store: Store<TcAppState>,
  ) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(getBreadcrumb({ route: RouteEnum.ExpertAnalysisConsultation }));
  }

}
