import { Match } from '@match-fix/shared';

export class MatchWithAnalystType {
  static className = 'MatchWithAnalyst';
  static gqlRootQueryName = 'matchwithanalyst';
  static gqlColumns: string[] = [
    '_id',
    'stadium',
    'competition',
    'clubA',
    'clubB',
    'stadium',
    'referees',
    'analysts',
    'totalVideos',
    'matchState',
    'matchAnalysisState',
    'userIds',
    'matchStatus',
    'date'
  ];

  static gqlNodeMapper = (node: any): Match => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Match;
  };
}
