import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'checkbox-cell-editor',
  templateUrl: './checkbox-cell-editor.component.html',
  styleUrls: ['./checkbox-cell-editor.component.scss']
})
export class CheckboxCellEditorComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public value: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.data[params.colDef.field];
  }

  onChange(checked: boolean) {
    this.value = checked;
    this.params.node.setDataValue(this.params.colDef.field, this.value);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
