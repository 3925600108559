import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { TeamListItem } from '@match-fix/shared';

@Component({
  selector: 'app-select-team-cell-editor',
  templateUrl: './select-team-cell-editor.component.html',
  styleUrls: ['./select-team-cell-editor.component.scss']
})
export class SelectTeamCellEditorComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('select') select: MatSelect;

  public value: TeamListItem;
  public params: ICellEditorParams;

  getValue() {
    return this.value;
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = params.data[params.colDef.field];
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.select.open());
  }

  public onChange(event) {
    this.params.data.deficiencyTypeId = null;
    this.value = event.value;

    this.params.api.stopEditing();
  }
}
