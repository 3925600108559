import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatchAnalysisStateEnum } from '@match-fix/shared';
import { TcConfirmDialogComponent } from '@tc/core';
import { AnalystService } from '../../../../../services/business-services/analyst-service';
import { ClubDetailComponent } from '../../../../clubs/components/smart/club-detail/club-detail.component';

@Component({
  selector: 'app-reopen-analysis',
  templateUrl: './reopen-analysis.component.html',
  styleUrls: ['./reopen-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReopenAnalysisComponent {
  public showCancelExpert = false;
  public showCancelOperator = false;
  public showCancelAll = false;
  public disableCancelExpert = false;
  public disableCancelOperator = false;
  public disableDeficiencies = false;

  public readonly modelEnum = {
    cancelExpert: 'cancelExpert',
    cancelOperator: 'cancelOperator',
    cancelAll: 'cancelAll'
  };
  public model = {
    cancelExpert: false,
    cancelOperator: false,
    cancelAll: false
  };

  public deleteDeficiencies = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly analystService: AnalystService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<ClubDetailComponent>
  ) {
    this.showCancelExpert = [MatchAnalysisStateEnum.Classified].includes(
      data.matchAnalysisState
    );
    this.showCancelOperator = this.showCancelAll =[
      MatchAnalysisStateEnum.Classified,
      MatchAnalysisStateEnum.Validated,
      MatchAnalysisStateEnum.InProgress
    ].includes(data.matchAnalysisState);
  }

  public change(value) {
    this.model[value] = !this.model[value];

    if (this.model.cancelAll) {
      this.model.cancelOperator = true;
      this.deleteDeficiencies = true;
    }

    if (this.model.cancelOperator && this.data.matchAnalysisState === MatchAnalysisStateEnum.Classified) {
      this.model.cancelExpert = true;
    }

    if (!this.model.cancelOperator) {
      this.deleteDeficiencies = false;
    }

    this.checkDisableCheckBoxes();
  }

  public confirm() {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'reopen-analysis.confirmation.no',
        title: 'reopen-analysis.confirmation.title',
        yesText: 'reopen-analysis.confirmation.yes',
        message: 'reopen-analysis.confirmation.message',
      },
      panelClass: 'reopen-analysis-dialog-panel'
    });

    dialog.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        await this.reopen();
      }
    });
  }

  private async reopen() {
    if (this.model.cancelAll) {
      await this.analystService.cancelAnalysis(this.data.matchId);
      return this.onClose(true);
    }

    if (this.model.cancelOperator) {
      const promises = [this.analystService.cancelExpert(this.data.analysisExpertId), this.analystService.cancelOperator(this.data.analysisOp1Id, this.deleteDeficiencies)]

      if (this.data.analysisOp2Id) {
        promises.push(this.analystService.cancelOperator(this.data.analysisOp2Id, this.deleteDeficiencies));
      }

      await Promise.all(promises)
      return this.onClose(true);
    }

    if (this.model.cancelExpert) {
      await this.analystService.resetExpert(this.data.analysisExpertId);
    }

    this.onClose(true);
  }

  public onClose(reloadList = false) {
    this.dialogRef.close(reloadList ? 'Yes' : null);
  }

  private checkDisableCheckBoxes() {
    this.disableCancelExpert = this.model.cancelOperator;
    this.disableCancelOperator = this.model.cancelAll;
    this.disableDeficiencies = this.model.cancelAll;
  }
}
