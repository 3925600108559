import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { take } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../../auth/store/auth.selectors';
import { ExpertAnalysisService } from '../../services/expert-analysis.service';
import { FinalizeKeyPointsClassification, SaveCategorizations } from '../../store/expert-analysis.actions';
import { TeamsPlayersCategorization } from '../../store/expert-analysis.interfaces';
import { getCategorizationData, getNoteMatchId } from '../../store/expert-analysis.selectors';

@Component({
  selector: 'app-note-key-points-classification',
  templateUrl: './note-key-points-classification.component.html',
  styleUrls: ['./note-key-points-classification.component.scss']
})
export class NoteKeyPointsClassificationComponent extends TcFormComponent<any> implements OnInit {

  private categorizationData: TeamsPlayersCategorization;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService,
    public store: Store<any>,
    private dialogRef: MatDialogRef<NoteKeyPointsClassificationComponent>,
    private expertAnalysisService: ExpertAnalysisService,
    @Inject(MAT_DIALOG_DATA) public readonly dialogData: any,
  ) {
    super(translate, elem);
  }

  async ngOnInit() {
    this.fields = [
      {
        key: 'comment',
        type: 'textarea',
        templateOptions: {
          autosize: true,
          autosizeMinRows: 6,
          autosizeMaxRows: 8,
        },
      }
    ];

    this.categorizationData = await this.store.select(getCategorizationData).pipe(take(1)).toPromise();
    this.store.dispatch(new SaveCategorizations(null));
  }

  onClose() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  async finalize() {
    const matchId = await this.store.select(getNoteMatchId).pipe(take(1)).toPromise();
    const user = await this.store.select(getAuthenticatedUser).pipe(take(1)).toPromise();

    const players = [...this.categorizationData.teamA, ...this.categorizationData.teamB]
      .map(({ teamPlayerId: id, deficiencyCategory: status }) => ({ id, status }));

    this.store.dispatch(new FinalizeKeyPointsClassification({
      players,
      matchId: matchId,
      comment: this.model.comment,
    }));
    this.dialogRef.close();
  }
}
