import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store } from '@ngrx/store';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { RouteEnum } from '../../modules/main/enums/routes.enum';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.css']
})
export class ReportingPageComponent extends TcPage implements OnInit {

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(getBreadcrumb({ route: RouteEnum.Reporting }));
  }

}
