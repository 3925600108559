import { Stadium } from '@match-fix/shared';

export class CustomStadiumsType {
  static className = 'CustomStadiums';
  static gqlRootQueryName = 'customstadiums';
  static gqlColumns: string[] = [
    '_id',
    'countryId',
    'city',
    'fieldLength',
    'fieldWidth',
    'name',
    'clubId',
    'dimensions',
    `country {
      code
    }`
  ];

  static gqlNodeMapper = (node: any): Stadium => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Stadium;
  };
}
