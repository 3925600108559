export enum MatchStatusEnum {
  Normal = 'Normal',
  PotentiallyFixed = 'PotentiallyFixed',
  Fixed = 'Fixed',
}

export const allMatchStatuses = Object.keys(MatchStatusEnum);

export const allMatchStatusesList = (translate) => ([
  {
    label: translate.instant(`matchStatus.${MatchStatusEnum.Normal}`),
    value: MatchStatusEnum.Normal
  },
  {
    label: translate.instant(`matchStatus.${MatchStatusEnum.PotentiallyFixed}`),
    value: MatchStatusEnum.PotentiallyFixed
  },
  {
    label: translate.instant(`matchStatus.${MatchStatusEnum.Fixed}`),
    value: MatchStatusEnum.Fixed
  },
])