import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { ClubsService } from '../../../services/business-services/clubs.service';

@Injectable()
export class ClubService {

    constructor(
        private readonly store$: Store<TcAppState>,
        private readonly clubsService: ClubsService,
    ) { }

    public async checkClubWithSameName(code: string): Promise<boolean> {
        if (code) {
            const club = await this.clubsService.getByCode(code);

            if (club) {
                return true;
            }
        }
        return false;
    }

}
