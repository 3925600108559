import { Player } from '@match-fix/shared';

export class CustomPlayerType {
  static className = 'CustomPlayer';
  static gqlRootQueryName = 'customplayer';
  static gqlColumns: string[] = [
    '_id',
    'birthDate',
    'lastName',
    'firstName',
    'clubId',
    'clubName',
    'startDate',
    'endDate',
    'teamId',
  ];

  static gqlNodeMapper = (node: any): Player => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Player;
  };
}
