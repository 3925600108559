export class ToggleFilterModel {
  name: string;
  values: any[];
  isChecked?: boolean;
  beforeKey?: string;
  afterKey?: string;
  beforeLabel?: string;
  afterLabel?: string;
  filterType?: string;
  toggleProperty?: string;
}
