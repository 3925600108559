import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ListRefreshName {
  Clubs,
  Players,
  Referees,
};

@Injectable()
export class ClubListRefreshService {

  private _subject: Subject<ListRefreshName> = new Subject();

  get subject(): Subject<ListRefreshName> {
    return this._subject;
  }

  public emitRefresh(type: ListRefreshName) {
    this._subject.next(type);
  }

}