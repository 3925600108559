import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { ExpertAnalysisConsultationPageComponent } from '../../pages/expert-analysis-consultation-page/expert-analysis-consultation-page.component';
import { ExpertAnalysisModule } from '../expert-analysis/expert-analysis.module';
import { ExpertAnalysisStoreEffects } from '../expert-analysis/store/expert-analysis.effects';
import { expertAnalysisStoreReducer } from '../expert-analysis/store/expert-analysis.reducers';
import { SharedModule } from '../shared/shared.module';
import { AutoRefreshComponent } from './components/auto-refresh/auto-refresh.component';
import { ExpertAnalysisConsultationMatchListComponent } from './components/expert-analysis-consultation-match-list/expert-analysis-consultation-match-list.component';
import { ExpertAnalysisConsultationComponent } from './components/expert-analysis-consultation/expert-analysis-consultation.component';
import { KeyPointsListComponent } from './components/key-points-list/key-points-list.component';
import { MatchAnalysisStatusComponent } from './components/match-analysis-status/match-analysis-status.component';
import { VideoPopupComponent } from './components/video-popup/video-popup.component';
import { VideoPreviewComponent } from './components/video-preview/video-preview.component';
import { ExpertAnalysisConsultationService } from './services/expert-analysis-consultation.service';

@NgModule({
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    ExpertAnalysisModule,
    StoreModule.forFeature('expertAnalysis', expertAnalysisStoreReducer),
    EffectsModule.forFeature([ExpertAnalysisStoreEffects]),
  ],
  declarations: [
    ExpertAnalysisConsultationComponent,
    ExpertAnalysisConsultationPageComponent,
    ExpertAnalysisConsultationMatchListComponent,
    AutoRefreshComponent,
    KeyPointsListComponent,
    MatchAnalysisStatusComponent,
    VideoPreviewComponent,
    VideoPopupComponent,
  ],
  exports: [
    ExpertAnalysisConsultationComponent,
    ExpertAnalysisConsultationMatchListComponent,
    AutoRefreshComponent,
    KeyPointsListComponent,
    MatchAnalysisStatusComponent,
    VideoPreviewComponent,
    VideoPopupComponent,
  ],
  providers: [
    ExpertAnalysisConsultationService,
  ]
})
export class ExpertAnalysisConsultationModule { }
