import { Player } from '..';

export class PlayerType {
  static className = 'Player';
  static gqlRootQueryName = 'player';
  static gqlColumns: string[] = [
      '_id',
      'birthDate',
      'firstName',
      'lastName',
  ];

  static gqlNodeMapper = (node: any): Player => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Player;
    }

}
