import { Match } from '..';

export class MatchType {
  static className = 'Match';
  static gqlRootQueryName = 'match';
  static gqlColumns: string[] = [
      '_id',
      'competitionId',
      'matchAnalysisState',
      'matchState',
      'matchStatus',
      'refereeStatus',
      'stadiumId',
      'date',
      'var',
      'wind',
      'rain',
      'weather',
  ];

  static gqlNodeMapper = (node: any): Match => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Match;
    }

}
