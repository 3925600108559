import { Organisation, User } from '@match-fix/shared';
import { createAction, props } from '@ngrx/store';

export const upsertUser = createAction(
    '[Users] Upsert User',
    props<{ user?: User }>()
);

export const saveOrganisations = createAction(
    '[Users] Save organisations',
    props<{ orgs: Array<Organisation & { add?: boolean }> }>(),
);

export const showResetUserAuthKeyDialog = createAction(
    '[users] Show reset user auth key dialog',
    props<{ user?: User }>()
);

export const resetUserAuthKey = createAction(
    '[users] Reset user auth key',
    props<{ user?: User }>()
);

export const resetUserAuthKeySuccess = createAction(
    '[users] Reset user auth key success',
    props<{}>()
);