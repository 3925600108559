import { KeyPointAnalysis } from '..';

export class KeyPointAnalysisType {
  static className = 'KeyPointAnalysis';
  static gqlRootQueryName = 'keypointanalysis';
  static gqlColumns: string[] = [
      '_id',
      'keyPointId',
      'analysisId',
      'keyPointAnalysisStatus',
      'invalid',
      'var',
      'reviewed',
  ];

  static gqlNodeMapper = (node: any): KeyPointAnalysis => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as KeyPointAnalysis;
    }

}
