import { FormGroup } from '@angular/forms';
import { OnInit, OnDestroy } from '@angular/core';
import { MatchError, MatchState } from '../store/matchs.interfaces';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getErrors } from '../store/matchs.selectors';
import { ClearStore } from '../store/matchs.actions';

export abstract class MatchFormAbstract implements OnInit, OnDestroy {
  public errorsSubscription: Subscription;
  public errors: MatchError[];
  public form: FormGroup;

  constructor(protected store: Store<MatchState>) {}

  /**
   * Set the form to use
   */
  SetForm(form: FormGroup) {
    this.form = form;
  }

  ngOnInit(): void {
    this.errorsSubscription = this.store.select(getErrors).subscribe(data => {
      this.errors = data;
      // Set the fields on error
      if (this.errors.length > 0) {
        for (const entry of this.errors) {
          if (this.form.controls[entry.field]) {
            this.form.controls[entry.field].setErrors({ incorrect: true });
          }
        }
        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
    });
  }

  ngOnDestroy() {
    this.errorsSubscription.unsubscribe();

    // If we close the interface, we clear the store of all values
    this.store.dispatch(new ClearStore());
  }

  /**
   * Return the error message for a field
   * @param field Name of the field
   */
  getErrorMessage(field: string) {
    let message = '';
    if (this.errors.length > 0) {
      for (const entry of this.errors) {
        if (entry.field === field) {
          message += entry.message + ' ';
        }
      }
    }
    message.trim();
    return message;
  }
}
