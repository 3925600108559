export enum EntitiesEnum {
  Analysiss = 'Analysiss',
  Chronos = 'Chronos',
  Clubs = 'Clubs',
  ClubPlayers = 'ClubPlayers',
  Competitions = 'Competitions',
  Countrys = 'Countrys',
  DbVersions = 'DbVersions',
  Deficiencies = 'Deficiencies',
  Documents = 'Documents',
  Events = 'Events',
  KeyPoints = 'KeyPoints',
  KeyPointAnalysiss = 'KeyPointAnalysiss',
  ListTypes = 'ListTypes',
  ListValues = 'ListValues',
  Matchs = 'Matchs',
  MatchReferees = 'MatchReferees',
  Organisations = 'Organisations',
  OrganisationUsers = 'OrganisationUsers',
  Players = 'Players',
  Referees = 'Referees',
  Stadiums = 'Stadiums',
  Teams = 'Teams',
  TeamPlayers = 'TeamPlayers',
  Users = 'Users',
  Videos = 'Videos',
}
