import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { UsersService } from '../business-services/users-service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private readonly usersService: UsersService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(
      this.usersService.getAuthToken()
    ).pipe(
      mergeMap(token => {
        if (token) {
          const request = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
          return next.handle(request);
        }

        return next.handle(req);
      })
    );

  }

}