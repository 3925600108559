/**
 * Utility functions to use in the stores. Put here to avoid code duplication.
 */

/**
 * Return the index of specific property of an array of objects based on value. 
 */
export function indexOfProperty(array: any[], property: string, value: any): number | null {
  let index = null;
  for (const item of array) {
    if (item[property] === value) {
      index = array.indexOf(item);
    }
  }
  return index;
}

/**
 * Empty function like the one in PHP. Return boolean true if the value provided is null, undefined, '', 0, empty array or false
 */
export function isEmpty(value: any): boolean {
  if (
    value === false ||
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value === '') ||
    (typeof value === 'number' && value === 0) ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return true;
  }
  return false;
}
