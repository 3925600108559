import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EntitiesEnum, User, UserModel } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { getAuthenticationToken } from '../../modules/auth/store/auth.selectors';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';


@Injectable({
  providedIn: 'root'
})
export class UsersService extends CrudService<User> {

  constructor(
    private readonly store$: Store<TcAppState>,
    public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    super(EntitiesEnum.Users, httpClient, basePath, configuration);
  }

  public async getPublicData(): Promise<UserModel> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient.get<UserModel>(
      `${url}/me`, {
      withCredentials: true,
      headers: headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise();
  }

  public async search(body): Promise<User[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient.post<User[]>(
      `${url}/search`,
      body,
      {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async resetAuthKey(body): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient.post<any>(
      `${url}/resetAuthKey`,
      body,
      {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  async getAuthToken(): Promise<string> {
    return await this.store$.select(getAuthenticationToken).pipe(take(1)).toPromise();
  }

}
