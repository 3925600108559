import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClubPageComponent } from './pages/club-page/club-page.component';
import { CompetitionPageComponent } from './pages/competition-page/competition-page.component';
import { ConfigurationPageComponent } from './pages/configuration-page/configuration-page.component';
import { ExpertAnalysisConsultationPageComponent } from './pages/expert-analysis-consultation-page/expert-analysis-consultation-page.component';
import { ExpertAnalysisPageComponent } from './pages/expert-analysis-page/expert-analysis-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MatchAnalysisPageComponent } from './pages/match-analysis-page/match-analysis-page.component';
import { MatchPageComponent } from './pages/match-page/match-page.component';
import { NoteKeyPointPageComponent } from './pages/note-key-point-page/note-key-point-page.component';
import { OrganisationPageComponent } from './pages/organisation-page/organisation-page.component';
import { PlayerPageComponent } from './pages/player-page/player-page.component';
import { RefereePageComponent } from './pages/referee-page/referee-page.component';
import { ReportViewPageComponent } from './pages/report-view-page/report-view-page.component';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { StadiumPageComponent } from './pages/stadium-page/stadium-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { AuthGuard } from './services/guards/auth.guard';
import { PermissionsGuard } from './services/guards/permissions.guard';
import { PermissionEnum } from './services/permissions/types';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [AuthGuard],
    // outlet: 'login'
  },
  {
    path: '',
    component: MatchPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Matches }
  },
  {
    path: 'organisations',
    component: OrganisationPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Organisations }
  },
  {
    path: 'competitions',
    component: CompetitionPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Competitions }
  },
  {
    path: 'stadiums',
    component: StadiumPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Stadiums }
  },
  {
    path: 'players',
    component: PlayerPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Players }
  },
  {
    path: 'clubs',
    component: ClubPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Clubs }
  },
  {
    path: 'users',
    component: UserPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Users }
  },
  {
    path: 'matches',
    component: MatchPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Matches }
  },
  {
    path: 'expert-analysis',
    component: ExpertAnalysisPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.ExpertMatchList }
  },
  {
    path: 'expert-analysis-consultation',
    component: ExpertAnalysisConsultationPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.ExpertMatchList }
  },
  {
    path: 'note-key-point',
    component: NoteKeyPointPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.ExpertMatchKeyPoints }
  },
  {
    path: 'referees',
    component: RefereePageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Referees }
  },
  {
    path: 'match-analysis',
    component: MatchAnalysisPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.MatchAnalysis }
  },

  {
    path: 'reporting',
    component: ReportingPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Reporting }
  },
  {
    path: 'report-view',
    component: ReportViewPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.ViewAnalysisReport }
  },
  {
    path: 'configuration',
    component: ConfigurationPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: PermissionEnum.Configuration }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
