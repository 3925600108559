import { Component, OnInit } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-transfer-dialog',
  templateUrl: './confirmation-transfer-dialog.component.html',
  styleUrls: ['./confirmation-transfer-dialog.component.scss']
})
export class ConfirmationTransferDialogComponent extends TcDumbComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ConfirmationTransferDialogComponent>,
  ) { 
    super();
  }

  ngOnInit(): void {
  }

  onClose(){
    this.dialogRef.close();
  }
}


