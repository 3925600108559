import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EventTypeEnum, KeyPointLabel, keyPoints } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ChronoService } from '../../services/chrono.service';
import { EventListItem } from '../../store/analysis.interfaces';
import { getEvents } from '../../store/analysis.selectors';
import { getMatchTime } from '../../store/chrono.selectors';
import { AddKeyPoint } from './../../store/analysis.actions';

interface KeyPointLabelLocal extends KeyPointLabel {
  enabled?: boolean;
}

@Component({
  selector: 'app-key-points',
  templateUrl: './key-points.component.html',
  styleUrls: ['./key-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyPointsComponent implements OnInit {
  public keypoints: KeyPointLabelLocal[] = keyPoints;

  constructor(
    private readonly store$: Store<any>,
    private readonly chrono: ChronoService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    await this.checkFirstEnable();
    this.listenEventList();
    this.listenMatchTime();
  }

  public addKeyPoint(keypoint: KeyPointLabelLocal) {
    this.store$.dispatch(new AddKeyPoint({ keyPointType: keypoint.value }));
  }

  private async checkFirstEnable() {
    const eventList = await this.getEventList();

    this.keypoints = this.keypoints.map(key => ({ ...key, enabled: !!eventList.length }))

    this.cdr.detectChanges();
  }

  private listenEventList() {
    this.store$.select(getEvents)
      .subscribe(() => {
        this.checkEnabled();
      });
  }

  private listenMatchTime() {
    this.store$.select(getMatchTime)
      .subscribe(() => {
        this.checkEnabled();
      });
  }

  private async checkEnabled() {
    const videoTime = this.chrono.getVideoTime();
    const matchTime = this.chrono.getMatchTime(videoTime)?.matchTime || 0;

    let events = await this.getEventList();
    events = events.filter(e => e.event && (e.event.matchTime <= matchTime) && e.event.eventType !== EventTypeEnum.Realign);

    const lastEvent: EventListItem = events[events.length - 1];

    let allDisabled;

    if (!lastEvent || !lastEvent.event) {
      allDisabled = true;
    } else {
      allDisabled = [
        EventTypeEnum.FirstHalfEnd,
        EventTypeEnum.SecondHalfEnd,
        EventTypeEnum.FirstExtraEnd,
        EventTypeEnum.SecondExtraEnd,
        EventTypeEnum.MatchEnd,
      ].includes(lastEvent.event.eventType);
    }

    this.keypoints = this.keypoints.map(keyPoint => ({ ...keyPoint, enabled: !allDisabled }))

    this.cdr.detectChanges();
  }

  private async getEventList() {
    return await this.store$.select(getEvents).pipe(take(1)).toPromise();
  }

}