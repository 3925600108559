import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ListRefreshService {

  private _subject: Subject<ListRefreshType> = new Subject();

  get subject(): Subject<ListRefreshType> {
    return this._subject;
  }

  public emitRefresh(type: ListRefreshType) {
    this._subject.next(type);
  }

}

export enum ListRefreshType {
  User,
  Match,
  Competition,
  NoteKeyPoints,
  Stadiums,
  Organisations,
}