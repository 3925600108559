import { createReducer, on } from '@ngrx/store';

import { addPlayerToClub, clubFilter, loadClubSuccess, saveClubInStore, resetClubState, clearClubPlayers } from './clubs.actions';
import ClubsState, { initializeState } from './clubs.state';

export const intialState = initializeState();

const reducer = createReducer(intialState,
  on(addPlayerToClub, (state, { payload }) => {
    const old = [...(state.players || []), payload];
    const players = [];
    const data = new Map();

    for (const player of old) {
      data.set(player.id, player);
    }

    for (const [_, value] of data.entries()) {
      players.push(value);
    }

    return {
      ...state,
      players
    };
  }),
  on(clubFilter, (state, { payload }) => ({
    ...state,
    clubFilter: payload
  }
  )),
  on(loadClubSuccess, (state, { payload }) => {
    const players = [];
    const data = new Map();

    for (const player of (payload.players || [])) {
      data.set(player.id, player);
    }

    for (const [_, value] of data.entries()) {
      players.push(value);
    }

    return {
      ...state,
      players
    };
  }),
  on(saveClubInStore, (state, { club }) => ({
    ...state,
    club,
  })),
  on(resetClubState, () => ({
    ...{}
  })),
  on(clearClubPlayers, (state) => ({
    ...state,
    players: []
  }))
);

export function clubsReducer(state, action) {
  return reducer(state, action);
}
