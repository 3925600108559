import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-club-page',
  templateUrl: './club-page.component.html',
  styleUrls: ['./club-page.component.scss']
})
export class ClubPageComponent extends TcPage implements OnInit {

  constructor(store: Store<any>) {
    super(store);
   }

  ngOnInit(): void {
    this.store.dispatch(getBreadcrumb({route: RouteEnum.Clubs}))
  }

}
