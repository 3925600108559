import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { allMatchStatusesList } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcGenericFormComponent, TcTranslateService } from '@tc/core';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { QlAutcompleteService } from 'apps/frontend/src/app/services/core/ql-autocomplete.service';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { ExpertAnalysisService } from '../../services/expert-analysis.service';

@Component({
  selector: 'app-change-match-status-dialog',
  templateUrl: './change-match-status-dialog.component.html',
  styleUrls: ['./change-match-status-dialog.component.scss']
})
export class ChangeMatchStatusDialogComponent extends TcFormComponent<any> implements OnInit {

  constructor(
    translate: TcTranslateService,
    private dialogRef: MatDialogRef<ChangeMatchStatusDialogComponent>,
    private readonly expertAnalysisService: ExpertAnalysisService,
    private store: Store<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    elem: ElementRef,
  ) {

    super(translate, elem);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'matchStatus',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-list.combos.labels.matchStatus'),
              options: allMatchStatusesList(this.translate),
            }
          },
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.model = Object.assign({}, this.data);
  }

  submit() {
    this.changeMatchStatus(this.data);
    this.dialogRef.close('yes');
  }

  public async changeMatchStatus(data: any) {
    await this.expertAnalysisService.setMatchStatusByAdmin(this.model.matchStatus, data.id);
  }

  onClose() {
    this.dialogRef.close('no');
  }

}
