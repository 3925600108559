import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EntitiesEnum, ServerVideo, Video } from '@match-fix/shared';
import { VideoWithChrono } from '../../modules/match/store/matchs.interfaces';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class VideosService extends CrudService<Video> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Videos, httpClient, basePath, configuration);
  }

  /**
   * Function to get all match videos
   */
  async getMatchVideos(matchId: number): Promise<VideoWithChrono[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<VideoWithChrono[]>(
      `${url}/match/${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<VideoWithChrono[]>();
  }

  async getServerVideos(): Promise<ServerVideo[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<ServerVideo[]>(
      `${url}/server-videos/`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<ServerVideo[]>();
  }

   selectServerVideo(
    item: ServerVideo,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<ServerVideo> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .post<ServerVideo>(`${url}/select-server-video/`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      })
      .toPromise<ServerVideo>();
  }

}
