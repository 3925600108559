import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AnalystTypeEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatchFormAbstract } from '../../../abstract/match-form.abstract';
import { ClearAnalysts } from '../../../store/matchs.actions';
import { MatchState } from '../../../store/matchs.interfaces';
import { getAnalysts, getStore } from '../../../store/matchs.selectors';
import { AutoCompleteOptions } from '../auto-complete/types';

@Component({
  selector: 'app-match-analysts',
  templateUrl: './match-analysts.component.html',
  styleUrls: ['./match-analysts.component.scss']
})
export class MatchAnalystsComponent extends MatchFormAbstract implements OnInit, OnDestroy {

  public form: FormGroup;
  public analysts = {
    [AnalystTypeEnum.Operator1]: null,
    [AnalystTypeEnum.Operator2]: null,
    [AnalystTypeEnum.Supervisor]: null,
    [AnalystTypeEnum.Expert]: null,
  };
  public competitionId: number;
  public analystsSubscription: Subscription;
  public autoCompleteOptions: AutoCompleteOptions = {};
  public analystsWrapperCssClass = 'analysts-container';

  public readonly analystTypeEnum = AnalystTypeEnum;

  private competitionSubscription$;

  @ViewChild('Operator1_userId', { static: true }) Operator1UserId: TemplateRef<any>;
  @ViewChild('Operator2_userId', { static: true }) Operator2UserId: TemplateRef<any>;
  @ViewChild('Supervisor_userId', { static: true }) SupervisorUserId: TemplateRef<any>;
  @ViewChild('Expert_userId', { static: true }) ExpertUserId: TemplateRef<any>;

  constructor(
    protected store: Store<MatchState>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(store);
  }

  async ngOnInit() {
    // Subscribe to automatically patch match form value
    this.analystsSubscription = this.store.select(getAnalysts).subscribe(data => {
      this.analysts = {
        [AnalystTypeEnum.Operator1]: data?.find(analyst => analyst.analystType === AnalystTypeEnum.Operator1),
        [AnalystTypeEnum.Operator2]: data?.find(analyst => analyst.analystType === AnalystTypeEnum.Operator2),
        [AnalystTypeEnum.Supervisor]: data?.find(analyst => analyst.analystType === AnalystTypeEnum.Supervisor),
        [AnalystTypeEnum.Expert]: data?.find(analyst => analyst.analystType === AnalystTypeEnum.Expert),
      };
    });

    // Setup form and formControl for the component : add form fields of the component here
    this.form = new FormGroup({
      Supervisor_userId: new FormControl(''),
      Operator1_userId: new FormControl(''),
      Operator2_userId: new FormControl(''),
      Expert_userId: new FormControl(''),
    });

    // Setup the form for the parent
    super.SetForm(this.form);
    // Call the parent to setup error system
    super.ngOnInit();

    await this.initCompetitionId();
    this.listenChangeCompetition();
  }

  public getLabel(body: any): string {
    return [body?.user?.firstName, body?.user?.lastName]
      .filter(name => !!name)
      .map(name => name.trim())
      .join(' ');
  }

  private async initCompetitionId() {
    const matchState = await this.store.select(getStore).pipe(take(1)).toPromise();

    this.competitionId = matchState?.match?.competitionId;
    this.autoCompleteOptions = { competitionId: this.competitionId }
  }

  private listenChangeCompetition() {
    this.competitionSubscription$ = this.store
      .select(getStore)
      .subscribe((matchState: MatchState) => {
        this.autoCompleteOptions = {
          operator1Id: matchState?.analysts?.find(analyst => analyst.analystType === AnalystTypeEnum.Operator1)?.userId,
          operator2Id: matchState?.analysts?.find(analyst => analyst.analystType === AnalystTypeEnum.Operator2)?.userId,
          supervisorId: matchState?.analysts?.find(analyst => analyst.analystType === AnalystTypeEnum.Supervisor)?.userId,
          expertId: matchState?.analysts?.find(analyst => analyst.analystType === AnalystTypeEnum.Expert)?.userId,
          competitionId: matchState?.match?.competitionId,
        }

        this.analystsWrapperCssClass = matchState?.match?.competitionId ? 'analysts-container' : 'analysts-container--disabled';

        if (matchState?.match?.competitionId === this.competitionId) {
          return;
        }

        this.competitionId = matchState?.match?.competitionId;
        this.clearAnalysts();

        this.cdr.detectChanges();
      });
  }

  private clearAnalysts() {
    this.store.dispatch(new ClearAnalysts());

    this.Operator1UserId['resetFormValue']();
    this.Operator2UserId['resetFormValue']();
    this.SupervisorUserId['resetFormValue']();
    this.ExpertUserId['resetFormValue']();
  }

  ngOnDestroy() {
    if (this.competitionSubscription$) {
      this.competitionSubscription$.unsubscribe();
    }
    this.analystsSubscription.unsubscribe();

    super.ngOnDestroy();
  }

}
