import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Country, EntitiesEnum, Stadium } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';

import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { upsertStadiumSuccess } from '../../../store/stadiums.actions';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';

@Component({
  selector: 'app-stadium-detail',
  templateUrl: './stadium-detail.component.html',
  styleUrls: ['./stadium-detail.component.scss']
})
export class StadiumDetailComponent extends TcGenericFormComponent<Stadium> implements OnInit {

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<StadiumDetailComponent>,
    private readonly refresh: ListRefreshService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Stadium,
    elem: ElementRef,
  ) {
    super('Stadiums', entityCollectionServiceFactory, translate, elem);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: false,
              maxLength: 100
            },
          },
          {
            key: 'city',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 50
            },
          },
          //TODO
          // {
          //   key: 'countryId',
          //   type: 'input',
          //   templateOptions: {
          //     type: 'text',
          //     required: true,
          //     maxLength: 50
          //   },
          // }
          {
            key: 'countryId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Country>(EntitiesEnum.Countrys, term, 'id', 'code'),
              listValue: 'country.code',
              required: true,
            },
          },
        ]
      },
      {
        key: 'dimensions',
        className: 'section-label',
        template: '<span><strong>Dimensions</strong></span>',
      },
      {
        fieldGroupClassName: 'form-display-row',
        fieldGroup: [
          {
            key: 'fieldLength',
            type: 'input',
            className: 'small-field',
            templateOptions: {
              type: 'number',
              max: 120,
              keypress(_, event: KeyboardEvent) {
                return (event as any).keyCode === 8 ||
                  (event as any).keyCode === 46 ? true : !isNaN(Number(event.key))
              },
            }
          },
          {
            key: '*',
            className: 'dimensions-inserted-element',
            template: '<div><strong>x</strong></div>',
          },
          {
            key: 'fieldWidth',
            type: 'input',
            className: 'small-field',
            templateOptions: {
              type: 'number',
              max: 90,
              keypress(_, event: KeyboardEvent) {
                return (event as any).keyCode === 8 ||
                  (event as any).keyCode === 46 ? true : !isNaN(Number(event.key))
              }
            }
          }
        ]

      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    this.afterSave = (model) => {
      this.store.dispatch(upsertStadiumSuccess({ stadium: model }));
      this.refresh.emitRefresh(ListRefreshType.Stadiums);
      this.onClose();
    }
  }
  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }
}
