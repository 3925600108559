import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Competition, EntitiesEnum, Organisation } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { filter, tap } from 'rxjs/operators';

import { CountriesService } from '../../../../../services/business-services/country-service';
import { OrganisationsService } from '../../../../../services/business-services/organisations-service';
import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { upsertCompetitionSuccess } from '../../../store/competitions.actions';

@Component({
  selector: 'app-competition-detail',
  templateUrl: './competition-detail.component.html',
  styleUrls: ['./competition-detail.component.scss']
})
export class CompetitionDetailComponent extends TcGenericFormComponent<Competition> implements OnInit, AfterViewInit {

  constructor(
    private readonly refresh: ListRefreshService,
    private readonly countriesService: CountriesService,
    private readonly orgService: OrganisationsService,
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<CompetitionDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Competition,
    elem: ElementRef) {

    super('Competitions', entityCollectionServiceFactory, translate, elem);

    const [organisationId, organisationName, countryName] = [data?.organisationId || null, (data as any)?.organisationName || null, (data as any)?.countryName || null];

    const preselectedOrganisation = organisationId ? {
      value: organisationId, label: organisationName
    } : null;

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'organisationId',
            type: 'autocomplete-list',
            modelOptions: {
              updateOn: 'change'
            },
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Organisation>(EntitiesEnum.Organisations, term, 'id', 'name'),
              listValue: 'organisation.name',
              preselected: preselectedOrganisation
            },
            validators: {
              organisationId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'organisation.country.code',
            type: 'input',
            defaultValue: countryName,
            templateOptions: {
              type: 'text',
              maxLength: 50,
              disabled: true
            },
          },
          {
            key: 'season',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 255
            },
          }
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);

      delete (this.model as any).haveMatch;
      delete (this.model as any).countryId;
      delete (this.model as any).organisationName;
      delete (this.model as any).organisation;
      delete (this.model as any).countryName;
    }
    this.afterSave = (model) => {
      this.store.dispatch(upsertCompetitionSuccess({ competition: model }));
      this.refresh.emitRefresh(ListRefreshType.Competition);

      this.onClose();
    }
  }

  ngAfterViewInit() {
    this.initListener();
  }

  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }

  private initListener() {
    this.form.controls.organisationId.valueChanges
      .pipe(
        tap(console.log),
        filter(value => !!value && typeof value === 'number'),
      )
      .subscribe(id => this.setCountry(id))
  }

  private async setCountry(id: number) {
    const organisation = await this.orgService.get(id);
    const country = await this.countriesService.get((organisation as any).countryId);
    this.form.controls.organisation.setValue({ country: { code: country.code } }, { emitEvent: false });
  }

}

