export enum DeficiencyStatusEnum {
  Normal = 'Normal',
  PotentiallyAbnormal = 'PotentiallyAbnormal',
  Abnormal = 'Abnormal'
}

export const allDeficiencyStatuses = Object.keys(DeficiencyStatusEnum);

export const allDeficiencyStatusesList = (translate) => ([
  {
    label: translate.instant(`deficiencyStatus.${DeficiencyStatusEnum.Normal}`),
    value: DeficiencyStatusEnum.Normal
  },
  {
    label: translate.instant(`deficiencyStatus.${DeficiencyStatusEnum.PotentiallyAbnormal}`),
    value: DeficiencyStatusEnum.PotentiallyAbnormal
  },
  {
    label: translate.instant(`deficiencyStatus.${DeficiencyStatusEnum.Abnormal}`),
    value: DeficiencyStatusEnum.Abnormal
  },
])
