import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { CustomEntitiesEnum, Player } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcGenericListComponent, TcListDisplayColumnType, TcListFilterType, TcListRowActionButtonsPosition, TcListSortType, TcSmartComponent } from '@tc/core';
import { Subscription } from 'rxjs';
import { ClubListRefreshService, ListRefreshName } from '../../../../clubs/services/club-list-refresh.service';
import { upsertPlayer, deletePlayerSuccess } from '../../../store/players.actions';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscription: Subscription;

  constructor(
    private readonly store: Store<any>,
    private readonly refreshService: ClubListRefreshService,
  ) {
    super();
  }

  playersList: TcGenericListComponent<any>;
  @ViewChild('playersList', { static: true }) set setPlayersList(values: TcGenericListComponent<Player>) {
    this.playersList = values;
    this.playersList.entityName = CustomEntitiesEnum.CustomPlayers;
  }

  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.refresh();

    this.subscription = this.refreshService.subject
      .pipe(
        filter(e => e === ListRefreshName.Players)
      )
      .subscribe(() => this.refresh())
  }

  ngOnInit() {
    this.playersList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.playersList.showTotalInActionsHeader = false;

    this.playersList.sortType = TcListSortType.Server;
    this.playersList.filterType = TcListFilterType.Server;
    this.playersList.hasFixedHeader = true;

    this.playersList.hasAddButton = false;
    this.playersList.addItemWhenKeyPresed = true;

    this.playersList.hasActionsLabel = true;
    this.playersList.isPaged = false;

    this.playersList.onScrollDown = () => {
      this.playersList.service.getAll();
    };

    this.playersList.columns = [
      {
        propertyName: 'firstName',
        visible: true
      },
      {
        propertyName: 'lastName',
        visible: true
      },
      {
        propertyName: 'birthDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'clubName',
        visible: true
      },
      {
        propertyName: 'startDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      // {
      //   propertyName: 'enddate',
      //   visible: true
      // }
    ];

    this.playersList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.playersList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editPlayer(row);
      }
    };

    this.playersList.onRowClick = (row: any) => {
      this.editPlayer(row);
    };

    (this.playersList as any).afterDeleteSuccess = (row: any) => {
      this.store.dispatch(deletePlayerSuccess({}));
    };
  }

  public canDelete(row) {
    return !row.teamId;
  }

  addItem() {
    this.store.dispatch(upsertPlayer({ isFromPlayerList: true }));
  }

  private refresh() {
    this.playersList.service.clearCache();
    this.playersList.service.getWithQuery({ initial: 'true' });
  }

  private editPlayer(row: any) {
    this.store.dispatch(upsertPlayer({ player: row, isFromPlayerList: true }));
  }

  private delete = (row: any) => {
    this.playersList.tcList.onDeleteAction(row);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
