import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { getAuthenticationState } from '../../modules/auth/store/auth.selectors';
import { UserRoleEnum } from '@match-fix/shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    return this.store.pipe(
      select(getAuthenticationState),
      map(authenticationState => {
        if (state.url === '/login') {
          switch (authenticationState.user?.role) {
            case UserRoleEnum.Administrator:
              this.router.navigate(['/users']);
              break;
            case UserRoleEnum.Client:
              this.router.navigate(['/expert-analysis']);
              break;
            case UserRoleEnum.Expert:
            case UserRoleEnum.Supervisor:
            case UserRoleEnum.Operator:
              this.router.navigate(['/']);
              break;

            default:
              return true;
          }
        } else {
          if (!!authenticationState.user) {
            return true;
          }

          this.router.navigate(['/login']);

          return false;
        }
      }));

  }
}
