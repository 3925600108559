import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomEntitiesEnum,
  Document,
  Match,
  MatchAnalysisStateEnum,
  MatchStateEnum,
  MatchStatusEnum,
  UserModel,
  UserRoleEnum
} from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  TcAppState,
  TcConfirmDialogComponent,
  TcGenericListComponent,
  TcListDisplayColumnType,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcNotificationService,
  TcSmartComponent
} from '@tc/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompetitionsService } from '../../../../services/business-services/competitions-service';
import { DownloadService } from '../../../../services/business-services/download-service';
import { MatchsService } from '../../../../services/business-services/matchs-service';
import { ReportsService } from '../../../../services/business-services/reports-service';
import { PermissionsService } from '../../../../services/permissions/permissions.service';
import { PermissionEnum } from '../../../../services/permissions/types';
import { getAuthenticatedUser } from '../../../auth/store/auth.selectors';
import { ExpertAnalysisConsultation, GetNoteKeyPointListSuccess, loadChangeMatchStatus, noteKeypoints, SetMatchAnalysisStatus, viewReport } from '../../store/expert-analysis.actions';
import { ChangeMatchStatusDialogComponent } from '../change-match-status-dialog/change-match-status-dialog.component';
import { GenerateProgressDialogComponent } from '../generate-progress-modal/generate-progress-modal.component';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';


@Component({
  selector: 'app-expert-match-list',
  templateUrl: './expert-match-list.component.html',
  styleUrls: ['./expert-match-list.component.scss']
})
export class ExpertMatchListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  public canGenerateReport = false;

  public isUserAdmin = false;
  public authenticatedUser: UserModel;
  public authenticatedUserSubscription: Subscription;

  public documentsData: { [key: number]: Document[] } = {};

  private competitionsIds: number[] = [];

  private isClient = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<TcAppState>,
    private readonly translate: TranslateService,
    private readonly matchsService: MatchsService,
    private readonly reportsService: ReportsService,
    private readonly downloadService: DownloadService,
    private readonly notification: TcNotificationService,
    private readonly permissionsService: PermissionsService,
    private readonly competitionsService: CompetitionsService,
  ) {
    super();
  }

  expertMatchsList: TcGenericListComponent<any>;
  @ViewChild('expertMatchsList', { static: true }) set setExpertMatchsList(values: TcGenericListComponent<Match>) {
    this.expertMatchsList = values;
    this.expertMatchsList.entityName = CustomEntitiesEnum.CustomMatches;
  }
  @ViewChild('colMatchAnalysisStateTemplate', { static: true }) colMatchAnalysisStateTemplate: TemplateRef<any>;
  @ViewChild('colMatchStatusTemplate', { static: true }) colMatchStatusTemplate: TemplateRef<any>;

  @ViewChild('rowMenuActionTemplate', { static: true }) rowMenuActionTemplate: TemplateRef<any>;
  @ViewChild('colMatchRefereeTemplate', { static: true }) colMatchRefereeTemplate: TemplateRef<any>;
  @ViewChild('colMatchScore', { static: true }) colMatchScore: TemplateRef<any>;
  @ViewChild('colMatchDocuments', { static: true }) colMatchDocuments: TemplateRef<any>;
  @ViewChild('colTeamATemplate', { static: true }) colTeamATemplate: TemplateRef<any>;
  @ViewChild('colTeamBTemplate', { static: true }) colTeamBTemplate: TemplateRef<any>;
  @ViewChild('colVarTemplate', { static: true }) colVarTemplate: TemplateRef<any>;

  async ngAfterViewInit() {
    await this.setAvailableCompetitions();
    this.refresh();
  }

  ngOnInit() {
    this.initListeners();
    this.canGenerateReport = this.permissionsService.hasAccess(PermissionEnum.GenerateAnalysisReport);

    this.expertMatchsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.expertMatchsList.showTotalInActionsHeader = false;

    this.expertMatchsList.sortType = TcListSortType.Server;
    this.expertMatchsList.filterType = TcListFilterType.Server;
    //this.expertMatchsList.filterType = TcListFilterType.Disabled;
    this.expertMatchsList.hasFixedHeader = true;
    this.expertMatchsList.isFiltrable = false;

    this.expertMatchsList.hasAddButton = false;
    this.expertMatchsList.addItemWhenKeyPresed = true;

    this.expertMatchsList.hasActionsLabel = true;
    this.expertMatchsList.isPaged = false;

    this.expertMatchsList.onScrollDown = () => {
      this.expertMatchsList.service.getAll();
    };

    const isClient = this.authenticatedUser.role === UserRoleEnum.Client;

    this.expertMatchsList.columns = [
      {
        propertyName: 'competitionName',
        visible: true
      },
      {
        propertyName: 'date',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'clubACode',
        visible: true,
        htmlTemplate: this.colTeamATemplate,
      },
      {
        propertyName: 'clubBCode',
        visible: true,
        htmlTemplate: this.colTeamBTemplate
      },
      {
        propertyName: 'score',
        visible: true,
        htmlTemplate: this.colMatchScore,
      },
      ...(!isClient ? [{
        propertyName: 'supervisorCode',
        visible: true,
      },
      {
        propertyName: 'operator1Code',
        visible: true,
      },
      {
        propertyName: 'operator2Code',
        visible: true,
      },
      {
        propertyName: 'expertCode',
        visible: true,
      },
      {
        propertyName: 'principalReferee',
        visible: true,
      }] : []),
      ...(isClient || this.isUserAdmin ? [{
        propertyName: 'documents',
        visible: true,
        htmlTemplate: this.colMatchDocuments,
      }] : []),
      {
        propertyName: 'matchState',
        visible: true
      },
      {
        propertyName: 'var',
        visible: true,
        htmlTemplate: this.colVarTemplate,
      },
      {
        propertyName: 'matchAnalysisState',
        visible: true,
        htmlTemplate: this.colMatchAnalysisStateTemplate
      },
      {
        propertyName: 'matchStatus',
        visible: true,
        htmlTemplate: this.colMatchStatusTemplate
      }
    ];

    this.expertMatchsList.rowActions = [
      {
        actionName: 'menu',
        icon: 'more_horiz',
        visible: true,
        htmlTemplate: this.rowMenuActionTemplate
      },
    ];
  }

  filterChanged(event: any) {
    this.refresh(event);
  }

  canAccessNoteKeyPoint(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.ExpertMatchKeyPoints,
      () => {
        return (data.matchAnalysisState === MatchAnalysisStateEnum.Validated && (data.expertId === this.authenticatedUser.id || this.isUserAdmin))
          || (data.matchAnalysisState === MatchAnalysisStateEnum.Classified && (this.isClient || this.isUserAdmin));
      }
    );
  }

  canOpenAnalysis(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.AnalysisResults,
      () => {
        if (data.matchState === MatchStateEnum.Live) {
          return data.supervisorId === this.authenticatedUser.id;
        }

        if (data.matchState === MatchStateEnum.InProgress || data.matchState === MatchStateEnum.Processed) {
          return [data.supervisorId, data.expertId].includes(this.authenticatedUser.id);
        }

        return false;
      }
    );
  }

  canGenerate(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.GenerateAnalysisReport,
      () => data.matchAnalysisState === MatchAnalysisStateEnum.Classified
    );
  }

  canChangeMatchStatus(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.ChangeMatchStatus,
      () => data.matchAnalysisState !== MatchAnalysisStateEnum.Classified || this.isUserAdmin
    );
  }

  canAddDocument(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.UploadDocument,
      () => [MatchStateEnum.Processed, MatchStateEnum.InProgress].includes(data.matchState)
    );
  }

  canDownload(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.DownloadAnalysisReport,
      () => data?.matchState === MatchStateEnum.Processed && !!data?.hasReport
    );
  }

  canViewReport(data: any) {
    return this.permissionsService.hasAccess(
      PermissionEnum.ViewAnalysisReport,
      () => Boolean(data?.hasReport) && data.matchState === MatchStateEnum.Processed
    );
  }

  openAnalysis(data: any) {
    this.store.dispatch(new ExpertAnalysisConsultation({ matchId: data.id }));
  }

  viewReport(data) {
    this.store.dispatch(viewReport({ matchId: data.id }));
  }

  addDocument(match) {
    const documentsDialog = this.dialog.open(UploadDocumentsComponent, {
      height: '530px',
      width: '700px',
      data: { matchId: match.id },
    });

    documentsDialog.afterClosed().subscribe(() => this.refresh());
  }

  public async download(data) {
    const report = await this.reportsService.getMatchReport(data.id);

    this.downloadDocument(report);
  }

  async generate(data: any) {
    let canGenerate = true;

    if (data.hasReport) {
      canGenerate = await this.confirmGenerate();
    }

    if (canGenerate) {
      this.generateReport(data);
    }
  }

  changeMatchStatus(data: any) {
    // this.store.dispatch(loadChangeMatchStatus({ analysis: data }));
    const dialog = this.dialog.open(ChangeMatchStatusDialogComponent, {
      height: '270px',
      width: '400px',
      data
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.refresh();
      }
    });
  }

  getMatchAnalysisStateColor(row: any) {
    switch (row.matchStatus) {
      case MatchStatusEnum.Fixed:
        return '#f3a88a';
      case MatchStatusEnum.PotentiallyFixed:
        return '#f2df98';
      case MatchStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  noteKeypoints = (data) => {
    this.store.dispatch(new GetNoteKeyPointListSuccess({ noteKeyPointList: null }));
    this.store.dispatch(new SetMatchAnalysisStatus(data.matchAnalysisState));
    this.store.dispatch(noteKeypoints({ matchId: data.id, matchBreadcrumbName: `${data.clubACode} VS ${data.clubBCode} ${moment(parseInt(data.date, 10)).format('DD/MM/YYYY')}` }));
  }

  getRefereees(row: any) {
    if (row.referees) {
      return row.referees.replace(',', '<br/>');
    }
    return '';
  }

  getMatchVarTitle(row: any) {
    if (row.var)
      return `${this.translate.instant('expert-match-list.labels.yes')}`;
    else
      return `${this.translate.instant('expert-match-list.labels.no')}`;
  }

  downloadDocument(doc: Document) {
    this.downloadService.download(doc);
  }

  private confirmGenerate(): Promise<boolean> {
    return new Promise(res => {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          noText: 'expert-match-list.report.dialog-confirm.noText',
          title: 'expert-match-list.report.dialog-confirm.title',
          yesText: 'expert-match-list.report.dialog-confirm.yesText',
          message: 'expert-match-list.report.dialog-confirm.message',
        },
        panelClass: 'report-generation-confirm-dialog',
      });

      dialog.afterClosed().subscribe(result => res(result === 'yes'));
    });
  }

  private async generateReport(data: any) {
    const modal = this.dialog.open(GenerateProgressDialogComponent, {
      disableClose: true,
      panelClass: 'report-generation-progress-dialog'
    });

    await this.reportsService.generateReport(data.id);

    modal.close();
    this.refresh();
    this.notification.success(this.translate.instant('expert-match-list.report.notification'));
  }

  private refresh(filter = null) {
    const isClient = this.authenticatedUser.role === UserRoleEnum.Client;
    this.expertMatchsList.service.clearCache();
    this.expertMatchsList.service
      .getWithQuery({
        initial: 'true',
        ...(isClient
          ? {
            filter: JSON.stringify({ competitionId: { value: (this.competitionsIds || []).join(','), filterType: 'In' }, ...(filter || {}) })
          }
          : {
            filter: JSON.stringify(filter || {})
          }),
      })
      .pipe(
        tap(matches => this.addDocs(matches)),
      ).subscribe();
  }

  private initListeners() {
    this.authenticatedUserSubscription = this.store
      .select(getAuthenticatedUser)
      .subscribe(user => {
        this.isUserAdmin = user ? user.role === UserRoleEnum.Administrator : false;
        this.isClient = user ? user.role === UserRoleEnum.Client : false;
        this.authenticatedUser = user;
      });
  }

  private async addDocs(matches: Match[]) {
    const data = {};
    const ids = matches.map(({ id }) => id);
    try {
      const docs = await this.matchsService.getMatchesDocuments(ids);

      for (const doc of (await docs)) {
        if (!data[doc.matchId]) { data[doc.matchId] = []; }
        data[doc.matchId].push(doc);
      }
    } catch (e) {
      console.error(e);
    }

    this.documentsData = data;
  }

  private async setAvailableCompetitions() {
    if (this.authenticatedUser.role === UserRoleEnum.Client) {
      const competitions = await this.competitionsService.getCurrentUserCompetitions()
      this.competitionsIds = competitions.map(({ id }) => id);
    }
  }

  ngOnDestroy() {
    if (this.authenticatedUserSubscription) {
      this.authenticatedUserSubscription.unsubscribe();
    }
  }

}
