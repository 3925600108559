import { createAction, props } from '@ngrx/store';
import { Organisation } from '@match-fix/shared';

export const upsertOrganisation = createAction(
    '[Organisations] Upsert Organisation',
    props<{ organisation?: Organisation }>()
);

export const upsertOrganisationSuccess = createAction(
    '[Organisations] Upsert Success Organisation',
    props<{ organisation?: Organisation }>()
);

export const deleteOrganisationSuccess = createAction(
    '[Organisations] Delete Success Organisation',
    props<{}>()
);
