import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomEntitiesEnum, Stadium } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcGenericListComponent,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent
} from '@tc/core';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { upsertStadium } from '../../../../stadiums/store/stadiums.actions';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-stadium-list',
  templateUrl: './stadium-list.component.html',
  styleUrls: ['./stadium-list.component.scss']
})
export class StadiumListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  constructor(
    private store: Store<any>,
    private readonly refresh: ListRefreshService,
  ) {
    super();
  }

  @ViewChild('dimensionsTemplate', { static: true }) dimensionsTemplate: TemplateRef<any>;

  stadiumsList: TcGenericListComponent<any>;
  @ViewChild('stadiumsList', { static: true }) set setStadiumsList(values: TcGenericListComponent<Stadium>) {
    this.stadiumsList = values;
    this.stadiumsList.entityName = CustomEntitiesEnum.CustomStadiums;
  }

  ngAfterViewInit() {
    this.refreshList()

    this.refresh.subject
      .pipe(filter((val) => val === ListRefreshType.Stadiums))
      .subscribe(() => this.refreshList());
  }

  ngOnInit() {
    this.stadiumsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.stadiumsList.showTotalInActionsHeader = false;

    this.stadiumsList.sortType = TcListSortType.Server;
    this.stadiumsList.filterType = TcListFilterType.Server;
    this.stadiumsList.hasFixedHeader = true;

    this.stadiumsList.hasAddButton = false;
    this.stadiumsList.addItemWhenKeyPresed = true;

    this.stadiumsList.hasActionsLabel = true;
    this.stadiumsList.isPaged = false;

    this.stadiumsList.onScrollDown = () => {
      this.stadiumsList.service.getAll();
    };

    this.stadiumsList.columns = [
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'city',
        visible: true
      },
      {
        propertyName: 'country.code',
        visible: true
      },
      {
        propertyName: 'dimensions',
        visible: true,
        htmlTemplate: this.dimensionsTemplate,
      }
    ];

    this.stadiumsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true
      }
    ];

    this.stadiumsList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editStadium(row);
      }
    };

    this.stadiumsList.onRowClick = (row: any) => {
      this.editStadium(row);
    };
  }

  addItem() {
    this.store.dispatch(upsertStadium({}));
  }

  private editStadium(row: any) {
    this.store.dispatch(upsertStadium({ stadium: row }));
  }

  private refreshList() {
    this.stadiumsList.service.clearCache();
    this.stadiumsList.service.getWithQuery({ initial: 'true' });
  }

}
