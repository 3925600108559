import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { RefereeDetailComponent } from './components/smart/referee-detail/referee-detail.component';
import { RefereeListComponent } from './components/smart/referee-list/referee-list.component';
import { EffectsModule } from '@ngrx/effects';
import { RefereesEffects } from './store/referees.effects';

const smartComponents = [RefereeDetailComponent, RefereeListComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([RefereesEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [RefereeDetailComponent]
})
export class RefereesModule { }
