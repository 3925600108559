import { createAction, props } from '@ngrx/store';
import { Competition } from '@match-fix/shared';

export const upsertCompetition = createAction(
    '[Competitions] Upsert Competition',
    props<{ competition?: Competition }>()
);

export const upsertCompetitionSuccess = createAction(
    '[Competitions] Upsert Success Competition',
    props<{ competition?: Competition }>()
);

export const deleteCompetitionSuccess = createAction(
    '[Competitions] Delete Success Competition',
    props<{}>()
);
