import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EntitiesEnum, OrganisationUser } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
    providedIn: 'root'
})
export class OrganisationUserService extends CrudService<OrganisationUser> {
    constructor(public httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration) {
        super(EntitiesEnum.OrganisationUsers, httpClient, basePath, configuration);
    }

    async getByUser(userId: number): Promise<OrganisationUser[]> {
        const headers = this.defaultHeaders;
        const url = this.getEntityServiceEndpoint();
        return this.httpClient.get<OrganisationUser[]>(
            `${url}/user/${userId}`, {
            withCredentials: true,
            headers,
            observe: 'body',
            reportProgress: false
        }
        ).toPromise();
    }

    async setForUser(userId: number, organisations: { id: number }[]): Promise<OrganisationUser[]> {
        const headers = this.defaultHeaders;
        const url = this.getEntityServiceEndpoint();
        return this.httpClient.post<OrganisationUser[]>(
            `${url}/user/${userId}`,
            { organisations },
            {
                withCredentials: true,
                headers,
                observe: 'body',
                reportProgress: false
            }
        ).toPromise();
    }
}
