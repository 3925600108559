/**
 * Receive a value with 'hh' | 'hhmm' | hhmmss format and convert to readable or usable format.
 */
export function normalizeTime(time: string) {
    const value = `${time}${'0'.repeat(6 - time.toString().length)}`.split('');
    value.splice(4, 0, ':');
    value.splice(2, 0, ':');
    const values = value.join('').split(':').map(v => parseInt(v, 10));

    return {
        hhmm: `${value[0]}${value[1]}:${value[3]}${value[4]}`,
        hhmmss: `${value[0]}${value[1]}:${value[3]}${value[4]}:${value[6]}${value[7]}`,
        seconds: values[0] * 3600 + (values[1] ? (values[1] * 60) : 0) + (values[2] ? values[2] : 0),
    }
}

/**
 * Receive a value with 'mmss' format and convert to readable or usable format.
 */
export function normalizeTimeMinutes(time: string) {
    const value = `${time}${'0'.repeat(4 - time.toString().length)}`.split('');
    value.splice(2, 0, ':');
    const values = value.join('').split(':').map(v => parseInt(v, 10));

    return {
        mm: `${value[0]}${value[1]}`,
        mmss: `${value[0]}${value[1]}:${value[3]}${value[4]}`,
        seconds: (values[0] ? (values[0] * 60) : 0) + (values[1] ? values[1] : 0),
    }
}

/**
 * Receive a value with 'mmmss' format and convert to readable or usable format.
 */
export function normalizeTimeMinutesMMMSS(time: string) {
    const value = `${time}${'0'.repeat(5 - time.toString().length)}`.split('');
    value.splice(3, 0, ':');
    const values = value.join('').split(':').map(v => parseInt(v, 10));

    return {
        mm: `${value[0]}${value[1]}${value[2]}`,
        mmss: `${value[0]}${value[1]}${value[2]}:${value[4]}${value[5]}`,
        seconds: (values[0] ? (values[0] * 60) : 0) + (values[1] ? values[1] : 0),
    }
}