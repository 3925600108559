import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-ngx-input-formly',
    template: `
    <input
        matInput
        [mask]="mask"
        [required]="required"
        [formControl]="formControl"
        (change)="handleChange()"
        >
    `,
})
export class NgxInputFromlyComponent extends FieldType implements OnInit {

    public mask = '00:00:00';
    public required = false;

    ngOnInit() {
        this.mask = this.to.mask || this.mask;
        this.required = this.to.required || this.required;
    }

    public handleChange() {
        if (this.to.setValue) {
            this.to.setValue(this.formControl.value);
        }
    }

}
