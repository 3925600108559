export enum UserAnalysisStatusEnum {
  Normal = 'Normal',
  PotentiallyAbnormal = 'PotentiallyAbnormal',
  Abnormal = 'Abnormal'
}

export const allUserAnalysisStatuses = Object.keys(UserAnalysisStatusEnum);

export const allUserStatusesList = (translate) => ([
  {
    label: translate.instant(`analysisStatus.${UserAnalysisStatusEnum.Normal}`),
    value: UserAnalysisStatusEnum.Normal
  },
  {
    label: translate.instant(`analysisStatus.${UserAnalysisStatusEnum.PotentiallyAbnormal}`),
    value: UserAnalysisStatusEnum.PotentiallyAbnormal
  },
  {
    label: translate.instant(`analysisStatus.${UserAnalysisStatusEnum.Abnormal}`),
    value: UserAnalysisStatusEnum.Abnormal
  },
])