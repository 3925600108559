import { KeyPoint } from '..';

export class KeyPointType {
  static className = 'KeyPoint';
  static gqlRootQueryName = 'keypoint';
  static gqlColumns: string[] = [
      '_id',
      'startChronoId',
      'endChronoId',
      'keyPointType',
      'keyPointAnalysisId',
      'teamPlayerOutId',
      'teamPlayerInId',
      'code',
      'invalid',
      'label',
      'teamId',
      'teamPlayerId',
  ];

  static gqlNodeMapper = (node: any): KeyPoint => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as KeyPoint;
    }

}
