import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { EntitiesEnum, Deficiency } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { ManualAssemblyBody, TeamsPlayersCategorization } from '../../modules/expert-analysis/store/expert-analysis.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DeficiencyService extends CrudService<Deficiency> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Deficiencies, httpClient, basePath, configuration);
  }

  async gedDeficienciesData(body: { ids: number[] }): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<any>(
      `${url}/bulk`, body, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<any>();
  }

  async manualAssembly(body: ManualAssemblyBody) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<any>(
      `${url}/manualAssembly`, body, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<any>();
  }

  async getPlayers(matchId: number) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<TeamsPlayersCategorization>(
      `${url}/${matchId}/players`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<TeamsPlayersCategorization>();
  }

}
