import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MatchState } from './matchs.interfaces';

const getMatchStoreState = createFeatureSelector<MatchState>(
    'match'
);

/**
 * Selector to return only match data
 */
export const getMatch = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.match
);

/**
 * Selector to return only analysts data
 */
export const getAnalysts = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.analysts
);

/**
 * Selector to return only referees data
 */
export const getReferees = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.referees
);

/**
 * Selector to return only teams data
 */
export const getTeams = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.teams
);

/**
 * Selector to return only error data
 */
export const getErrors = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.errors
);

/**
 * Selector to return the all store
 */
export const getStore = createSelector(
    getMatchStoreState,
    (state: MatchState) => state
);


/**
 * Get match videos
 */
export const getMatchVideos = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.videos
);

/**
 * Get server videos
 */
export const getServerVideos = createSelector(
  getMatchStoreState,
  (state: MatchState) => state.serverVideos
);

export const getSelectedMatch = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.selectedMatch
);

/**
 * Selector to return a boolean if analysts have been updated
 */
export const getHasAnalystsUpdates = createSelector(
    getMatchStoreState,
    (state: MatchState) => state.analystsUpdated
);