import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ConsultationStatusData, Document, EntitiesEnum, Match, Stadium, Team, Club, MatchReferee, Analysis, User, Competition } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class MatchsService extends CrudService<Match> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Matchs, httpClient, basePath, configuration);
  }

  /**
   * Function to delete a match
   */
  async deleteAll(id: number): Promise<void> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.delete<void>(
      `${url}/${id}/all`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise();
  }

  // Method used to load all match related information
  public async getAll(matchId: number): Promise<MatchRelatedInformation> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<any>(
      `${url}/${matchId}/all`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }).toPromise<any>();
  }

  // Method used to create match and all related entities
  public async createAll(match): Promise<Match> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Match>(
      `${url}/all`,
      match,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      }).toPromise();
  }

  // Method used to update match and all related entities
  public async updateAll(data): Promise<Match> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.put<Match>(
      `${url}/${data.match.id}/all`,
      data,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      }).toPromise();
  }

  async getMatchesDocuments(ids: number[]): Promise<Document[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Document[]>(
      `${url}/documents`,
      ids,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      }).toPromise();
  }

  async getConsultationStatus(matchId: number): Promise<ConsultationStatusData[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<any>(
      `${url}/consultation-status?matchId=${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<any>();
  }

}

export interface MatchRelatedInformation {
  match: Match;
  stadium: Stadium;
  competition: Competition;
  teams?: Team[];
  clubA: Club;
  clubB: Club;
  referees: any[];
  teamPlayers: any[];
  analysis: Analysis[];
  users: User[];
}