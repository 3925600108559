import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { eventsTypes, EventTypeEnum, EventTypeLabel } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ChronoService } from '../../services/chrono.service';
import { AddEvent, SaveEvent } from '../../store/analysis.actions';
import { EventListItem } from '../../store/analysis.interfaces';
import { getEvents } from '../../store/analysis.selectors';
import { getMatchTime } from '../../store/chrono.selectors';
import { RealignComponent } from '../realign-popup/realign-popup.component';

interface EventTypeLabelLocal extends EventTypeLabel {
  enabled?: boolean;
}

@Component({
  selector: 'app-match-milestones',
  templateUrl: './match-milestones.component.html',
  styleUrls: ['./match-milestones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchMilestonesComponent implements OnInit {
  public milestones: EventTypeLabelLocal[] = eventsTypes;

  private disabledAll = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store$: Store<any>,
    private readonly chrono: ChronoService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    await this.checkEnableFirst();
    this.listenEventList();
    this.listenMatchTime();
  }

  public addMilestone(milestone: EventTypeEnum) {
    this.chrono.pause();
    if (milestone === EventTypeEnum.Realign) {
      this.addRealign();
    } else {
      this.storeEvent(milestone);
    }
  }

  private addRealign() {
    this.dialog.open(RealignComponent, {
      minHeight: '240',
      maxHeight: '300px',
      width: '368px',
    });
  }

  private storeEvent(milestone: EventTypeEnum) {
    // Ask for the store to set a new event with the desired type
    this.store$.dispatch(new AddEvent({ eventType: milestone }));
    // Tell the store to save it now
    this.store$.dispatch(new SaveEvent());
  }

  private async checkEnableFirst() {
    const eventList = await this.getEventList();

    this.milestones[0].enabled = eventList.length === 0;

    this.cdr.detectChanges();
  }

  private async getEventList() {
    return await this.store$.select(getEvents).pipe(take(1)).toPromise();
  }

  private filterMilestones(events: EventListItem[]): EventListItem[] {
    return events.filter(e => !!e.event && e.event.eventType !== EventTypeEnum.Realign);
  }

  private listenMatchTime() {
    this.store$.select(getMatchTime)
      .subscribe(() => {
        this.checkEnablingByMatchTime()
      });
  }

  private async checkEnablingByMatchTime() {
    if (!this.chrono.getMatchEvent(EventTypeEnum.MatchStart)) {
      return;
    }

    let eventList = await this.getEventList();
    eventList = this.filterMilestones(eventList);

    if (!eventList.length) {
      return;
    }

    const lastMatchEvent = this.chrono.getMatchEvent(eventList[eventList.length - 1].event.eventType);
    const videoTime = this.chrono.getVideoTime();
    const matchTime = this.chrono.getMatchTime(videoTime);

    if (!matchTime) {
      this.disabledAll = true;
      this.milestones = this.milestones.map((milestone) => ({ ...milestone, enabled: false }));
    } else if (matchTime.matchTime < lastMatchEvent.matchTime) {
      this.disabledAll = true;
      this.milestones = this.milestones.map((milestone) => ({ ...milestone, enabled: milestone.value === EventTypeEnum.Realign }));
    } else {
      this.disabledAll = false;
      this.milestones = this.milestones.map((milestone) => ({ ...milestone, enabled: this.ableToAdd(milestone, eventList) }));
    }

    this.cdr.detectChanges();
  }

  private listenEventList() {
    this.store$.select(getEvents)
      .subscribe(list => {
        if (!list.length) {
          return;
        }

        const events = this.filterMilestones(list);

        this.milestones = eventsTypes.map((milestone) => {
          return {
            ...milestone,
            enabled: this.ableToAdd(milestone, events)
          };
        });

        this.cdr.detectChanges();
      });
  }

  private ableToAdd(milestone: EventTypeLabel, events: EventListItem[]): boolean {
    if (this.disabledAll) {
      return false;
    }

    const alreadyAdded = events.some(e => e.event.eventType === milestone.value);
    const nextAdded = !!milestone.mandatoryNext.length && events.some(e => milestone.mandatoryNext[0] === e.event.eventType);
    const allRequirementsDone = milestone.mandatoryPrecedent.some(m => events.map(e => e.event.eventType).includes(m));

    return !alreadyAdded && !nextAdded && allRequirementsDone && (this.chrono.getVideoTime() !== 0);
  }

}
