import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UploadTypeEnum } from '@match-fix/shared';
import { TcListComponent, TcListFilterType, TcListSortType } from '@tc/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject, Subscription } from 'rxjs';
import { last, map, tap } from 'rxjs/operators';
import { Retry } from '../../decorators/retry.decorator';
import { UploadedFiles, UploadResponse } from '../../interfaces/upload.interface';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit, OnDestroy {
  @Input() type: UploadTypeEnum = UploadTypeEnum.Video;
  @Output() uploaded = new EventEmitter<UploadedFiles[]>();

  public files: NgxFileDropEntry[];
  public progress: Record<string, number> = {};

  private readonly list$ = new Subject<NgxFileDropEntry[]>();
  private progressUploadList: TcListComponent;

  private uploadSubscription: Subscription;

  @ViewChild('progressUploadList', { static: true }) set setProgressUploadList(values: TcListComponent) {
    this.progressUploadList = values;
  }
  @ViewChild('progressTemplate', { static: true }) progressTemplate: TemplateRef<any>;

  constructor(
    private readonly uploadService: UploadService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.progressUploadList.rows$ = this.list$;
    this.progressUploadList.isFiltrable = false;
    this.progressUploadList.filterType = TcListFilterType.Disabled;
    this.progressUploadList.sortType = TcListSortType.Disabled;
    this.progressUploadList.isPaged = false;
    this.progressUploadList.hasFixedHeader = true;
    this.progressUploadList.hasAddButton = false;

    this.progressUploadList.columns = [
      {
        propertyName: 'relativePath',
        visible: true,
      }, {
        propertyName: 'progress',
        visible: true,
        htmlTemplate: this.progressTemplate,
      }
    ];
  }

  public async drop(files: NgxFileDropEntry[]) {
    this.files = files;
    this.list$.next(files);
    const identifiers: UploadedFiles[] = [];

    for (const droppedFile of files) {

      if (!droppedFile.fileEntry.isFile) {
        continue;
      }

      const file = await this.getFile(droppedFile.fileEntry as FileSystemFileEntry);

      try {
        const success = await this.upload(file, droppedFile.relativePath);
        if (!success) {
          return;
        }

        identifiers.push({
          identifier: success.id,
          name: file.name,
          size: file.size,
          type: file.type,
        });
      } catch (e) {
        console.error('Upload failed', e);
      }
    }

    this.uploaded.emit(identifiers);
  }

  private getFile(entry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve) => {
      entry.file((file: File) => resolve(file));
    });
  }

  @Retry({ maxRetryAttempts: 2 })
  private upload(file: File, path: string): Promise<UploadResponse> {
    return new Promise((resolve, reject) => {
      this.uploadSubscription = this.uploadService.upload(file, path, this.type)
        .pipe(
          map(event => this.getProgress(event, file)),
          tap((event) => {
            if (!event || !event.progress) {
              return;
            }

            this.progress[event.progress.name] = event.progress.percentage;
            this.cdr.detectChanges();
          }),
          last(),
        ).subscribe((response) => {
          if (!response) {
            return reject();
          }
          resolve(response);
        })
    });
  }

  private getProgress(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return { progress: { name: file.name, percentage: 0 } };

      case HttpEventType.UploadProgress:
        const done = Math.round(100 * event.loaded / event.total);
        return { progress: { name: file.name, percentage: done } };

      case HttpEventType.Response:
        return event.body;
    }
  }

  ngOnDestroy() {
    if(this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }
}
