import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ClubPlayer, EntitiesEnum, Player } from '@match-fix/shared';

import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class ClubPlayerService extends CrudService<ClubPlayer> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.ClubPlayers, httpClient, basePath, configuration);
  }

  async getClubPlayer(playerId: number): Promise<ClubPlayer> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<ClubPlayer>(
      `${url}/getByPlayerId?playerId=${playerId}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<ClubPlayer>();
  }
}
