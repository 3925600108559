import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { EntitiesEnum, Event } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class EventService extends CrudService<Event> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Events, httpClient, basePath, configuration);
  }

  /**
   * Custom insertion method because the CRUD service does not handle table without autoincrement.
   */
  async insertEvent(event:Event): Promise<Event> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Event>(
      `${url}/insertEvent?id=${event.id}&type=${event.type}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Event>();
  }

  /**
   * Function to get all match events
   */
  async getMatchEvents(matchId: number) : Promise<Event[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Event[]>(
      `${url}/getMatchEvents?matchId=${matchId}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Event[]>();
  }
}
