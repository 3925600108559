import { normalizeTimeMinutes, normalizeTimeMinutesMMMSS } from './normalizeTimeFormat';

// Normalize time acording to value format
export function getChronoTime(value) {
    const time = value.replace(':', '');

    if (/[0-9]{5}/.test(time)) {
        return normalizeTimeMinutesMMMSS(time);
    }

    if (/[0-9]{4}/.test(time) && time.length === 4) {
        return normalizeTimeMinutes(time);
    }

    return { mm: '00', mmss: '00:00', seconds: 0 };
}