import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActorListItem, ActorListTypeEnum, KeyPoint, NoteDeficiencyItem, NoteKeyPointListItem, NoteKeyPointState, UserRoleEnum, Video } from '@match-fix/shared';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcAction, TcAppState, TcNotificationService } from '@tc/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { AnalystService } from '../../../services/business-services/analyst-service';
import { DeficiencyService } from '../../../services/business-services/deficiency-service';
import { DocumentsService } from '../../../services/business-services/documents.service';
import { KeyPointAnalysisService } from '../../../services/business-services/key-point-analysis-service';
import { KeyPointService } from '../../../services/business-services/key-point-service';
import { VideosService } from '../../../services/business-services/match-videos-service';
import { getAuthenticatedUser } from '../../auth/store/auth.selectors';
import { isEmpty } from '../../match-analysis/store/store.utility';
import { ListRefreshService, ListRefreshType } from '../../match/services/list-refresh.service';
import { SetMatchVideos } from '../../match/store/matchs.actions';
import { AssembleKeyPointsDetailsComponent } from '../components/assemble-key-points-details/assemble-key-points-details.component';
import { ChangeMatchStatusDialogComponent } from '../components/change-match-status-dialog/change-match-status-dialog.component';
import { NoteKeyPointDetailsComponent } from '../components/note-key-point-details/note-key-point-details.component';
import { NoteKeyPointsClassificationComponent } from '../components/note-key-points-classification/note-key-points-classification.component';
import { PlayersCategorizationComponent } from '../components/players-categorization/players-categorization.component';
import { ExpertAnalysisService } from '../services/expert-analysis.service';
import {
  AddDocumentForMatch,
  ClearExpertKeyPoint,
  ExpertAnalysisActionTypes,
  ExpertAnalysisConsultation,
  FinalizeKeyPointsClassification,
  GetNoteKeyPoint,
  GetNoteKeyPointListSuccess,
  InitExpertAnalysis,
  LoadAssemble,
  loadChangeMatchStatus,
  LoadExpertKeyPoint,
  loadKeyPointsClassification,
  ManageAssemble,
  ManageExpertKeyPoint,
  noteKeypoints,
  OpenPlayersCategorizations, SaveExpertKeyPoint,
  SetExpertAnalysisConsultationMatchId,
  SetExpertAnalysisReadOnly,
  SetNoteMatchId,
  SetReportMatchId, UpdateNoteKeyPointSuccess,
  viewReport
} from './expert-analysis.actions';
import { defaultAssembleState, TeamsPlayersCategorization } from './expert-analysis.interfaces';
import { getMatchBreadcrumbName, getNoteMatchId } from './expert-analysis.selectors';

@Injectable()
export class ExpertAnalysisStoreEffects {

  constructor(
    private readonly router: Router,
    private readonly actions: Actions,
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly store$: Store<TcAppState>,
    private readonly keyPointService: KeyPointService,
    private readonly matchVideosService: VideosService,
    private readonly keyPointAnalysisService: KeyPointAnalysisService,
    private readonly analysisService: AnalystService,
    private readonly documentsService: DocumentsService,
    private readonly expertAnalysisService: ExpertAnalysisService,
    private readonly deficiencyService: DeficiencyService,
    private readonly listRefreshService: ListRefreshService,
    private readonly notification: TcNotificationService,
  ) { }

  noteKeypoints = createEffect(
    () =>
      this.actions$.pipe(
        ofType(noteKeypoints),
        tap(action => {
          this.store$.dispatch(new SetNoteMatchId({ matchId: action.matchId, matchBreadcrumbName: action.matchBreadcrumbName }));
          this.router.navigateByUrl('/note-key-point');
        })
      ),
    { dispatch: false }
  );

  viewReport = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewReport),
        tap(action => {
          this.store$.dispatch(new SetReportMatchId({ matchId: action.matchId }));

          this.router.navigateByUrl('/report-view');
        })
      ),
    { dispatch: false }
  );

  loadKeyPointsClassification = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadKeyPointsClassification),
        tap(() => {
          this.dialog.closeAll();
          this.dialog.open(NoteKeyPointsClassificationComponent, {
            height: '370px',
            width: '400px'
          });
        })
      ),
    { dispatch: false }
  );

  @Effect({ dispatch: false })
  openExpertAnalysisConsultation: Observable<TcAction> = this.actions.pipe(
    ofType<ExpertAnalysisConsultation>(ExpertAnalysisActionTypes.EXPERT_ANALYSIS_CONSULTATION),
    tap((action: ExpertAnalysisConsultation) => {
      this.store$.dispatch(new SetExpertAnalysisConsultationMatchId({ matchId: action.payload.matchId }));
      this.router.navigateByUrl('/expert-analysis-consultation');
    })
  );

  @Effect({ dispatch: false })
  initExpertAnalysisEffect: Observable<TcAction> = this.actions.pipe(
    ofType<InitExpertAnalysis>(ExpertAnalysisActionTypes.INIT),
    tap((action: InitExpertAnalysis) => {
      this.init(action.payload.matchId, action.payload.matchBreadcrumbName);
    })
  );

  @Effect({ dispatch: false })
  loadExpertKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<LoadExpertKeyPoint>(ExpertAnalysisActionTypes.LOAD_EXPERT_KEY_POINT),
    tap((action: LoadExpertKeyPoint) => {
      this.store$.dispatch(new GetNoteKeyPoint({ keyPointId: action.payload.keyPointId }));

      this.dialog.open(NoteKeyPointDetailsComponent, {
        height: '800px',
        width: '1400px',
      });
    })
  );

  @Effect({ dispatch: false })
  getNoteKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<GetNoteKeyPoint>(ExpertAnalysisActionTypes.GET_NOTE_KEY_POINT),
    tap((action: GetNoteKeyPoint) => {
      this.loadKeyPoint(action.payload.keyPointId).then(item => {
        this.store$.dispatch(new ManageExpertKeyPoint({ noteKeyPoint: item }));
      });
    })
  );

  @Effect()
  loadAssembleEffect: Observable<TcAction> = this.actions.pipe(
    ofType<LoadAssemble>(ExpertAnalysisActionTypes.LOAD_ASSEMBLE),
    mergeMap((action: LoadAssemble) =>
      from(
        this.loadAssemble(
          action.payload.keyPointId,
          action.payload.deficienyId1,
          action.payload.deficienyId2,
          action.payload.expertDeficiencyId,
        )
      ).pipe(map(item => new ManageAssemble({ assemble: item })))
    ),
    tap(async action => {
      this.dialog.open(AssembleKeyPointsDetailsComponent, {
        height: '635px',
        width: '1300px',
        data: (action as any).payload.assemble
      });
    })
  );

  @Effect({ dispatch: false })
  saveExpertKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<SaveExpertKeyPoint>(ExpertAnalysisActionTypes.SAVE_EXPERT_KEY_POINT),
    tap((action: SaveExpertKeyPoint) => {
      this.setKeyPointValidation(action.payload).then(item => {
        this.store$.dispatch(new ClearExpertKeyPoint());
        this.dialog.closeAll();

        this.store$.dispatch(new UpdateNoteKeyPointSuccess({
          keyPointId: +item.id,
          invalidatedExpert: item.invalid,
        }));
        this.listRefreshService.emitRefresh(ListRefreshType.NoteKeyPoints);
      });
    })
  );

  @Effect({ dispatch: false })
  finalizeKeyPointsClassificationEffect$ = this.actions$.pipe(
    ofType<FinalizeKeyPointsClassification>(ExpertAnalysisActionTypes.FINALIZE_KEY_POINTS_CLASSIFICATION),
    tap(async action => {
      const payload = action.payload;
      this.analysisService.finalizeClassificationKeyPoints(payload.matchId, payload.comment, payload.players).then(result => {
        this.store$.dispatch(new SetExpertAnalysisReadOnly(true));
      });
    }),
  );

  @Effect({ dispatch: false })
  addDocumentForMatch$ = this.actions$.pipe(
    ofType<AddDocumentForMatch>(ExpertAnalysisActionTypes.ADD_DOCUMENT_FOR_MATCH),
    tap(async ({ payload }) => {
      try {
        await this.documentsService.create({
          id: 42,
          matchId: payload.matchId,
          fileIdentifier: payload.fileIdentifier,
          fileName: payload.fileName,
          report: false,
        });
      } catch (e) {
        this.notification.error('Failed to upload document');
        console.error(e);
      }
    }),
  );

  @Effect({ dispatch: false })
  openPlayersCategorizations$ = this.actions$.pipe(
    ofType<OpenPlayersCategorizations>(ExpertAnalysisActionTypes.OPEN_PLAYERS_CATEGOTIZATIONS),
    tap(async () => {
      const matchId = await this.getStoreMatchId();
      const { teamA, teamB }: TeamsPlayersCategorization = await this.deficiencyService.getPlayers(matchId);

      teamA.sort((a, b) => b.number - a.number);
      teamB.sort((a, b) => b.number - a.number);

      this.dialog.open(
        PlayersCategorizationComponent,
        {
          data: { teamA, teamB },
          height: '800px',
          width: '1300px',
        }
      );
    }),
  );

  loadChangeMatchStatus = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadChangeMatchStatus),
        tap(({ analysis }) => {
          this.dialog.open(ChangeMatchStatusDialogComponent, {
            height: '270px',
            width: '400px',
            data: analysis
          });
        })
      ),
    { dispatch: false }
  );

  /**
   * Init or reset the store content
   */
  public async init(matchId: number, matchBreadcrumbName: string) {
    if (!matchId) {
      matchId = await this.getStoreMatchId();
    }

    if (!matchBreadcrumbName) {
      matchBreadcrumbName = await this.getMatchBreadcrumbName();
    }

    this.store$.dispatch(new SetNoteMatchId({ matchId, matchBreadcrumbName }));

    this.loadVideosList(matchId).then(videos => {
      this.store$.dispatch(new SetMatchVideos(videos));
    });

    this.loadNoteKeyPointList(matchId).then(noteKeyPointList => {
      this.store$.dispatch(
        new GetNoteKeyPointListSuccess({ noteKeyPointList })
      );
    });

    const { role } = await this.store$.select(getAuthenticatedUser).pipe(take(1)).toPromise();

    const isReadonly = (role !== UserRoleEnum.Administrator) && await this.expertAnalysisService.analysisForExpertIsValidated();
    this.store$.dispatch(new SetExpertAnalysisReadOnly(isReadonly));
  }

  /**
   * Get matchId from the store
   */
  private async getStoreMatchId(): Promise<number> {
    const matchId = await this.store$
      .select(getNoteMatchId)
      .pipe(take(1))
      .toPromise();

    // Security check
    if (isEmpty(matchId)) {
      throw new Error('MatchId cannot be select in store.');
    }
    return matchId;
  }

  private async getMatchBreadcrumbName(): Promise<string> {
    const matchBreadcrumbName = await this.store$
      .select(getMatchBreadcrumbName)
      .pipe(take(1))
      .toPromise();

    if (isEmpty(matchBreadcrumbName)) {
      throw new Error('MatchBreadcrumbName cannot be select in store.');
    }
    return matchBreadcrumbName;
  }

  /**
   * Get videos from database
   */
  private async loadVideosList(matchId: number): Promise<Video[]> {
    // Load all that we need to construct the videos list
    const videos = await this.matchVideosService.getMatchVideos(matchId);

    // Return the list of videos
    return Promise.resolve(videos);
  }

  /**
   * Get note key point list from database
   */
  private async loadNoteKeyPointList(
    matchId: number
  ): Promise<NoteKeyPointListItem[]> {
    return await this.keyPointService.getMatchNoteKeyPoints(matchId);
  }

  public async loadKeyPoint(keyPointId: number): Promise<NoteKeyPointState> {
    this.store$.dispatch(new ClearExpertKeyPoint());

    return await this.keyPointAnalysisService.getNoteKeyPointState(keyPointId);
  }

  public setKeyPointValidation({ keyPointId, noteKeyPoint }): Promise<KeyPoint> {
    return this.keyPointService.update({ id: keyPointId, invalid: noteKeyPoint.keyPoint.invalid } as KeyPoint);
  }

  public async loadAssemble(
    keyPointId: number,
    deficienyId1: number,
    deficienyId2: number,
    expertDeficiencyId: number,
  ) {
    // TODO : Need to load all analysis from the key point, actors and the two deficiencies
    const assemble = { ...defaultAssembleState };
    assemble.keyPointId = keyPointId;

    assemble['keyPoint'] = await this.keyPointService.get(Number(assemble.keyPointId));

    const defs = await this.getDataByDeficiencyies([deficienyId1, deficienyId2, expertDeficiencyId]);

    assemble.deficiencies = {
      operator1: defs[deficienyId1] || null,
      operator2: defs[deficienyId2] || null,
      expert: defs[expertDeficiencyId] || null,
    };

    return this.preselectValuse(assemble);
  }
  private preselectValuse(assemble: {
    keyPointId: any;
    deficiencies: {
      operator1: { actor: ActorListItem, deficiency: NoteDeficiencyItem };
      operator2: { actor: ActorListItem, deficiency: NoteDeficiencyItem };
      expert: { actor: ActorListItem, deficiency: NoteDeficiencyItem };
    }
  }) {

    const expert = assemble?.deficiencies?.expert;

    if (expert) {
      return assemble;
    }

    const op1 = assemble?.deficiencies?.operator1;
    const op2 = assemble?.deficiencies?.operator2;

    const isSameChrono = Math.floor(op1.deficiency.chrono.videoTime) === Math.floor(op2.deficiency.chrono.videoTime);

    const body = {
      actor: this.isSameActor(op1.actor, op2.actor) ? op1.actor : null,
      deficiency: {
        chrono: isSameChrono ? op1.deficiency.chrono : null,
        comment: [op1.deficiency.comment, op2.deficiency.comment].join(' '),
        deficiencyId: null,
        deficiencyType: op1.deficiency.deficiencyType === op2.deficiency.deficiencyType ? op1.deficiency.deficiencyType : null,
        keyPointAnalysisId: null,
        var: op1.deficiency.invalid && op2.deficiency.invalid,
        invalid: false
      }
    };

    return {
      ...assemble,
      deficiencies: {
        ...assemble.deficiencies,
        expert: body,
      }
    };
  }

  private isSameActor(op1: ActorListItem, op2: ActorListItem): boolean {

    if (op1.type === ActorListTypeEnum.teamPlayer && op1.type === ActorListTypeEnum.teamPlayer && op1.id === op2.id) {
      return true;
    }

    if (op1.type === ActorListTypeEnum.referee && op1.type === ActorListTypeEnum.referee && op1.id === op2.id) {
      return true;
    }

    return false;
  }

  private async getDataByDeficiencyies(ids: number[]): Promise<{ [key: number]: { actor: ActorListItem, deficiency: NoteDeficiencyItem } }> {
    if (!ids?.length) {
      return {};
    }

    const defs = await this.deficiencyService.gedDeficienciesData({ ids });

    return defs;
  }
}
