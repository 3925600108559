import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../shared/shared.module';
import { AssembleKeyPointsDetailsComponent } from './components/assemble-key-points-details/assemble-key-points-details.component';
import { ChangeMatchStatusDialogComponent } from './components/change-match-status-dialog/change-match-status-dialog.component';
import { ExpertMatchFilterComponent } from './components/expert-match-filter/expert-match-filter.component';
import { ExpertMatchListComponent } from './components/expert-match-list/expert-match-list.component';
import { GenerateProgressDialogComponent } from './components/generate-progress-modal/generate-progress-modal.component';
import { KeyPointTypeComponent } from './components/key-point-type/key-point-type.component';
import { NoteKeyPointDetailsComponent } from './components/note-key-point-details/note-key-point-details.component';
import { NoteKeyPointFiltersComponent } from './components/note-key-point-filters/note-key-point-filters.component';
import { NoteKeyPointListComponent } from './components/note-key-point-list/note-key-point-list.component';
import { NoteKeyPointsClassificationComponent } from './components/note-key-points-classification/note-key-points-classification.component';
import { PlayersCategorizationComponent } from './components/players-categorization/players-categorization.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { ExpertAnalysisStoreEffects } from './store/expert-analysis.effects';
import { expertAnalysisStoreReducer } from './store/expert-analysis.reducers';

@NgModule({
  declarations: [
    ExpertMatchListComponent,
    AssembleKeyPointsDetailsComponent,
    NoteKeyPointDetailsComponent,
    NoteKeyPointListComponent,
    KeyPointTypeComponent,
    NoteKeyPointFiltersComponent,
    NoteKeyPointsClassificationComponent,
    UploadDocumentsComponent,
    GenerateProgressDialogComponent,
    ExpertMatchFilterComponent,
    PlayersCategorizationComponent,
    ChangeMatchStatusDialogComponent
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    StoreModule.forFeature('expertAnalysis', expertAnalysisStoreReducer),
    EffectsModule.forFeature([ExpertAnalysisStoreEffects]),
  ],
  exports: [
    ExpertMatchListComponent,
    AssembleKeyPointsDetailsComponent,
    NoteKeyPointDetailsComponent,
    NoteKeyPointListComponent,
    KeyPointTypeComponent,
    NoteKeyPointFiltersComponent,
    UploadDocumentsComponent,
  ],
  entryComponents: [
    ChangeMatchStatusDialogComponent
  ]
})
export class ExpertAnalysisModule { }
