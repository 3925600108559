import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserModel } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcAppComponent,
  TcAppState,
  TcConfigService,
  TcMenuComponent,
  TcMenuItem,
  TcNavMenuNodes
} from '@tc/core';
import { Observable, timer } from 'rxjs';
import { clearUser, logout } from './modules/auth/store/auth.actions';
import { getAuthenticatedUser, getAuthenticationState } from './modules/auth/store/auth.selectors';
import { ExampleDialogComponent } from './modules/examples/components/smart/example-dialog/example-dialog.component';
import { PermissionsService } from './services/permissions/permissions.service';
import { PermissionEnum } from './services/permissions/types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TcAppComponent implements OnInit {
  authenticatedUser$: Observable<UserModel>;

  allMenuItems: any[] = [
    {
      id: 1,
      faIcon: 'fa-futbol',
      label: 'menu.items.matches',
      route: 'matches',
      class: 'menu-item-bold',
      permission: PermissionEnum.Matches
    },
    {
      id: 2,
      faIcon: 'fa-sitemap',
      label: 'menu.items.organisations',
      route: 'organisations',
      class: 'menu-item-bold',
      permission: PermissionEnum.Organisations
    },
    {
      id: 3,
      faIcon: 'fa-trophy',
      label: 'menu.items.competitions',
      route: 'competitions',
      class: 'menu-item-bold',
      permission: PermissionEnum.Competitions
    },
    {
      id: 4,
      faIcon: 'fa-users',
      label: 'menu.items.clubs',
      route: 'clubs',
      class: 'menu-item-bold',
      permission: PermissionEnum.Clubs
    },
    {
      id: 5,
      faIcon: 'fa-user',
      label: 'menu.items.players',
      route: 'players',
      class: 'menu-item-bold',
      permission: PermissionEnum.Players
    },
    {
      id: 6,
      faIcon: 'fa-balance-scale',
      label: 'menu.items.referees',
      route: 'referees',
      class: 'menu-item-bold',
      permission: PermissionEnum.Referees
    },
    {
      id: 7,
      faIcon: 'fa-map-marker-alt',
      label: 'menu.items.stadiums',
      route: 'stadiums',
      class: 'menu-item-bold',
      permission: PermissionEnum.Stadiums
    },
    {
      id: 8,
      faIcon: 'fa-chart-pie',
      label: 'menu.items.analysis',
      route: 'expert-analysis',
      class: 'menu-item-bold',
      permission: PermissionEnum.ExpertMatchList
    },
    {
      id: 9,
      icon: 'build',
      label: 'menu.items.users',
      route: 'users',
      class: 'menu-item-bold',
      permission: PermissionEnum.Users
    },
    {
      id: 10,
      faIcon: 'fa-cogs',
      label: 'menu.items.configuration',
      route: 'configuration',
      class: 'menu-item-bold',
      permission: PermissionEnum.Configuration
    },
    {
      id: 11,
      icon: 'search',
      label: 'menu.items.reporting',
      route: 'reporting',
      class: 'menu-item-bold',
      permission: PermissionEnum.Reporting
    }
  ];
  appMenuItems: any[] = [];

  isCollapsed = false;

  private allowedItems = [];

  currentUserMenu: TcMenuComponent;
  @ViewChild('currentUserMenu', { static: true }) set appCurrentUserMenu(
    value: TcMenuComponent
  ) {
    this.currentUserMenu = value;
  }

  constructor(
    configService: TcConfigService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly store: Store<TcAppState>,
    private readonly permissionsService: PermissionsService
  ) {
    super(configService);
  }

  openExampleDialog() {
    this.dialog.open(ExampleDialogComponent);
  }

  ngOnInit() {
    this.authenticatedUser$ = this.store.select(getAuthenticatedUser);
    this.authenticatedUser$.subscribe(user => {
      if (!user) {
        return;
      }
      this.isCollapsed = false;

      this.permissionsService.init(user);

      this.allowedItems = this.allMenuItems.filter(item =>
        this.filterByPermission(item)
      );
      this.appMenuItems = this.allowedItems;
    });

    this.currentUserMenu.items = [
      {
        name: 'logout',
        icon: 'power_settings_new'
      }
    ];

    this.currentUserMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === 'logout') {
        // todo trigger logout action
        this.store.dispatch(clearUser());
        this.store.dispatch(logout());
      }
    };
  }

  onSelectedNavMenuItem(menuItem: TcNavMenuNodes) {
    // todo trigger navigation action
    this.router.navigate([(menuItem as any).route]);
  }

  expandColapseMenu() {
    this.isCollapsed = !this.isCollapsed;

    this.appMenuItems = this.isCollapsed
      ? this.allowedItems.map(item => ({ ...item, label: '' }))
      : this.allowedItems;
  }

  private filterByPermission(item): boolean {
    return this.permissionsService.hasAccess(item.permission);
  }
}
