import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Club, EntitiesEnum, Organisation } from '@match-fix/shared';

import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService extends CrudService<Club> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Organisations, httpClient, basePath, configuration);
  }

  async getAll(): Promise<Organisation[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Organisation[]>(
      `${url}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Organisation[]>();
  }

}
