import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TcCoreModule } from '@tc/core';

import { ListRefreshService } from '../match/services/list-refresh.service';
import { CompetitionDetailComponent } from './components/smart/competition-detail/competition-detail.component';
import { CompetitionListComponent } from './components/smart/competition-list/competition-list.component';
import { CompetitionsEffects } from './store/competitions.effects';

const smartComponents = [CompetitionDetailComponent, CompetitionListComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([CompetitionsEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [CompetitionDetailComponent],
  providers: [
    ListRefreshService
  ]
})
export class CompetitionsModule { }
