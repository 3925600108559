import { Injectable, Optional, Inject } from '@angular/core';
import { TcService } from '@tc/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { Referee, EntitiesEnum } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class RefereesService extends CrudService<Referee> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Referees, httpClient, basePath, configuration);
  }
}
