import {
  ActorListItem,
  MatchAnalysisStateEnum, NoteDeficiencyItem,
  NoteKeyPointListItem,
  NoteKeyPointState,
  TeamPlayerDeficiencyCategoryEnum
} from '@match-fix/shared';

/**
 * Default value for NoteKeyPointState
 */
export const defaultNoteKeyPointState = {
  keyPointId: null,
  keyPoint: null,
  implication: {
    operator1: null,
    operator2: null,
    expert: null
  },
  analysis: {
    operator1: null,
    operator2: null,
    expert: null
  },
  deficiencies: []
}

export interface ExpertAnalysisState {
  noteMatchId: number; // Id of the match
  noteMatchBreadcrumbName: string;
  expertAnalysisConsultationMatchId: number;
  noteKeypoint: NoteKeyPointState;
  assemble: AssembleState;
  noteKeyPointList: NoteKeyPointListItem[];
  isReadOnly: boolean;
  matchAnalysisState: MatchAnalysisStateEnum,
  reportMatchId: string;
  categorizations: TeamsPlayersCategorization,
};

/**
 * Interface for assembly popup
 */
export interface AssembleState {
  keyPointId: number; // Needed to update the analysis
  deficiencies: {
    operator1: {
      actor: ActorListItem,
      deficiency: NoteDeficiencyItem
    },
    operator2: {
      actor: ActorListItem,
      deficiency: NoteDeficiencyItem
    }
    expert: {
      actor: ActorListItem,
      deficiency: NoteDeficiencyItem
    }
  }
}

/**
 * Default values for AssembleState
 */
export const defaultAssembleState = {
  keyPointId: null,
  deficiencies: {
    operator1: null,
    operator2: null,
    expert: null
  }
}

export interface AddMatchForMatchPayload {
  matchId: number;
  fileIdentifier: string;
  fileName: string;
}

export interface ManualAssemblyBody {
  deficiencyOp1: number;
  deficiencyOp2: number;
  expert: {
    id?: number;
    chronoId: number;
    teamPlayerId?: number;
    refereeId?: number;
    comment: string;
    type: string;
    var: boolean;
  }
}

export interface UpdateNoteKeyPointSuccessPayload {
  id?: number;
  keyPointId: number;
  invalidatedExpert?: boolean;
  keyPointAnalysisStatus?: string;
  var?: boolean;
}

export interface TeamsPlayersCategorization {
  teamA: PlayersCategorization[];
  teamB: PlayersCategorization[];
}

export interface PlayersCategorization {
  teamPlayerId: number;
  firstName: string;
  lastName: string;
  number: number;
  deficienciesCount: number;
  deficiencyCategory?: TeamPlayerDeficiencyCategoryEnum;
}