import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseCustomPlayerModel, Club, ClubPlayer } from '@match-fix/shared';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcConfirmDialogComponent, TcNotificationService, TcTranslateService } from '@tc/core';
import { take, tap } from 'rxjs/operators';
import { ClubPlayerService } from '../../../services/business-services/club-player-service';
import { ClubsService } from '../../../services/business-services/clubs.service';
import { CustomPlayersService } from '../../../services/business-services/custom-player-service';
import { PlayersService } from '../../../services/business-services/players-service';
import { ClubDetailComponent } from '../components/smart/club-detail/club-detail.component';
import { ConfirmationTransferDialogComponent } from '../components/smart/confirmation-transfer-dialog/confirmation-transfer-dialog.component';
import { ClubListRefreshService, ListRefreshName } from '../services/club-list-refresh.service';
import {
  AddPlayer,
  addPlayerToClub,
  ClubActionTypes,
  confirmTransfer,
  CreateClub,
  loadClub,
  loadClubSuccess,
  UpdateClub,
  UpdateClubSuccess as UpdateClubSuccess,
  upsertClub,
  upsertClubSuccess,
  clearClubPlayers
} from './clubs.actions';
import { getClub, getClubPlayersList } from './clubs.selectors';
import { getStartOfDay } from '../../../utils/startOfDay';
import * as moment from 'moment';

@Injectable()
export class ClubsEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly notificationService: TcNotificationService,
    private readonly translateService: TcTranslateService,
    private readonly clubsService: ClubsService,
    private readonly clubPlayerService: ClubPlayerService,
    private readonly store: Store<any>,
    private readonly playersService: PlayersService,
    private readonly customPlayersService: CustomPlayersService,
    private readonly refreshService: ClubListRefreshService,
  ) { }

  upsertClub = createEffect(() => this.actions$.pipe(
    ofType(upsertClub),
    tap(({ club }) => {
      this.dialog.open(ClubDetailComponent, {
        height: !!club ? '862px' : '470px',
        width: '600px',
        data: club,
      });
    })),
    { dispatch: false }
  );

  confirmTransfer = createEffect(() => this.actions$.pipe(
    ofType(confirmTransfer),
    tap(({ payload }) => {
      this.dialog.open(ConfirmationTransferDialogComponent, {
        height: '400px',
        width: '400px',
        data: payload
      });
    })),
    { dispatch: false }
  );

  // TODO
  loadClub = createEffect(() => this.actions$.pipe(
    ofType(loadClub),
    tap(({ id }) => {
      this.clubsService.get(id).then(club => {
        club.id = id;
        this.store.dispatch(loadClubSuccess({ payload: club }));

        // this.store.dispatch(upsertClub({ club }));
      })
    })
  ),
    { dispatch: false }
  );

  loadClubSuccess = createEffect(() => this.actions$.pipe(
    ofType(loadClubSuccess),
    tap(({ payload }) => {
      this.store.dispatch(upsertClub({ club: payload }));
    })
  ),
    { dispatch: false }
  );

  upsertClubSuccess = createEffect(() => this.actions$.pipe(
    ofType(upsertClubSuccess),
    tap(({ club }) => {
      if (club.id) {
        this.notificationService.success(this.translateService.instant('club-detail.alerts.save-success'));
      } else {
        this.notificationService.success(this.translateService.instant('club-detail.alerts.edit-success'));
      }
    })),
    { dispatch: false }
  );

  @Effect({ dispatch: false })
  createClub$ = this.actions$.pipe(
    ofType<CreateClub>(ClubActionTypes.CREATE_CLUB),
    tap(async action => {
      const data: Club = {
        id: null,
        code: action.payload.code,
        name: action.payload.name,
        countryId: action.payload.countryId,
        logoFileIdentifier: action.payload.logoFileIdentifier,
      };

      const club = await this.clubsService.create(data);

      this.store.dispatch(
        upsertClub({ club })
      );
      this.store.dispatch(
        new UpdateClubSuccess()
      );
    }),
  );

  @Effect({ dispatch: false })
  updateClub$ = this.actions$.pipe(
    ofType<UpdateClub>(ClubActionTypes.UPDATE_CLUB),
    tap(async action => {
      const data: Club = {
        id: action.payload.id,
        code: action.payload.code,
        name: action.payload.name,
        countryId: action.payload.countryId,
        logoFileIdentifier: action.payload.logoFileIdentifier,
      };

      await this.clubsService.update(data);

      this.store.dispatch(new UpdateClubSuccess());
    }),
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType<UpdateClubSuccess>(ClubActionTypes.UPDATE_CLUB_SUCCESS),
    tap(async () => {
      this.refreshService.emitRefresh(ListRefreshName.Clubs);
      this.store.dispatch(clearClubPlayers({}));
    }),
  );

  @Effect({ dispatch: false })
  addPlayer$ = this.actions$.pipe(
    ofType<AddPlayer>(ClubActionTypes.ADD_PLAYER),
    tap(async action => {
      const player = action.payload;

      if (!player || !player.id) {
        return;
      }

      const currentClubPlayer = await this.clubPlayerService.getClubPlayer(player.id);
      const club = await this.store.select(getClub).pipe(take(1)).toPromise();

      if (currentClubPlayer) {
        await this.checkTransfer(player, currentClubPlayer, club);
      } else {
        await this.updatePlayerClub(player, club);
      }
    }),
  );

  private async checkTransfer(player: BaseCustomPlayerModel, current: ClubPlayer, club: Club) {
    const players = await this.store.select(getClubPlayersList).pipe(take(1)).toPromise();

    const exists = players.some(p => p.id === current.playerId);
    if (exists) {
      return;
    }

    if (club.id !== current.clubId) {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          noText: 'club-player-dialog.transfer.no',
          title: 'club-player-dialog.transfer.title',
          yesText: 'club-player-dialog.transfer.yes',
          message: 'club-player-dialog.transfer.message',
        },
        panelClass: 'club-player-transfer-dialog-panel'
      });

      dialog.afterClosed().subscribe(async result => {
        if (result === 'yes') {
          await this.updatePlayerClub(player, club);
        }
      });
    }
  }


  private async updatePlayerClub(player: BaseCustomPlayerModel, club: Club) {
    const playerData = await this.playersService.get(player.id);

    const response = await this.customPlayersService.update({
      id: playerData.id,
      firstName: playerData.firstName,
      lastName: playerData.lastName,
      birthDate: playerData.birthDate,

      clubId: club.id,
      startDate: getStartOfDay(moment()),
      endDate: null,
    });
    if (!response) {
      return;
    }

    this.store.dispatch(addPlayerToClub({
      payload: {
        id: playerData.id,
        firstName: playerData.firstName,
        lastName: playerData.lastName,
        birthDate: playerData.birthDate,
      }
    }));
  }
}
