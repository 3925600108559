import { createAction, props } from '@ngrx/store';
import { Referee } from '@match-fix/shared';

export const upsertReferee = createAction(
    '[Referees] Upsert Referee',
    props<{ referee?: Referee }>()
);

export const deleteReferee = createAction(
    '[Referees] Delete Referee',
    props<{ id: number }>()
);

export const upsertRefereeSuccess = createAction(
    '[Referees] Upsert Success Referee',
    props<{ referee?: Referee }>()
);