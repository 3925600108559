import { Video } from '..';

export class VideoType {
  static className = 'Video';
  static gqlRootQueryName = 'video';
  static gqlColumns: string[] = [
      '_id',
      'matchId',
      'endChronoid',
      'startChronoid',
      'isPrimary',
      'fileIdentifier',
  ];

  static gqlNodeMapper = (node: any): Video => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Video;
    }

}
