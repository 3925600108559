import { ServerVideosComponent } from './components/smart/server-videos/server-videos.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatchAnalysisModule } from '../match-analysis/match-analysis.module';
import { SharedModule } from '../shared/shared.module';
import { AutoCompleteComponent } from './components/dumb/auto-complete/auto-complete.component';
import { MatchAnalystsComponent } from './components/dumb/match-analysts/match-analysts.component';
import { MatchRefereeComponent } from './components/dumb/match-referee/match-referee.component';
import { MatchTeamComponent } from './components/dumb/match-team/match-team.component';
import { EditChronoComponent } from './components/smart/edit-video/components/edit-chrono/edit-chrono.component';
import { EditVideoComponent } from './components/smart/edit-video/edit-video.component';
import { ManageVideosComponent } from './components/smart/manage-videos/manage-videos.component';
import { MatchDetailComponent } from './components/smart/match-detail/match-detail.component';
import { MatchListHeaderComponent } from './components/smart/match-list-header/match-list-header.component';
import { MatchListComponent } from './components/smart/match-list/match-list.component';
import { ReopenAnalysisComponent } from './components/smart/reopen-analysis/reopen-analysis.component';
import { UploadVideosComponent } from './components/smart/upload-videos/upload-videos.component';
import { ListRefreshService } from './services/list-refresh.service';
import { MatchsEffects } from './store/matchs.effects';
import { matchStoreReducer } from './store/matchs.reducers';
import { MatTooltipModule } from '@angular/material/tooltip';

const smartComponents = [
  MatchDetailComponent,
  MatchListComponent,
  ManageVideosComponent,
  UploadVideosComponent,
  EditVideoComponent,
  EditChronoComponent,
  ReopenAnalysisComponent,
  ServerVideosComponent
];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    ...smartComponents,
    MatchRefereeComponent,
    MatchAnalystsComponent,
    MatchAnalystsComponent,
    MatchRefereeComponent,
    MatchTeamComponent,
    AutoCompleteComponent,
    MatchListHeaderComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(options),
    SharedModule,
    MatTooltipModule,
    CommonModule,
    TcCoreModule,
    MatAutocompleteModule,
    StoreModule.forFeature('match', matchStoreReducer),
    MatchAnalysisModule,
    MatCheckboxModule,
    EffectsModule.forFeature([MatchsEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [MatchDetailComponent],
  providers: [
    ListRefreshService,
  ]
})
export class MatchsModule { }
