import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'chrono-input',
  templateUrl: './chrono-input.component.html',
  styleUrls: ['./chrono-input.component.scss'],
})
export class ChronoInputComponent extends FieldType implements OnInit {

  public required = false;
  public maxlength = 6;

  ngOnInit() {
    this.required = this.to.required || this.required;
    this.required = this.to.maxlength || this.maxlength;
  }

  public handleChange() {
    if (this.to.setValue) {
      this.to.setValue((this.formControl.value || ''));
    }
  }

}