import { UserProfilEnum } from '@match-fix/shared';
import { UserIdentityDocumentType } from './user-identity-document-type.enum';

export class SelectValues {

  userProfil(): any[] {
    return [
      { value: UserProfilEnum.Administrator, label: 'Administrator' },
      { value: UserProfilEnum.Expert, label: 'Expert' },
      { value: UserProfilEnum.Supervisor, label: 'Supervisor' },
      { value: UserProfilEnum.Operator, label: 'Operator' },
      { value: UserProfilEnum.Client, label: 'Client' }
    ];
  }

  userIdentityDocumentType(): any[] {
    return [
      { value: UserIdentityDocumentType.Passport, label: 'Passport' },
      { value: UserIdentityDocumentType.IdentityCard, label: 'IdentityCard' },
    ];
  }
}
