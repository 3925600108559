import { ClubPlayer } from '..';

export class ClubPlayerType {
  static className = 'ClubPlayer';
  static gqlRootQueryName = 'clubplayer';
  static gqlColumns: string[] = [
      '_id',
      'clubId',
      'playerId',
      'begin',
      'end',
  ];

  static gqlNodeMapper = (node: any): ClubPlayer => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as ClubPlayer;
    }

}
