export enum TeamPlayerDeficiencyCategoryEnum {
  Involved = 'Involved',
  PotentiallyInvolved = 'PotentiallyInvolved',
  Unclassified = 'Unclassified',
  NotInvolved = 'NotInvolved',
}

export const allTeamPlayerDeficiencyCategoryEnum = Object.keys(TeamPlayerDeficiencyCategoryEnum);

export const allTeamPlayerDeficiencyCategoryList = (translate) => ([
  {
    label: translate.instant(`teamPlayerDeficiencyCategory.${TeamPlayerDeficiencyCategoryEnum.Involved}`),
    value: TeamPlayerDeficiencyCategoryEnum.Involved,
  },
  {
    label: translate.instant(`teamPlayerDeficiencyCategory.${TeamPlayerDeficiencyCategoryEnum.PotentiallyInvolved}`),
    value: TeamPlayerDeficiencyCategoryEnum.PotentiallyInvolved,
  },
  {
    label: translate.instant(`teamPlayerDeficiencyCategory.${TeamPlayerDeficiencyCategoryEnum.Unclassified}`),
    value: TeamPlayerDeficiencyCategoryEnum.Unclassified,
  },
  {
    label: translate.instant(`teamPlayerDeficiencyCategory.${TeamPlayerDeficiencyCategoryEnum.NotInvolved}`),
    value: TeamPlayerDeficiencyCategoryEnum.NotInvolved,
  },
]);
