import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core/option';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-formly-autocomplete-type',
  template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (change)="handleEmptyInput($event)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let item of filteredOptions" [value]="item.value" (onSelectionChange)="customSelect($event); selected($event, item)">
        {{ item.label }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class AppFormlyAutocompleteComponent extends FieldType implements OnInit, OnDestroy {

  // Optional: only if you want to rely on `MatInput` implementation
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  filter: Observable<any>;
  filteredOptions: any[] = [];

  filteredOptionsSubscription: Subscription;

  ngOnInit() {

    if (this.to.preselected) {
      this.setListValue({
        value: this.to.preselected.value,
        label: this.to.preselected.label,
        selectedItem: {
          id: this.to.preselected.value,
          name: this.to.preselected.label,
        }
      });
    }

    if (this.to.disabled) {
      this.formControl.disable();
    }

    this.filteredOptionsSubscription = this.formControl.valueChanges
      .pipe(
        startWith(''),
        switchMap(term => this.to.filter(term ? term : null)),
      ).subscribe(s => {
        console.log('subscribe');
        this.filteredOptions = s as any[];
      });
  }

  ngOnDestroy() {
    if (this.filteredOptionsSubscription) {
      this.filteredOptionsSubscription.unsubscribe();
    }
  }

  displayFn(itemId): string | undefined {
    if (itemId && itemId > 0 && this.filteredOptions) {
      const item = this.filteredOptions.find(x => x.value === itemId);
      if (item) {
        return item.label;
      } else {
        return this.getInitialValue();
      }
    } else {
      this.setListValue(null);
      return null;
    }
  }

  selected(event: MatOptionSelectionChange, item: any) {
    if (this.to.customSelect) {
      return;
    }

    if (event.source.selected) {
      this.setListValue(item);
    }

    if (this.to.selected) {
      this.to.selected(item);
    }

  }

  customSelect(e: MatOptionSelectionChange) {
    if (e.isUserInput && this.to.customSelect) {
      this.to.customSelect(this.field.key, e.source.value);
    }
  }

  private getInitialValue() {
    if (this.to.listValue) {
      const listValue = this.to.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        return this.model[values[0]] ? this.model[values[0]][values[1]] : null;
      }
      return this.model[listValue];
    }
  }

  handleEmptyInput(event: any) {
    if (event.target.value === '') {
      if (this.to.customSelect) {
        this.to.customSelect(this.field.key, null);
      } else {
        this.setListValue(null);
      }
    }
  }

  private setListValue(item: any) {

    const selectedItem = this.to.selectedItem;

    if (selectedItem) {
      if (item === null) {
        this.model = Object.assign({});
      } else {
        this.model[selectedItem] = Object.assign({}, item.selectedItem);
      }
    }


    if (this.to.listValue) {
      const listValue = this.to.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        if (item === null) {
          this.model[values[0]] = Object.assign({});
        } else {
          this.model[values[0]] = Object.assign({}, { id: item.value, [values[1]]: item.label });
        }
      } else {
        if (item === null) {
          this.model[listValue] = null;
        } else {
          this.model[listValue] = item.label;
        }
      }
    }
  }

}
