import { createAction, props } from '@ngrx/store';
import { RouteEnum } from '../../enums/routes.enum';
import { BreadcrumbModel } from '../../models/breadcrumb.model';

export const getBreadcrumb = createAction(
    '[Breadcrumb] Get Breadcrumb',
    props<{ route: RouteEnum, name?: string }>()
);

export const setBreadcrumb = createAction(
    '[Breadcrumb] Set Breadcrumb',
    props<{ payload: BreadcrumbModel[]; }>()
);
