import { ListValue } from '..';

export class ListValueType {
  static className = 'ListValue';
  static gqlRootQueryName = 'listvalue';
  static gqlColumns: string[] = [
      '_id',
      'listTypeId',
      'key',
      'value',
  ];

  static gqlNodeMapper = (node: any): ListValue => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as ListValue;
    }

}
