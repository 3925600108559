import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-auto-refresh',
  templateUrl: './auto-refresh.component.html',
  styleUrls: ['./auto-refresh.component.scss']
})
export class AutoRefreshComponent extends TcDumbComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  @Output() timerExpired: EventEmitter<any> = new EventEmitter<any>();

  @Input() searchDate: moment.Moment = moment();
  @Input() elapsTime = 10;

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;

  everySecond: Observable<number> = timer(0, 1000);

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.searchEndDate = moment(this.searchDate).add(this.elapsTime, 'minutes');

    this.subscription = this.everySecond.subscribe(seconds => {
      const currentTime: moment.Moment = moment();
      this.remainingTime = this.searchEndDate.diff(currentTime) / 1000;

      if (this.remainingTime <= 0) {
        this.searchDate = moment();
        this.searchEndDate = moment(this.searchDate).add(this.elapsTime, 'minutes');

        this.timerExpired.emit();
      } else {
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      }
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
