import { Player } from '@match-fix/shared';

export class PlayerWithClubType {
  static className = 'PlayerWithClub';
  static gqlRootQueryName = 'playerwithclub';
  static gqlColumns: string[] = [
    '_id',
    'clubId',
    'name'
  ];

  static gqlNodeMapper = (node: any): Player => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Player;
  };
}
