import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'app-expert-analysis-consultation-match-list',
  templateUrl: './expert-analysis-consultation-match-list.component.html',
  styleUrls: ['./expert-analysis-consultation-match-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertAnalysisConsultationMatchListComponent extends TcSmartComponent {

  constructor() {
    super();
  }

}
