import { Match, ServerVideo } from '@match-fix/shared';
import { cloneDeep } from 'lodash';
import { MatchsActionTypes, MatchsStoreActions } from './matchs.actions';
import { defaultMatchState, MatchError, MatchReferee, MatchState, TeamPlayer, VideoWithChrono } from './matchs.interfaces';

/**
 * Main reducer function
 */
export function matchStoreReducer(
  state = defaultMatchState,
  action: MatchsStoreActions
): MatchState {
  switch (action.type) {
    // Update a match value
    case MatchsActionTypes.UPDATE_MATCH_VALUE: {
      return matchValidator(state, action.payload.key, action.payload.value);
    }
    // Update analyst value
    case MatchsActionTypes.UPDATE_ANALYST_VALUE: {
      return analystValidator(
        state,
        action.payload?.id || '',
        action.payload.key,
        action.payload.value
      );
    }
    //validate referee
    case MatchsActionTypes.VALIDATE_ANALYSTS: {
      return validateAnalysts(
        state);
    }
    // Set a club for a team
    case MatchsActionTypes.SET_TEAM_CLUB: {
      return setTeamClub(state, action.payload.team, action.payload.clubId);
    }
    // Add a player
    case MatchsActionTypes.ADD_TEAM_PLAYER: {
      return addTeamPlayer(
        state,
        action.payload.team
      );
    }
    // Update a team player value
    case MatchsActionTypes.UPDATE_TEAM_PLAYER_VALUE: {
      return teamPlayerValidator(
        state,
        action.payload.team,
        action.payload.id,
        action.payload.key,
        action.payload.value,
        action.payload.playerId
      );
    }
    // Update a team player value
    case MatchsActionTypes.REMOVE_TEAM_PLAYER: {
      return removeTeamPlayer(
        state,
        action.payload.team,
        action.payload.id
      );
    }
    // Update current referee
    case MatchsActionTypes.UPDATE_CURRENT_PLAYER: {
      return updateCurrentPlayer(
        state,
        action.payload.team,
        action.payload.playerId,
        action.payload.titular,
        action.payload.number,
        action.payload.name
      );
    }
    // validate teams
    case MatchsActionTypes.VALIDATE_TEAMS: {
      return validateTeams(
        state
      );
    }
    // Add a referee
    case MatchsActionTypes.ADD_REFEREE: {
      return refereeValidator(
        state
      );
    }
    // Remove a referee
    case MatchsActionTypes.REMOVE_REFEREE: {
      return removeReferee(state, action.payload.id);
    }
    // Update current referee
    case MatchsActionTypes.UPDATE_CURRENT_REFEREE: {
      return updateCurrentReferee(
        state,
        action.payload.refereeId,
        action.payload.name,
        action.payload.role);
    }
    //validate referee
    case MatchsActionTypes.VALIDATE_REFEREE: {
      return validateReferee(
        state);
    }
    case MatchsActionTypes.CREATE_MATCH_SUCCESS: {
      return {
        ...state,
      };
    }
    // Update current referee
    case MatchsActionTypes.GET_CLUB_PLAYERS_SUCCESS: {
      return getClubPlayersSuccess(
        state,
        action.payload.team,
        action.payload.players
      );
    }

    // Clear the all store
    case MatchsActionTypes.CLEAR_STORE: {
      return defaultMatchState;
    }
    // Check the required fields
    case MatchsActionTypes.CHECK_REQUIRED: {
      return checkRequired(state);
    }
    case MatchsActionTypes.SET_MATCH_VIDEOS: {
      return setMatchVideos(state, action.payload);
    }
    case MatchsActionTypes.SET_SERVER_VIDEOS: {
      return setServerVideos(state, action.payload);
    }
    case MatchsActionTypes.ADD_MATCH_VIDEO_SUCCESS: {
      return addMatchVideo(state, action.payload);
    }
    case MatchsActionTypes.UPDATE_MATCH_VIDEO_SUCCESS: {
      return updateMatchVideo(state, action.payload);
    }
    case MatchsActionTypes.DELETE_MATCH_VIDEO_SUCCESS: {
      return deleteMatchVideoSuccess(state, action.payload.id);
    }
    case MatchsActionTypes.CLEAR_ANALYSTS_VALUE: {
      return clearAnalysts(state);
    }

    // Load match data for edit
    case MatchsActionTypes.START_EDIT_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case MatchsActionTypes.SET_MATCH: {
      return setMatch(state, action.payload);
    }

    case MatchsActionTypes.ADD_NEW_PLAYER_SUCCESS: {
      const players = state.teams[(action as any).payload.team].players
        .filter(e => e.id !== 'CURRENT_PLAYER_ID');

      const exists = players.some(e => e.playerId === (action as any).payload.playerId);

      if (exists) {
        return { ...state };
      }

      return {
        ...state,
        teams: {
          ...state.teams,
          [(action as any).payload.team]: {
            ...state.teams[(action as any).payload.team],
            players: [...players, { ...(action as any).payload, id: new Date().toISOString() }]
          }
        }
      }
    }

    default: {
      return state;
    }
  }
}

/**
 * Validators to handle match errors
 */
export function matchValidator(
  state: MatchState,
  key: string,
  value: any
): MatchState {
  // Get the errors from the state, remove the ones related to the field and send the array back.
  let errors = resetErrors([...state.errors], [key]);
  // Execute the checks on the field an populate error array if needed.
  switch (key) {
    case 'date':
      if (empty(value)) {
        errors = [...errors, { field: key, message: 'Field is required.' }];
        // errors.push({ field: key, message: 'Field is required.' });
      }
      break;
    case 'competitionId':
      {
        if (value && isNaN(value)) {
          errors = [...errors, { field: key, message: 'Not an number.' }];
          // errors.push({ field: key, message: 'Not an number.' });
        }
        if (empty(value)) {
          errors = [...errors, { field: key, message: 'Field is required.' }];
          // errors.push({ field: key, message: 'Field is required.' });
        }
      }
      break;
  }

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    // Put the value in the match property of the store
    match: { ...state.match, [key]: value },
    // Update the errors
    errors: errors
  };
}

/**
 * Set a club for a team
 */
export function setTeamClub(
  state: MatchState,
  team: string,
  clubId: number
): MatchState {
  // Create the error key (team prefix + underscore + name of the field)
  const errorkey = team + '_clubId';

  // Get the errors from the state, remove the ones related to the field and send the array back.
  let errors = resetErrors([...state.errors], [errorkey]);

  // Execute the checks on the field an populate error array if needed.
  if (empty(clubId)) {
    // errors =
    // errors.push({ field: errorkey, message: 'Field is required.' });
    errors = [...errors, { field: errorkey, message: 'Field is required.' }];

  } else {
    if (isNaN(clubId)) {
      errors = [...errors, { field: errorkey, message: 'Field is required.' }];
      // errors.push({ field: errorkey, message: 'Not an number.' });
    }
  }

  // We reset the players in case in club has changed during edition
  const players = [];

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        clubId: clubId,
        players: players
      }
    },
    // Update the errors
    errors: errors
  };
}

/**
 * Add a new player
 */
export function addTeamPlayer(
  state: MatchState,
  team: string
): MatchState {
  const errorkey = team + '_playerId';
  let errors = resetErrors([...state.errors], [errorkey]);
  let players = [...state.teams[team].players];
  let count = 0;

  const currentPlayerIndex = indexOfProperty(players, 'id', 'CURRENT_PLAYER_ID');
  if (currentPlayerIndex == null) {
    //errors.push({ field: errorkey, message: 'Field is required' });
    errors = [...errors, { field: errorkey, message: 'Field is required' }];
    // Return a new state with the new errors and values
    return {
      // Existing state
      ...state,
      teams: {
        ...state.teams,
        [team]: {
          ...state.teams[team],
          players: players
        }
      },
      // Update the errors
      errors: errors
    };
  }

  const playerId = players[currentPlayerIndex].playerId;
  const titular = players[currentPlayerIndex].titular;
  const number = players[currentPlayerIndex].number;
  const name = players[currentPlayerIndex].name;

  if (empty(playerId)) {
    errors = [...errors, {
      field: errorkey,
      message: 'Field is required.'
    }];
    // errors.push({
    //   field: errorkey,
    //   message: 'Field is required.'
    // });
    count++;
  }

  if (playerId && isNaN(playerId)) {
    errors = [...errors, {
      field: errorkey,
      message: 'Not an number.'
    }];
    // errors.push({
    //   field: errorkey,
    //   message: 'Not an number.'
    // });
    count++;
  }

  // Check if the player is already in the store : he can't be two times in it
  const index = indexOfProperty(players, 'playerId', playerId);
  if (index !== null && index !== currentPlayerIndex) {
    errors = [...errors, {
      field: errorkey,
      message: 'Player is already in the match.'
    }];
    // errors.push({
    //   field: errorkey,
    //   message: 'Player is already in the match.'
    // });
    count++;
  }

  // If no errors
  if (count === 0) {
    players.splice(currentPlayerIndex, 1);
    const newPlayer: TeamPlayer = {
      id: team + 'Player' + new Date().valueOf(),
      playerId: playerId,
      number: number,
      titular: titular,
      name: name
    }
    players = [...players, newPlayer];
  }

  console.log('from reducer - add new player');
  console.log(players);

  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        players: players
      }
    },
    // Update the errors
    errors: errors
  };
}

/**
 * Validators to handle player errors
 */
export function teamPlayerValidator(
  state: MatchState,
  team: string,
  id: string,
  key: string,
  value: any,
  playerId: number
): MatchState {
  const errorKey = id + '_' + key;
  // Get the errors from the state, remove the ones related to the field and send the array back.
  let errors = resetErrors([...state.errors], [errorKey]);
  errors = resetErrors([...state.errors], [`${team}_number`]);

  // Execute the checks on the field an populate error array if needed.
  switch (key) {
    case 'playerId':
      if (empty(value)) {
        errors = [...errors, { field: errorKey, message: 'Player is required.' }];
      }
      if (value && isNaN(value)) {
        errors = [...errors, { field: errorKey, message: 'Not an number.' }];
      }
      break;
    case 'titular':
      if ((value === false || value === true) === false) {
        errors = [...errors, { field: errorKey, message: 'Titular is requiring a boolean value.' }];
        // errors.push({ field: errorKey, message: 'Titular is requiring a boolean value.' });
      }
      break;
    case 'number':
      if (value && isNaN(value)) {
        errors = [...errors, { field: errorKey, message: 'Not an number.' }];
      } else {
        const errs = {};

        let players = cloneDeep(state.teams[team].players)
        const item = players.find(p => p.playerId === playerId)

        item.number = value;

        players = players.filter(e => !!e.number);

        for (const { number } of players) {
          if (!errs[number]) {
            errs[number] = 0;
          }
          errs[number] += 1;
        }

        const errValues = Object.keys(errs)
          .filter(k => errs[k] > 1);

        errors = [...errors, { field: `${team}_number`, message: '', value: errValues }];
      }
      break;
  }

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        players: [...state.teams[team].players.map(item =>
          item.id === id
            ? {
              ...item,
              [key]: value
            }
            : item
        )]
      }
    },
    // Update the errors
    errors: errors
  };
}

/**
 * Remove team player
 */
export function removeTeamPlayer(
  state: MatchState,
  team: string,
  id: number
): MatchState {
  const players = [...state.teams[team].players];
  const newPlayers = players.filter(e => e.playerId !== id);

  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        players: newPlayers
      }
    }
  };
}

/**
 * Set value for current player
 */
export function updateCurrentPlayer(
  state: MatchState,
  team: string,
  playerId: number,
  titular: boolean,
  number?: number,
  name?: string): MatchState {
  let players = [...state.teams[team].players];
  const index = indexOfProperty(players, 'id', 'CURRENT_PLAYER_ID');
  let player: TeamPlayer;
  if (index !== null) {
    player = {
      id: 'CURRENT_PLAYER_ID',
      playerId: playerId ? playerId : players[index].playerId,
      number: number ? number : players[index].number,
      titular: titular ? titular : players[index].titular,
      name: name ? name : players[index].name,
    };
    players.splice(index, 1);
  } else {
    player = {
      id: 'CURRENT_PLAYER_ID',
      playerId: playerId,
      number: number,
      titular: titular,
      name: name,
    };
  }

  players = [...players, player];
  console.log('from reducer - update current player');
  console.log(players)
  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        players: players
      }
    },
    // Update the errors
    // errors: errors
  };
}

/**
 * validate teams
 */
export function validateTeams(state: MatchState): MatchState {
  const teamNames = ['teamA_clubId', 'teamB_clubId'];
  const teams = state.teams;
  let errors = resetErrors([...state.errors], teamNames);

  const [teamA, teamB] = teamNames.map(name => name.replace('_clubId', '')).map(key => teams[key])
  const [teamAExists, teamBExists] = [
    !!teamA?.clubId,
    !!teamB?.clubId,
  ];

  if (!teamAExists) {
    errors = [...errors, { field: 'teamA_clubId', message: 'Club is required' }];
  }

  if (!teamBExists) {
    errors = [...errors, { field: 'teamB_clubId', message: 'Club is required' }];
  }

  if (teamAExists && teamBExists && teamA.clubId === teamB.clubId) {
    errors = [...errors, { field: 'teamA_clubId', message: 'Same club' }];
    errors = [...errors, { field: 'teamB_clubId', message: 'Same club' }];
  }

  return {
    // Existing state
    ...state,
    // Update errors
    errors: errors
  };
}


export function getClubPlayersSuccess(state: MatchState, team: string, players: any[]): MatchState {
  players = (players || []).slice(0, 17);
  console.log(players)
  const teamPlayers = players.map((p, index) => ({
    id: team + 'Player' + new Date().valueOf() + p.id,
    playerId: p.id,
    number: p.number,
    titular: p.titular,
    name: p.playerName
  }));

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    teams: {
      ...state.teams,
      [team]: {
        ...state.teams[team],
        players: teamPlayers
      }
    },
  };
}


/**
 * Validators to handle analysts errors
 */
export function analystValidator(
  state: MatchState,
  id: string,
  key: string,
  value: any
): MatchState {
  const index = indexOfProperty(state.analysts, 'id', id);
  let analysts: any;
  if (index !== null) {
    // If we have an index, it's an existing item, we rebuild the array (store is immutable)
    // with map function to update the value where the id is matching. If not matching, we simply return the current item.
    analysts = [
      ...state.analysts.map(item =>
        item.id === id
          ? {
            ...item,
            [key]: value
          }
          : item
      )
    ];
  } else {
    // If we don't have an index, we create an empty object from scratch
    // we put the value in it and we return the array with the new object.
    const analyst = {
      id: id,
      userId: null,
      analystType: null
    };
    analyst[key] = value;
    analysts = [...state.analysts, analyst];
  }

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    // Update analysts
    analysts: analysts,
    analystsUpdated: true,
  };
}

/**
 * validate analysts
 */
export function validateAnalysts(state: MatchState): MatchState {
  const fields = ['Operator1_userId', 'Supervisor_userId', 'Expert_userId'];
  const analysts = [...state.analysts];
  let errors = resetErrors([...state.errors], fields);

  for (const field of fields) {
    const property = field.replace('_userId', '');
    const analyst = analysts.find(item => item?.id === property);

    if (!analyst || analyst?.userId === null) {
      errors = [...errors, { field: field, message: 'Field is required' }];
    }
  }

  return {
    // Existing state
    ...state,
    // Update errors
    errors: errors
  };
}

/**
 * Validators to handle referees errors
 */
export function refereeValidator(
  state: MatchState,
): MatchState {
  let errors = resetErrors([...state.errors], ['role', 'refereeId']);
  let refeeres = [...state.referees];

  const currentRefereeIndex = indexOfProperty(refeeres, 'id', 'CURRENT_REFEREE_ID');
  if (currentRefereeIndex == null) {
    errors = [...errors, { field: 'refereeId', message: 'Field is required' }];
    // errors.push({ field: 'refereeId', message: 'Field is required' });
    // Return a new state with the new errors and values
    return {
      // Existing state
      ...state,
      // Update referee
      referees: refeeres,
      // Update errors
      errors: errors
    };
  }

  const refereeId = refeeres[currentRefereeIndex].refereeId;
  const role = refeeres[currentRefereeIndex].role;
  const name = refeeres[currentRefereeIndex].name;

  let count = 0;
  // Required controls
  if (empty(role)) {
    errors = [...errors, { field: 'role', message: 'Field is required.' }];
    count++;
  }
  if (empty(refereeId)) {
    errors = [...errors, { field: 'refereeId', message: 'Field is required.' }];
    count++;
  }
  // Check if the referee is already in the store : he can't be at two roles at the time
  const index = indexOfProperty(refeeres, 'refereeId', refereeId);
  if (index !== null && currentRefereeIndex !== index) {
    errors = [...errors, {
      field: 'role',
      message: 'Referee is already in the match.'
    }];
    count++;
  }

  // No errors, we insert the referee
  if (count === 0) {
    // refeeres.splice(currentRefereeIndex, 1);
    const referee: MatchReferee = {
      // Unique identifier based on date milliseconds
      id: 'referee' + new Date().valueOf(),
      refereeId: refereeId,
      role: role,
      name: name
    };
    refeeres = [...refeeres, referee];
  }

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    // Update referee
    referees: refeeres,
    // Update errors
    errors: errors
  };
}

/**
 * Validators to handle referees errors
 */
export function removeReferee(state: MatchState, id: string): MatchState {
  const referees = [...state.referees]
    .filter(ref => ref.id !== id);

  return {
    // Existing state
    ...state,
    // Update referee
    referees,
  };
}

/**
 * Set value for current referee
 */
export function updateCurrentReferee(state: MatchState, refereeId?: number, name?: string, role?: string): MatchState {
  let refeeres = [...state.referees];
  const index = indexOfProperty(refeeres, 'id', 'CURRENT_REFEREE_ID');
  let referee: MatchReferee;
  if (index !== null) {
    referee = {
      id: 'CURRENT_REFEREE_ID',
      refereeId: refereeId ? refereeId : refeeres[index].refereeId,
      name: name ? name : refeeres[index].name,
      role: role ? role : refeeres[index].role,
    };
    refeeres.splice(index, 1);
  } else {
    referee = {
      id: 'CURRENT_REFEREE_ID',
      refereeId: refereeId,
      name: name,
      role: role,
    };
  }

  refeeres = [...refeeres, referee];
  return {
    // Existing state
    ...state,
    // Update referee
    referees: refeeres
  };
}

/**
 * validate referees
 */
export function validateReferee(state: MatchState): MatchState {
  const referees = [...state.referees].filter(r => r.id !== 'CURRENT_REFEREE_ID');
  let errors = resetErrors([...state.errors], ['role']);

  if (referees.length < 1) {
    errors = [...errors, { field: 'role', message: 'One referee is required' }];
  }

  // Return a new state with the new errors and values
  return {
    // Existing state
    ...state,
    // Update errors
    errors: errors
  };
}

/**
 * Function to check if mandatory fields are set
 */
export function checkRequired(state: MatchState) {
  const checks = [
    { key: 'competitionId', value: state.match.competitionId },
    { key: 'stadiumId', value: state.match.stadiumId },
    { key: 'date', value: state.match.date },
    // {key: 'hour', value: state.match.hour},
    // {key: 'var', value: state.match.var},
    { key: 'teamA_clubId', value: state.teams.teamA.clubId },
    { key: 'teamB_clubId', value: state.teams.teamB.clubId },
    { key: 'teamA_playerId', value: state.teams.teamA.players },
    { key: 'teamB_playerId', value: state.teams.teamB.players }
  ];

  let errors = [...state.errors];
  for (const check of checks) {
    errors = resetErrors(errors, [check.key]);
    if (empty(check.value)) {
      // errors.push({field:check.key, message:'Field is required.'});
      errors = [...errors, { field: check.key, message: 'Field is required.' }];
    }
  }

  return {
    // Existing state
    ...state,
    // Update errors
    errors: errors
  };
}


/**
 * Return the index of specific property of an array of objects based on value
 */
export function indexOfProperty(array: any[], property: string, value: any) {
  let index = null;
  for (const item of array) {
    if (item && item[property] === value) {
      index = array.indexOf(item);
    }
  }
  return index;
}

/**
 * Empty function like the one in PHP. Return boolean true if the value provided is null, undefined, '', 0, empty array or false
 */
export function empty(value: any): boolean {
  if (
    value === false ||
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value === '') ||
    (typeof value === 'number' && value === 0) ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return true;
  }
  return false;
}

/**
 * Get the errors from the state, remove the ones related to the field and send the array back without them
 */
export function resetErrors(errors: MatchError[], fields: string[]): MatchError[] {
  // Loop on each term
  for (const field of fields) {
    for (const entry of errors) {
      if (entry.field === field) {
        const index = errors.indexOf(entry, 0);
        if (index > -1) {
          errors.splice(index, 1);
        }
      }
    }
  }
  return errors;
}

export function setMatchVideos(state: MatchState, videos: VideoWithChrono[]): MatchState {
  return {
    ...state,
    videos,
  };
}

export function setServerVideos(state: MatchState, serverVideos: ServerVideo[]): MatchState {
  return {
    ...state,
    serverVideos,
  };
}

export function addMatchVideo(state: MatchState, video: VideoWithChrono): MatchState {
  return {
    ...state,
    videos: [...state.videos, video],
  };
}

export function deleteMatchVideoSuccess(state: MatchState, id: number): MatchState {
  return {
    ...state,
    videos: state.videos.filter(video => video.id !== id),
  };
}

export function updateMatchVideo(state: MatchState, video: VideoWithChrono): MatchState {
  const videos = state.videos.map((v => {
    if (video.isPrimary && v.id !== video.id) {
      return { ...v, isPrimary: false };
    }

    if (video.id === v.id) {
      return { ...v, ...video };
    }

    return v;
  }))
  return {
    ...state,
    videos,
  };
}

export function setMatch(state: MatchState, match: Match): MatchState {
  return {
    ...state,
    selectedMatch: match,
  };
}

export function clearAnalysts(state: MatchState): MatchState {
  return {
    ...state,
    analysts: state.analysts.map(analyst => ({ ...analyst, userId: null, user: null }))
  };
}
