import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';

import { ClubDetailComponent } from './components/smart/club-detail/club-detail.component';
import { ClubListComponent } from './components/smart/club-list/club-list.component';
import {
  ConfirmationTransferDialogComponent,
} from './components/smart/confirmation-transfer-dialog/confirmation-transfer-dialog.component';
import { SelectPlayerComponent } from './components/smart/select-player/select-player.component';
import { ClubListRefreshService } from './services/club-list-refresh.service';
import { ClubsEffects } from './store/clubs.effects';
import { clubsReducer } from './store/clubs.reducer';
import { ClubService } from './services/club.service';

const smartComponents = [ClubListComponent, ClubDetailComponent, SelectPlayerComponent, ConfirmationTransferDialogComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([ClubsEffects]),
    StoreModule.forFeature( 'clubs', clubsReducer )
  ],
  exports: [...smartComponents],
  entryComponents: [ClubDetailComponent, SelectPlayerComponent],
  providers: [
    ClubListRefreshService,
    ClubService
  ],
})
export class ClubsModule { }
