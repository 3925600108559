import { ListType } from '..';

export class ListTypeType {
  static className = 'ListType';
  static gqlRootQueryName = 'listtype';
  static gqlColumns: string[] = [
      '_id',
      'Code',
  ];

  static gqlNodeMapper = (node: any): ListType => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as ListType;
    }

}
