export enum EventTypeEnum {
  MatchStart = "M1_Start",
  Match45 = "M1_Add",
  FirstHalfEnd = "M1_End",

  SecondHalfStart = "M2_Start",
  Match90 = "M2_Add",
  SecondHalfEnd = "M2_End",

  FirstExtraStart = "P1_Start",
  Extra15 = "P1_Add",
  FirstExtraEnd = "P1_End",

  SecondExtraStart = "P2_Start",
  Extra30 = "P2_Add",
  SecondExtraEnd = "P2_End",

  Penalty = "TRB",
  MatchEnd = "End_Match",
  Realign = "Realign",
}

export const allEvents = Object.keys(EventTypeEnum);

export interface EventTypeLabel {
  value: EventTypeEnum;
  label: string;
  mandatoryPrecedent: EventTypeEnum[];
  mandatoryNext: EventTypeEnum[];
}

export const eventsTypes: EventTypeLabel[] = [
  {
    label: 'Match Start',
    value: EventTypeEnum.MatchStart,
    mandatoryPrecedent: [],
    mandatoryNext: []
  },
  {
    label: "45<sup>''</sup>",
    value: EventTypeEnum.Match45,
    mandatoryPrecedent: [EventTypeEnum.MatchStart],
    mandatoryNext: [EventTypeEnum.FirstHalfEnd]
  },
  {
    label: '1<sup>st</sup> Half End',
    value: EventTypeEnum.FirstHalfEnd,
    mandatoryPrecedent: [EventTypeEnum.MatchStart, EventTypeEnum.Match45],
    mandatoryNext: []
  },
  {
    label: '2<sup>nd</sup> Half Start',
    value: EventTypeEnum.SecondHalfStart,
    mandatoryPrecedent: [EventTypeEnum.FirstHalfEnd],
    mandatoryNext: []
  },
  {
    label: "90<sup>''</sup>",
    value: EventTypeEnum.Match90,
    mandatoryPrecedent: [EventTypeEnum.SecondHalfStart],
    mandatoryNext: [EventTypeEnum.SecondHalfEnd]
  },
  {
    label: '2<sup>nd</sup> Half End',
    value: EventTypeEnum.SecondHalfEnd,
    mandatoryPrecedent: [EventTypeEnum.SecondHalfStart, EventTypeEnum.Match90],
    mandatoryNext: []
  },
  {
    label: '1<sup>st</sup> Extra Start',
    value: EventTypeEnum.FirstExtraStart,
    mandatoryPrecedent: [EventTypeEnum.SecondHalfEnd],
    mandatoryNext: []
  },
  {
    label: "15<sup>''</sup> Extra",
    value: EventTypeEnum.Extra15,
    mandatoryPrecedent: [EventTypeEnum.FirstExtraStart],
    mandatoryNext: [EventTypeEnum.FirstExtraEnd, EventTypeEnum.MatchEnd]
  },
  {
    label: '1<sup>st</sup> Extra End',
    value: EventTypeEnum.FirstExtraEnd,
    mandatoryPrecedent: [EventTypeEnum.FirstExtraStart, EventTypeEnum.Extra15],
    mandatoryNext: [EventTypeEnum.MatchEnd]
  },
  {
    label: '2<sup>nd</sup> Extra Start',
    value: EventTypeEnum.SecondExtraStart,
    mandatoryPrecedent: [EventTypeEnum.FirstExtraEnd],
    mandatoryNext: [EventTypeEnum.MatchEnd]
  },
  {
    label: "30<sup>''</sup> Extra",
    value: EventTypeEnum.Extra30,
    mandatoryPrecedent: [EventTypeEnum.SecondExtraStart],
    mandatoryNext: [EventTypeEnum.SecondExtraEnd, EventTypeEnum.MatchEnd]
  },
  {
    label: '2<sup>nd</sup> Extra End',
    value: EventTypeEnum.SecondExtraEnd,
    mandatoryPrecedent: [EventTypeEnum.SecondExtraStart, EventTypeEnum.Extra30],
    mandatoryNext: [EventTypeEnum.MatchEnd]
  },
  {
    label: 'Penalty Shoot-out',
    value: EventTypeEnum.Penalty,
    mandatoryPrecedent: [EventTypeEnum.SecondExtraEnd],
    mandatoryNext: [EventTypeEnum.MatchEnd, EventTypeEnum.MatchEnd]
  },
  {
    label: 'Match End',
    value: EventTypeEnum.MatchEnd,
    mandatoryPrecedent: [EventTypeEnum.SecondHalfEnd, EventTypeEnum.FirstExtraEnd, EventTypeEnum.SecondExtraEnd, EventTypeEnum.Penalty],
    mandatoryNext: []
  },
  {
    label: 'Realign',
    value: EventTypeEnum.Realign,
    mandatoryPrecedent: [EventTypeEnum.MatchStart],
    mandatoryNext: []
  }
];
















