import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Competition, CustomEntitiesEnum, EntitiesEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcGenericListComponent,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent
} from '@tc/core';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { deleteCompetitionSuccess, upsertCompetition } from '../../../store/competitions.actions';


@Component({
  selector: 'app-competition-list',
  templateUrl: './competition-list.component.html',
  styleUrls: ['./competition-list.component.scss']
})
export class CompetitionListComponent extends TcSmartComponent implements OnInit, AfterViewInit {

  private subscription: Subscription;

  constructor(
    private store: Store<any>,
    private readonly refreshService: ListRefreshService,
  ) {
    super();
  }

  competitionsList: TcGenericListComponent<any>;
  @ViewChild('competitionsList', { static: true }) set setCompetitionsList(values: TcGenericListComponent<Competition>) {
    this.competitionsList = values;
    this.competitionsList.entityName = CustomEntitiesEnum.CustomCompetition;
  }

  @ViewChild('countryTemplate', { static: true }) countryTemplate: TemplateRef<any>;
  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  ngOnInit() {
    this.competitionsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.competitionsList.showTotalInActionsHeader = false;

    this.competitionsList.sortType = TcListSortType.Server;
    this.competitionsList.filterType = TcListFilterType.Server;
    this.competitionsList.hasFixedHeader = true;

    this.competitionsList.hasAddButton = false;
    this.competitionsList.addItemWhenKeyPresed = true;

    this.competitionsList.hasActionsLabel = true;
    this.competitionsList.isPaged = false;

    this.competitionsList.onScrollDown = () => {
      this.competitionsList.service.getAll();
    };

    this.competitionsList.columns = [
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'organisationName',
        visible: true
      },
      {
        propertyName: 'season',
        visible: true
      },
      {
        propertyName: 'countryName',
        visible: true,
        htmlTemplate: this.countryTemplate
      }
    ];

    this.competitionsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.competitionsList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editCompetition(row);
      }
    };

    this.competitionsList.onRowClick = (row: any) => {
      this.editCompetition(row);
    };

    (this.competitionsList as any).afterDeleteSuccess = (row: any) => {
      this.store.dispatch(deleteCompetitionSuccess({}));
    };
  }

  ngAfterViewInit() {
    this.refresh();

    this.subscription = this.refreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.Competition))
      .subscribe(() => this.refresh());
  }

  addItem() {
    this.store.dispatch(upsertCompetition({}));
  }

  private editCompetition(row: any) {
    this.store.dispatch(upsertCompetition({ competition: row }));
  }

  private refresh() {
    this.competitionsList.service.clearCache();
    this.competitionsList.service.getWithQuery({ initial: 'true' });
  }

  public delete = (row: any) => {
    this.competitionsList.tcList.onDeleteAction(row);
  }

}
