import { Competition } from '@match-fix/shared';

export class CustomCompetitionType {
  static className = 'CustomCompetition';
  static gqlRootQueryName = 'customcompetition';
  static gqlColumns: string[] = [
    '_id',
    'organisationId',
    'name',
    'organisationName',
    'haveMatch',
    'season',
    'countryName',
    'countryId',
  ];

  static gqlNodeMapper = (node: any): Competition => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Competition;
  };
}
