import { Component, Inject, OnDestroy, OnInit, Optional, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AnalystTypeEnum, Match, MatchAnalysisStateEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcConfirmDialogComponent, TcNotificationService, TcTranslateService } from '@tc/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatchFormAbstract } from '../../../abstract/match-form.abstract';
import {
  CreateMatch,
  GetClubPlayers,
  UpdateMatch,
  UpdateMatchData,
  ValidateTeams,
} from '../../../store/matchs.actions';
import { MatchData, MatchState } from '../../../store/matchs.interfaces';
import { getHasAnalystsUpdates, getMatch, getStore, getTeams } from '../../../store/matchs.selectors';

@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MatchDetailComponent extends MatchFormAbstract implements OnInit, AfterViewInit, OnDestroy {

  public initialMatchData: any;

  public form: FormGroup;
  public match: MatchData = {} as any;
  public matchSubscription: Subscription;
  public teamWrapperCssClass: string;
  public selectedWeather;
  public matchAnalysisStarted: boolean;
  public isMatchUpdate: boolean;

  constructor(
    protected store$: Store<MatchState>,
    private dialogRef: MatDialogRef<MatchDetailComponent>,
    private readonly dialog: MatDialog,
    private readonly notification: TcNotificationService,
    private readonly translate: TcTranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Match,
  ) {
    super(store$);
  }

  async ngOnInit() {
    this.form = new FormGroup({
      weather: new FormControl(''),
      wind: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
      rain: new FormControl(''),
    });

    this.initListeners(); // Subscribe to automatically patch match form value
    super.SetForm(this.form); // Setup the form for the parent
    super.ngOnInit(); // Call the parent to setup error system

  }

  ngAfterViewInit() {
    const initialDate = this.data?.date ? moment(this.data.date).format('YYYY-MM-DD') : '';
    const initialHour = this.data?.date ? moment(this.data.date).format('HH:mm') : '';

    if (initialDate) {
      this.form.controls.date.setValue(initialDate);
    }

    if (initialHour) {
      this.form.controls.time.setValue(initialHour);
    }
  }

  selectDate(event: MatDatepickerInputEvent<Date>) {
    const date = moment(event.target.value).format('YYYY-MM-DD');
    this.updateMatch('date', date);

    this.resetTeamPlayers()
  }

  private async resetTeamPlayers() {
    const teams = await this.store$.select(getTeams).pipe(take(1)).toPromise();

    for (const key of Object.keys(teams)) {
      const team = teams[key];

      if (team?.clubId) {
        this.store.dispatch(new GetClubPlayers({ team: key, clubId: team.clubId }));
      }
    }
  }

  onClose() {
    this.dialogRef.close(null);
  }

  async submit() {
    this.validateMatch();

    const simpleErrors = this.errors.filter(err => err.field !== 'teamA_number' && err.field !== 'teamB_number');
    const numberErrors = this.errors.filter(err => err.field === 'teamA_number' || err.field === 'teamB_number');
    const isNumberError = numberErrors.some(err => err.value?.length);

    const error = simpleErrors?.length
      || isNumberError
      || !this.form.controls.time.valid;

    if (error) { // Only submit the main form if the fields are all good
      return;
    }

    const data = await this.store$
      .select(getStore)
      .pipe(take(1))
      .toPromise();

    const payload = {
      ...data,
      analysts: data.analysts.filter(analyst => analyst?.userId),
    };

    if (!this.hasValidAnalysts(payload.analysts)) {
      return;
    }

    const hasAnalystsChanges = await this.hasAnalystsChanges();
    if (hasAnalystsChanges && !(await this.promptAnalystsChanges())) {
      return;
    }

    const [hour, minutes] = (this.form.controls?.time?.value || '0:0').split(':');
    const dateTime = moment(payload.match.date)
      .set('hour', Number(hour))
      .set('minutes', Number(minutes))
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss');

    this.form.controls.date.setValue(moment(dateTime).format('YYYY-MM-DD'))

    if (this.data) {
      this.store$.dispatch(new UpdateMatchData({ ...payload, match: { ...payload.match, date: dateTime, time: undefined } }));
    } else {
      this.store$.dispatch(new CreateMatch({ ...payload, match: { ...payload.match, date: dateTime, time: undefined } }));
    }
  }

  private hasValidAnalysts(analysts: any[]) {
    if (!this.matchAnalysisStarted) {
      return true;
    }

    const supervisor = analysts.find(analyst => analyst?.analystType === AnalystTypeEnum.Supervisor);
    if (!supervisor || !supervisor.userId) {
      this.notification.error(this.translate.instant('match-detail.errors.supervisorRequired'));
      return false;
    }

    const operator1 = analysts.find(analyst => analyst?.analystType === AnalystTypeEnum.Operator1);
    if (!operator1 || !operator1.userId) {
      this.notification.error(this.translate.instant('match-detail.errors.operator1Required'));
      return false;
    }

    const expert = analysts.find(analyst => analyst?.analystType === AnalystTypeEnum.Expert);
    if (!expert || !expert.userId) {
      this.notification.error(this.translate.instant('match-detail.errors.expertRequired'));
      return false;
    }

    return true;
  }

  private async hasAnalystsChanges() {
    if (!this.matchAnalysisStarted) {
      return false;
    }

    const hasAnalystsChanges = await this.store$.select(getHasAnalystsUpdates).pipe(take(1)).toPromise();
    return hasAnalystsChanges;
  }

  private promptAnalystsChanges() {
    return new Promise((resolve) => {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          title: 'match-detail.texts.title',
          message: 'match-detail.texts.message',
          noText: 'match-detail.texts.no',
          yesText: 'match-detail.texts.yes',
        },
        panelClass: 'club-player-transfer-dialog-panel'
      });

      dialog.afterClosed().subscribe(result => resolve(result === 'yes'));
    });
  }

  /**
   * Update a value in the store for match property
   */
  updateMatch(key: string, value?: any) {
    const newValue = value ? value : this.form.controls[key].value;
    this.store$.dispatch(new UpdateMatch({ key: key, value: newValue }));
  }

  /**
   * Validate all the fields from the match with store values
   */
  validateMatch() {
    this.store$.dispatch(new ValidateTeams());

    Object.keys(this.match).forEach((key: string) => {
      this.store$.dispatch(new UpdateMatch({ key: key, value: this.match[key] }));
    });
  }

  private initListeners() {
    this.matchSubscription = this.store$.select(getMatch).subscribe(data => {
      const time = data.time ?? (data.date ? moment(data.date).format('HH:mm') : null);
      this.match = { ...data, time };
      this.matchAnalysisStarted = this.match['matchAnalysisState'] && this.match['matchAnalysisState'] !== MatchAnalysisStateEnum.NotStarted;
      this.isMatchUpdate = !!this.data;

      this.form.patchValue(this.match, { emitEvent: true });

      this.selectedWeather = data.weather && { value: data.weather, label: data.weather };

      this.teamWrapperCssClass = data.date ? 'team-wrapper' : 'team-wrapper disabled-input';
    });
  }

  ngOnDestroy() {
    if (this.matchSubscription) {
      this.matchSubscription.unsubscribe(); // Destroy current subscriptions
    }

    super.ngOnDestroy(); // Call the parent to destroy parent subscriptions
  }

}
