import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcAppState, TcListComponent, TcListFilterType, TcListSortType } from '@tc/core';
import { Subject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { convertToReadableFormat } from '../../../../../utils/milisecondsToReadableFormat';
import { DeleteMatchVideo, GetMatchVideos, UpdateMatchVideo } from '../../../store/matchs.actions';
import { VideoWithChrono } from '../../../store/matchs.interfaces';
import { getMatchVideos } from '../../../store/matchs.selectors';
import { EditVideoComponent } from '../edit-video/edit-video.component';
import { ServerVideosComponent } from '../server-videos/server-videos.component';
import { UploadVideosComponent } from '../upload-videos/upload-videos.component';

@Component({
  selector: 'app-manage-videos',
  templateUrl: './manage-videos.component.html',
  styleUrls: ['./manage-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageVideosComponent implements OnInit, AfterViewInit {

  private videos: VideoWithChrono[] = [];
  private readonly list$ = new Subject<VideoWithChrono[]>();

  manageVideoList: TcListComponent;

  @ViewChild('manageVideoList', { static: true }) set setManageVideoList(values: TcListComponent) {
    this.manageVideoList = values;
  }

  @ViewChild('chronoStartTemplate', { static: true }) chronoStartTemplate: TemplateRef<any>;
  @ViewChild('chronoEndTemplate', { static: true }) chronoEndTemplate: TemplateRef<any>;
  @ViewChild('primaryTemplate', { static: true }) primaryTemplate: TemplateRef<any>;
  @ViewChild('colActionEditTemplate', { static: true }) colActionEditTemplate: TemplateRef<any>;
  @ViewChild('colActionDeleteTemplate', { static: true }) colActionDeleteTemplate: TemplateRef<any>;
  @ViewChild('colActionPrimaryTemplate', { static: true }) colActionPrimaryTemplate: TemplateRef<any>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly store$: Store<TcAppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.manageVideoList.rows$ = this.list$;
    this.manageVideoList.isFiltrable = false;
    this.manageVideoList.filterType = TcListFilterType.Disabled;
    this.manageVideoList.sortType = TcListSortType.Disabled;
    this.manageVideoList.isPaged = false;
    this.manageVideoList.hasFixedHeader = true;
    this.manageVideoList.hasAddButton = false;

    this.manageVideoList.columns = [
      {
        propertyName: 'fileIdentifier',
        visible: true,
      }, {
        propertyName: 'chronoStart',
        visible: true,
        htmlTemplate: this.chronoStartTemplate,
      }, {
        propertyName: 'chronoEnd',
        visible: true,
        htmlTemplate: this.chronoEndTemplate,
      }, {
        propertyName: 'primary',
        visible: true,
        htmlTemplate: this.primaryTemplate,
      }
    ];
    this.manageVideoList.rowActions = [
      {
        actionName: 'edit',
        visible: true,
        hasText: false,
        icon: 'edit',
        htmlTemplate: this.colActionEditTemplate
      },
      {
        actionName: 'delete',
        visible: true,
        hasText: false,
        icon: 'delete',
        htmlTemplate: this.colActionDeleteTemplate
      },
    ];
  }

  ngAfterViewInit() {
    setTimeout(() => this.store$.dispatch(new GetMatchVideos({ matchId: this.data.matchId })));

    this.initCurrentMatchVideos();
  }

  public onClose() {
    this.dialog.closeAll();
  }

  public setPrimary(row) {
    this.store$.dispatch(new UpdateMatchVideo({ id: row.id, isPrimary: true, fileIdentifier: row.fileIdentifier, matchId: row.matchId } as any));
  }

  public editItem(row) {
    this.dialog.open(EditVideoComponent, {
      height: '530px',
      width: '700px',
      data: {
        video: row,
      },
    });
  }

  public deleteItem(row) {
    this.store$.dispatch(new DeleteMatchVideo({ id: row.id }));
  }

  public uploadVideoPopup() {
    this.dialog.open(UploadVideosComponent, {
      height: '530px',
      width: '700px',
      data: {
        matchId: this.data.matchId,
        setFirstPrimary: !this.videos.some(video => video.isPrimary),
      },
    });
  }

  public readonly convertToReadableFormat = m => {
    if (!m && m !== 0) {
      return '00:00';
    }

    return convertToReadableFormat(m, 'mm:ss')
  };

  private async initCurrentMatchVideos() {
    this.store$
      .select(getMatchVideos)
      .pipe(skipWhile(v => !v))
      .subscribe(videos => {
        this.videos = videos as any;
        this.list$.next(this.videos);

        this.cdr.detectChanges();
      })
  }

  public addServerVideo() {
    this.dialog.open(ServerVideosComponent, {
      height: '65vh',
      width: '700px',
      data: {
        matchId: this.data.matchId,
        setFirstPrimary: !this.videos.some(video => video.isPrimary),
      },
      autoFocus: false
    });
  }

}
