import { Club } from '..';

export class ClubType {
  static className = 'Club';
  static gqlRootQueryName = 'club';
  static gqlColumns: string[] = [
      '_id',
      'code',
      'name',
      'countryId',
      'logoFileIdentifier',
  ];

  static gqlNodeMapper = (node: any): Club => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Club;
    }

}
