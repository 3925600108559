import { TcReportingQueryService, TcReportingQuery } from '@tc/core';
import { Injectable, Optional, Inject } from '@angular/core';
import { TcService } from '@tc/core';
import { Player, EntitiesEnum } from '@match-fix/shared';
import { QlAutcompleteService } from '../core/ql-autocomplete.service';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportingService extends TcService {
  protected basePath = 'http://localhost:3333/api';
  protected defaultHeaders = new HttpHeaders();
  protected configuration = new Configuration();

  constructor(
    private reportingQueries: TcReportingQueryService,
    private qlAutocompleteService: QlAutcompleteService,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  public init() {
    // Queries declaration
    const queries: TcReportingQuery[] = [
      {
        id: 'reportingPlayersClubs',
        name: 'Clubs history for players',
        limit: 500,
        // parentCategory : 'Clubs',
        category: 'Clubs',
        filters: [
          {
            key: 'playerId',
            type: 'multi-select',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Player>(EntitiesEnum.Players, term, 'id', 'firstName,lastName'),
              // listValue: 'player.name',
              display: (item) => item.label,
              // selectedItem: 'player',
            }

          }
        ],
      },
      {
        id: 'reportingPlayersMatch',
        name: 'Matches history for players',
        limit: 500,
        // parentCategory : 'Matches',
        category: 'Matches',
        filters: [
          {
            key: 'playerId',
            type: 'multi-select',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Player>(EntitiesEnum.Players, term, 'id', 'firstName,lastName'),
              // listValue: 'player.name',
              display: (item) => item.label,
              // selectedItem: 'player',
            }

          }
        ],
      },
    ];

    // Save queries into the main service in Tc/Core
    this.reportingQueries.registerBackEndDomain(this.configuration.basePath);
    this.reportingQueries.registerQueries(queries);
  }
}
