import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EntitiesEnum, Player } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { QlAutcompleteService } from 'apps/frontend/src/app/services/core/ql-autocomplete.service';
import { EntityCollectionServiceFactory } from 'ngrx-data';

import { PlayerDetailComponent } from '../../../../players/components/smart/player-detail/player-detail.component';
import { AddPlayer } from '../../../store/clubs.actions';
import { ClubDetailComponent } from '../club-detail/club-detail.component';

@Component({
  selector: 'app-select-player',
  templateUrl: './select-player.component.html',
  styleUrls: ['./select-player.component.scss']
})
export class SelectPlayerComponent extends TcGenericFormComponent<any> implements OnInit {

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<ClubDetailComponent>,
    private dialog: MatDialog,
    private store: Store<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    elem: ElementRef,
  ) {

    super('Players', entityCollectionServiceFactory, translate, elem);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'playerId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Player>(EntitiesEnum.Players, term, 'id', 'firstName,lastName'),
              // listValue: 'player.name',
              selectedItem: 'player',
              selected: (item) => {
                this.selectPlayer(item);
              }
            },
            validators: {
              countryId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },

          }
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);
      delete (this.model as any).clubName;
    }
    this.afterSave = () => {
      this.onClose();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  addPlayer() {
    this.dialog.open(PlayerDetailComponent, {
      height: '600px',
      width: '450px',
    });
  }

  submit() {
    this.store.dispatch(new AddPlayer(this.model.player || null));
    this.onClose();
  }

  private async selectPlayer(player) {
  }

}
