import { Match, ServerVideo } from '@match-fix/shared';
import { createAction, props } from '@ngrx/store';
import { TcAction } from '@tc/core';
import { MatchState, UpdateVideo, VideoWithChrono } from './matchs.interfaces';


export const upsertMatch = createAction(
  '[Matchs] Upsert Match',
  props<{ match?: Match }>()
);

export enum MatchsActionTypes {
  GO_TO_MATCH_ANALYSIS = '[Matchs] Navigate to match analysis',
  UPDATE_MATCH_VALUE = '[Matchs] Update match value',
  UPDATE_ANALYST_VALUE = '[Matchs] Update analyst value',
  CLEAR_ANALYSTS_VALUE = '[Matchs] Clear analysts value',
  VALIDATE_ANALYSTS = '[Matchs] validate analyst',
  SET_TEAM_CLUB = '[Matchs] Set team club',
  GET_CLUB_PLAYERS = '[Matchs] Get Club Players',
  GET_CLUB_PLAYERS_SUCCESS = '[Matchs] Get Club Players Success',
  ADD_NEW_TEAM = '[Matchs] Add new team',
  ADD_NEW_PLAYER = '[Matchs] Add new player',
  ADD_NEW_PLAYER_SUCCESS = '[Matchs] Add new player success',
  ADD_TEAM_PLAYER = '[Matchs] Add team player',
  UPDATE_TEAM_PLAYER_VALUE = '[Matchs] Update team player value',
  REMOVE_TEAM_PLAYER = '[Matchs] Remove team player',
  UPDATE_CURRENT_PLAYER = '[Matchs] Update current player',
  VALIDATE_TEAMS = '[Matchs] Validate Teams',
  ADD_REFEREE = '[Matchs] Add referee',
  REMOVE_REFEREE = '[Matchs] Remove referee',
  REMOVE_PLAYER = '[Matchs] Remove player',
  UPDATE_CURRENT_REFEREE = '[Matchs] Update current referee',
  VALIDATE_REFEREE = '[Matchs] Validate referee',
  UPDATE_COMPETITION_VALUE = '[Matchs] Update competition value',
  CREATE_MATCH = '[Matchs] Create Match',
  DELETE_MATCH = '[Matchs] Delete Match',
  CREATE_MATCH_SUCCESS = '[Matchs] Create Match Success',
  DELETE_MATCH_SUCCESS = '[Matchs] Delete Match Success',
  CLEAR_STORE = '[Matchs] Clear all store values',
  CHECK_REQUIRED = '[Matchs] Cheking required values',

  SET_MATCH = '[Matchs] Set match',
  START_MATCH_ANALYSIS = '[Matchs] Start match analysis',

  GET_MATCH_VIDEOS = '[Matchs] Get match videos',
  SET_MATCH_VIDEOS = '[Matchs] Set match videos',
  ADD_MATCH_VIDEO = '[Matchs] Add match video',
  ADD_MATCH_VIDEO_SUCCESS = '[Matchs] Add match video success',
  UPDATE_MATCH_VIDEO = '[Matchs] Update match video',
  UPDATE_MATCH_VIDEO_SUCCESS = '[Matchs] Update match video success',
  DELETE_MATCH_VIDEO = '[Matchs] Delete match video',
  DELETE_MATCH_VIDEO_SUCCESS = '[Matchs] Delete match video success',

  START_EDIT_MATCH = '[Matches] Start edit match',
  START_EDIT_SUCCESS = '[Matches] Start edit success',
  UPDATE_MATCH_DATA = '[Matches] Update match data',
  UPDATE_MATCH_DATA_SUCCESS = '[Matches] Update match data success',

  GET_SERVER_VIDEOS = '[Matchs] Get server videos',
  SET_SERVER_VIDEOS = '[Matchs] Set server videos',
  SELECT_SERVER_VIDEO = '[Matchs] Select server video',
}

export class GoToMatchAnalysis implements TcAction {
  readonly type = MatchsActionTypes.GO_TO_MATCH_ANALYSIS;
  constructor() { }
}

export class UpdateMatch implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_MATCH_VALUE;
  constructor(public payload: { key: string; value: any }) { }
}

export class UpdateAnalyst implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_ANALYST_VALUE;
  constructor(public payload: { id: string; key: string; value: any }) { }
}

export class ClearAnalysts implements TcAction {
  readonly type = MatchsActionTypes.CLEAR_ANALYSTS_VALUE;
  constructor() { }
}

// TODO: delete
export class ValidateAnalysts implements TcAction {
  readonly type = MatchsActionTypes.VALIDATE_ANALYSTS;
  constructor(
    // public payload: {}
  ) { }
}

export class SetTeamClub implements TcAction {
  readonly type = MatchsActionTypes.SET_TEAM_CLUB;
  constructor(public payload: { team: string; clubId: number }) { }
}

export class GetClubPlayers implements TcAction {
  readonly type = MatchsActionTypes.GET_CLUB_PLAYERS;
  constructor(public payload: { team: string, clubId: number }) { }
}

export class GetClubPlayersSuccess implements TcAction {
  readonly type = MatchsActionTypes.GET_CLUB_PLAYERS_SUCCESS;
  constructor(public payload: {
    team: string,
    players: any[]
  }) { }
}

export class AddNewTeam implements TcAction {
  readonly type = MatchsActionTypes.ADD_NEW_TEAM;
  constructor(
    public payload: {
    }
  ) { }
}

export class AddNewPlayer implements TcAction {
  readonly type = MatchsActionTypes.ADD_NEW_PLAYER;
  constructor(
    public payload: any
  ) { }
}

export class AddNewPlayerSuccess implements TcAction {
  readonly type = MatchsActionTypes.ADD_NEW_PLAYER_SUCCESS;
  constructor(
    public payload: any
  ) { }
}

export class AddTeamPlayer implements TcAction {
  readonly type = MatchsActionTypes.ADD_TEAM_PLAYER;
  constructor(
    public payload: {
      team: string;
      playerId: number;
      titular: boolean;
      number?: number;
    }
  ) { }
}

export class UpdateTeamPlayer implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_TEAM_PLAYER_VALUE;
  constructor(
    public payload: { team: string; id: string; key: string; value: any, playerId?: number }
  ) { }
}

export class ValidateTeams implements TcAction {
  readonly type = MatchsActionTypes.VALIDATE_TEAMS;
  constructor() { }
}

// TODO: delete
export class ValidateReferee implements TcAction {
  readonly type = MatchsActionTypes.VALIDATE_REFEREE;
  constructor() { }
}

export class RemoveTeamPlayer implements TcAction {
  readonly type = MatchsActionTypes.REMOVE_TEAM_PLAYER;
  constructor(public payload: { team: string; id: number }) { }
}

export class UpdateCurrentPlayer implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_CURRENT_PLAYER;
  constructor(public payload: { team: string, playerId: number, titular: boolean, number: number, name: string }) { }
}

export class AddReferee implements TcAction {
  readonly type = MatchsActionTypes.ADD_REFEREE;
  constructor() { }
}

export class RemoveReferee implements TcAction {
  readonly type = MatchsActionTypes.REMOVE_REFEREE;
  constructor(public payload: { id: string }) { }
}

export class RemovePlayer implements TcAction {
  readonly type = MatchsActionTypes.REMOVE_PLAYER;
  constructor(public payload: { team: string, id: number, playerId: number }) { }
}

export class UpdateCurrentReferee implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_CURRENT_REFEREE;
  constructor(
    public payload: { refereeId: number, name: string, role: string }
  ) { }
}

export class CreateMatch implements TcAction {
  readonly type = MatchsActionTypes.CREATE_MATCH;
  constructor(
    public payload: MatchState
  ) { }
}

export class CreateMatchSuccess implements TcAction {
  readonly type = MatchsActionTypes.CREATE_MATCH_SUCCESS;
  constructor(public payload: Match) { }
}

export class DeleteMatch implements TcAction {
  readonly type = MatchsActionTypes.DELETE_MATCH;
  constructor(public payload: { id: number }) { }
}

export class UpdateCompetition implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_COMPETITION_VALUE;
  constructor(public payload: { id: string, key: string, value: any }) { }
}

export class ClearStore implements TcAction {
  readonly type = MatchsActionTypes.CLEAR_STORE;
}

export class CheckRequired implements TcAction {
  readonly type = MatchsActionTypes.CHECK_REQUIRED;
}

export class GetMatchVideos implements TcAction {
  readonly type = MatchsActionTypes.GET_MATCH_VIDEOS;
  constructor(public payload: { matchId: number }) { }
}

export class SetMatchVideos implements TcAction {
  readonly type = MatchsActionTypes.SET_MATCH_VIDEOS;
  constructor(public payload: VideoWithChrono[]) { }
}

export class GetServerVideos implements TcAction {
  readonly type = MatchsActionTypes.GET_SERVER_VIDEOS;
  constructor() { }
}

export class SetServerVideos implements TcAction {
  readonly type = MatchsActionTypes.SET_SERVER_VIDEOS;
  constructor(public payload: ServerVideo[]) { }
}

export class SetMatch implements TcAction {
  readonly type = MatchsActionTypes.SET_MATCH;
  constructor(public payload: Match) { }
}

export class StartMatchAnalysis implements TcAction {
  readonly type = MatchsActionTypes.START_MATCH_ANALYSIS;
}

export class AddMatchVideo implements TcAction {
  readonly type = MatchsActionTypes.ADD_MATCH_VIDEO;
  constructor(public payload: {
    file: string;
    matchId: number;
    isPrimary: boolean;
  }) { }
}

export class AddMatchVideoSuccess implements TcAction {
  readonly type = MatchsActionTypes.ADD_MATCH_VIDEO_SUCCESS;
  constructor(public payload: VideoWithChrono) { }
}

export class SelectServerVideo implements TcAction {
  readonly type = MatchsActionTypes.SELECT_SERVER_VIDEO;
  constructor(public payload: {
    file: string;
    matchId: number;
    isPrimary: boolean;
  }) { }
}

export class UpdateMatchVideo implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_MATCH_VIDEO;
  constructor(public payload: UpdateVideo) { }
}

export class UpdateMatchVideoSuccess implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_MATCH_VIDEO_SUCCESS;
  constructor(public payload: VideoWithChrono) { }
}

export class DeleteMatchVideo implements TcAction {
  readonly type = MatchsActionTypes.DELETE_MATCH_VIDEO;
  constructor(public payload: { id: number }) { }
}

export class DeleteMatchVideoSuccess implements TcAction {
  readonly type = MatchsActionTypes.DELETE_MATCH_VIDEO_SUCCESS;
  constructor(public payload: { id: number }) { }
}

export class StartEditMatch implements TcAction {
  readonly type = MatchsActionTypes.START_EDIT_MATCH;

  constructor(public payload: Match) { }
}

export class StartEditSuccess implements TcAction {
  readonly type = MatchsActionTypes.START_EDIT_SUCCESS;

  constructor(public payload: MatchState) { }
}

export class UpdateMatchData implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_MATCH_DATA;
  constructor(
    public payload: MatchState
  ) { }
}

export class UpdateMatchDataSuccess implements TcAction {
  readonly type = MatchsActionTypes.UPDATE_MATCH_DATA_SUCCESS;
}

export type MatchsStoreActions =
  | GoToMatchAnalysis
  | UpdateMatch
  | UpdateAnalyst
  | SetTeamClub
  | GetClubPlayers
  | GetClubPlayersSuccess
  | AddTeamPlayer
  | UpdateTeamPlayer
  | RemoveTeamPlayer
  | UpdateCurrentPlayer
  | AddReferee
  | RemoveReferee
  | RemovePlayer
  | UpdateCurrentReferee
  | ValidateReferee
  | ValidateAnalysts
  | ValidateTeams
  | CreateMatch
  | CreateMatchSuccess
  | DeleteMatch
  | ClearStore
  | CheckRequired
  | GetMatchVideos
  | SetMatchVideos
  | AddMatchVideo
  | UpdateMatchVideo
  | DeleteMatchVideo
  | DeleteMatchVideoSuccess
  | AddMatchVideoSuccess
  | UpdateMatchVideoSuccess
  | StartEditMatch
  | StartEditSuccess
  | StartMatchAnalysis
  | SetMatch
  | UpdateMatchData
  | UpdateMatchDataSuccess
  | AddNewPlayer
  | AddNewPlayerSuccess
  | ClearAnalysts
  | GetServerVideos
  | SetServerVideos
  | SelectServerVideo;
