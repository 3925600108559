import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ListService } from '../../../services/list.service';
import { filterListItems } from './list.actions';


@Injectable()
export class ListEffects {
  constructor(
    private actions$: Actions,
    private listService: ListService
  ) { }

  filterListItems = createEffect(() =>
    this.actions$.pipe(
      ofType(filterListItems),
      tap(({ entityName, filter, order, initial }) => {
        this.listService.filterListItems(entityName, filter, order, initial);
      })),
    { dispatch: false }
  );
}
