export enum CustomEntitiesEnum {
  RefereeWithMatch = 'RefereeWithMatch',
  MatchWithAnalyst = 'MatchWithAnalyst',
  CustomMatches = 'CustomMatches',
  PlayerWithClub = 'PlayerWithClub',
  CustomClubs = 'CustomClubs',
  CustomOrganisation = 'CustomOrganisation',
  CustomPlayers = 'CustomPlayers',
  Auth = 'Auth',
  CustomUsers = 'CustomUsers',
  CustomStadiums = 'CustomStadiums',
  Reports = "Reports",
  CustomCompetition = 'CustomCompetition'
}
