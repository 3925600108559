import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatchVideosService } from '../../../../services/match-videos.service';
import { VideoPopupComponent } from '../video-popup/video-popup.component';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPreviewComponent implements OnInit, AfterViewInit {

  public src: string;

  @Input() public identifier: string;
  @Input() public time: number;

  @ViewChild('videopreview', { static: false }) public video: ElementRef;

  constructor(
    private readonly dialog: MatDialog,
    private readonly matchVideosService: MatchVideosService,
  ) { }

  ngOnInit() {
    this.src = this.matchVideosService.getVideoUrl(this.identifier);
  }

  ngAfterViewInit() {
    this.video.nativeElement.onloadstart = () => {
      this.video.nativeElement.currentTime = this.time || 1;
    };
  }

  public openVideo() {
    this.dialog.open(VideoPopupComponent, {
      data: {
        src: this.src,
        time: this.time,
      },
      width: '900px',
      height: '615px',
    });
  }

}