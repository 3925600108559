import { Competition } from '..';

export class CompetitionType {
  static className = 'Competition';
  static gqlRootQueryName = 'competition';
  static gqlColumns: string[] = [
      '_id',
      'organisationId',
      'name',
      'season',
  ];

  static gqlNodeMapper = (node: any): Competition => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Competition;
    }

}
