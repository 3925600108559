import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TcCoreModule } from '@tc/core';
import { AgGridModule } from 'ag-grid-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VideoPreloadComponent } from '../match-analysis/components/tc-video-player/components/video-preload.component';
import { TcVideoPlayerComponent } from '../match-analysis/components/tc-video-player/tc-video-player.component';
import { ButtonsCellRenderComponent } from './components/buttons-cell-render/buttons-cell-render.component';
import { CheckboxCellEditorComponent } from './components/checkbox-cell-editor/checkbox-cell-editor.component';
import { ChronoInputCellEditorComponent } from './components/chrono-input-cell-editor/chrono-input-cell-editor.component';
import { GridComponent } from './components/grid/grid.component';
import { InputCellEditorComponent } from './components/input-cell-editor/input-cell-editor.component';
import { NgxInputCellEditorComponent } from './components/ngx-input-cell-editor/ngx-input-cell-editor.component';
import { SelectActorCellEditorComponent } from './components/select-actor-cell-editor/select-actor-cell-editor.component';
import { SelectCellEditorComponent } from './components/select-cell-editor/select-cell-editor.component';
import { SelectTeamCellEditorComponent } from './components/select-team-cell-editor/select-team-cell-editor.component';
import { UploadComponent } from './components/upload/upload.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    GridComponent,
    CheckboxCellEditorComponent,
    SelectCellEditorComponent,
    SelectActorCellEditorComponent,
    SelectTeamCellEditorComponent,
    InputCellEditorComponent,
    NgxInputCellEditorComponent,
    ChronoInputCellEditorComponent,
    ButtonsCellRenderComponent,
    TcVideoPlayerComponent,
    VideoPreloadComponent,
    UploadComponent,
  ],
  exports: [
    GridComponent,
    CheckboxCellEditorComponent,
    SelectCellEditorComponent,
    SelectActorCellEditorComponent,
    SelectTeamCellEditorComponent,
    InputCellEditorComponent,
    NgxInputCellEditorComponent,
    ChronoInputCellEditorComponent,
    ButtonsCellRenderComponent,
    TcVideoPlayerComponent,
    VideoPreloadComponent,
    UploadComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    TcCoreModule,
    MatIconModule,
    MatButtonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(options),
    NgxFileDropModule,
  ]
})
export class SharedModule { }
