import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BreadcrumbState } from './breadcrumb.state';

export const getBreadcrumbState = createFeatureSelector<BreadcrumbState>(
  'breadcrumb'
);

export const getBreadcrumbList = createSelector(
    getBreadcrumbState,
  (state: BreadcrumbState) => state.breadcrumbList
);


