import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country, EntitiesEnum, Organisation } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { QlAutcompleteService } from 'apps/frontend/src/app/services/core/ql-autocomplete.service';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { take } from 'rxjs/operators';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { saveOrganisations } from '../../../../users/store/users.actions';
import { getOrganisations } from '../../../../users/store/users.selectors';
import { upsertOrganisationSuccess } from '../../../store/organisations.actions';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss']
})
export class OrganisationDetailComponent extends TcGenericFormComponent<Organisation> implements OnInit {

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<OrganisationDetailComponent>,
    private readonly refresh: ListRefreshService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Organisation,
    elem: ElementRef,
  ) {

    super('Organisations', entityCollectionServiceFactory, translate, elem);

    const [countryId, countryName] = [data?.countryId || null, (data as any)?.countryName || null];

    const preselected = countryId ? {
      value: countryId, label: countryName
    } : null;

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'code',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: false,
              maxLength: 50
            },
          },
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          //TODO
          // {
          //   key: 'countryId',
          //   type: 'input',
          //   templateOptions: {
          //     type: 'text',
          //     required: true,
          //     maxLength: 50
          //   },
          // },
          {
            key: 'countryId',
            type: 'autocomplete-list',
            templateOptions: {
              required: true,
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Country>(EntitiesEnum.Countrys, term, 'id', 'code'),
              listValue: 'country.code',
              preselected
            },
            validators: {
              countryId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
            // expressionProperties: {
            //   'templateOptions.disabled': 'model.readOnly'
            // },
          },
          //TODO REMOVE THIS
          // {
          //   key: 'organisationCode',
          //   type: 'input',
          //   templateOptions: {
          //     type: 'text',
          //     required: true,
          //     maxLength: 50
          //   },
          // }
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);
      delete (this.model as any).organisationName;
      delete (this.model as any).haveOrganisationUser;
      delete (this.model as any).haveCompetition;
      delete (this.model as any).countryName;
    }

    this.afterSave = async (model) => {
      this.store.dispatch(upsertOrganisationSuccess({ organisation: model }));

      const orgs = await this.store.select(getOrganisations).pipe(take(1)).toPromise()
      this.store.dispatch(saveOrganisations({ orgs: [...orgs, { ...model, add: true }] as any }));

      this.refresh.emitRefresh(ListRefreshType.Organisations);

      this.onClose();
    }
  }

  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }
}
