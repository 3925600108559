import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { ImagePreviewService } from '../../services/image-preview/image-preview.service';
import { toBase64 } from '../../utils/toBase64';
import { ConfigKeys } from '../shared/interfaces/config.interface';
import { ConfigService } from '../shared/services/config.service';

@Component({
    selector: 'app-formly-upload-type',
    template: `
    <input
        type="file"
        #fileInput
        style="display: none"
        (change)="onScannedFileSelected()"
        accept="image/*"
        capture="camera"
    >
    <div
        class="photo-select"
        (click)="onScanFile()"
        >
        <img
            *ngIf="base64 || file"
            class="image"
            [src]="getSrc()"
            width="50px"
            (click)="zoom($event)"
        >
        <input
            matInput
            [hidden]="true"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [(ngModel)]="file"
        >
        <span style="margin-left: auto;">
            <mat-icon class="mat-icon">camera_enhance</mat-icon>
        </span>
    </div>
  `,
    styles: [`
    .photo-select {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 1px;
        cursor: pointer;
    }

    .image {
        margin-right: 10px;
    }
    
    .mat-icon {
        color: #00A6FB;
    }
`]
})
export class AppFormlyUploadComponent extends FieldType implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput;

    public base64 = null;
    public file = null;
    public label: string;

    constructor(
        private readonly config: ConfigService,
        private readonly translate: TcTranslateService,
        private readonly imagePreview: ImagePreviewService,
        private readonly notificationService: TcNotificationService,
    ) {
        super();
    }

    ngOnInit() {
        this.label = this.field?.templateOptions?.label ?? '';
        this.file = this.model.logoFileIdentifier
            || this.model.signatureFileIdentifier;
    }

    onScanFile() {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }

    public async onScannedFileSelected() {
        this.file = this.fileInput.nativeElement.files[0];

        if ((this.file?.size || 0) / 1024 / 1024 > 10) {
            this.notificationService.error(this.translate.instant('image-upload.error'));
            return;
        }

        this.base64 = await toBase64(this.file);
    }

    public zoom(event: MouseEvent) {
        this.imagePreview.open(this.base64 || this.getIdentifierUrl(this.file));

        event.stopPropagation();
        return false;
    }

    public getSrc() {
        return this.base64 || this.getIdentifierUrl(this.file);
    }

    private getIdentifierUrl(identifier: string): string {
        if (!identifier) {
            return null;
        }

        const base = `${this.config.get(ConfigKeys.API_BASE_PATH)}/storage`;

        return `${base}/${identifier}`;
    }

}
