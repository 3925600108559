import { OrganisationUser } from '..';

export class OrganisationUserType {
  static className = 'OrganisationUser';
  static gqlRootQueryName = 'organisationuser';
  static gqlColumns: string[] = [
      '_id',
      'organisationId',
      'userId',
  ];

  static gqlNodeMapper = (node: any): OrganisationUser => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as OrganisationUser;
    }

}
