import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-preload',
  templateUrl: './video-preload.component.html',
  styleUrls: ['./video-preload.component.scss']
})
export class VideoPreloadComponent implements AfterViewInit {
  @Input() public src: string;

  @ViewChild('videopreload', { static: false }) public video: ElementRef;

  ngAfterViewInit() {
    this.video.nativeElement.onloadstart = () => {
      this.video.nativeElement.currentTime = 1;
    };
  }

}