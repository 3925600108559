import { DbVersion } from '..';

export class DbVersionType {
  static className = 'DbVersion';
  static gqlRootQueryName = 'dbversion';
  static gqlColumns: string[] = [
      'version',
  ];

  static gqlNodeMapper = (node: any): DbVersion => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as DbVersion;
    }

}
