import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Club, Country, CustomEntitiesEnum, EntitiesEnum, Player, UploadTypeEnum } from '@match-fix/shared';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  TcGenericFormComponent,
  TcGenericListComponent,
  TcListDisplayColumnType,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcNotificationService, TcTranslateService
} from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { Subscription } from 'rxjs';
import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { upsertPlayer } from '../../../../players/store/players.actions';
import { UploadService } from '../../../../shared/services/upload.service';
import { CreateClub, saveClubInStore, UpdateClub, upsertClubSuccess } from '../../../store/clubs.actions';
import { getClubFilter, getClubPlayersList } from '../../../store/clubs.selectors';
import { SelectPlayerComponent } from '../select-player/select-player.component';

// tslint:disable-next-line: nx-enforce-module-boundaries
@Component({
  selector: 'app-club-detail',
  templateUrl: './club-detail.component.html',
  styleUrls: ['./club-detail.component.scss']
})
export class ClubDetailComponent extends TcGenericFormComponent<Club> implements OnInit, AfterViewInit {

  edit = false;

  playersList: TcGenericListComponent<any>;
  @ViewChild('selectPlayersList', { static: true }) set setPlayersList(values: TcGenericListComponent<Player>) {
    this.playersList = values;
    this.playersList.entityName = CustomEntitiesEnum.CustomPlayers;
  }

  playersSubscription: Subscription;

  clubFilterSubscription: Subscription;

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    elem: ElementRef,
    private store: Store<any>,
    private dialog: MatDialog,
    private uploadService: UploadService,
    private dialogRef: MatDialogRef<ClubDetailComponent>,
    private qlAutocompleteService: QlAutcompleteService,
    private readonly notification: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Club,
  ) {
    super(CustomEntitiesEnum.CustomClubs, entityCollectionServiceFactory, translate, elem);

    this.edit = !!data;
    const [countryId, countryName] = [data?.countryId || null, (data as any)?.countryName || null];

    const preselected = countryId ? {
      value: countryId, label: countryName
    } : null;

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            model: this.model,
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'code',
            type: 'input',
            model: this.model,
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
            validators: {
              codeLength: {
                expression: (c) => c.value?.length <= 3,
                message: (error, field: FormlyFieldConfig) => `${this.translate.instant(`club-detail.validations.code`)}`,
              }
            },
          },
          {
            key: 'countryId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Country>(EntitiesEnum.Countrys, term, 'id', 'code'),
              listValue: 'country.code',
              required: true,
              preselected
            },
          },
          {
            key: 'logoFileIdentifier',
            type: 'upload-image',
            templateOptions: {
              required: true,
            },
          },
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.data) {
      this.store.dispatch(saveClubInStore({ club: this.data }));
      this.model = { ...this.data };
    } else {
      this.clubFilterSubscription = this.store.pipe(select(getClubFilter)).subscribe(clubFilter => {

        if (clubFilter) {
          const club = { id: null, code: null, name: clubFilter, players: [] };
          this.model = Object.assign({}, club);
        }
      });
    }

    this.playersList.rows$ = this.store.select(getClubPlayersList);

    this.playersList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.playersList.showTotalInActionsHeader = false;

    this.playersList.sortType = TcListSortType.Disabled;
    this.playersList.filterType = TcListFilterType.Disabled;
    this.playersList.hasFixedHeader = true;

    this.playersList.hasAddButton = false;
    this.playersList.addItemWhenKeyPresed = false;

    this.playersList.hasActionsLabel = false;
    this.playersList.isPaged = false;


    this.playersList.columns = [
      {
        propertyName: 'firstName',
        visible: true
      },
      {
        propertyName: 'lastName',
        visible: true
      },
      {
        propertyName: 'birthDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },

    ];

    this.playersList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editPlayer(row);
      }
    };

    this.playersList.onRowClick = (row: any) => {
      this.editPlayer(row);
    };

    this.playersList.addItem = () => {
      this.store.dispatch(upsertPlayer({}));
    };

    if (this.data) {
      this.model = { ...this.data };
      delete (this.model as any).clubName;
    } else {
      this.playersList.rows = [];
    }

    this.afterSave = (model) => {
      this.store.dispatch(upsertClubSuccess({ club: model }));
      this.onClose();
    }

  }

  ngAfterViewInit() {
    this.playersList.rows$ = this.store.select(getClubPlayersList);
  }

  onClose() {
    this.store.dispatch(saveClubInStore({ club: null }));
    this.dialogRef.close();
  }

  selectPlayer() {
    this.dialog.open(SelectPlayerComponent, {
      height: '650px',
      width: '500px',
    });
  }

  private editPlayer(row: any) {
    this.store.dispatch(upsertPlayer({ player: row }));
  }

  async submit() {
    if (this.form.valid) {
      let fileIdentifier = this.model.logoFileIdentifier;

      if (typeof this.model.logoFileIdentifier === 'object') {
        const fileRes = await this.uploadService.upload(this.model.logoFileIdentifier, (this.model.logoFileIdentifier as any).name, UploadTypeEnum.Image).toPromise();

        fileIdentifier = (fileRes as any)?.body?.id;
      }

      if (!fileIdentifier) {
        this.notification.error('Failed to upload');
        return;
      }

      const data: Club = {
        id: this.model.id || null,
        code: this.model.code,
        name: this.model.name,
        countryId: this.model.countryId,
        logoFileIdentifier: fileIdentifier,
        players: [],
      } as any;

      this.store.dispatch(
        data.id ? new UpdateClub(data) : new CreateClub(data),
      );

      this.onClose();
    }
  }

}
