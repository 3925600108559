import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TcConfirmDialogComponent, TcNotificationService, TcTranslateService } from '@tc/core';
import { tap } from 'rxjs/operators';
import { RefereesService } from '../../../services/business-services/referees-service';
import { ClubListRefreshService, ListRefreshName } from '../../clubs/services/club-list-refresh.service';
import { RefereeDetailComponent } from '../components/smart/referee-detail/referee-detail.component';
import { deleteReferee, upsertReferee, upsertRefereeSuccess } from './referees.actions';

@Injectable()
export class RefereesEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly refereesService: RefereesService,
    private readonly translateService: TcTranslateService,
    private readonly refreshService: ClubListRefreshService,
    private readonly notificationService: TcNotificationService,
  ) { }

  upsertReferee = createEffect(() => this.actions$.pipe(
    ofType(upsertReferee),
    tap(({ referee }) => {
      this.dialog.open(RefereeDetailComponent, {
        height: '440px',
        width: '414px',
        data: referee,
      });
    })),
    { dispatch: false }
  );

  deleteReferee = createEffect(() => this.actions$.pipe(
    ofType(deleteReferee),
    tap((referee) => {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          message: 'referees-list.dialog.delete.message',
          noText: 'referees-list.dialog.delete.no',
          yesText: 'referees-list.dialog.delete.yes'
        },
        panelClass: 'referees-list-dialog-panel'
      });
      dialog.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.refereesService.delete(referee.id).then((r) => {
            //TODO: refresh the list
          })
        }
      });
    })),
    { dispatch: false }
  );

  upsertRefereeSuccess = createEffect(() => this.actions$.pipe(
    ofType(upsertRefereeSuccess),
    tap(({ referee }) => {
      if (referee.id) {
        this.notificationService.success(this.translateService.instant('referee-detail.alerts.save-success'));
      } else {
        this.notificationService.success(this.translateService.instant('referee-detail.alerts.edit-success'));
      }

      this.refreshService.emitRefresh(ListRefreshName.Referees);
    })),
    { dispatch: false }
  );
}
