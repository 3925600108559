export enum PlayerDeficiencyTypeEnum {
  HighReactionTime = 'HighReactionTime',
  LowReactionTime = 'LowReactionTime',
  LowRun = 'LowRun',
  NoMarking = 'NoMarking',
  NoPressing = 'NoPressing',
  OwnGoal = 'OwnGoal',
  Simulation = 'Simulation',
  VoluntaryFault = 'VoluntaryFault',
  WrongAttitude = 'WrongAttitude',
  WrongClearance = 'WrongClearance',
  WrongControl = 'WrongControl',
  WrongDribble = 'WrongDribble',
  WrongPass = 'WrongPass',
  WrongPosition = 'WrongPosition',
  WrongSave = 'WrongSave',
  WrongShot = 'WrongShot',
  Other = 'Other',
};

export const allPlayerDeficiencyTypes = Object.keys(PlayerDeficiencyTypeEnum);

export const allPlayerDeficiencyList = (translate) => ([
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.NoMarking}`),
    value: PlayerDeficiencyTypeEnum.NoMarking
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.NoPressing}`),
    value: PlayerDeficiencyTypeEnum.NoPressing
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.LowRun}`),
    value: PlayerDeficiencyTypeEnum.LowRun
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.HighReactionTime}`),
    value: PlayerDeficiencyTypeEnum.HighReactionTime
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.LowReactionTime}`),
    value: PlayerDeficiencyTypeEnum.LowReactionTime
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongAttitude}`),
    value: PlayerDeficiencyTypeEnum.WrongAttitude
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongControl}`),
    value: PlayerDeficiencyTypeEnum.WrongControl
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongPass}`),
    value: PlayerDeficiencyTypeEnum.WrongPass
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongDribble}`),
    value: PlayerDeficiencyTypeEnum.WrongDribble
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongShot}`),
    value: PlayerDeficiencyTypeEnum.WrongShot
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.OwnGoal}`),
    value: PlayerDeficiencyTypeEnum.OwnGoal
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongPosition}`),
    value: PlayerDeficiencyTypeEnum.WrongPosition
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongSave}`),
    value: PlayerDeficiencyTypeEnum.WrongSave
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.WrongClearance}`),
    value: PlayerDeficiencyTypeEnum.WrongClearance
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.VoluntaryFault}`),
    value: PlayerDeficiencyTypeEnum.VoluntaryFault
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.Simulation}`),
    value: PlayerDeficiencyTypeEnum.Simulation
  },
  {
    label: translate.instant(`deficiencies.${PlayerDeficiencyTypeEnum.Other}`),
    value: PlayerDeficiencyTypeEnum.Other
  },
]);
