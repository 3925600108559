import { ClubPlayerModel, Player, BaseCustomPlayerModel, Club } from '@match-fix/shared';

export default class ClubsState {
    players: BaseCustomPlayerModel[];
    clubFilter: string;
    club: Club;
}

export const initializeState = (): ClubsState => {
    return {
        players: [],
        clubFilter: null,
        club: null,
    };
};
