import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomEntitiesEnum,
  Match,
  MatchAnalysisStateEnum,
  MatchStatusEnum,
  UserModel,
  UserRoleEnum
} from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcGenericListComponent, TcListRowActionButtonsPosition, TcListSortType, TcSmartComponent } from '@tc/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CompetitionsService } from '../../../../../services/business-services/competitions-service';
import { PermissionsService } from '../../../../../services/permissions/permissions.service';
import { PermissionEnum } from '../../../../../services/permissions/types';
import { getAuthenticatedUser } from '../../../../auth/store/auth.selectors';
import { AnalysisService } from '../../../../match-analysis/services/analysis.service';
import { SetMatchAnalysis } from '../../../../match-analysis/store/analysis.actions';
import { ListRefreshService } from '../../../services/list-refresh.service';
import { DeleteMatch, SetMatchVideos, StartEditMatch, upsertMatch } from '../../../store/matchs.actions';
import { ManageVideosComponent } from '../manage-videos/manage-videos.component';
import { ReopenAnalysisComponent } from '../reopen-analysis/reopen-analysis.component';
import { GoToMatchAnalysis } from './../../../store/matchs.actions';


@Component({
  selector: 'app-match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchListComponent extends TcSmartComponent
  implements OnInit, AfterViewInit, OnDestroy {
  public canManageVideos = false;

  private filter = { key: 'id', order: 'DESC' };
  private search: string;
  private onlyMine: boolean;

  private competitionsIds: number[] = [];

  constructor(
    private readonly store: Store<any>,
    private readonly dialog: MatDialog,
    private readonly analysisService: AnalysisService,
    private readonly permissionsService: PermissionsService,
    private readonly listRefreshService: ListRefreshService,
    private readonly competitionsService: CompetitionsService,
  ) {
    super();
  }

  authenticatedUser: UserModel;
  authenticatedUserSubscription: Subscription;

  refreshSubscription: Subscription;

  private matchsList: TcGenericListComponent<any>;
  private isUserAdmin = false;
  private isSupervisorOrOperator = false;

  @ViewChild('colMatchAnalysisStateTemplate', { static: true })
  colMatchAnalysisStateTemplate: TemplateRef<any>;
  @ViewChild('rowMenuActionTemplate', { static: true })
  rowMenuActionTemplate: TemplateRef<any>;
  // @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;
  @ViewChild('colMatchStadiumTemplate', { static: true })
  colMatchStadiumTemplate: TemplateRef<any>;
  @ViewChild('colMatchRefereeTemplate', { static: true })
  colMatchRefereeTemplate: TemplateRef<any>;
  @ViewChild('colMatchStatusTemplate', { static: true })
  colMatchStatusTemplate: TemplateRef<any>;
  @ViewChild('colDateTemplate', { static: true })
  colDateTemplate: TemplateRef<any>;
  @ViewChild('colTimeTemplate', { static: true })
  colTimeTemplate: TemplateRef<any>;
  @ViewChild('matchsList', { static: true }) set setMatchsList(
    values: TcGenericListComponent<Match>
  ) {
    this.matchsList = values;
    this.matchsList.entityName = CustomEntitiesEnum.CustomMatches;
  }

  async ngAfterViewInit() {
    await this.setAvailableCompetitions();
    this.refresh();
  }

  async ngOnInit() {
    this.initListeners();

    this.matchsList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.AfterData;
    this.matchsList.showTotalInActionsHeader = false;

    this.matchsList.sortType = TcListSortType.Server;
    this.matchsList.isFiltrable = false;
    this.matchsList.hasFixedHeader = true;

    this.matchsList.hasAddButton = false;
    this.matchsList.addItemWhenKeyPresed = true;

    this.matchsList.hasActionsLabel = true;
    this.matchsList.isPaged = false;

    this.matchsList.onScrollDown = () => {
      this.matchsList.service.getAll();
    };

    this.matchsList.columns = [
      {
        propertyName: 'competitionName',
        visible: true
      },
      {
        propertyName: 'clubACode',
        visible: true
      },
      {
        propertyName: 'clubBCode',
        visible: true
      },
      {
        propertyName: 'principalReferee',
        visible: true,
      },
      {
        propertyName: 'stadium.name',
        visible: true,
        htmlTemplate: this.colMatchStadiumTemplate
      },
      {
        propertyName: 'date',
        visible: true,
        htmlTemplate: this.colDateTemplate
      },
      {
        propertyName: 'time',
        visible: true,
        htmlTemplate: this.colTimeTemplate
      },
      {
        propertyName: 'operator1Code',
        visible: true
      },
      {
        propertyName: 'operator2Code',
        visible: true
      },
      {
        propertyName: 'supervisorCode',
        visible: true
      },
      {
        propertyName: 'expertCode',
        visible: true
      },
      {
        propertyName: 'totalVideos',
        visible: true
      },
      {
        propertyName: 'matchState',
        visible: true
      },
      {
        propertyName: 'matchAnalysisState',
        visible: true,
        htmlTemplate: this.colMatchAnalysisStateTemplate
      },
      {
        propertyName: 'matchStatus',
        visible: true,
        htmlTemplate: this.colMatchStatusTemplate
      }
    ];

    this.matchsList.rowActions = [
      {
        actionName: 'menu',
        icon: 'more_horiz',
        visible: true,
        htmlTemplate: this.rowMenuActionTemplate
      }
    ];

    this.matchsList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editMatch(row);
      }
    };

    this.matchsList.onRowClick = (row: any) => {
      if (this.isUserAdmin || row.matchAnalysisState === MatchAnalysisStateEnum.NotStarted) {
        this.editMatch(row);
      }
    };

    this.canManageVideos = await this.permissionsService.hasAccess(
      PermissionEnum.Videos
    );
  }

  public canForceAutomaticAssembly(data): boolean {
    return data.matchAnalysisState !== MatchAnalysisStateEnum.NotStarted &&
      data.matchAnalysisState !== MatchAnalysisStateEnum.Classified && this.permissionsService.hasAccess(
        PermissionEnum.ForceAutomaticAssembly,
        () => [data.analysisOp1Validated, data.analysisOp2Id ? data.analysisOp2Validated : undefined].filter(item => typeof item === 'boolean').every(item => item === true)
      );
  }

  public forceAutomaticAssembly(data) {
    this.analysisService.forceAutomaticAssembly(data.id);
  }

  public canReopenAnalysisReport(data): boolean {
    return this.permissionsService.hasAccess(
      PermissionEnum.ReopenMatchAnalysis,
      () => data.matchAnalysisState !== MatchAnalysisStateEnum.NotStarted
    );
  }

  public reopenAnalysis(data) {
    const dialog = this.dialog.open(ReopenAnalysisComponent, {
      height: '303px',
      width: '600px',
      data: {
        matchAnalysisState: data.matchAnalysisState,
        analysisExpertId: data.analysisExpertId,
        analysisOp1Id: data.analysisOp1Id,
        analysisOp2Id: data.analysisOp2Id,
        matchId: data.id,
      },
    });

    dialog.afterClosed().subscribe(async result => {
      if (result === 'Yes') {
        this.refresh();
      }
    });
  }

  filterList(value: string) {
    this.search = value;
    this.refresh();
  }

  toggleChanged(event: boolean) {
    this.onlyMine = event;
    this.refresh();
  }

  delete(data: any) {
    this.store.dispatch(new DeleteMatch({ id: data.id }));
  }

  formatDate(date: string): string {
    return moment(Number(date)).format('DD/MM/YYYY ');
  }

  formatTime(date: string): string {
    return moment(Number(date)).format('HH:mm');
  }

  analyseMatch(data: any) {
    this.store.dispatch(new GoToMatchAnalysis());

    if (this.authenticatedUser && data) {
      const payload = {
        matchId: data.id,
        operator1Id: data.operator1Id,
        operator2Id: data.operator2Id,
        supervisorId: data.supervisorId,
        userIdByAnalysisId: {
          [data.analysisOp1Id]: data.operator1Id,
          [data.analysisOp2Id]: data.operator2Id,
          [data.analysisSupervisorId]: data.supervisorId
        },
        analysisBreadcrumbName: `${data.clubACode} VS ${data.clubBCode
          } ${moment(parseInt(data.date, 10)).format('DD/MM/YYYY')}`
      };

      if (this.authenticatedUser.role === UserRoleEnum.Administrator) {
        return this.store.dispatch(
          new SetMatchAnalysis({
            ...payload,
            analysisRole: UserRoleEnum.Supervisor,
            analysisId: data.analysisSupervisorId
          })
        );
      }
      if (this.authenticatedUser.id === data.expertId) {
        return this.store.dispatch(
          new SetMatchAnalysis({
            ...payload,
            analysisRole: UserRoleEnum.Expert,
            analysisId: data.analysisExpertId
          })
        );
      }
      if (this.authenticatedUser.id === data.supervisorId) {
        return this.store.dispatch(
          new SetMatchAnalysis({
            ...payload,
            analysisRole: UserRoleEnum.Supervisor,
            analysisId: data.analysisSupervisorId
          })
        );
      }
      if (this.authenticatedUser.id === data.operator1Id) {
        return this.store.dispatch(
          new SetMatchAnalysis({
            ...payload,
            analysisRole: UserRoleEnum.Operator,
            analysisId: data.analysisOp1Id,
            analysisStatus: data.analysisOp1Status
          })
        );
      }
      if (this.authenticatedUser.id === data.operator2Id) {
        return this.store.dispatch(
          new SetMatchAnalysis({
            ...payload,
            analysisRole: UserRoleEnum.Operator,
            analysisId: data.analysisOp2Id,
            analysisStatus: data.analysisOp2Status
          })
        );
      }
    }
  }

  checkMatchRoles(row: any) {
    const customPermission = (): boolean => {
      const matchOperators = [
        row.operator1Id,
        row.operator2Id,
        row.supervisorId,
        row.expertId
      ];

      return matchOperators.includes(this.authenticatedUser.id);
    };

    const matchHasAnalyst = row.supervisorId && row.operator1Id && row.expertId;

    return (
      matchHasAnalyst &&
      row.hasPrimaryVideo &&
      (this.isUserAdmin ||
        this.permissionsService.hasAccess(
          PermissionEnum.MatchAnalysis,
          customPermission
        ))
    );
  }

  public canDelete(row: any): boolean {
    return row.matchAnalysisState === MatchAnalysisStateEnum.NotStarted;
  }

  addItem() {
    this.store.dispatch(upsertMatch({}));
  }

  private editMatch(row: Match) {
    this.store.dispatch(new StartEditMatch(row));
  }

  getMatchAnalysisStateColor(row: any) {
    switch (row.matchStatus) {
      case MatchStatusEnum.Fixed:
        return '#f3a88a';
      case MatchStatusEnum.PotentiallyFixed:
        return '#f2df98';
      case MatchStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  getStadiumDetails(row: any) {
    if (row.stadium) {
      return row.stadium.name + ' - ' + row.stadium.city;
    }
    return '';
  }

  public manageVideos(data) {
    this.store.dispatch(new SetMatchVideos(null));
    const videoDialog = this.dialog.open(ManageVideosComponent, {
      height: '72vh',
      width: '1400px',
      data: { matchId: data.id }
    });

    videoDialog.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  private refresh() {
    const filter = this.getFilter();

    this.matchsList.service.clearCache();
    this.matchsList.service.getWithQuery({
      initial: 'true',
      orderBy: JSON.stringify(this.filter),
      ...filter
    } as any);
  }

  private initListeners() {
    this.refreshSubscription = this.listRefreshService.subject.subscribe(() =>
      this.refresh()
    );

    this.authenticatedUserSubscription = this.store
      .select(getAuthenticatedUser)
      .subscribe(user => {
        this.isUserAdmin = user?.role === UserRoleEnum.Administrator;
        this.isSupervisorOrOperator = user?.role === UserRoleEnum.Supervisor || user?.role === UserRoleEnum.Operator;
        this.authenticatedUser = user;
      });
  }

  private async setAvailableCompetitions() {
    if (this.authenticatedUser.role !== UserRoleEnum.Administrator) {
      const competitions = await this.competitionsService.getCurrentUserCompetitions()
      this.competitionsIds = competitions.map(({ id }) => id);
    }
  }

  private getFilter(): { filter?: string } {
    const searchFilter = !!this.search
      ? { anyFieldContains: this.search }
      : {};

    let filter: any = {
      ...searchFilter,
    };

    if (!this.isUserAdmin) {
      filter = {
        ...filter,
        competitionId: { value: (this.competitionsIds || []).join(','), filterType: 'In' },
      };
    }

    if (this.isSupervisorOrOperator) {
      filter = {
        ...filter,
        matchAnalysisState: { value: MatchAnalysisStateEnum.Classified, filterType: 'NotEqual' },
      };
    }

    if (this.onlyMine) {
      filter = {
        ...filter,
        myMatches: `${this.authenticatedUser.id}`
      };
    }

    return {
      filter: JSON.stringify(filter)
    };
  }

  ngOnDestroy() {
    if (this.authenticatedUserSubscription) {
      this.authenticatedUserSubscription.unsubscribe();
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
