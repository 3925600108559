import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EntitiesEnum, KeyPoint, NoteKeyPointListItem } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class KeyPointService extends CrudService<KeyPoint> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.KeyPoints, httpClient, basePath, configuration);
  }

  /**
   * Function to get all match keypoints
   */
  async getMatchKeyPoints(matchId: number): Promise<KeyPoint[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<KeyPoint[]>(
      `${url}/getMatchKeyPoints?matchId=${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<KeyPoint[]>();
  }

  /**
   * Function to get all match keypoints
   */
  async getMatchNoteKeyPoints(matchId: number): Promise<NoteKeyPointListItem[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<NoteKeyPointListItem[]>(
      `${url}/getMatchNoteKeyPoints?matchId=${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<NoteKeyPointListItem[]>();
  }

  async realignKeyPoints(matchId: number, body: { currentTime: number, newTime: number }): Promise<void> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post(
      `${url}/realign/${matchId}`,
      body,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise() as any;
  }

  async deleteAll(id: number): Promise<void> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.delete<void>(
      `${url}/${id}/all`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise();
  }

}
