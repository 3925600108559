import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Chrono } from '@match-fix/shared';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { getChronoTime } from '../../../../../../../utils/getChronoTime';
import { convertToReadableFormat } from '../../../../../../../utils/milisecondsToReadableFormat';
import { isChronoValide } from '../../../../../../../utils/validateChrono';

@Component({
  selector: 'app-edit-chrono',
  templateUrl: './edit-chrono.component.html',
  styleUrls: ['./edit-chrono.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditChronoComponent extends TcFormComponent<any>
  implements OnInit {
  @Input() public chrono: Chrono = {} as Chrono;
  @Input() public formTitle = '';
  @Input() public formId;

  @Output() public changeChrono: EventEmitter<Chrono> = new EventEmitter();

  constructor(public elem: ElementRef, public translate: TcTranslateService) {
    super(translate, elem);
  }

  ngOnInit() {
    super.ngOnInit();

    const chrono = JSON.parse(JSON.stringify(this.chrono));

    this.model = {
      round: chrono?.round || 1,
      isAdditionalTime: Boolean(chrono?.isAdditionalTime),
      matchTime: convertToReadableFormat(chrono?.matchTime || 0, 'mm:ss')
    };

    this.chrono = {
      ...chrono,
      round: chrono?.round || 1,
      matchTime: chrono?.matchTime || 0,
      isAdditionalTime: Boolean(chrono?.isAdditionalTime)
    };

    this.changeChrono.emit(this.chrono);

    this.initFields();
  }

  private initFields() {
    this.fields = [
      {
        key: 'matchTime',
        type: 'chrono-input',
        templateOptions: {
          label: this.translate.instant('edit-video-chrono.labels.matchTime'),
          required: true,
          modelKey: 'matchTime',
          setValue: value => {

            let time;
            if (isChronoValide(value)) {
              time = getChronoTime(value).seconds;
            } else {
              time = null;
            }

            this.chrono = {
              ...this.chrono,
              matchTime: time === null ? null : time * 1000,
            };

            this.changeChrono.emit(this.chrono);
          }
        },
        validators: {
          chrono: {
            expression: (c) => isChronoValide(c.value),
          },
        },
      },
      {
        key: 'round',
        type: 'select',
        templateOptions: {
          label: this.translate.instant('edit-video-chrono.labels.round'),
          required: true,
          modelKey: 'round',
          options: [
            { value: 1, label: 'Round 1' },
            { value: 2, label: 'Round 2' },
            { value: 3, label: 'Round 3' },
            { value: 4, label: 'Round 4' },
            { value: 5, label: 'Round 5' }
          ],
          change: event => this.onChangeModel('round', event)
        }
      },
      {
        key: 'isAdditionalTime',
        type: 'checkbox',
        templateOptions: {
          label: this.translate.instant(
            'edit-video-chrono.labels.isAdditionalTime'
          ),
          modelKey: 'isAdditionalTime',
          pattern: 'true',
          change: event => this.onChangeModel('isAdditionalTime', event)
        }
      }
    ];
  }

  onChangeModel(key: string, event: any) {
    this.chrono[key] = event.model[key];

    this.changeChrono.emit(this.chrono);
  }
}
