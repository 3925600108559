export enum ListTypeEnum {
  AnalystType = 'AnalystType',
  MatchAnalysisState = 'MatchAnalysisState',
  MatchRefereeRole = 'MatchRefereeRole',
  MatchStatus = 'MatchStatus',
  MatchState = 'MatchState',
  KeypointAnalysisStatus = 'KeypointAnalysisStatus',
  IdentityDocumentType = 'IdentityDocumentType',
  DocumentAllowedExtension = 'DocumentAllowedExtension',
}

export const allListTypes = Object.keys(ListTypeEnum);
