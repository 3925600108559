import { Injectable } from '@angular/core';
import { TeamListItem } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { TeamsService } from '../../../services/business-services/teams-service';
import { getMatchId } from '../store/analysis.selectors';

@Injectable()
export class TeamService {

    constructor(
        private readonly store$: Store<TcAppState>,
        private readonly teamsService: TeamsService,
    ) { }

    public async getTeamsByMatch(): Promise<TeamListItem[]> {
        const matchId = await this.getMatchId();
        const teams = await this.teamsService.getTeamsByMatch(matchId);

        return teams;
    }

    private async getMatchId(): Promise<number> {
        return await this.store$.select(getMatchId).pipe(take(1)).toPromise();
    }

}
