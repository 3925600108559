import { UserRoleEnum } from '@match-fix/shared';

export enum PermissionEnum {
  Users = 'Users',
  Clubs = 'Clubs',
  Videos = 'Videos',
  Matches = 'Matches',
  Players = 'Players',
  Referees = 'Referees',
  Stadiums = 'Stadiums',
  Reporting = 'Reporting',
  CaptureVideo = 'CaptureVideo',
  Competitions = 'Competitions',
  MatchAnalysis = 'MatchAnalysis',
  Configuration = 'Configuration',
  Organisations = 'Organisations',
  UploadDocument = 'UploadDocument',
  AnalysisResults = 'AnalysisResults',
  ExpertMatchList = 'ExpertMatchList',
  DownloadDocument = 'DownloadDocument',
  EditTranslations = 'EditTranslations',
  ViewAnalysisReport = 'ViewAnalysisReport',
  ReopenMatchAnalysis = 'ReopenMatchAnalysis',
  ExpertMatchKeyPoints = 'ExpertMatchKeyPoints',
  DownloadAnalysisReport = 'DownloadAnalysisReport',
  ForceAutomaticAssembly = 'ForceAutomaticAssembly',
  GenerateAnalysisReport = 'GenerateAnalysisReport',
  MatchAnalysisEditKeyPoint = 'MatchAnalysisEditKeyPoint',
  MatchAnalysisDeleteKeyPoint = 'MatchAnalysisDeleteKeyPoint',
  ChangeMatchStatus = 'ChangeMatchStatus'
}

export interface PermissionItem {
  key: PermissionEnum;
  roles: UserRoleEnum[];
}

export const appPermissions: PermissionItem[] = [
  {
    key: PermissionEnum.Users,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.Reporting,
    roles: [
      UserRoleEnum.Administrator
    ]
  },
  {
    key: PermissionEnum.Organisations,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.Players,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.Clubs,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.Referees,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.Stadiums,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.Competitions,
    roles: [UserRoleEnum.Administrator, UserRoleEnum.Operator]
  },
  {
    key: PermissionEnum.Matches,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.Videos,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.MatchAnalysis,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator
    ]
  },
  {
    key: PermissionEnum.MatchAnalysisEditKeyPoint,
    roles: [
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Administrator
    ]
  },
  {
    key: PermissionEnum.MatchAnalysisDeleteKeyPoint,
    roles: [
      UserRoleEnum.Supervisor,
      UserRoleEnum.Operator,
      UserRoleEnum.Administrator
    ]
  },
  {
    key: PermissionEnum.ForceAutomaticAssembly,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.ExpertMatchList,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Expert,
      UserRoleEnum.Client,
      UserRoleEnum.Supervisor
    ]
  },
  {
    key: PermissionEnum.ExpertMatchKeyPoints,
    roles: [UserRoleEnum.Administrator, UserRoleEnum.Expert, UserRoleEnum.Client]
  },
  {
    key: PermissionEnum.ReopenMatchAnalysis,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.GenerateAnalysisReport,
    roles: [UserRoleEnum.Administrator, UserRoleEnum.Expert]
  },
  {
    key: PermissionEnum.AnalysisResults,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Client,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.ViewAnalysisReport,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Expert,
      UserRoleEnum.Client,
      UserRoleEnum.Supervisor
    ]
  },
  {
    key: PermissionEnum.DownloadAnalysisReport,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Client
    ]
  },
  {
    key: PermissionEnum.UploadDocument,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Client
    ]
  },
  {
    key: PermissionEnum.DownloadDocument,
    roles: [UserRoleEnum.Administrator, UserRoleEnum.Client]
  },
  {
    key: PermissionEnum.CaptureVideo,
    roles: [
      UserRoleEnum.Administrator,
      UserRoleEnum.Operator,
      UserRoleEnum.Expert
    ]
  },
  {
    key: PermissionEnum.EditTranslations,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.Reporting,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.Configuration,
    roles: [UserRoleEnum.Administrator]
  },
  {
    key: PermissionEnum.ChangeMatchStatus,
    roles: [UserRoleEnum.Administrator, UserRoleEnum.Expert]
  },
];
