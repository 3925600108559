import { EntityCollectionReducerMethods, EntityDefinition, EntityCollection, EntityAction, UpdateResponseData } from 'ngrx-data';
import { act } from '@ngrx/effects';

export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(public entityName: string, public definition: EntityDefinition<T>) {
    super(entityName, definition);
  }
  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);
    const total = (action.payload as any).total;
    if (total !== null && total !== undefined) {
      (ec as any).total = (action.payload as any).total;
    }
    return ec;
  }

  protected queryAllSuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = super.queryAllSuccess(collection, action);
    const total = (action.payload as any).total;
    if (total !== null && total !== undefined) {
      (ec as any).total = (action.payload as any).total;
    }

    return ec;
  }

  // protected saveAddOneSuccess(collection: EntityCollection<T>, action: EntityAction<T>): EntityCollection<T> {
  //   const ec = super.saveAddOneSuccess(collection, action);
  //   if ((collection as any).total && action.payload.data && (action.payload.data as any).id) {
  //     (ec as any).total = (collection as any).total + 1;
  //   }
  //   return ec;
  // }

  // protected saveDeleteOneSuccess(collection: EntityCollection<T>, action: EntityAction<number | string>): EntityCollection<T> {
  //   const ec = super.saveDeleteOneSuccess(collection, action);
  //   if ((collection as any).total) {
  //     (ec as any).total = (collection as any).total - 1;
  //   }
  //   return ec;
  // }

}
