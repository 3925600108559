import { Injectable, Optional, Inject } from '@angular/core';
import { CrudService } from '../crud.service';
import { Club, EntitiesEnum } from '@match-fix/shared';
import { HttpClient } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ClubsService extends CrudService<Club> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Clubs, httpClient, basePath, configuration);
  }

  /**
   * Function to get teamm by code
   */
  async getByCode(code: string): Promise<Club> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient.get<Club>(
      `${url}/code/${code}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise();
  }

}
