import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Document, EntitiesEnum, CustomEntitiesEnum } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends CrudService<Document> {

    constructor(
        public httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
    ) {
        super(CustomEntitiesEnum.Reports, httpClient, basePath, configuration);
    }

    public async getMatchReport(matchId: number): Promise<Document> {
        const headers = this.defaultHeaders;
        const url = this.getEntityServiceEndpoint();

        return this.httpClient.get(
            `${url}/match/${matchId}`, {
            withCredentials: true,
            headers,
            observe: 'body',
            reportProgress: false
        }
        ).toPromise() as any;
    }

    public async generateReport(matchId: number) {
        const headers = this.defaultHeaders;
        return this.httpClient.post<any>(
            `${this.configuration.basePath}/reports?matchId=${matchId}`, {
            withCredentials: true,
            headers,
            observe: 'body',
            reportProgress: false
        }
        ).toPromise<any>();
    }
}
