import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { tap } from 'rxjs/operators';
import { OrganisationDetailComponent } from '../components/smart/organisation-detail/organisation-detail.component';
import { deleteOrganisationSuccess, upsertOrganisation, upsertOrganisationSuccess } from './organisations.actions';

@Injectable()
export class OrganisationsEffects {

    constructor(
        private actions$: Actions,
        private dialog: MatDialog,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
    ) { }

    upsertOrganisation = createEffect(() => this.actions$.pipe(
        ofType(upsertOrganisation),
        tap(({ organisation }) => {
            this.dialog.open(OrganisationDetailComponent, {
                height: '400px',
                width: '414px',
                data: organisation,
            });
        })),
        { dispatch: false }
    );

    upsertOrganisationSuccess = createEffect(() => this.actions$.pipe(
        ofType(upsertOrganisationSuccess),
        tap(({ organisation }) => {
            if (organisation.id) {
                this.notificationService.success(this.translateService.instant('organisation-detail.alerts.save-success'));
            } else {
                this.notificationService.success(this.translateService.instant('organisation-detail.alerts.edit-success'));
            }
        })),
        { dispatch: false }
    );

    deleteOrganisationSuccess = createEffect(() => this.actions$.pipe(
        ofType(deleteOrganisationSuccess),
        tap(() => {
            this.notificationService.success(this.translateService.instant('organisations-list.alerts.delete-success'));
        })),
        { dispatch: false }
    );
}
