import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleEnum } from '@match-fix/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcAppState, TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/business-services/auth.service';
import { UsersService } from '../../../services/business-services/users-service';
import { PermissionsService } from '../../../services/permissions/permissions.service';
import { getTokenSuccess, login, loginFailure, loginSuccess, logout } from './auth.actions';

@Injectable()
export class AuthEffects {

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly usersService: UsersService,
    private readonly translateService: TcTranslateService,
    private readonly permissionsService: PermissionsService,
    private readonly notificationService: TcNotificationService,
  ) { }

  login$ = createEffect(() => this.actions$.pipe(
    ofType(login),
    mergeMap(async ({ token, code }) => {
      try {
        const { access_token } = await this.authService.verify(`Bearer ${token}`, code);

        this.store$.dispatch(getTokenSuccess({ token: access_token }));
      } catch (error) {
        this.notificationService.error(this.translateService.instant('login-form.invalid'));
      }
    })),
    { dispatch: false }
  );

  getTokenSuccess = createEffect(() => this.actions$.pipe(
    ofType(getTokenSuccess),
    mergeMap(async () => {
      const user = await this.usersService.getPublicData();

      this.permissionsService.init(user);

      return loginSuccess({ user })
    }))
  );

  loginSuccess = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess),
    tap((action) => {
      switch (action.user?.role) {
        case UserRoleEnum.Administrator:
          this.router.navigateByUrl('/users');
          break;
        case UserRoleEnum.Client:
          this.router.navigateByUrl('/expert-analysis');
          break;
        default:
          this.router.navigateByUrl('/');

      }
    })),
    { dispatch: false }
  );

  loginFailed = createEffect(() => this.actions$.pipe(
    ofType(loginFailure),
    tap(({ error }) => {
      this.notificationService.error(this.translateService.instant(error));
    })),
    { dispatch: false }
  );

  logout = createEffect(() => this.actions$.pipe(
    ofType(logout),
    tap(() => {
      this.router.navigate(['/login']);
    })),
    { dispatch: false }
  );

}
