import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { getAuthenticationState } from '../../modules/auth/store/auth.selectors';
import { CustomPermissionsService } from '../permissions/custom-permissions.service';
import { PermissionsService } from '../permissions/permissions.service';
import { PermissionEnum } from '../permissions/types';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {

  constructor(
    private readonly store: Store<TcAppState>,
    private readonly permissionsService: PermissionsService,
    private readonly customPermissionsService: CustomPermissionsService,
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const authState = await this.store.select(getAuthenticationState).pipe(take(1)).toPromise();

    if (!authState.user) {
      return false;
    }

    this.permissionsService.init(authState.user);

    const genericPermission = this.permissionsService.hasAccess(route.data.permission)

    if (genericPermission && route.data.permission === PermissionEnum.MatchAnalysis) {
      return await this.customPermissionsService.matchHasPrimaryVideo();
    }

    return genericPermission;
  }
}
