import { Country } from '..';

export class CountryType {
  static className = 'Country';
  static gqlRootQueryName = 'country';
  static gqlColumns: string[] = [
      '_id',
      'code',
      'isInternational',
  ];

  static gqlNodeMapper = (node: any): Country => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Country;
    }

}
