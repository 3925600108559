import { Deficiency } from '..';

export class DeficiencyType {
  static className = 'Deficiency';
  static gqlRootQueryName = 'deficiency';
  static gqlColumns: string[] = [
      '_id',
      'chronoId',
      'deficiencyType',
      'keyPointAnalyseId',
      'refereeId',
      'teamPlayerId',
      'assembledToDeficiencyId',
      'var',
      'comment',
      'invalid',
      'assemblyType',
      'statut',
      'teamId',
  ];

  static gqlNodeMapper = (node: any): Deficiency => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Deficiency;
    }

}
