import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomEntitiesEnum, Referee } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState, TcGenericListComponent, TcListDisplayColumnType, TcListFilterType, TcListRowActionButtonsPosition, TcListSortType, TcSmartComponent } from '@tc/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ClubListRefreshService, ListRefreshName } from '../../../../clubs/services/club-list-refresh.service';
import { upsertReferee } from '../../../store/referees.actions';

@Component({
  selector: 'app-referee-list',
  templateUrl: './referee-list.component.html',
  styleUrls: ['./referee-list.component.scss']
})
export class RefereeListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscription: Subscription;

  constructor(
    private readonly store: Store<TcAppState>,
    private readonly refreshService: ClubListRefreshService,
  ) {
    super();
  }

  refereesList: TcGenericListComponent<any>;
  @ViewChild('refereesList', { static: true }) set setRefereesList(values: TcGenericListComponent<Referee>) {
    this.refereesList = values;
    this.refereesList.entityName = CustomEntitiesEnum.RefereeWithMatch;
  }

  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.refresh();

    this.subscription = this.refreshService.subject
      .pipe(
        filter(e => e === ListRefreshName.Referees)
      )
      .subscribe(() => this.refresh())
  }

  ngOnInit() {
    this.refereesList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.refereesList.showTotalInActionsHeader = false;

    this.refereesList.sortType = TcListSortType.Server;
    this.refereesList.filterType = TcListFilterType.Server;
    this.refereesList.hasFixedHeader = true;

    this.refereesList.hasAddButton = false;
    this.refereesList.addItemWhenKeyPresed = true;

    this.refereesList.hasActionsLabel = true;
    this.refereesList.isPaged = false;

    this.refereesList.onScrollDown = () => {
      this.refereesList.service.getAll();
    };

    this.refereesList.columns = [
      {
        propertyName: 'lastName',
        visible: true
      },
      {
        propertyName: 'firstName',
        visible: true
      },
      {
        propertyName: 'birthDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'lastMatchDate',
        visible: true,
        displayColumnType: TcListDisplayColumnType.Date
      },
      {
        propertyName: 'matchCounts',
        visible: true
      },
      {
        propertyName: 'clubs',
        visible: true
      },
      {
        propertyName: 'countryName',
        visible: true
      },
    ];

    this.refereesList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.refereesList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editReferee(row);
      }
    };

    this.refereesList.onRowClick = (row: any) => {
      this.editReferee(row);
    };

  }

  addItem() {
    this.store.dispatch(upsertReferee({}));
  }

  private refresh() {
    this.refereesList.service.clearCache();
    this.refereesList.service.getWithQuery({ initial: 'true' });
  }

  private editReferee(row: any) {
    const referee = {
      "id": row.id,
      "lastName": row.lastName,
      "firstName": row.firstName,
      "birthDate": row.birthDate,
      "countryId": row.countryId,
      "countryName": row.countryName,
    }
    this.store.dispatch(upsertReferee({ referee: referee }));
  }

  private delete = (row: any) => {
    this.refereesList.tcList.onDeleteAction(row);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
