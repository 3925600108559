import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActorListItem, EntitiesEnum, MatchReferee } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class MatchRefereesService extends CrudService<MatchReferee> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.MatchReferees, httpClient, basePath, configuration);
  }

  /**
   * Function to get all match referees
   */
  async getMatchReferees(matchId: number): Promise<ActorListItem[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<ActorListItem[]>(
      `${url}/match/${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<ActorListItem[]>();
  }
}
