import { ActorListItem, ChronoItem, EventTypeEnum, KeyPointTypeEnum, MatchStatusEnum, UserRoleEnum, Team, TeamListItem } from '@match-fix/shared';
import { TcAction } from '@tc/core';
import { AnalysisState, EventListItem, KeyPointItem } from './analysis.interfaces';

/**
 * Actions to provide to AnalysisState
 */
export enum AnalysisActionTypes {
  INIT = '[Analysis] Init store for analysis', // Setup the store with required ids.
  INIT_SUCCESS = '[Analysis] Init store for analysis done',

  // Key point management
  ADD_KEY_POINT = '[Analysis] Opening popup to add a new key point',
  LOAD_KEY_POINT = '[Analysis] Opening popup for key point edition',
  DELETE_KEY_POINT = '[Analysis] Deleting key point in event list',
  MANAGE_KEY_POINT = '[Analysis] Updating key point in the store',
  CANCEL_KEY_POINT = '[Analysis] Closing the popup and cancelling the key point',
  CLEAR_KEY_POINT = '[Analysis] Key point removed from store',
  SAVE_KEY_POINT = '[Analysis] Save key point in database and close the popup',
  SET_ANALYSIS_STATUS = '[Analysis] Set new analysis status',
  SET_ANALYSIS_STATUS_SUCCESS = '[Analysis] Set new analysis status success',

  // Add a event
  ADD_EVENT = '[Analysis] Add new event in the store',
  MANAGE_EVENT = '[Analysis] Updating event in the store',
  SAVE_EVENT = '[Analysis] Save event in database',
  CLEAR_EVENT = '[Analysis] Event removed from store',

  // List management
  RELOAD_EVENT_LIST = '[Analysis] Reload event list',
  ADD_TO_EVENT_LIST = '[Analysis] Add a item to the event list',
  UPDATE_EVENT_LIST = '[Analysis] Update of the event list',
  UPDATE_ACTORS_LIST = '[Analysis] Update of the actors list',
  UPDATE_EVENT = '[Analysis] Update of the event',

  //Video Management
  SET_VIDEO_TIME = '[Analysis] Set video time',
  SET_MATCH_ANALYSIS = '[Analysis] Set Match Analysis',

  SHOW_DELETE_KEYPOINT_POPUP = '[Analysis] Show delete keypoint popup',

  CHECK_NAVIGATION_STATE = '[Analysis] Check navigation state',

  UPDATE_TEAMS = '[Analysis] Update teams',
}

/**
 * Init and event list
 */

export class SetMatchAnalysis implements TcAction {
  readonly type = AnalysisActionTypes.SET_MATCH_ANALYSIS;
  constructor(public payload: {
    matchId: number;
    analysisId: number;
    operator1Id: number;
    operator2Id: number;
    supervisorId: number;
    userIdByAnalysisId?: { [key: number]: number };
    analysisStatus?: MatchStatusEnum;
    analysisRole?: UserRoleEnum;
    analysisBreadcrumbName?: string;
  }) { }
}

export class InitAnalysis implements TcAction {
  readonly type = AnalysisActionTypes.INIT;
  constructor() { }
}

export class InitAnalysisSuccess implements TcAction {
  readonly type = AnalysisActionTypes.INIT_SUCCESS;
  constructor(public payload: { analysisState: AnalysisState }) { }
}

export class AddToEventList implements TcAction {
  readonly type = AnalysisActionTypes.ADD_TO_EVENT_LIST;
  constructor(public payload: { eventListItem: EventListItem }) { }
}

export class UpdateEventList implements TcAction {
  readonly type = AnalysisActionTypes.UPDATE_EVENT_LIST;
  constructor(public payload: { eventList: EventListItem[] }) { }
}

export class UpdateActorsList implements TcAction {
  readonly type = AnalysisActionTypes.UPDATE_ACTORS_LIST;
  constructor(public payload: { actorsList: ActorListItem[] }) { }
}

export class UpdateTeams implements TcAction {
  readonly type = AnalysisActionTypes.UPDATE_TEAMS;
  constructor(public payload: { teams: TeamListItem[] }) { }
}

export class UpdateEvent implements TcAction {
  readonly type = AnalysisActionTypes.UPDATE_EVENT;
  constructor(public payload: { event: EventListItem }) { }
}

/**
 * Key points management
 */

export class AddKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.ADD_KEY_POINT;
  constructor(public payload: { keyPointType: KeyPointTypeEnum }) { }
}

export class LoadKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.LOAD_KEY_POINT;
  constructor(public payload: { eventListId: number }) { }
}

export class DeleteKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.DELETE_KEY_POINT;
  constructor(public payload: { eventListId: number }) { }
}

export class ManageKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.MANAGE_KEY_POINT;
  constructor(public payload: { keyPoint: KeyPointItem }) { }
}

export class SetAnalysisStatus implements TcAction {
  readonly type = AnalysisActionTypes.SET_ANALYSIS_STATUS;
  constructor(public payload: { status: MatchStatusEnum }) { }
}

export class ReloadEventList implements TcAction {
  readonly type = AnalysisActionTypes.RELOAD_EVENT_LIST;
  constructor() { }
}

export class SetAnalysisStatusSuccess implements TcAction {
  readonly type = AnalysisActionTypes.SET_ANALYSIS_STATUS_SUCCESS;
  constructor(public payload: { status: MatchStatusEnum }) { }
}

export class SaveKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.SAVE_KEY_POINT;
}

export class CancelKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.CANCEL_KEY_POINT;
}

export class ClearKeyPoint implements TcAction {
  readonly type = AnalysisActionTypes.CLEAR_KEY_POINT;
}

/**
 * Event management
 */

export class AddEvent implements TcAction {
  readonly type = AnalysisActionTypes.ADD_EVENT;
  constructor(public payload: { eventType: EventTypeEnum }) { }
}

export class ManageEvent implements TcAction {
  readonly type = AnalysisActionTypes.MANAGE_EVENT;
  constructor(public payload: { event: ChronoItem }) { }
}

export class SaveEvent implements TcAction {
  readonly type = AnalysisActionTypes.SAVE_EVENT;
}

export class ClearEvent implements TcAction {
  readonly type = AnalysisActionTypes.CLEAR_EVENT;
}

/**
 * Video management
 */

export class SetVideoTime implements TcAction {
  readonly type = AnalysisActionTypes.SET_VIDEO_TIME;
  constructor(public payload: { time: number }) { }
}

export class ShowDeleteKeypointPopup implements TcAction {
  readonly type = AnalysisActionTypes.SHOW_DELETE_KEYPOINT_POPUP;

  constructor(public payload: { id: number }) { }
}

export class CheckNavigationState implements TcAction {
  readonly type = AnalysisActionTypes.CHECK_NAVIGATION_STATE;

  constructor(public payload: { id: number }) { }
}

export type AnalysisStoreActions =
  | InitAnalysis
  | InitAnalysisSuccess
  | AddToEventList
  | UpdateEventList
  | UpdateActorsList
  | UpdateEvent
  | AddKeyPoint
  | LoadKeyPoint
  | DeleteKeyPoint
  | ManageKeyPoint
  | SaveKeyPoint
  | CancelKeyPoint
  | ClearKeyPoint
  | AddEvent
  | ManageEvent
  | SaveEvent
  | ClearEvent
  | SetVideoTime
  | SetMatchAnalysis
  | ShowDeleteKeypointPopup
  | SetAnalysisStatus
  | SetAnalysisStatusSuccess
  | CheckNavigationState
  | UpdateTeams
  | ReloadEventList;
