import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { tap } from 'rxjs/operators';
import { ClubListRefreshService, ListRefreshName } from '../../clubs/services/club-list-refresh.service';
import { PlayerDetailComponent } from '../components/smart/player-detail/player-detail.component';
import { upsertPlayer, upsertPlayerSuccess, deletePlayerSuccess } from './players.actions';

@Injectable()
export class PlayersEffects {

    constructor(
        private actions$: Actions,
        private dialog: MatDialog,
        private translateService: TcTranslateService,
        private refreshService: ClubListRefreshService,
        private notificationService: TcNotificationService,
    ) { }

    upsertPlayer = createEffect(() => this.actions$.pipe(
        ofType(upsertPlayer),
        tap((payload) => {
            const dialog = this.dialog.open(PlayerDetailComponent, {
                height: '590px',
                width: '450px',
                data: payload,
            });

            dialog.afterClosed().subscribe(async result => {
                if (result === 'Yes') {
                    this.refreshService.emitRefresh(ListRefreshName.Players);
                }
            });
        })),
        { dispatch: false }
    );

    upsertPlayerSuccess = createEffect(() => this.actions$.pipe(
        ofType(upsertPlayerSuccess),
        tap(({ player }) => {
            if (player.id) {
                this.notificationService.success(this.translateService.instant('player-detail.alerts.save-success'));
            } else {
                this.notificationService.success(this.translateService.instant('player-detail.alerts.edit-success'));
            }
        })),
        { dispatch: false }
    );

    deletePlayerSuccess = createEffect(() => this.actions$.pipe(
        ofType(deletePlayerSuccess),
        tap(() => {
            this.notificationService.success(this.translateService.instant('players-list.alerts.delete-success'));
        })),
        { dispatch: false }
    );
}
