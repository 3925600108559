import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private readonly jsonConfig = require('../../../../assets/config.json');

  get<T>(key: string): T {
    return get(this.jsonConfig, key, undefined);
  }
}