import { Injectable } from '@angular/core';
import { ChronoItem, EventTypeEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { take } from 'rxjs/operators';
import { KeyPointService } from '../../../services/business-services/key-point-service';
import { AddEvent, ManageEvent, SaveEvent } from '../store/analysis.actions';
import { getMatchId, getSelectedEvent } from '../store/analysis.selectors';

@Injectable()
export class RealignService {

    constructor(
        private readonly store$: Store<TcAppState>,
        private readonly keyPointService: KeyPointService,
    ) { }

    public async realign(currentTime: number, newTime: number) {
        const matchId = await this.store$.select(getMatchId).pipe(take(1)).toPromise();

        await this.keyPointService.realignKeyPoints(matchId, { currentTime, newTime });

        // We create a realign event in the store
        this.store$.dispatch(new AddEvent({ eventType: EventTypeEnum.Realign }));
        // We modify event matchtime with the value submitted in popup
        const realign: ChronoItem = await this.store$.select(getSelectedEvent).pipe(take(1)).toPromise();
        // We store the new realign event in the store and we save it
        this.store$.dispatch(new ManageEvent({ event: { ...realign, matchTime: newTime } }));
        this.store$.dispatch(new SaveEvent());
    }

}
