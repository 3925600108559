import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState, TcMenuComponent, TcMenuItem } from '@tc/core';
import { PermissionsService } from '../../../../../services/permissions/permissions.service';
import { PermissionEnum } from '../../../../../services/permissions/types';
import { getAuthenticatedUser } from '../../../../auth/store/auth.selectors';
import { upsertClub } from './../../../../clubs/store/clubs.actions';
import { upsertCompetition } from './../../../../competitions/store/competitions.actions';
import { upsertMatch } from './../../../../match/store/matchs.actions';
import { upsertOrganisation } from './../../../../organisations/store/organisations.actions';
import { upsertPlayer } from './../../../../players/store/players.actions';
import { upsertReferee } from './../../../../referees/store/referees.actions';
import { upsertStadium } from './../../../../stadiums/store/stadiums.actions';
import { upsertUser } from '../../../../users/store/users.actions';

@Component({
  selector: 'app-add-new-menu',
  templateUrl: './add-new-menu.component.html',
  styleUrls: ['./add-new-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewMenuComponent implements OnInit {

  // Declaration of menu items
  private readonly addMenuItems = [
    { name: 'match', faIcon: 'fa-futbol', permission: PermissionEnum.Matches },
    { name: 'organisation', faIcon: 'fa-sitemap', permission: PermissionEnum.Organisations },
    { name: 'competition', faIcon: 'fa-trophy', permission: PermissionEnum.Competitions },
    { name: 'club', faIcon: 'fa-users', permission: PermissionEnum.Clubs },
    { name: 'player', faIcon: 'fa-user', permission: PermissionEnum.Players },
    { name: 'referee', faIcon: 'fa-balance-scale', permission: PermissionEnum.Referees },
    { name: 'stadium', faIcon: 'fa-map-marker-alt', permission: PermissionEnum.Stadiums },
    { name: 'user', icon: 'build', permission: PermissionEnum.Users }
  ];
  private allowedItems = [];

  @Input() public isCollapsed = false;

  addNewMenu: TcMenuComponent;
  @ViewChild('addNewMenu', { static: true }) set appAddNewMenu(value: TcMenuComponent) {
    this.addNewMenu = value;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<TcAppState>,
    private readonly permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    this.listenAuthenticatedUser();

    this.addNewMenu.items = this.allowedItems;

    // Declaration of menu actions
    this.addNewMenu.onMenuItemClick = (item: TcMenuItem) => {
      switch (item.name) {
        case 'match':
          this.store.dispatch(upsertMatch({}));
          break;
        case 'organisation':
          this.store.dispatch(upsertOrganisation({}));
          break;
        case 'competition':
          this.store.dispatch(upsertCompetition({}));
          break;
        case 'club':
          this.store.dispatch(upsertClub({}));
          break;
        case 'player':
          this.store.dispatch(upsertPlayer({}));
          break;
        case 'referee':
          this.store.dispatch(upsertReferee({}));
          break;
        case 'stadium':
          this.store.dispatch(upsertStadium({}));
          break;
        case 'user':
          this.store.dispatch(upsertUser({}));
          break;
      }
    };
  }

  private listenAuthenticatedUser() {
    this.store.select(getAuthenticatedUser).subscribe((user) => {
      if (!user) {
        return;
      }

      this.permissionsService.init(user);

      this.allowedItems = [...this.addMenuItems.filter(item => this.filterByPermission(item))];
      this.addNewMenu.items = this.allowedItems;

      this.cdr.detectChanges();
    });
  }

  private filterByPermission(item): boolean {
    return this.permissionsService.hasAccess(item.permission);
  }

}
