import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  TcSmartComponent,
  TcGenericListComponent,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcListFilterType,
  TcListFilterModel
} from '@tc/core';
import { Store } from '@ngrx/store';
import { ListValue, EntitiesEnum, ListTypeEnum } from '@match-fix/shared';
import { upsertListValue } from './../../../store/configuration.actions';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent extends TcSmartComponent
  implements OnInit, AfterViewInit {
  constructor(private store: Store<any>) {
    super();
  }

  configurationList: TcGenericListComponent<any>;
  @ViewChild('configurationList', { static: true }) set setConfigurationList(
    values: TcGenericListComponent<ListValue>
  ) {
    this.configurationList = values;
    this.configurationList.entityName = EntitiesEnum.ListValues;
  }

  filter = {
    listTypeId: {
      filterType: 'Equal',
      value: 8
    }
  };

  ngOnInit() {
    this.configurationList.rowActionButtonsPosition =
      TcListRowActionButtonsPosition.AfterData;
    this.configurationList.showTotalInActionsHeader = false;

    this.configurationList.sortType = TcListSortType.Server;
    this.configurationList.filterType = TcListFilterType.Disabled;
    this.configurationList.hasFixedHeader = true;

    this.configurationList.hasAddButton = false;
    this.configurationList.addItemWhenKeyPresed = true;

    this.configurationList.hasActionsLabel = true;
    this.configurationList.isPaged = false;

    this.configurationList.onScrollDown = () => {
      this.configurationList.service.getAll();
    };

    this.configurationList.columns = [
      {
        propertyName: 'value',
        visible: true
      }
    ];

    this.configurationList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true
      }
    ];

    this.configurationList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editConfiguration(row);
      }
    };

    this.configurationList.onRowClick = (row: any) => {
      this.editConfiguration(row);
    };
  }

  addItem() {
    this.store.dispatch(upsertListValue({}));
  }

  editConfiguration(row: any) {
    this.store.dispatch(upsertListValue({ listValue: row }));
  }

  ngAfterViewInit() {
    this.refresh();
  }

  refresh() {
    const filter = this.getFilter();

    this.configurationList.service.clearCache();
    this.configurationList.service.getWithQuery({
      initial: 'true',
      ...filter
    });
  }

  private getFilter(): { filter?: string } {
    const filter = {
      listTypeId: {
        filterType: 'Equal',
        value: 8 + ''
      }
    } as TcListFilterModel;

    return {
      filter: JSON.stringify(filter)
    };
  }
}
