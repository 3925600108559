import { AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'input-cell-editor',
  templateUrl: './input-cell-editor.component.html',
  styleUrls: ['./input-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputCellEditorComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  public value: string;
  public params: ICellEditorParams;

  ngAfterViewInit() {
    setTimeout(() => this.input.nativeElement.focus());
  }

  getValue() {
    return this.value;
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = params.data[params.colDef.field];
  }

  public onChange(value: string) {
    this.params.data[this.params.colDef.field] = value;
    this.value = value;
  }
}