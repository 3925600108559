import { Match } from '@match-fix/shared';

export class CustomMatchType {
  static className = 'CustomMatch';
  static gqlRootQueryName = 'custommatch';
  static gqlColumns: string[] = [
    '_id',
    'date',
    'clubACode',
    'clubAName',
    'clubBCode',
    'clubBName',
    'supervisorCode',
    'operator1Code',
    'operator2Code',
    'expertCode',
    'supervisorId',
    'operator1Id',
    'operator2Id',
    'expertId',
    'var',
    'analysisOp1Id',
    'analysisOp2Id',
    'analysisExpertId',
    'analysisSupervisorId',
    'referees',
    'principalReferee',
    'matchState',
    'matchAnalysisState',
    'matchStatus',
    'totalVideos',
    'hasPrimaryVideo',
    'analysisOp1Status',
    'analysisOp2Status',
    'analysisOp1Validated',
    'analysisOp2Validated',
    `competitionName`,
    `competitionId`,
    `stadium {
      name,
      city,
    }`,

    'operator1Name',
    'operator2Name',
    'supervisorName',
    'expertName',

    'teamAScore',
    'teamBScore',
    'hasReport',
    'time',
  ];

  static gqlNodeMapper = (node: any): Match => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Match;
  };
}
