import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcVersionComponent } from './components/tc-version/tc-version.component';
import { TcVersionService } from './services/tc-version.service';

const components = [TcVersionComponent];

@NgModule({
  declarations: [ ...components],
  imports: [
    CommonModule
  ],
  providers: [
    TcVersionService
  ],
  exports: [
    ...components,
    TcVersionService
  ]
})
export class TcVersionModule { }
