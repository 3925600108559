import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
// import { ToggleFilterModel } from '@match-fix/shared';
import { CustomEntitiesEnum } from '@match-fix/shared';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-match-page',
  templateUrl: './match-page.component.html',
  styleUrls: ['./match-page.component.scss']
})

export class MatchPageComponent extends TcPage implements OnInit {
  // toggles: ToggleFilterModel[] = [
  //   {
  //     name: 'mymatches',
  //     values: [1],//TODO: change value to current userId
  //     filterType: 'In',
  //     toggleProperty: 'userId',
  //   },
  // ];

  entityName = CustomEntitiesEnum.MatchWithAnalyst;

  constructor(store: Store<any>) {
    super(store);
   }

  ngOnInit(): void {
    this.store.dispatch(getBreadcrumb({route: RouteEnum.Matches}))
  }

}
