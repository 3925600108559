import { Injectable } from '@angular/core';
import { EntitiesEnum } from '@match-fix/shared';
import { TcListFilterModel, TcListSortModel } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(
    private entityCollectionServiceFactory: EntityCollectionServiceFactory) {

  }

  filterListItems = (entityName: EntitiesEnum, filterModel: TcListFilterModel, order?: TcListSortModel, initial?: boolean) => {

    const service = this.entityCollectionServiceFactory.create(entityName);

    const query = {
      filter: JSON.stringify(filterModel)
    } as any;

    if (initial) {
      query.initial = 'true';
    }

    service.clearCache();
    if (order) {
      query.orderBy = JSON.stringify(order);
    }

    service.getWithQuery(query);
  }
}
