import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Optional, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Club, CustomEntitiesEnum, CustomPlayer, EntitiesEnum, Player } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import * as moment from 'moment';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { take } from 'rxjs/operators';

import { CustomPlayersService } from '../../../../../services/business-services/custom-player-service';
import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { addPlayerToClub } from '../../../../clubs/store/clubs.actions';
import { getClub } from '../../../../clubs/store/clubs.selectors';
import { upsertPlayerSuccess } from '../../../store/players.actions';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.scss']
})
export class PlayerDetailComponent extends TcGenericFormComponent<Player> implements OnInit, AfterViewInit {
  minDateOfBirth = new Date(1970,0,1);
  maxDateOfBirth = new Date(2015,11,31);

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<PlayerDetailComponent>,
    private readonly customPlayersService: CustomPlayersService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    elem: ElementRef,
  ) {

    super(CustomEntitiesEnum.CustomPlayers, entityCollectionServiceFactory, translate, elem);

    this.initFields();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.data && this.data.player) {
      this.model = Object.assign({}, this.data.player);

      // MF-218
      if (this.data.player.startDate === undefined || this.data.player.startDate === null) {
        (this.model as any).startDate = null;
      }
      if (this.data.player.endDate === undefined || this.data.player.endDate === null) {
        (this.model as any).endDate = null;
      }
      delete (this.model as any).playerName;
    } else {
      // MF-270
      (this.model as any).startDate = null;
      (this.model as any).endDate = null;
    }
    if ((this.model as any).clubId) {
      (this.model as any).oldClubId = (this.model as any).clubId;
    }

    this.afterSave = (model) => {
      this.store.dispatch(upsertPlayerSuccess({ player: model }));
      this.store.dispatch(addPlayerToClub({ payload: model }));
      this.onClose(null, true);
    }
  }

  ngAfterViewInit() {
    this.checkDatesAvailability();
  }

  public async submit(model: CustomPlayer) {
    if (this.form.valid) {
      const startError = !!this.form.controls.clubId.value && !this.form.controls.startDate.value;
      if (startError) {
        this.form.controls.startDate.setErrors({ required: true });
      } else {
        const parsedModel: CustomPlayer = {
          ...model, ...this.parseModel(model, this.fields)
        };
        if (model.id && model.id > 0) {
          const player = await this.customPlayersService.update(parsedModel);
          if (player) {
            this.afterSave(parsedModel);
          }
        } else {
          const player = await this.customPlayersService.create(parsedModel);
          if (player) {
            this.afterSave(parsedModel);
          }
        }

      }
    }
  }

  private parseModel(model: CustomPlayer, fields: FormlyFieldConfig[]): CustomPlayer {
    let parsedModel = {};
    fields.filter((f) => f.type === 'datepicker').forEach(f => {
      if (model[f.key as string] !== null && model[f.key as string] !== undefined) {
        const date = formatDate(model[f.key as string], 'yyyy-MM-dd' + '\'T\'' + 'HH:mm:ss.SSS' + '\'Z\'', 'en');

        if (f.key !== 'birthDate') {
          parsedModel[f.key as string] = moment.utc(date).startOf('day').toISOString();
        } else {
          parsedModel[f.key] = date;
        }
      }
    });
    fields.filter(f => f.type === 'autocomplete-list').forEach(f => {
      if (model[f.key as string] === '') {
        parsedModel[f.key as string] = null;
      }
    });
    fields.filter(f => f.type === 'formly-group').forEach(group => {
      parsedModel = { ...parsedModel, ...this.parseModel(model, group.fieldGroup) };
    });
    return parsedModel as CustomPlayer;
  }

  onClose(event?, reloadList?: boolean) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(reloadList ? 'Yes' : null);
  }

  private isRequired(form: FormGroup, field) {
    let fieldChanges = false;
    return function (control) {
      if (!fieldChanges) {
        form.get(field).valueChanges
          .subscribe(() => {
            control.updateValueAndValidity();
          });
        fieldChanges = true;
      }

      const hasValue = !!(form.get(field).value);
      return hasValue && !control.value ? { required: true } : null;
    };
  }

  private async initFields() {

    const club = await this.store.select(getClub).pipe(take(1)).toPromise();
    const preselected = club ? { value: club.id, label: club.name } : null

    this.fields = [
      {
        // validators: {
        //   validation: [
        //     { name: 'fieldMatch', options: { errorPath: 'endDate' } },
        //   ],
        // },
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'firstName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'lastName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'birthDate',
            type: 'datepicker',
            templateOptions: {
              required: true,
              type: 'text',
              datepickerPopup: 'dd-MMMM-yyyy',
              datepickerOptions: {
                format: 'dd-MM-yyyy hh:mm:ss',
              }
            },
            validators: {
              birthDate: {
                expression: (c) => !c.value || (c.value >= this.minDateOfBirth && c.value <= this.maxDateOfBirth),
                message: (error, field: FormlyFieldConfig) => `${field.templateOptions.label} ${this.translate.instant(`player-detail.validations.birthDate`)} ${formatDate(this.minDateOfBirth,'dd/MM/yyyy','en')} and ${formatDate(this.maxDateOfBirth,'dd/MM/yyyy','en')}`,
              },
            },            
          },
          {
            key: 'clubId',
            type: 'autocomplete-list',
            defaultValue: preselected?.value || null,
            templateOptions: {
              change: () => this.checkDatesAvailability(),
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Club>(EntitiesEnum.Clubs, term, 'id', 'name'),
              listValue: 'clubName',
              preselected,
            },
            validators: {
              countryId: {
                expression: (c) => !c.value || /^\d+$/.test(c.value),
                message: (error, field: FormlyFieldConfig) => `${field.formControl.value} is not a valid ${field.templateOptions.label}`,
              },
            },
          },
          {
            key: 'startDate',
            type: 'datepicker',
            templateOptions: {
              disabled: !preselected,
              type: 'text'
            },
            validators: {
              validation: this.isRequired(this.form, 'clubId'),
            },
          },
          {
            key: 'endDate',
            type: 'datepicker',
            templateOptions: {
              disabled: !preselected,
              type: 'text'
            },
            validators: {
              endDate: {
                expression: (c) => !c.value || c.value > c.parent.controls.startDate.value,
                message: (error, field: FormlyFieldConfig) => `${field.templateOptions.label} ${this.translate.instant(`player-detail.validations.endDate`)}`,
              },
            },
          }
        ]
      }
    ];
  }



  private checkDatesAvailability() {
    const available = !!this.form.controls.clubId.value;

    if (available) {
      this.form.controls.startDate.enable();
      this.form.controls.endDate.enable();
    } else {
      this.form.controls.startDate.disable();
      this.form.controls.startDate.setValue(null);

      this.form.controls.endDate.disable();
      this.form.controls.endDate.setValue(null);
    }

  }

  
}
