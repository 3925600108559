import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { EntitiesEnum, Chrono } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class ChronoService extends CrudService<Chrono> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Chronos, httpClient, basePath, configuration);
  }

  /**
   * Function to get all match chrono
   */
  async getMatchChronos(matchId: number) : Promise<Chrono[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Chrono[]>(
      `${url}/getMatchChronos?matchId=${matchId}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Chrono[]>();
  }
}
