import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store } from '@ngrx/store';
import { RouteEnum } from '../../modules/main/enums/routes.enum';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss']
})
export class ConfigurationPageComponent extends TcPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(getBreadcrumb({ route: RouteEnum.Configuration }));
  }
}
