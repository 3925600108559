import { MissingTranslationStrategy } from '@angular/core';

export enum RefereeDeficiencyTypeEnum {
  GoalDeniedButValide = 'GoalDeniedButValide',
  GoalAcceptedButInvalide = 'GoalAcceptedButInvalide',
  NoPenalty = 'NoPenalty',
  PenaltyNotWhistled = 'PenaltyNotWhistled',
  UnassignedYellowCard = 'UnassignedYellowCard',
  UnjustifiedYellowCard = 'UnjustifiedYellowCard',
  UnassignedRedCard = 'UnassignedRedCard',
  UnjustifiedRedCard = 'UnjustifiedRedCard',
  NoFault = 'NoFault',
  FaultNotWhistled = 'FaultNotWhistled',
  NoOffside = 'NoOffside',
  OffsideNotWhistled = 'OffsideNotWhistled',
  NoCornerKick = 'NoCornerKick',
  CornerKickNotWhistled = 'CornerKickNotWhistled',
  NoThrowIn = 'NoThrowIn',
  ThrowInNotWhistled = 'ThrowInNotWhistled',
}

export const allRefereeDeficiencyTypes = Object.keys(RefereeDeficiencyTypeEnum);

export const allRefereeDeficiencyList = (translate) => ([
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.GoalDeniedButValide}`),
    value: RefereeDeficiencyTypeEnum.GoalDeniedButValide,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.GoalAcceptedButInvalide}`),
    value: RefereeDeficiencyTypeEnum.GoalAcceptedButInvalide,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.NoPenalty}`),
    value: RefereeDeficiencyTypeEnum.NoPenalty,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.PenaltyNotWhistled}`),
    value: RefereeDeficiencyTypeEnum.PenaltyNotWhistled,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.UnassignedYellowCard}`),
    value: RefereeDeficiencyTypeEnum.UnassignedYellowCard,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.UnjustifiedYellowCard}`),
    value: RefereeDeficiencyTypeEnum.UnjustifiedYellowCard,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.UnassignedRedCard}`),
    value: RefereeDeficiencyTypeEnum.UnassignedRedCard,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.UnjustifiedRedCard}`),
    value: RefereeDeficiencyTypeEnum.UnjustifiedRedCard,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.NoFault}`),
    value: RefereeDeficiencyTypeEnum.NoFault,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.FaultNotWhistled}`),
    value: RefereeDeficiencyTypeEnum.FaultNotWhistled,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.NoOffside}`),
    value: RefereeDeficiencyTypeEnum.NoOffside,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.OffsideNotWhistled}`),
    value: RefereeDeficiencyTypeEnum.OffsideNotWhistled,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.NoCornerKick}`),
    value: RefereeDeficiencyTypeEnum.NoCornerKick,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.CornerKickNotWhistled}`),
    value: RefereeDeficiencyTypeEnum.CornerKickNotWhistled,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.NoThrowIn}`),
    value: RefereeDeficiencyTypeEnum.NoThrowIn,
  },
  {
    label: translate.instant(`deficiencies.${RefereeDeficiencyTypeEnum.ThrowInNotWhistled}`),
    value: RefereeDeficiencyTypeEnum.ThrowInNotWhistled,
  },
]);

