import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Analysis, EntitiesEnum, MatchStatusEnum, UserAnalysisStatusEnum, TeamPlayerDeficiencyCategoryEnum } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class AnalystService extends CrudService<Analysis> {

  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Analysiss, httpClient, basePath, configuration);
  }

  public async cancelExpert(analysisId: number) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/cancel-expert/${analysisId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async resetExpert(analysisId: number) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/reset-expert/${analysisId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async cancelOperator(analysisId: number, removeDeficiencies = false) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/cancel-operator/${analysisId}?removeDeficiencies=${removeDeficiencies}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async cancelAnalysis(matchId: number) {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/cancel/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async validateAnalysis(id: number): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/${id}/valid`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  public async forceAssembly(id: number): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient.post<Analysis>(
      `${url}/force-assembly/${id}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method finalize the classification of the key points
  */
  async finalizeClassificationKeyPoints(
    matchId: number,
    comment: string,
    teamPlayers: { id: number, status: TeamPlayerDeficiencyCategoryEnum }[],
  ): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Analysis>(
      `${url}/finalizeClassification/${matchId}`,
      { comment, teamPlayers },
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Analysis>();
  }

  /**
  * This method returns all statuses needed for analysis combos
  */
  async getAllStatuses(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/getAllStatuses/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method returns match status for analysis combos
  */
  async getMatchStatus(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/getMatchStatus/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method returns team A status for analysis combos
  */
  async getTeamAStatus(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/getTeamAStatus/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method returns team B status for analysis combos
  */
  async getTeamBStatus(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/getTeamBStatus/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method returns referee status for analysis combos
  */
  async getRefereeStatus(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/getRefereeStatus/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

  /**
  * This method set match status
  */
  async setMatchStatus(matchId: number, matchStatus: MatchStatusEnum): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Analysis>(
      `${url}/setMatchStatus/${matchId}`,
      { matchId, matchStatus },
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Analysis>();
  }

  /**
  * This method set referee status
  */
  async setRefereeStatus(matchId: number, refereeStatus: UserAnalysisStatusEnum): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Analysis>(
      `${url}/setRefereeStatus/${matchId}`,
      { matchId, refereeStatus },
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Analysis>();
  }

  /**
  * This method set referee status
  */
  async setTeamStatus(matchId: number, teamStatus: UserAnalysisStatusEnum, type: string): Promise<Analysis> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.post<Analysis>(
      `${url}/setTeamStatus/${matchId}`,
      { matchId, teamStatus, type },
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Analysis>();
  }

  /**
  * This method returns if analysis for an expert isValidated or not
  */
  async analysisForExpertIsValidated(matchId: number): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Analysis[]>(
      `${url}/analysisForExpertIsValidated/${matchId}`,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise();
  }

}
