import { createFeatureSelector, createSelector } from '@ngrx/store';
import ClubsState from './clubs.state';

const getClubsState = createFeatureSelector<ClubsState>(
  'clubs'
);

export const getClubPlayersList = createSelector(
  getClubsState,
  (state: ClubsState) => state.players || []
);

export const getClubFilter = createSelector(
  getClubsState,
  (state: ClubsState) => state.clubFilter
);

export const getClub = createSelector(
  getClubsState,
  (state: ClubsState) => state.club,
);