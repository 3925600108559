import { Organisation } from '..';

export class OrganisationType {
  static className = 'Organisation';
  static gqlRootQueryName = 'organisation';
  static gqlColumns: string[] = [
      '_id',
      'countryId',
      'code',
      'name',
  ];

  static gqlNodeMapper = (node: any): Organisation => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Organisation;
    }

}
