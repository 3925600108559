import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Chrono, Deficiency, EntitiesEnum, KeyPointAnalysis, KeyPointConsultationItem, NoteKeyPointState } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class KeyPointAnalysisService extends CrudService<KeyPointAnalysis> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.KeyPointAnalysiss, httpClient, basePath, configuration);
  }

  /**
   * Custom insertion method because the CRUD service does not handle table without autoincrement.
   */
  async removeDeficiencies(keyPointAnalysisId: number): Promise<Boolean> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Boolean>(
      `${url}/removeDeficiencies?keyPointAnalysisId=${keyPointAnalysisId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<Boolean>();
  }

  /**
   * Get the all KeyPointAnalysis based on analysisId
   * @param analysisId 
   */
  async getKeyPointsAnalyses(analysisId: number): Promise<KeyPointAnalysis[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<KeyPointAnalysis[]>(
      `${url}/analyses?analysisId=${analysisId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<KeyPointAnalysis[]>();
  }

  /**
   * Get the user KeyPointAnalysis from keyPointId and analysisId if he exists
   * @param keyPointId 
   * @param analysisId 
   */
  async getKeyPointAnalysisConsultation(matchId: number): Promise<KeyPointConsultationItem[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<any>(
      `${url}/analysis-conclusion?matchId=${matchId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<any>();
  }

  /**
   * This method returns all deficiencies related to an analysis
   * @param query 
   */
  public async getDeficienciesByAnalysis(analysisId: number): Promise<Deficiency[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Deficiency[]>(
      `${url}/deficiencies?analysisId=${analysisId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<Deficiency[]>();
  }

  /**
   * This method returns NoteKeyPointState to an keyPoint
   * @param query 
   */
  public async getNoteKeyPointState(keyPointId: number): Promise<NoteKeyPointState> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<NoteKeyPointState>(
      `${url}/note-key-point/${keyPointId}`, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise<NoteKeyPointState>();
  }

  /**
   * This method update an NoteKeyPoint
   * @param query 
   */
  public async updateNoteKeyPointState(keyPointId: number, model: NoteKeyPointState): Promise<NoteKeyPointState> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.put<NoteKeyPointState>(
      `${url}/note-key-point/${keyPointId}`,
      model,
      {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<NoteKeyPointState>();
  }
}
