import { MatchTimeChrono } from './analysis.interfaces';
import { TcAction } from '@tc/core';

/**
 * Actions to provide for ChronoState
 */
export enum ChronoActionTypes {
    INIT_CHRONO = '[Chrono] Init of chrono', // Need videoId, matchId, currentVideoTime on payload
    UPDATE_VIDEO_TIME = '[Chrono] Updating video time', // Need new currentVideoTime on payload. Call getMatchTime() in chrono service and set the new time in the store.
    UPDATE_VIDEO_TIME_SUCCESS = '[Chrono] Updating video time done'
  }
  
  export class InitChrono implements TcAction {
    readonly type = ChronoActionTypes.INIT_CHRONO;
    constructor(
      public payload: { matchId: number; videoTime: number }
    ) {}
  }
  
  export class UpdateVideoTime implements TcAction {
    readonly type = ChronoActionTypes.UPDATE_VIDEO_TIME;
    constructor(public payload: { videoTime: number }) {}
  }
  
  export class UpdateVideoTimeSuccess implements TcAction {
    readonly type = ChronoActionTypes.UPDATE_VIDEO_TIME_SUCCESS;
    constructor(public payload: { videoTime: number, matchTime: MatchTimeChrono }) {}
  }
  
  export type ChronoStoreActions =
    | InitChrono
    | UpdateVideoTime
    | UpdateVideoTimeSuccess;