import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPopupComponent implements OnInit {

  public time = 0;
  public src: string;

  constructor(
    private readonly dialogRef: MatDialogRef<VideoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.src = this.data.src;
    this.time = this.data.time;
  }

  public close() {
    this.dialogRef.close();
  }

}
