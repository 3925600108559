import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomEntitiesEnum } from '@match-fix/shared';
import { LOGIN_INAVLID_USERNAME_OR_PASSWORD } from '../../modules/auth/auth.module';
import { Configuration } from '../configuration';
import { CrudService } from '../crud.service';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends CrudService {

  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(CustomEntitiesEnum.Auth, httpClient, basePath, configuration);
  }

  async login(username: string, password: string): Promise<{ access_token: string, code: string, image: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    if (!username || !password) {
      throw LOGIN_INAVLID_USERNAME_OR_PASSWORD;
    }

    const res = await this.httpClient.post(
      `${url}/login`, {
      username,
      password
    }, {
      withCredentials: true,
      headers,
      observe: 'body',
      reportProgress: false
    }
    ).toPromise() as any;

    if (!res?.access_token) {
      throw LOGIN_INAVLID_USERNAME_OR_PASSWORD;
    }

    return res;
  }

}

