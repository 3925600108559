import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store } from '@ngrx/store';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { RouteEnum } from '../../modules/main/enums/routes.enum';

@Component({
  selector: 'app-competition-page',
  templateUrl: './competition-page.component.html',
  styleUrls: ['./competition-page.component.scss']
})
export class CompetitionPageComponent extends TcPage implements OnInit {

  constructor(store: Store<any>) {
    super(store);
   }

  ngOnInit(): void {
    this.store.dispatch(getBreadcrumb({route: RouteEnum.Competitions}))
  }

}
