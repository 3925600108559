import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalystTypeEnum, ChronoItem, MatchStatusEnum, UserModel, UserRoleEnum } from '@match-fix/shared';
import { select, Store } from '@ngrx/store';
import { TcAppState, TcConfirmDialogComponent, TcListComponent, TcListFilterType, TcListSortType, TcMenuComponent, TcMenuItem, TcTranslateService } from '@tc/core';
import { get } from 'lodash';
import { take } from 'rxjs/operators';
import { PermissionsService } from '../../../../services/permissions/permissions.service';
import { PermissionEnum } from '../../../../services/permissions/types';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { getAuthenticatedUser } from '../../../auth/store/auth.selectors';
import { AnalysisService } from '../../services/analysis.service';
import { getAnalysisStoreState, getEvents } from '../../store/analysis.selectors';
import { ChronoService } from './../../services/chrono.service';
import { LoadKeyPoint, SetAnalysisStatus, SetMatchAnalysis, SetVideoTime, ShowDeleteKeypointPopup } from './../../store/analysis.actions';
import { AnalysisState, EventListItem } from './../../store/analysis.interfaces';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListComponent implements OnInit {

  matchAnalysisEventList: TcListComponent;
  analysisStatusMenu: TcMenuComponent;
  eventListRolesMenu: TcMenuComponent;

  public canEditKeyPoint = false;
  public canDeleteKeyPoint = false;
  public canValidateAnalysis;
  public showValidateAnalysis;

  public analysisStatus = MatchStatusEnum.Normal;
  public showCombo = false;

  public showListRole: boolean;
  public userAnalystRoleType: AnalystTypeEnum;

  @ViewChild('analysisStatusMenu', { static: true }) set appAnalysisStatusMenu(value: TcMenuComponent) {
    this.analysisStatusMenu = value;
  }
  @ViewChild('eventListRolesMenu', { static: true }) set appEventListRolesMenu(value: TcMenuComponent) {
    this.eventListRolesMenu = value;
  }
  @ViewChild('matchAnalysisEventList', { static: true }) set setMatchAnalysisEventList(values: TcListComponent) {
    this.matchAnalysisEventList = values;
  }

  @ViewChild('chronoTemplate', { static: true }) chronoTemplate: TemplateRef<any>;
  @ViewChild('timelineTemplate', { static: true }) timelineTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate', { static: true }) typeTemplate: TemplateRef<any>;

  @ViewChild('colActionEditTemplate', { static: true }) colActionEditTemplate: TemplateRef<any>;
  @ViewChild('colActionDeleteTemplate', { static: true }) colActionDeleteTemplate: TemplateRef<any>;

  private authenticatedUser: UserModel;
  private analysisState: AnalysisState;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<TcAppState>,
    private readonly chronoService: ChronoService,
    private readonly analysisService: AnalysisService,
    private readonly translateService: TcTranslateService,
    private readonly permissionsService: PermissionsService,
  ) { }

  async ngOnInit() {
    this.initList();

    await this.initUser();
    await this.initAnalysisState();

    this.setAnalysisStatusMenu();
    this.setEventListRolesMenu();

    await this.getAnalysisData();

    this.cdr.detectChanges();
  }

  private async initUser() {
    this.authenticatedUser = await this.store.select(getAuthenticatedUser).pipe(take(1)).toPromise();
  }

  private async initAnalysisState() {
    this.analysisState = await this.store.select(getAnalysisStoreState).pipe(take(1)).toPromise();
  }

  private setAnalysisStatusMenu() {
    this.analysisStatusMenu.items = [
      { name: MatchStatusEnum.Normal, displayName: this.translateService.instant(`match-status-menu.${MatchStatusEnum.Normal}`) },
      { name: MatchStatusEnum.PotentiallyFixed, displayName: this.translateService.instant(`match-status-menu.${MatchStatusEnum.PotentiallyFixed}`) },
    ];

    this.analysisStatusMenu.onMenuItemClick = (item: TcMenuItem) => {
      this.analysisStatus = item.name as MatchStatusEnum;

      this.store.dispatch(new SetAnalysisStatus({ status: item.name as MatchStatusEnum }));
    };
  }

  private setEventListRolesMenu() {
    if (![UserRoleEnum.Administrator, UserRoleEnum.Supervisor].includes(this.authenticatedUser.role)) {
      return;
    }

    const listToShow = [];

    if (this.authenticatedUser.role === UserRoleEnum.Administrator) {
      listToShow.push({ name: AnalystTypeEnum.Supervisor, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Supervisor}`) });
      listToShow.push({ name: AnalystTypeEnum.Operator1, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Operator1}`) });

      if (this.analysisState.operator2Id) {
        listToShow.push({ name: AnalystTypeEnum.Operator2, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Operator2}`) });
      }
    }

    if (this.authenticatedUser.role === UserRoleEnum.Supervisor) {
      listToShow.push({ name: AnalystTypeEnum.Supervisor, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Supervisor}`) });

      if (this.authenticatedUser.id === this.analysisState.operator1Id) {
        listToShow.push({ name: AnalystTypeEnum.Operator1, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Operator1}`) });
      }

      if (this.authenticatedUser.id === this.analysisState.operator2Id) {
        listToShow.push({ name: AnalystTypeEnum.Operator2, displayName: this.translateService.instant(`event-list-roles-menu.${AnalystTypeEnum.Operator2}`) });
      }
    }

    this.eventListRolesMenu.items = listToShow;
    this.eventListRolesMenu.onMenuItemClick = (item: TcMenuItem) => {
      this.changeAnalysisRoleForUser(item.name as AnalystTypeEnum);
    };

    this.setUserAnalystRoleType();

    this.showListRole = true;
  }

  private setUserAnalystRoleType() {
    const { analysisRole, userIdByAnalysisId, analysisId, supervisorId, operator1Id, operator2Id } = this.analysisState;

    if (analysisRole === UserRoleEnum.Supervisor && userIdByAnalysisId[analysisId] === supervisorId) {
      this.userAnalystRoleType = AnalystTypeEnum.Supervisor;
    }
    if (analysisRole === UserRoleEnum.Operator && userIdByAnalysisId[analysisId] === operator1Id) {
      this.userAnalystRoleType = AnalystTypeEnum.Operator1;
    }
    if (analysisRole === UserRoleEnum.Operator && userIdByAnalysisId[analysisId] === operator2Id) {
      this.userAnalystRoleType = AnalystTypeEnum.Operator2;
    }
  }

  private changeAnalysisRoleForUser(name: AnalystTypeEnum) {
    if (name === this.userAnalystRoleType) {
      return;
    }

    const payload = {
      matchId: this.analysisState.matchId,
      operator1Id: this.analysisState.operator1Id,
      operator2Id: this.analysisState.operator2Id,
      supervisorId: this.analysisState.supervisorId,
      userIdByAnalysisId: this.analysisState.userIdByAnalysisId,
      analysisBreadcrumbName: this.analysisState.analysisBreadcrumbName,
    };

    const analysisId = (userId: number): number => {
      const userIds = Object.values(this.analysisState.userIdByAnalysisId);
      const analysisIds = Object.keys(this.analysisState.userIdByAnalysisId);

      const userIdIndex = userIds.indexOf(userId);

      return parseInt(analysisIds[userIdIndex], 10);
    }

    switch (name) {
      case (AnalystTypeEnum.Supervisor):
        this.store.dispatch(new SetMatchAnalysis({ ...payload, analysisRole: UserRoleEnum.Supervisor, analysisId: analysisId(this.analysisState.supervisorId) }));
        break;
      case (AnalystTypeEnum.Operator1):
        this.store.dispatch(new SetMatchAnalysis({ ...payload, analysisRole: UserRoleEnum.Operator, analysisId: analysisId(this.analysisState.operator1Id), }));
        break;
      case (AnalystTypeEnum.Operator2):
        this.store.dispatch(new SetMatchAnalysis({ ...payload, analysisRole: UserRoleEnum.Operator, analysisId: analysisId(this.analysisState.operator2Id), }));
        break;
    }

    setTimeout(() => location.reload(), 100);
  }

  private async getAnalysisData() {
    const { analysisRole, analysisStatus, userIdByAnalysisId, analysisId, supervisorId, operator1Id, operator2Id } = this.analysisState;
    const isAnalysisValidated = await this.analysisService.isAnalysisValidated();

    const canEditKeyPoints = analysisRole === UserRoleEnum.Operator
      && (userIdByAnalysisId[analysisId] === operator1Id
        || userIdByAnalysisId[analysisId] === operator2Id) || (analysisRole === UserRoleEnum.Supervisor && userIdByAnalysisId[analysisId] === supervisorId);

    this.analysisStatus = analysisStatus || this.analysisStatus;
    this.showCombo = analysisRole === UserRoleEnum.Operator && canEditKeyPoints;
    this.showValidateAnalysis = analysisRole === UserRoleEnum.Operator && canEditKeyPoints;

    this.canEditKeyPoint = !isAnalysisValidated && this.permissionsService.hasAccess(PermissionEnum.MatchAnalysisEditKeyPoint, () => canEditKeyPoints) || (this.authenticatedUser.role === UserRoleEnum.Administrator);
    this.canValidateAnalysis = this.canEditKeyPoint || (this.authenticatedUser.role === UserRoleEnum.Administrator);

    const customDeletePermission = (): boolean => userIdByAnalysisId[analysisId] === supervisorId && analysisRole === UserRoleEnum.Supervisor;
    this.canDeleteKeyPoint = this.permissionsService.hasAccess(PermissionEnum.MatchAnalysisDeleteKeyPoint, customDeletePermission);
  }

  readonly convertToReadableFormat = m => {
    return convertToReadableFormat(m, 'mm:ss')
  };

  public filterEventType(event) {
    this.matchAnalysisEventList.dataSource.filter = event.value;
  }

  /**
   * Use ChronoService to get match time from ChronoItem
   * @param chrono ChronoItem
   */
  public formatMatchTime(chrono: ChronoItem): string {
    const result = this.chronoService.diplayMatchTime(chrono);
    if (result.additionalTime) {
      return result.time + ' + ' + result.additionalTime;
    } else {
      return result.time;
    }
  }

  /**
    * Edit the item
    */
  public editItem(row: EventListItem) {
    this.store.dispatch(new LoadKeyPoint({ eventListId: row.id }));
  }

  /**
    * Delete the item
    */
  public deleteItem({ keyPoint }: { keyPoint: EventListItem }) {
    this.store.dispatch(new ShowDeleteKeypointPopup({ id: keyPoint.id }));
  }

  public goToTime(row: EventListItem) {
    const keyPointTime = get(row, 'keyPoint.startChrono.videoTime', null);
    const eventPointTime = get(row, 'event.videoTime', null);

    const time = keyPointTime || eventPointTime || 0;

    this.store.dispatch(new SetVideoTime({ time }));
  }

  public validateAnalysis() {
    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'event-list.dialog.validate.no',
        title: 'event-list.dialog.validate.title',
        yesText: 'event-list.dialog.validate.yes',
        message: 'event-list.dialog.validate.message',
      },
      panelClass: 'validate-analysis-dialog-panel',
      width: '351px',
      height: '250px',
      autoFocus: false
    });

    dialog.afterClosed().subscribe(async (result) => {
      if (result === 'yes') {
        this.analysisService.validateAnalysis().then(() => {
          this.canEditKeyPoint = false;
          this.canValidateAnalysis = false;

          this.cdr.detectChanges();
        });
      }
    });
  }

  public notReviewedByOperator(row): boolean {
    return this.analysisState.analysisRole === UserRoleEnum.Operator
      && !row.keyPoint?.keyPointAnalysis?.reviewed;
  }

  private initList() {
    this.matchAnalysisEventList.rows$ = this.store.pipe(select(getEvents));

    this.matchAnalysisEventList.isFiltrable = false;
    this.matchAnalysisEventList.filterType = TcListFilterType.Disabled;
    this.matchAnalysisEventList.sortType = TcListSortType.Disabled;
    this.matchAnalysisEventList.isPaged = false;
    this.matchAnalysisEventList.hasFixedHeader = true;
    this.matchAnalysisEventList.hasAddButton = false;


    this.matchAnalysisEventList.columns = [
      {
        propertyName: 'chrono',
        visible: true,
        htmlTemplate: this.chronoTemplate
      },
      {
        propertyName: 'timeline',
        visible: true,
        htmlTemplate: this.timelineTemplate
      },
      {
        propertyName: 'type',
        visible: true,
        htmlTemplate: this.typeTemplate
      },
      {
        propertyName: 'details',
        visible: true
      }
    ];

    this.initRowActions();

    this.matchAnalysisEventList.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      switch (filter) {
        case '0':
          return data;
        case '1':
          return data.keyPoint;
        case '2':
          return data.event;
      }
    };
  }

  private initRowActions() {
    this.matchAnalysisEventList.rowActions = [
      {
        actionName: 'edit',
        visible: true,
        hasText: false,
        icon: 'edit',
        htmlTemplate: this.colActionEditTemplate
      }, {
        actionName: 'delete',
        visible: true,
        hasText: false,
        icon: 'delete',
        htmlTemplate: this.colActionDeleteTemplate
      }
    ];
  }

}
