import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Analysis, ActorListItem, ActorListTypeEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState, TcConfirmDialogComponent, TcNotificationService, TcTranslateService } from '@tc/core';
import { take } from 'rxjs/operators';
import { AnalystService } from '../../../services/business-services/analyst-service';
import { getAnalysisId } from '../store/analysis.selectors';
import { isEmpty } from 'lodash';

@Injectable()
export class AnalysisService {

    constructor(
        private readonly dialog: MatDialog,
        private readonly store$: Store<TcAppState>,
        private readonly analystService: AnalystService,
        private readonly translateService: TcTranslateService,
        private readonly notificationService: TcNotificationService
    ) { }

    public async validateAnalysis(): Promise<Analysis> {
        const analysisId = await this.getAnalysisId();
        const validatedAnalysis = await this.analystService.validateAnalysis(analysisId);

        return validatedAnalysis;
    }

    public async isAnalysisValidated(): Promise<boolean> {
        const analysisId = await this.getAnalysisId();
        const analysis = await this.analystService.get(analysisId);

        return Boolean(analysis.isValidated);
    }

    public async forceAutomaticAssembly(matchId: number) {
        const dialog = this.dialog.open(TcConfirmDialogComponent, {
            data: {
                noText: 'force-assembly.dialog.no',
                title: 'force-assembly.dialog.title',
                yesText: 'force-assembly.dialog.yes',
                message: 'force-assembly.dialog.message',
            },
            width: '414px',
            height: '260px',
            panelClass: 'force-assembly-dialog-panel'
        });

        dialog.afterClosed().subscribe(async (result) => {
            if (result === 'yes') {
                await this.analystService.forceAssembly(matchId);

                this.notificationService.success(this.translateService.instant('force-assembly.success'));
            }
        });
    }

    private async getAnalysisId(): Promise<number> {
        return await this.store$.select(getAnalysisId).pipe(take(1)).toPromise();
    }

    public getActorLabel(actor: ActorListItem): string {
        let label = '';
        if (isEmpty(actor) === false) {
          if (actor.type === ActorListTypeEnum.teamPlayer) {
            label += `${actor.number || '?'} - `;
          }
          label += `${actor.lastName ? actor.lastName : ''} ${actor.firstName ? actor.firstName : ''}`;
          if (actor.club !== undefined) {
            label += ` (${actor.club})`;
          }
        }
        return label;
      }

}
