import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadTypeEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { UploadedFiles } from '../../../shared/interfaces/upload.interface';
import { AddDocumentForMatch } from '../../store/expert-analysis.actions';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDocumentsComponent {
  public readonly UploadTypeEnum = UploadTypeEnum;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly dialogRef: MatDialogRef<UploadDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { matchId: number },
  ) { }

  public onClose() {
    this.dialogRef.close();
  }

  public onFilesUpload(files: UploadedFiles[]) {
    this.addDocuments(files);
  }

  private async addDocuments(files: UploadedFiles[]) {
    for (const [index, file] of files.entries()) {
      this.store$.dispatch(new AddDocumentForMatch({
        fileIdentifier: file.identifier,
        fileName: file.name,
        matchId: this.data.matchId,
      }));
    }
  }

}
