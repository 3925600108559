import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomEntitiesEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcGenericListComponent,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent,
} from '@tc/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClubListRefreshService, ListRefreshName } from '../../../services/club-list-refresh.service';
import { loadClub, resetClubState, upsertClub } from '../../../store/clubs.actions';

@Component({
  selector: 'app-club-list',
  templateUrl: './club-list.component.html',
  styleUrls: ['./club-list.component.scss']
})
export class ClubListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  private search = '';
  private subscription: Subscription;

  constructor(
    private store: Store<any>,
    private readonly refreshService: ClubListRefreshService,
  ) {
    super();
  }

  clubsList: TcGenericListComponent<any>;
  @ViewChild('clubsList', { static: true }) set setClubsList(values: TcGenericListComponent<any>) {
    this.clubsList = values;
    this.clubsList.entityName = CustomEntitiesEnum.CustomClubs;
  }

  @ViewChild('rowActionDeleteTemplate', { static: true }) rowActionDeleteTemplate: TemplateRef<any>;

  ngAfterViewInit() {

    this.refresh();
  }

  ngOnInit() {
    this.clubsList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.clubsList.showTotalInActionsHeader = false;

    this.clubsList.sortType = TcListSortType.Server;
    this.clubsList.filterType = TcListFilterType.Client;
    this.clubsList.hasFixedHeader = true;

    this.clubsList.hasAddButton = false;
    this.clubsList.addItemWhenKeyPresed = true;

    this.clubsList.hasActionsLabel = true;
    this.clubsList.isPaged = false;

    //TODO
    this.clubsList.applyClientSideFilter = (value) => {
      this.search = value;
      this.refresh();
    }
    this.clubsList.onScrollDown = () => {
      this.clubsList.service.getAll();
    };

    this.clubsList.columns = [
      {
        propertyName: 'name',
        visible: true
      },
      {
        propertyName: 'code',
        visible: true
      },
      {
        propertyName: 'numberOfPlayers',
        visible: true
      },
      {
        propertyName: 'countryName',
        visible: true
      },
    ];

    this.clubsList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true,
        htmlTemplate: this.rowActionDeleteTemplate
      }
    ];

    this.clubsList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editClub(row);
      }
    };

    this.clubsList.onRowClick = (row: any) => {
      this.editClub(row);
    };

    this.subscription = this.refreshService.subject
      .pipe(
        filter(e => e === ListRefreshName.Clubs)
      )
      .subscribe(() => this.refresh())
  }

  addItem() {
    this.store.dispatch(resetClubState({}));
    this.store.dispatch(upsertClub({}));
  }

  private editClub(row: any) {
    this.store.dispatch(loadClub({ id: row.id }));
  }

  private delete = (row: any) => {
    this.clubsList.tcList.onDeleteAction(row);
  }

  private refresh() {
    this.clubsList.service.clearCache();
    this.clubsList.service.getWithQuery({ initial: 'true', filter: JSON.stringify({ anyFieldContains: this.search }) });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
