import { MatchReferee } from '..';

export class MatchRefereeType {
  static className = 'MatchReferee';
  static gqlRootQueryName = 'matchreferee';
  static gqlColumns: string[] = [
      '_id',
      'refereeId',
      'matchId',
      'matchRefereeRole',
  ];

  static gqlNodeMapper = (node: any): MatchReferee => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as MatchReferee;
    }

}
