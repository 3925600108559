import { UserModel } from '@match-fix/shared';

export interface AuthState {
  isAuthenticated: boolean;
  user: UserModel;
  errorMessage: string | null;
  token: string;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  token: null,
};
