import { Injectable } from '@angular/core';
import { User } from '@match-fix/shared';
import { UsersService } from './business-services/users-service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private readonly usersService: UsersService,
    ) { }

    public async get(body): Promise<User[]> {
        return await this.usersService.search(body);
    }

}
