export function convertToReadableFormat(miliseconds: number, format = 'hh:mm:ss'): string {
    if (format === 'mm:ss') {
        const maxCountForFormat = (99 * 60 + 99) * 1000; // miliseconds for chrono time = "99:99"

        if (miliseconds > maxCountForFormat) {
            format = 'mmm:ss';
        }
    }

    return format.split(':').map(f => getFromMiliseconds(`${format[0]}${format[1]}` === 'hh', (miliseconds || 0) / 1000, f)).join(':');
}

function getFromMiliseconds(haveHour: boolean, time: number, format: string) {
    switch (format) {
        case 'hh':
            const hour = parseInt(time / 3600 as any, 10);
            return hour.toString().length > 1 ? hour : `0${hour}`;
        case 'mmm':
        case 'mm':
        case 'm':
            const hours = haveHour ? time % 3600 : time;
            const minute = parseInt(hours / 60 as any, 10);
            if (format === 'mmm') {
                return minute.toString().length > 2 ? minute : minute.toString().length > 1 ? `0${minute}` : `0${minute}`;
            } else if (format === 'mm') {
                return minute.toString().length > 1 ? minute : `0${minute}`;
            } else {
                return minute.toString().length > 1 ? minute : `${minute}`;
            }
        case 'ss':
            const second = parseInt(time % 3600 % 60 as any, 10);
            return second.toString().length > 1 ? second : `0${second}`;
        default:
            return '00'
    }
}
