import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { upsertStadium, upsertStadiumSuccess } from './stadiums.actions';
import { StadiumDetailComponent } from '../components/smart/stadium-detail/stadium-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { TcNotificationService, TcTranslateService } from '@tc/core';

@Injectable()
export class StadiumsEffects {

    constructor(
        private actions$: Actions,
        private dialog: MatDialog,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService
    ) { }

    upsertStadium = createEffect(() => this.actions$.pipe(
        ofType(upsertStadium),
        tap(({ stadium }) => {
            this.dialog.open(StadiumDetailComponent, {
                height: '480px',
                width: '414px',
                data: stadium,
            });
        })),
        { dispatch: false }
    );

    upsertStadiumSuccess = createEffect(() => this.actions$.pipe(
        ofType(upsertStadiumSuccess),
        tap(({ stadium }) => {
            if (stadium.id) {
                this.notificationService.success(this.translateService.instant('stadium-detail.alerts.save-success'));
            } else {
                this.notificationService.success(this.translateService.instant('stadium-detail.alerts.edit-success'));
            }
        })),
        { dispatch: false }
    );
}
