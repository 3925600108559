import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Club } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcNotificationService } from '@tc/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatchFormAbstract } from '../../../abstract/match-form.abstract';
import { GetClubPlayers, RemovePlayer, RemoveTeamPlayer, SetTeamClub } from '../../../store/matchs.actions';
import { MatchError, MatchState, TeamData, TeamPlayer } from '../../../store/matchs.interfaces';
import { getErrors, getTeams } from '../../../store/matchs.selectors';
import { AddNewPlayer, AddNewTeam, UpdateTeamPlayer } from './../../../store/matchs.actions';

@Component({
  selector: 'app-match-team',
  templateUrl: './match-team.component.html',
  styleUrls: ['./match-team.component.scss']
})
export class MatchTeamComponent extends MatchFormAbstract implements OnInit, OnDestroy {

  errorNumbers: { [key: string]: boolean };

  selected: any;
  teamData: TeamData & { club: Club } = null;

  public form: FormGroup;
  public selectedTeam: TeamData;
  public players: TeamPlayer[] = [];
  public clubId: number = null;

  public teamSubscription: Subscription;
  public errorSubscription: Subscription;

  @Input() team: string;
  @Input() readonly = false;

  @Input() matchAnalysisStarted: boolean;

  constructor(
    protected store: Store<MatchState>,
    private readonly notification: TcNotificationService,
  ) {
    super(store);
  }

  async ngOnInit() {

    await this.initTeamData();

    // Setup form and formControl for the component : we use the team as prefix
    // to be able to rise errors even if the component is showed twice on the screen
    this.form = new FormGroup({});
    this.form.addControl(this.team + '_clubId', new FormControl(''));
    this.form.addControl(this.team + '_playerId', new FormControl(''));

    // Setup the form for the parent
    super.SetForm(this.form);

    // Subscribe to automatically have the team in the selectedTeam property
    this.teamSubscription = this.store.select(getTeams).subscribe(data => {
      // Add form controls dynamically for each players to control the fields number and titular
      if (data[this.team] && data[this.team].players.length > 0) {
        for (const player of data[this.team].players) {
          // Set the controls if they don't exists
          if (!this.form.controls[player.id + '_number'] && player.id !== 'CURRENT_PLAYER_ID') {
            this.form.addControl(player.id + '_number', new FormControl(''));
            super.SetForm(this.form);
          }
          if (!this.form.controls[player.id + '_titular'] && player.id !== 'CURRENT_PLAYER_ID') {
            this.form.addControl(player.id + '_titular', new FormControl(''));
            super.SetForm(this.form);
          }
        }
      }
      // Recall parent to update the new controls
      super.SetForm(this.form);
      // Only when you have set the control, then, change the property value to display players
      //this.selectedTeam = data[this.team];
      this.players = data[this.team].players
        .filter(r => r.id !== 'CURRENT_PLAYER_ID')
        .map(e => ({ ...e, titular: !!e.titular }));
      this.clubId = data[this.team].clubId;
    });
    // Call the parent to setup error system
    super.ngOnInit();
  }

  /**
   * Set the club in the store
   */
  setClub() {
    const clubId = this.form.controls[this.team + '_clubId'].value;
    this.store.dispatch(new SetTeamClub({ team: this.team, clubId: clubId }));
  }

  /**
   * Set the player in the store
   */
  addPlayer() {
    if (this.selected) {
      this.store.dispatch(new AddNewPlayer(this.selected));
    }

  }

  addTeam() {
    console.log('dispatch action add new team')
    this.store.dispatch(new AddNewTeam({}));
  }

  removePlayer(player: any) {
    if (player) {
      // this.store.dispatch(new RemovePlayer({ team: this.team, id: player.id, playerId: player.playerId }));
      this.store.dispatch(new RemoveTeamPlayer({ team: this.team, id: player.playerId }));
    }
  }

  /**
   * Set the player number in the store
   */
  setNumber(id: string, number, player: any) {
    this.store.dispatch(new UpdateTeamPlayer({ playerId: player.playerId, team: this.team, id: id, key: 'number', value: number }));
  }

  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  /**
   * Set the player titular info in the store
   */
  changeTitular(id: string, titular: boolean) {
    this.store.dispatch(new UpdateTeamPlayer({ team: this.team, id: id, key: 'titular', value: !titular }));
  }

  getClubControlName() {
    return this.team + '_clubId';
  }

  getPlayerControlName() {
    return this.team + '_playerId';
  }

  getLabel() {
    return 'match-detail.labels.' + this.team;
  }

  selectPlayer(e) {
    this.selected = e;
  }

  async checkAvailability(club: { id: number, name: string }) {
    const verifyIn = this.team === 'teamA' ? 'teamB' : 'teamA';
    const teams = await this.store.select(getTeams).pipe(take(1)).toPromise();

    const alreadySelected = club.id === teams[verifyIn].clubId;

    if (alreadySelected) {
      this.notification.error(`Team ${club.name} already selected.`);
    }

    this.store.dispatch(new SetTeamClub({ team: this.team, clubId: club.id }));
    this.store.dispatch(new GetClubPlayers({ team: this.team, clubId: club.id }));
  }

  private async initTeamData() {

    this.errorSubscription = this.store.select(getErrors)
      .subscribe(errData => this.checkError(errData));

    this.teamData = (await this.store
      .select(getTeams)
      .pipe(take(1))
      .toPromise())[this.team];
  }

  private checkError(errData: MatchError[]) {
    this.errorNumbers = {};

    const err = errData.find(e => e.field === `${this.team}_number`);

    for (const e of (err?.value || [])) {
      this.errorNumbers[e] = true;
    }
  }

  ngOnDestroy() {
    // Destroy current subscriptions
    this.teamSubscription.unsubscribe();
    // Call the parent to destroy parent subscriptions

    this.errorSubscription.unsubscribe();
    super.ngOnDestroy();
  }

}
