import { TeamPlayer } from '..';

export class TeamPlayerType {
  static className = 'TeamPlayer';
  static gqlRootQueryName = 'teamplayer';
  static gqlColumns: string[] = [
      '_id',
      'teamId',
      'playerId',
      'number',
      'titular',
      'deficiencyCategory',
  ];

  static gqlNodeMapper = (node: any): TeamPlayer => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as TeamPlayer;
    }

}
