import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { OrganisationListComponent } from './components/smart/organisation-list/organisation-list.component';
import { OrganisationDetailComponent } from './components/smart/organisation-detail/organisation-detail.component';
import { MainModule } from '../main/main.module';
import { EffectsModule } from '@ngrx/effects';
import { OrganisationsEffects } from './store/organisations.effects';

const smartComponents = [
  OrganisationListComponent,
  OrganisationDetailComponent
];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([OrganisationsEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [OrganisationDetailComponent]
})
export class OrganisationsModule { }
