import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { ButtonsCellRenderComponent } from '../buttons-cell-render/buttons-cell-render.component';
import { CheckboxCellEditorComponent } from '../checkbox-cell-editor/checkbox-cell-editor.component';
import { ChronoInputCellEditorComponent } from '../chrono-input-cell-editor/chrono-input-cell-editor.component';
import { InputCellEditorComponent } from '../input-cell-editor/input-cell-editor.component';
import { NgxInputCellEditorComponent } from '../ngx-input-cell-editor/ngx-input-cell-editor.component';
import { SelectActorCellEditorComponent } from '../select-actor-cell-editor/select-actor-cell-editor.component';
import { SelectCellEditorComponent } from '../select-cell-editor/select-cell-editor.component';
import { SelectTeamCellEditorComponent } from '../select-team-cell-editor/select-team-cell-editor.component';

@Component({
  selector: 'tc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridComponent {
  @Input() styles: string;
  @Input() classes: string;
  @Input() rowData;
  @Input() columnDefs;
  @Input() grid: GridOptions = {};

  @Output() rowUpdated = new EventEmitter();

  public readonly gridOptions = <GridOptions>{
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    },
    frameworkComponents: {
      checkboxEditor: CheckboxCellEditorComponent,
      selectEditor: SelectCellEditorComponent,
      selectActorEditor: SelectActorCellEditorComponent,
      selectTeamEditor: SelectTeamCellEditorComponent,
      inputEditor: InputCellEditorComponent,
      ngxInputEditor: NgxInputCellEditorComponent,
      chronoInputEditor: ChronoInputCellEditorComponent,
      buttonsRender: ButtonsCellRenderComponent,
    },
    singleClickEdit: true,
    domLayout: 'autoHeight',
    ...this.grid
  };

  cellUpdated(e) {
    const data = { ...e.data };
    
    if (e?.event?.target?.value && !!data.chrono) {
      data.chrono = e.event.target.value;
    }

    this.rowUpdated.emit(data);
  }
}
