import { Referee } from '@match-fix/shared';

export class RefereeWithMatchType {
  static className = 'RefereeWithMatch';
  static gqlRootQueryName = 'refereewithmatch';
  static gqlColumns: string[] = [
    '_id',
    'firstName',
    'lastName',
    'birthDate',
    'lastMatchDate',
    'matchCounts',
    'clubs',
    'countryName',
    'countryId',
  ];

  static gqlNodeMapper = (node: any): Referee => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Referee;
  }

}  
