import { Organisation } from '@match-fix/shared';

export class CustomOrganisationType {
  static className = 'CustomOrganisation';
  static gqlRootQueryName = 'customorganisation';
  static gqlColumns: string[] = [
    '_id',
    'countryId',
    'code',
    'name',
    'haveCompetition',
    'haveOrganisationUser',
    'countryName',
  ];

  static gqlNodeMapper = (node: any): Organisation => {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as Organisation;
  };
}
