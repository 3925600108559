import { Observable } from 'rxjs';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { GetServerVideos, SelectServerVideo } from '../../../store/matchs.actions';
import { getServerVideos } from '../../../store/matchs.selectors';
import { TcListComponent, TcListFilterType, TcListSortType } from '@tc/core';
import { ServerVideo } from '@match-fix/shared';

@Component({
  selector: 'app-server-videos',
  templateUrl: './server-videos.component.html',
  styleUrls: ['./server-videos.component.scss']
})
export class ServerVideosComponent implements OnInit {

  serverVideos$: Observable<ServerVideo[]>;

  serverVideosList: TcListComponent;
  @ViewChild('serverVideosList', { static: true }) set setManageVideoList(values: TcListComponent) {
    this.serverVideosList = values;
  }

  @ViewChild('colVideoTemplate', { static: true }) colVideoTemplate: TemplateRef<any>;

  constructor(
    private readonly store$: Store<any>,
    private readonly dialogRef: MatDialogRef<ServerVideosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.store$.dispatch(new GetServerVideos());
    this.serverVideos$ = this.store$.pipe(select(getServerVideos));

    this.serverVideosList.rows$ = this.serverVideos$;
    this.serverVideosList.isFiltrable = true;
    this.serverVideosList.filterType = TcListFilterType.Client;
    this.serverVideosList.sortType = TcListSortType.Disabled;
    this.serverVideosList.isPaged = false;
    this.serverVideosList.hasFixedHeader = true;
    this.serverVideosList.hasAddButton = false;

    this.serverVideosList.columns = [
      {
        propertyName: 'name',
        visible: true
      }
    ];

    this.serverVideosList.onRowClick = (row: any) => {
      this.store$.dispatch(new SelectServerVideo({
        file: row.name,
        isPrimary: this.data.setFirstPrimary,
        matchId: this.data.matchId,
      }));
      this.onClose();
    }

  }

  public onClose() {
    this.dialogRef.close();
  }

}
