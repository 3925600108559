import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { setBreadcrumb, getBreadcrumb } from './breadcrumb.actions';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Store } from '@ngrx/store';


@Injectable()
export class BreadcrumbEffects {

    constructor(
        private actions$: Actions,
        private breadcrumbService: BreadcrumbService,
        private store: Store<any>
    ) { }

    breadcrumbItems = createEffect(() => this.actions$.pipe(
        ofType(getBreadcrumb),
        tap(({ route, name }) => {
            const breadcrumbItems = this.breadcrumbService.getBreadcrumbList(route, name);
            this.store.dispatch(setBreadcrumb({ payload: breadcrumbItems }))
        })),
        { dispatch: false }
    );
}  
