import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import {
  TcApiConfig,
  TcAppReducers,
  TcCoreModule,
  TcFormlyMultiSelectComponent,
  TcMetaReducers,
  TC_LIST_DEFAULT_NB_LINES
} from '@tc/core';
import { TcLayoutModule } from '@tc/layout';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  DefaultDataServiceConfig,
  DefaultDataServiceFactory,
  EntityCollectionReducerMethodsFactory,
  EntityDispatcherDefaultOptions,
  NgrxDataModule,
  PersistenceResultHandler
} from 'ngrx-data';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { entityConfig, qlServiceFactoryConfig } from './entity-metadata';
import { GraphQLModule } from './graphql.module';
import { AuthModule } from './modules/auth/auth.module';
import { ClubsModule } from './modules/clubs/clubs.module';
import { CompetitionsModule } from './modules/competitions/competitions.module';
import { ConfigurationModule } from './modules/configuration/configuration.module';
import { ExamplesModule } from './modules/examples/examples.module';
import { ExpertAnalysisConsultationModule } from './modules/expert-analysis-consultation/expert-analysis-consultation.module';
import { ExpertAnalysisModule } from './modules/expert-analysis/expert-analysis.module';
import { AppFormlyAutocompleteComponent } from './modules/main/autocomplete.component';
import { ButtonFromlyComponent } from './modules/main/button.component';
import { ChronoInputComponent } from './modules/main/components/smart/chrono-input/chrono-input.component';
import { AppFormlyUploadComponent } from './modules/main/form-upload.component';
import { MainModule } from './modules/main/main.module';
import { NgxInputFromlyComponent } from './modules/main/ngx-input.component';
import { MatchAnalysisModule } from './modules/match-analysis/match-analysis.module';
import { MatchsModule } from './modules/match/matchs.module';
import { OrganisationsModule } from './modules/organisations/organisations.module';
import { PlayersModule } from './modules/players/players.module';
import { RefereesModule } from './modules/referees/referees.module';
import { ReportingModule } from './modules/reporting/reporting.module';
import { SharedModule } from './modules/shared/shared.module';
import { StadiumsModule } from './modules/stadiums/stadiums.module';
import { TcVersionModule } from './modules/tc-version/tc-version.module';
import { UsersModule } from './modules/users/users.module';
import { ClubPageComponent } from './pages/club-page/club-page.component';
import { CompetitionPageComponent } from './pages/competition-page/competition-page.component';
import { ConfigurationPageComponent } from './pages/configuration-page/configuration-page.component';
import { ExpertAnalysisPageComponent } from './pages/expert-analysis-page/expert-analysis-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MatchAnalysisPageComponent } from './pages/match-analysis-page/match-analysis-page.component';
import { MatchPageComponent } from './pages/match-page/match-page.component';
import { NoteKeyPointPageComponent } from './pages/note-key-point-page/note-key-point-page.component';
import { OrganisationPageComponent } from './pages/organisation-page/organisation-page.component';
import { PlayerPageComponent } from './pages/player-page/player-page.component';
import { RefereePageComponent } from './pages/referee-page/referee-page.component';
import { ReportViewPageComponent } from './pages/report-view-page/report-view-page.component';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { StadiumPageComponent } from './pages/stadium-page/stadium-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { ReportingService } from './services/business-services/reporting-service';
import { Configuration } from './services/configuration';
import { AdditionalEntityCollectionReducerMethodsFactory } from './services/core/additional-entity-collection-reducer-methods.factory';
import { AdditionalPropertyPersistenceResultHandler } from './services/core/additional-property-persistence';
import { QlDefaultDataServiceFactory } from './services/core/ql-default-data-service-factory';
import { QlServiceFactoryConfig } from './services/core/ql-service-factory-config';
import { ImagePreviewService } from './services/image-preview/image-preview.service';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';

// all modules in app
const modules = [
  ExamplesModule,
  AuthModule,
  OrganisationsModule,
  MainModule,
  PlayersModule,
  ClubsModule,
  CompetitionsModule,
  RefereesModule,
  StadiumsModule,
  UsersModule,
  MatchsModule,
  MatchAnalysisModule,
  SharedModule,
  ExpertAnalysisModule,
  ExpertAnalysisConsultationModule,
  ConfigurationModule,
  ReportingModule
];

// all pages in app
const pages = [
  LoginPageComponent,
  UserPageComponent,
  OrganisationPageComponent,
  PlayerPageComponent,
  ClubPageComponent,
  RefereePageComponent,
  StadiumPageComponent,
  ReportViewPageComponent,
  CompetitionPageComponent,
  MatchPageComponent,
  NoteKeyPointPageComponent,
  ExpertAnalysisPageComponent,
  MatchAnalysisPageComponent,
  ConfigurationPageComponent,
  ReportingPageComponent
];

export function getApiBasePath(): string {
  const apiBasePath = require('../assets/config.json').API_BASE_PATH;
  return apiBasePath;
}

// default data service config
const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: require('../assets/config.json').API_BASE_PATH,
  timeout: 6000,
  delete404OK: false
};

// entity dispatcher default options
const entityDispatcherDefaultOptions: EntityDispatcherDefaultOptions = {
  optimisticAdd: false,
  optimisticDelete: false,
  optimisticUpdate: false,
  optimisticUpsert: false,
  optimisticSaveEntities: false
};

const apiServiceConfig: TcApiConfig = {
  // spinnerExceptions: ['api/starships/'],
  // hideToastError: false
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth', 'analysis', 'chrono', 'expertAnalysis'],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [
  ...TcMetaReducers,
  localStorageSyncReducer
];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export function emailTakenValidator(err, field): string {
  return field.templateOptions['emailTaken'];
}

export function emailInvalidValidator(err, field): string {
  return field.templateOptions['invalid'];
}

@NgModule({
  declarations: [...pages, AppComponent],
  imports: [
    ...modules,
    TcVersionModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PdfViewerModule,
    NgxMaskModule.forRoot(options),
    FormlyModule.forRoot({
      types: [
        {
          name: 'autocomplete-list',
          component: AppFormlyAutocompleteComponent,
          wrappers: ['form-field']
        },
        {
          name: 'upload-image',
          component: AppFormlyUploadComponent,
          wrappers: ['form-field']
        },
        {
          name: 'select',
          wrappers: ['form-field']
        },
        {
          name: 'ngx-input',
          component: NgxInputFromlyComponent,
          wrappers: ['form-field']
        },
        {
          name: 'chrono-input',
          component: ChronoInputComponent,
          wrappers: ['form-field']
        },
        {
          name: 'button-formly',
          component: ButtonFromlyComponent
        },
        {
          name: 'multi-select',
          component: TcFormlyMultiSelectComponent,
          wrappers: ['form-field']
        }
      ],
      validationMessages: [
        { name: 'emailTaken', message: emailTakenValidator },
        { name: 'invalid', message: emailInvalidValidator }
      ]
    }),
    TcCoreModule,
    TcLayoutModule,
    MatButtonModule,
    StoreModule.forRoot(TcAppReducers, { metaReducers }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'MatchFix'
        }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    NgrxDataModule.forRoot(entityConfig),

    GraphQLModule,

    HttpClientModule
  ],
  providers: [
    { provide: TcApiConfig, useValue: apiServiceConfig },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    {
      provide: EntityDispatcherDefaultOptions,
      useValue: entityDispatcherDefaultOptions
    },
    { provide: QlServiceFactoryConfig, useValue: qlServiceFactoryConfig },
    {
      provide: DefaultDataServiceFactory,
      useClass: QlDefaultDataServiceFactory
    },
    {
      provide: PersistenceResultHandler,
      useClass: AdditionalPropertyPersistenceResultHandler
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    },
    {
      provide: TC_LIST_DEFAULT_NB_LINES,
      useValue: require('../assets/config.json').DEFAULT_NB_LINES
    },
    { provide: Configuration },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    ImagePreviewService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private reporting: ReportingService) {
    this.reporting.init();
  }
}
