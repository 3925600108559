import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';
import { Store } from '@ngrx/store';
import { getBreadcrumb } from '../../modules/main/store/breadcrumb/breadcrumb.actions';
import { RouteEnum } from '../../modules/main/enums/routes.enum';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent extends TcPage implements OnInit {

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit(): void {
    this.store.dispatch(getBreadcrumb({route: RouteEnum.Users}))
  }
}
