import { Injectable, Optional, Inject } from '@angular/core';
import { TcService } from '@tc/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { CrudService } from '../crud.service';
import { EntitiesEnum, Player } from '@match-fix/shared';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { Observable } from 'apollo-link';

@Injectable({
  providedIn: 'root'
})
export class PlayersService extends CrudService<Player> {
  constructor(public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(EntitiesEnum.Players, httpClient, basePath, configuration);
  }

  async getClubPlayers(clubId: number, date: string): Promise<Player[]> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient.get<Player[]>(
      `${url}/lastmatch?clubId=${clubId}&date=${date}`,{
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false
      }
    ).toPromise<Player[]>();
  }
}
