import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  OnDestroy, OnInit,
  Optional, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { allMatchStatusesList, allUserStatusesList, AnalystTypeEnum, Competition, MatchAnalysisStateEnum, MatchStatusEnum, NoteKeyPointListItem, UserAnalysisStatusEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcAppState, TcFormComponent, TcListComponent, TcListFilterType, TcListSortType, TcNotificationService, TcTranslateService } from '@tc/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, skipWhile, take } from 'rxjs/operators';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { ListRefreshService, ListRefreshType } from '../../../match/services/list-refresh.service';
import { ExpertAnalysisService } from '../../services/expert-analysis.service';
import { LoadExpertKeyPoint, OpenPlayersCategorizations } from '../../store/expert-analysis.actions';
import { getExpertAnalysisMatchStatus, getExpertAnalysisReadonly } from '../../store/expert-analysis.selectors';

@Component({
  selector: 'app-note-key-point-list',
  templateUrl: './note-key-point-list.component.html',
  styleUrls: ['./note-key-point-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NoteKeyPointListComponent extends TcFormComponent<any> implements OnInit, OnDestroy, AfterViewInit, OnDestroy {

  analystsLabel: string;

  private refreshSubscription: Subscription;
  private readonly list$ = new Subject<NoteKeyPointListItem[]>();

  private matchAnalysisState: MatchAnalysisStateEnum;

  noteKeyPointList: TcListComponent;

  private readOnlySubscription: Subscription;

  @ViewChild('noteKeyPointList', { static: true }) set setNoteKeyPointList(values: TcListComponent) {
    this.noteKeyPointList = values;
  }

  @ViewChild('typeTemplate', { static: true }) typeTemplate: TemplateRef<any>;
  @ViewChild('chronoStartTemplate', { static: true }) chronoStartTemplate: TemplateRef<any>;
  @ViewChild('chronoEndTemplate', { static: true }) chronoEndTemplate: TemplateRef<any>;
  @ViewChild('invalidatedExpertTemplate', { static: true }) invalidatedExpertTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly store$: Store<TcAppState>,
    private readonly listRefreshService: ListRefreshService,
    private readonly notificationService: TcNotificationService,
    private readonly expertAnalysisService: ExpertAnalysisService,
    translate: TcTranslateService,
    private store: Store<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Competition,
    elem: ElementRef
  ) {
    super(translate, elem);

    this.fields = [
      {
        fieldGroupClassName: 'form-display-small-columns',
        fieldGroup: [
          {
            key: 'matchStatus',
            // model: this.model,
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-list.combos.labels.matchStatus'),
              options: allMatchStatusesList(this.translate),
              change: (event) => this.changeMatchStatus(event),
            }
          },
          {
            key: 'teamAStatus',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-list.combos.labels.teamAStatus'),
              options: allUserStatusesList(this.translate),
              change: (event) => this.changeTeamAStatus(event),
            }
          },
          {
            key: 'teamBStatus',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-list.combos.labels.teamBStatus'),
              options: allUserStatusesList(this.translate),
              change: (event) => this.changeTeamBStatus(event),
            }
          },
          {
            key: 'refereeStatus',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-list.combos.labels.refereeStatus'),
              options: allUserStatusesList(this.translate),
              change: (event) => this.changeRefereeStatus(event),
            }
          },

          this.buttonField('classify', false, () => this.classify()),
        ]
      }
    ];
  }

  ngOnDestroy() {
    if (this.readOnlySubscription) {
      this.readOnlySubscription.unsubscribe();
    }

    this.refreshSubscription?.unsubscribe();
  }

  async ngOnInit() {
    this.noteKeyPointList.rows$ = this.list$;
    this.noteKeyPointList.isFiltrable = false;
    this.noteKeyPointList.filterType = TcListFilterType.Disabled;
    this.noteKeyPointList.sortType = TcListSortType.Disabled;
    this.noteKeyPointList.isPaged = false;
    this.noteKeyPointList.hasFixedHeader = true;
    this.noteKeyPointList.hasAddButton = false;
    this.noteKeyPointList.onRowClick = (row) => this.store$.dispatch(new LoadExpertKeyPoint({ keyPointId: row.id as number }));

    this.noteKeyPointList.columns = [
      {
        propertyName: 'code',
        visible: true,
      },
      {
        propertyName: 'type',
        visible: true,
        htmlTemplate: this.typeTemplate,
      },
      {
        propertyName: 'chronoStart',
        visible: true,
        htmlTemplate: this.chronoStartTemplate,
      },
      {
        propertyName: 'chronoEnd',
        visible: true,
        htmlTemplate: this.chronoEndTemplate,
      },
      {
        propertyName: 'invalidatedExpert',
        visible: true,
        htmlTemplate: this.invalidatedExpertTemplate,
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.statusTemplate,
      },
    ];

    this.cdr.detectChanges();

    this.matchAnalysisState = await this.store$.select(getExpertAnalysisMatchStatus).pipe(take(1)).toPromise();

    this.getInitialList();

    this.readOnlySubscription = this.store.select(getExpertAnalysisReadonly)
      .pipe(debounceTime(100))
      .subscribe(isReadOnly => {
        this.disableFields(this.fields, isReadOnly);
        if (isReadOnly) {
          this.fields[0].fieldGroup = this.fields[0].fieldGroup.filter(f => f.key !== 'classify')
        }
      });

    const statuses = await this.expertAnalysisService.getAllStatuses();
    this.model = Object.assign({}, statuses);

    if (this.model.matchStatus === null || this.model.matchStatus === undefined) {
      await this.expertAnalysisService.getMatchStatus();
    }

    if (this.model.teamAStatus === null || this.model.teamAStatus === undefined) {
      await this.expertAnalysisService.getTeamAStatus();
    }

    if (this.model.teamBStatus === null || this.model.teamBStatus === undefined) {
      await this.expertAnalysisService.getTeamBStatus();
    }

    if (this.model.refereeStatus === null || this.model.refereeStatus === undefined) {
      await this.expertAnalysisService.getRefereeStatus();
    }

  }

  ngAfterViewInit() {
    this.refreshSubscription = this.listRefreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.NoteKeyPoints))
      .subscribe(() => this.getInitialList());

    this.setAnalysts();
  }

  private disableFields(fields: any[], isReadOnly: boolean) {
    fields.forEach(field => {
      if (field.templateOptions) {
        field.templateOptions.disabled = isReadOnly;
      }
      if (field.fieldGroup) {
        this.disableFields(field.fieldGroup, isReadOnly);
      }
    });
  }

  private buttonField(key: string, outline: boolean, action): FormlyFieldConfig {
    const label = this.translate.instant(`note-key-point-list.buttons.${key}`);

    return ({
      key: key,
      type: 'button-formly',
      templateOptions: {
        label,
        outline,
        onClick: action
      }
    });
  }

  public classify() {
    if (this.model.matchStatus === MatchStatusEnum.PotentiallyFixed) {
      this.notificationService.error('Match Status cannot remain Potentially Fixed');
      return;
    }
    if ([this.model.teamAStatus, this.model.teamBStatus, this.model.refereeStatus].includes(UserAnalysisStatusEnum.PotentiallyAbnormal)) {
      this.notificationService.error('<Team A / Team B / Referee > Status cannot remain Potentially Abnormal');
      return;
    }

    this.store.dispatch(new OpenPlayersCategorizations())
  }

  public async changeTeamAStatus(data: any) {
    await this.expertAnalysisService.setTeamStatus(data.formControl.value, 'A');
  }

  public async changeTeamBStatus(data: any) {
    await this.expertAnalysisService.setTeamStatus(data.formControl.value, 'B');
  }

  public async changeMatchStatus(data: any) {
    await this.expertAnalysisService.setMatchStatus(data.formControl.value);
  }

  public async changeRefereeStatus(data: any) {
    await this.expertAnalysisService.setRefereeStatus(data.formControl.value);
  }

  private async getInitialList() {
    const list = await this.expertAnalysisService.getNoteKeyPointList(this.matchAnalysisState);

    this.list$.next(list);
  }

  public async onFiltersChange(filters) {
    let list = await this.expertAnalysisService.getNoteKeyPointList(this.matchAnalysisState);

    list = list.filter(item =>
      (item.chronoStart.matchTime >= filters.startPeriod && item.chronoStart.matchTime <= filters.endPeriod)
      || (item.chronoEnd.matchTime >= filters.startPeriod && item.chronoEnd.matchTime <= filters.endPeriod)
    );

    if (filters.teams && filters.teams.length) {
      list = list.filter(item => item.deficiencies.some(def => filters.teams.includes(def.clubId)));
    }

    if (filters.players && filters.players.length) {
      list = list.filter(item => item.deficiencies.some(def => filters.players.includes(def.playerId)));
    }

    if (filters.referees && filters.referees.length) {
      list = list.filter(item => item.deficiencies.some(def => filters.referees.includes(def.refereeId)));
    }

    if (filters.statuses && filters.statuses.length) {
      list = list.filter(item => item.deficiencies.some(def => filters.statuses.includes(def.status)));
    }

    if (filters.implications && filters.implications.length) {
      const getActorsLength = (item: NoteKeyPointListItem): number => {
        const refereeLength = item.deficiencies
          .reduce((accumulator, current) => (accumulator || []).includes(current.refereeId) ? accumulator : [...accumulator, current.refereeId], []).length;

        const playersLength = item.deficiencies
          .reduce((accumulator, current) => (accumulator || []).includes(current.playerId) ? accumulator : [...accumulator, current.playerId], []).length;

        return refereeLength + playersLength;
      }

      if (filters.implications.length === 1 && filters.implications[0] === 'Simple') {
        list = list.filter(item => getActorsLength(item) === 1);
      } else if (filters.implications.length === 1 && filters.implications[0] === 'Multiple') {
        list = list.filter(item => getActorsLength(item) > 1);
      } else {
        list = list.filter(item => getActorsLength(item) > 0);
      }
    }

    if (filters.keyPointType && filters.keyPointType.length) {
      list = list.filter(item => filters.keyPointType.includes(item.type));
    }

    if (filters.deficiencies && filters.deficiencies.length) {
      list = list.filter(item => item.deficiencies.some(def => filters.deficiencies.includes(def.type)));
    }

    this.list$.next(list);
  }

  public readonly convertToReadableFormat = m => {
    return convertToReadableFormat(m, 'mm:ss')
  };

  private async setAnalysts() {
    const list = await this.list$.pipe(skipWhile(e => !e?.length), take(1)).toPromise();

    this.analystsLabel = list[0].analysts
      .map(analyst => `${this.getAnalystPrefix(analyst.analystType)}: ${(analyst as any).code || ''}`).join(', ');
  }

  private getAnalystPrefix(type: string) {
    switch (type) {
      case AnalystTypeEnum.Expert:
        return 'EXP';
      case AnalystTypeEnum.Operator1:
        return 'OP1';
      case AnalystTypeEnum.Operator2:
        return 'OP2';
      case AnalystTypeEnum.Supervisor:
        return 'SUP';
      default:
        return '';
    }
  }

}
