import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConsultationStatusData, MatchStatusEnum, UserAnalysisStatusEnum } from '@match-fix/shared';
import { TcGenericListColumn, TcGenericListComponent, TcListFilterType, TcListSortType } from '@tc/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-match-analysis-status',
  templateUrl: './match-analysis-status.component.html',
  styleUrls: ['./match-analysis-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MatchAnalysisStatusComponent implements OnInit {

  @Input() public statusList: Subject<ConsultationStatusData[]>;
  @Input() public expertConclusion: string;

  matchStatusList: TcGenericListComponent<any>;

  @ViewChild('matchStatusList', { static: true }) set setMatchStatusList(values: TcGenericListComponent<any>) {
    this.matchStatusList = values;
  }
  @ViewChild('colMatchStatusTemplate', { static: true }) colMatchStatusTemplate: TemplateRef<any>;
  @ViewChild('colTeamAStatusTemplate', { static: true }) colTeamAStatusTemplate: TemplateRef<any>;
  @ViewChild('colTeamBStatusTemplate', { static: true }) colTeamBStatusTemplate: TemplateRef<any>;
  @ViewChild('colRefereeStatusTemplate', { static: true }) colRefereeStatusTemplate: TemplateRef<any>;

  ngOnInit() {
    this.initListComponent();
  }

  public getMatchStatusColor(status: MatchStatusEnum) {
    switch (status) {
      case MatchStatusEnum.Fixed:
        return '#f3a88a';
      case MatchStatusEnum.PotentiallyFixed:
        return '#f2df98';
      case MatchStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  public getAnalysisStatusColor(status: UserAnalysisStatusEnum) {
    switch (status) {
      case UserAnalysisStatusEnum.Abnormal:
        return '#f3a88a';
      case UserAnalysisStatusEnum.PotentiallyAbnormal:
        return '#f2df98';
      case UserAnalysisStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  private initListComponent() {
    this.matchStatusList.rows$ = this.statusList;
    this.matchStatusList.isFiltrable = false;
    this.matchStatusList.filterType = TcListFilterType.Disabled;
    this.matchStatusList.sortType = TcListSortType.Disabled;
    this.matchStatusList.isPaged = false;
    this.matchStatusList.hasFixedHeader = true;
    this.matchStatusList.hasAddButton = false;
    this.matchStatusList.onRowClick = () => { };

    this.matchStatusList.columns = this.getTableColumns();
  }

  private getTableColumns(): TcGenericListColumn[] {
    return [
      {
        propertyName: 'matchStatus',
        visible: true,
        htmlTemplate: this.colMatchStatusTemplate,
      },
      {
        propertyName: 'teamAStatus',
        visible: true,
        htmlTemplate: this.colTeamAStatusTemplate,
      },
      {
        propertyName: 'teamBStatus',
        visible: true,
        htmlTemplate: this.colTeamBStatusTemplate,
      },
      {
        propertyName: 'refereeStatus',
        visible: true,
        htmlTemplate: this.colRefereeStatusTemplate,
      }
    ];
  }

}
