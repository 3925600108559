import { MatchTimeChrono } from './analysis.interfaces';
import { ChronoService } from './../services/chrono.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ChronoActionTypes, UpdateVideoTime, UpdateVideoTimeSuccess } from './chrono.actions';
import { Observable, of } from 'rxjs';
import { TcAction } from '@tc/core';
import { mergeMap, map } from 'rxjs/operators';
import { ChronoItem } from '@match-fix/shared';

@Injectable()
export class ChronoStoreEffects {
  constructor(private actions: Actions, private chronoService: ChronoService) {}

  @Effect()
  UpdateVideoTimeEffect: Observable<TcAction> = this.actions.pipe(
    ofType<UpdateVideoTime>(ChronoActionTypes.UPDATE_VIDEO_TIME),
    mergeMap((action: UpdateVideoTime) =>
      of(this.UpdateVideoTime(action.payload)).pipe(
        map(item => new UpdateVideoTimeSuccess({ videoTime:item.videoTime, matchTime: item.matchTime }))
      )
    )
  );

  /**
   * Calculate time from video and return human reading time
   */
  public UpdateVideoTime(payload) {
    const matchTime = this.chronoService.getMatchTime(payload.videoTime);
    if (matchTime !== null) {
      const chrono: ChronoItem = {
        videoTime: matchTime.videoTime,
        matchTime: matchTime.matchTime,
        round: matchTime.lastEvent.round,
        isAdditionalTime: (matchTime.lastEvent.isAdditionalTime) ? matchTime.lastEvent.isAdditionalTime : false
      }
      const matchTimeChrono: MatchTimeChrono = this.chronoService.diplayMatchTime(chrono);
      return {videoTime: chrono.videoTime, matchTime: matchTimeChrono};
    } else {
      return {videoTime: 0, matchTime: {time: '--:--'}};
    }
  }
}