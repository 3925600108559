import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-ngx-input-formly',
    template: `
        <div
            class="button-container"
            [ngClass]="{'outline': outline}"
            (click)="handleClick($event)"
        >
            <span 
                class="font-12 bold-text"
                [ngClass]="{'blue': outline}"
            >
                {{label}}
            </span>
        </div>
    `,
    styles: [`
        .button-container {
            background-color: #F3CA40;
            background: #F3CA40 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 15px;
            width: 148px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        
        .outline {
            background-color: transparent;
            background: none;
            width: 100px;
            box-shadow: none;
            border-radius: none;

        }

        .blue {
            font: Bold 14px/17px Rubik;
            color: #00A6FB !important;    
            white-space: nowrap;
            background-color: transparent !important;
        }
    ` ]
})
export class ButtonFromlyComponent extends FieldType implements OnInit {

    public label = '';
    public outline = false;

    ngOnInit() {
        this.label = this.to.label;
        this.outline = this.to.outline;
    }

    public handleClick(event) {
        event.stopPropagation();

        this.to.onClick();
    }

}
