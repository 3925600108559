import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActorListItem,
  ActorListTypeEnum,
  Analysis,
  Chrono,
  ChronoItem,
  Deficiency,
  DeficiencyStatusEnum,
  Event,
  eventsTypes,
  EventTypeEnum,
  EventTypeLabel,
  KeyPoint,
  KeyPointAnalysis,
  KeypointAnalysisStatusEnum,
  KeyPointLabel,
  keyPoints,
  KeyPointTypeEnum,
  MatchStatusEnum,
  PlayerDeficiencyTypeEnum,
  RefereeDeficiencyTypeEnum,
  TeamListItem,
  Video
} from '@match-fix/shared';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TcAction, TcConfirmDialogComponent } from '@tc/core';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AnalystService } from '../../../services/business-services/analyst-service';
import { ChronoService as ChronoCrudService } from '../../../services/business-services/chrono-service';
import { EventService } from '../../../services/business-services/event-service';
import { MatchRefereesService } from '../../../services/business-services/match-referees-service';
import { VideosService } from '../../../services/business-services/match-videos-service';
import { MatchsService } from '../../../services/business-services/matchs-service';
import { TeamsService } from '../../../services/business-services/teams-service';
import { TcVideoPlayerService } from '../../../services/tc-video-player.service';
import { SetMatch, SetMatchVideos, StartMatchAnalysis } from '../../match/store/matchs.actions';
import { KeyPointDetailsComponent } from '../components/key-point-details/key-point-details.component';
import { DeficiencyService } from './../../../services/business-services/deficiency-service';
import { KeyPointAnalysisService } from './../../../services/business-services/key-point-analysis-service';
import { KeyPointService } from './../../../services/business-services/key-point-service';
import { ChronoService } from './../services/chrono.service';
import {
  AddEvent,
  AddKeyPoint,
  AddToEventList,
  AnalysisActionTypes,
  CancelKeyPoint,
  CheckNavigationState,
  ClearEvent,
  ClearKeyPoint,
  DeleteKeyPoint,
  InitAnalysis,
  InitAnalysisSuccess,
  LoadKeyPoint,
  ManageEvent,
  ManageKeyPoint,
  ReloadEventList,
  SaveEvent,
  SaveKeyPoint,
  SetAnalysisStatus,
  SetAnalysisStatusSuccess,
  ShowDeleteKeypointPopup,
  UpdateActorsList,
  UpdateEventList,
  UpdateTeams
} from './analysis.actions';
import { AnalysisState, DeficiencyItem, EventListItem, KeyPointAnalysisItem, KeyPointItem } from './analysis.interfaces';
import { sortEventArray } from './analysis.reducers';
import {
  getActors,
  getAnalysisId,
  getAnalysisStoreState,
  getEvents,
  getMatchId,
  getSelectedEvent,
  getSelectedKeyPoint,
  getTeams
} from './analysis.selectors';
import { InitChrono } from './chrono.actions';
import { indexOfProperty, isEmpty } from './store.utility';

@Injectable()
export class AnalysisStoreEffects {
  constructor(
    private readonly actions: Actions,
    private readonly store: Store<any>,
    private readonly dialog: MatDialog,
    private readonly eventService: EventService,
    private readonly teamsService: TeamsService,
    private readonly chronoService: ChronoService,
    private readonly matchsService: MatchsService,
    private readonly analystService: AnalystService,
    private readonly keyPointService: KeyPointService,
    private readonly matchVideosService: VideosService,
    private readonly chronoCrudService: ChronoCrudService,
    private readonly deficiencyService: DeficiencyService,
    private readonly matchRefereesService: MatchRefereesService,
    private readonly tcVideoPlayerService: TcVideoPlayerService,
    private readonly keyPointAnalysisService: KeyPointAnalysisService,
  ) { }

  @Effect()
  initAnalysisEffect: Observable<TcAction> = this.actions.pipe(
    ofType<InitAnalysis>(AnalysisActionTypes.INIT),
    mergeMap((action: InitAnalysis) =>
      of(this.init()).pipe(
        map(item => new InitAnalysisSuccess({ analysisState: item }))
      )
    )
  );

  @Effect()
  addKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<AddKeyPoint>(AnalysisActionTypes.ADD_KEY_POINT),
    mergeMap((action: AddKeyPoint) =>
      of(this.addKeyPoint(action.payload)).pipe(
        map(item => new ManageKeyPoint({ keyPoint: item })),
      )
    ),
    tap(action => {
      this.chronoService.pause();
      this.dialog.open(KeyPointDetailsComponent, {
        height: '90vh',
        width: '1300px',
        data: action
      });
    })
  );
  @Effect()
  loadKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<LoadKeyPoint>(AnalysisActionTypes.LOAD_KEY_POINT),
    mergeMap((action: LoadKeyPoint) =>
      of(this.LoadKeyPoint(action.payload.eventListId)).pipe(
        map(item => new ManageKeyPoint({ keyPoint: item }))
      )
    ),
    tap(() => {
      this.dialog.closeAll();
    }),
    tap(action => {
      this.store.dispatch(new CheckNavigationState({ id: (action as any)?.payload?.keyPoint?.eventListId }));
      this.chronoService.pause();
      this.dialog.open(KeyPointDetailsComponent, {
        height: '90vh',
        width: '1300px',
        data: action
      });
    })
  );
  @Effect()
  cancelKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<CancelKeyPoint>(AnalysisActionTypes.CANCEL_KEY_POINT),
    mergeMap((action: CancelKeyPoint) =>
      of(action).pipe(map(item => new ClearKeyPoint()))
    ),
    tap(action => {
      this.dialog.closeAll();
      this.chronoService.play();
    })
  );
  @Effect()
  saveKeyPointEffect: Observable<TcAction> = this.actions.pipe(
    ofType<SaveKeyPoint>(AnalysisActionTypes.SAVE_KEY_POINT),
    mergeMap((action: SaveKeyPoint) =>
      of(action).pipe(switchMap(item => [this.saveKeyPoint()]))
    ),
    tap(action => {
      this.dialog.closeAll();
    })
  );
  @Effect()
  addEventEffect: Observable<TcAction> = this.actions.pipe(
    ofType<AddEvent>(AnalysisActionTypes.ADD_EVENT),
    mergeMap((action: AddEvent) =>
      of(action).pipe(switchMap(item => [this.addEvent(action.payload)]))
    )
  );
  @Effect()
  saveEventEffect: Observable<TcAction> = this.actions.pipe(
    ofType<SaveEvent>(AnalysisActionTypes.SAVE_EVENT),
    mergeMap((action: SaveEvent) =>
      of(action).pipe(switchMap(item => [this.saveEvent()]))
    )
  );

  @Effect({ dispatch: false })
  showDeleteKeypointPopup = this.actions.pipe(
    ofType<ShowDeleteKeypointPopup>(AnalysisActionTypes.SHOW_DELETE_KEYPOINT_POPUP),
    tap(action => {
      const { id } = action.payload;

      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          noText: 'analysis-list.dialog.delete.no',
          title: 'analysis-list.dialog.delete.title',
          yesText: 'analysis-list.dialog.delete.yes',
          message: 'analysis-list.dialog.delete.message',
        },
        panelClass: 'analysis-list-dialog-panel'
      });

      dialog.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.keyPointService.deleteAll(id).then(() => {
            this.store.dispatch(new DeleteKeyPoint({ eventListId: id }),);
          });
        }
      });
    }),
  )

  @Effect({ dispatch: false })
  setAnalysisStatus = this.actions.pipe(
    ofType<SetAnalysisStatus>(AnalysisActionTypes.SET_ANALYSIS_STATUS),
    tap(async (action) => {
      const analysisId = await this.store.select(getAnalysisId).pipe(take(1)).toPromise();

      const item = await this.analystService.update({ id: analysisId, status: action.payload.status } as Analysis);

      this.store.dispatch(new SetAnalysisStatusSuccess({ status: item.status as MatchStatusEnum }));
    }),
  )

  @Effect({ dispatch: false })
  reloadEventList = this.actions.pipe(
    ofType<ReloadEventList>(AnalysisActionTypes.RELOAD_EVENT_LIST),
    tap(async () => {
      const matchId = this.getMatchIdFromStore();
      const analysisId = this.getAnalysisIdFromStore();

      this.getEventList(matchId, analysisId);
    }),
  )

  /**
   * Init or reset the store content
   */
  public init(): AnalysisState {
    const store = this.getAnalysisState();
    const matchId = this.getMatchIdFromStore();
    const analysisId = this.getAnalysisIdFromStore();

    this.store.dispatch(new InitChrono({ matchId: matchId, videoTime: 0 }));

    this.getActorsList(matchId).then(() => this.getEventList(matchId, analysisId));
    this.getVideos(matchId);
    this.getMatch(matchId);

    this.getTeams(matchId);

    return {
      ...store,
      matchId: matchId,
      analysisId: analysisId,
      eventsList: [],
      actorsList: [],
    };
  }

  /**
   * Init a new key point
   */
  public addKeyPoint(payload): KeyPointItem {
    const videoTime = this.chronoService.getVideoTime();
    const matchTime = this.chronoService.getMatchTime(videoTime);
    if (matchTime === null) {
      throw new Error(
        'Unable to calculate match time. Has the match started ? Check your events.'
      );
    }
    return {
      startChrono: {
        videoTime: videoTime,
        matchTime: matchTime.matchTime,
        round: matchTime.lastEvent.round,
        isAdditionalTime: matchTime.lastEvent.isAdditionalTime
      },
      endChrono: {
        // Add 30 seconds (30000 milliseconds) as defaut end time
        videoTime: videoTime + 30000,
        matchTime: matchTime.matchTime + 30000,
        round: matchTime.lastEvent.round,
        isAdditionalTime: matchTime.lastEvent.isAdditionalTime
      },
      keyPointType: payload.keyPointType,
      keyPointTypeLabel: payload.keyPointLabel,
      teamPlayerId: null,
      teamId: null,
      teamPlayerInId: null,
      teamPlayerOutId: null,
      code: null,
      invalid: false
    };
  }

  /**
   * Load a key point
   * @param eventListId
   */
  public LoadKeyPoint(eventListId: number) {
    // Get the list item that needs to be edited
    let events: EventListItem[];
    this.store.pipe(select(getEvents));
    const subscription = this.store
      .select(getEvents)
      .subscribe(data => (events = data));
    subscription.unsubscribe();
    let eventListItem: EventListItem;
    for (const event of events) {
      if (event.id === eventListId) {
        eventListItem = event;
        break;
      }
    }

    // Set the id of the list in the keypoint to know on save how to update the store
    const keyPoint = { ...eventListItem.keyPoint };
    keyPoint.eventListId = eventListItem.id;

    // If keypoint has no analysis, we need to add it
    if (keyPoint.hasOwnProperty('keyPointAnalysis') === false) {
      keyPoint.keyPointAnalysis = {
        analysisId: this.getAnalysisIdFromStore(),
        keyPointAnalysisStatus: KeypointAnalysisStatusEnum.Normal,
        invalid: false, // Status of the keypoint analysis : normal by default
        deficiencies: [], // Deficiencies of the match. Empty by default.
        var: false,
        reviewed: false
      };
    }

    return keyPoint;
  }

  /**
   * Init a new event
   */
  public addEvent(payload) {
    const videoTime = this.chronoService.getVideoTime();
    const matchTime = this.chronoService.getMatchTime(videoTime);
    if (payload.eventType !== EventTypeEnum.MatchStart && matchTime === null) {
      throw new Error(
        'Unable to calculate match time. Has the match started ? Check your events.'
      );
    }
    const event: ChronoItem = {
      videoTime: this.chronoService.getVideoTime(),
      matchTime: this.chronoService.getEventMatchTime(
        payload.eventType,
        matchTime
      ),
      round: this.chronoService.getRound(payload.eventType, matchTime),
      isAdditionalTime: this.chronoService.getAdditionalTime(
        payload.eventType,
        matchTime
      ),
      eventType: payload.eventType
    };

    // Commit your work to the store
    return new ManageEvent({ event: event });
  }

  /**
   * Effect to call the backend to save data
   */
  public saveKeyPoint() {
    let selectedKeyPoint: KeyPointItem;
    const subscription = this.store
      .select(getSelectedKeyPoint)
      .subscribe(data => (selectedKeyPoint = data));
    subscription.unsubscribe();

    if (isEmpty(selectedKeyPoint)) {
      throw new Error('Store value "selectedKeyPoint" is empty.');
    }

    // If keypoint is new, we need to add analysis it to the store to insert it so the analyst who created it is recorded in the keypoint
    let keyPointToSave: KeyPointItem;
    if (
      selectedKeyPoint.hasOwnProperty('id') === false ||
      (selectedKeyPoint.hasOwnProperty('id') && isEmpty(selectedKeyPoint.id))
    ) {
      keyPointToSave = {
        ...selectedKeyPoint,
        keyPointAnalysis: {
          analysisId: this.getAnalysisIdFromStore(),
          keyPointAnalysisStatus: KeypointAnalysisStatusEnum.Normal,
          invalid: false, // Status of the keypoint analysis : normal by default
          deficiencies: [], // Deficiencies of the match. Empty by default.
          var: false,
          reviewed: false,
        }
      };
    } else {
      keyPointToSave = selectedKeyPoint;
    }

    // Asynchronous save of key point
    this.saveKeyPointItem(
      this.getMatchIdFromStore(),
      this.getAnalysisIdFromStore(),
      keyPointToSave
    ).then(keyPoint => {
      // If the key point has no list id, create one
      if (!keyPoint.eventListId) {
        // Building list event
        const evenListItem: EventListItem = {
          id: new Date().valueOf(),
          type: this.getKeyPointLabel(keyPoint.keyPointType),
          details: keyPoint.keyPointTypeLabel,
          teamId: keyPoint.teamId,
          teamPlayerId: keyPoint.teamPlayerId,
          teamPlayerInId: keyPoint.teamPlayerInId,
          teamPlayerOutId: keyPoint.teamPlayerOutId,
          keyPoint: keyPoint
        };

        // Adding list event to the store
        this.store.dispatch(
          new AddToEventList({ eventListItem: evenListItem })
        );
      } else {
        // If the key point has an id, update the event list with the new data
        let eventList: EventListItem[];
        const subscriptionEventList = this.store
          .select(getEvents)
          .subscribe(data => (eventList = data));
        subscriptionEventList.unsubscribe();

        // Search for the id and replace the key point
        const newEventList = [...eventList];
        for (const eventItem of newEventList) {
          if (eventItem.id === keyPoint.eventListId) {
            const index = eventList.indexOf(eventItem);
            const type = keyPoints.find(item => item.value === keyPoint.keyPointType)?.label;

            newEventList[index] = {
              ...eventItem,
              keyPoint,
              type,
              teamId: keyPoint.teamId,
              teamPlayerId: keyPoint.teamPlayerId,
              teamPlayerInId: keyPoint.teamPlayerInId,
              teamPlayerOutId: keyPoint.teamPlayerOutId,
            };
          }
        }

        // Return the event list for updating the store
        this.store.dispatch(new UpdateEventList({ eventList: newEventList }));
      }

      this.chronoService.play();
    });

    this.store.dispatch(new StartMatchAnalysis());

    return new ClearKeyPoint();
  }

  /**
   * Effect to call the backend to save data
   */
  public saveEvent() {
    // Get the selected
    let selectedEvent: ChronoItem;
    const subscription = this.store
      .select(getSelectedEvent)
      .subscribe(data => (selectedEvent = data));
    subscription.unsubscribe();

    if (isEmpty(selectedEvent)) {
      throw new Error('Store value "selectedEvent" is empty.');
    }

    // Asynschronous save of event
    this.saveChronoItem(this.getMatchIdFromStore(), selectedEvent).then(
      chronoItem => {
        if (selectedEvent.eventType === EventTypeEnum.Realign) {
          this.store.dispatch(new ReloadEventList());

          return;
        }

        if (!chronoItem.eventListId) {
          // Building list event
          const evenListItem: EventListItem = {
            id: new Date().valueOf(),
            type: this.getEventTypeLabel(chronoItem.eventType),
            teamId: null,
            teamPlayerId: null,
            teamPlayerInId: null,
            teamPlayerOutId: null,
            details: '',
            event: chronoItem
          };

          // Adding list event to the store when it's ready
          this.store.dispatch(
            new AddToEventList({ eventListItem: evenListItem })
          );
        } else {
          // If the event has an id, update the event list with the new data
          let eventList: EventListItem[];
          const subscriptionEventList = this.store
            .select(getEvents)
            .subscribe(data => (eventList = data));
          subscriptionEventList.unsubscribe();

          // Search for the id and replace the key point
          const newEventList = [...eventList];
          for (const eventItem of newEventList) {
            if (eventItem.id === chronoItem.eventListId) {
              const index = eventList.indexOf(eventItem);
              newEventList[index] = { ...eventItem, event: chronoItem };
            }
          }

          // Return the event list for updating the store
          this.store.dispatch(new UpdateEventList({ eventList: newEventList }));
        }

        this.chronoService.play();
      }
    );

    this.store.dispatch(new StartMatchAnalysis());

    // Remove the selected event from the store and end process.
    return new ClearEvent();
  }

  public getAnalysisState(): AnalysisState {
    let state: AnalysisState;
    const subscription = this.store
      .select(getAnalysisStoreState)
      .subscribe(data => (state = data));
    subscription.unsubscribe();

    return state;
  }

  /**
   * Get matchId from the store
   */
  public getMatchIdFromStore(): number {
    let matchId: number;
    const subscription = this.store
      .select(getMatchId)
      .subscribe(data => (matchId = data));
    subscription.unsubscribe();
    // Security check
    if (isEmpty(matchId)) {
      throw new Error('MatchId cannot be select in store.');
    }
    return matchId;
  }

  /**
   * Get analysis id from the store
   */
  public getAnalysisIdFromStore(): number {
    let analysisId: number;
    const subscription = this.store
      .select(getAnalysisId)
      .subscribe(data => (analysisId = data));
    subscription.unsubscribe();
    // Security check
    if (isEmpty(analysisId)) {
      throw new Error('AnalysisId cannot be select in store.');
    }
    return analysisId;
  }

  /**
   * Get actors from the store
   */
  public getActorsFromStore(): ActorListItem[] {
    let actors: ActorListItem[];
    const subscription = this.store
      .select(getActors)
      .subscribe(data => (actors = data));
    subscription.unsubscribe();
    // Security check
    if (isEmpty(actors)) {
      throw new Error('Actors cannot be select in store.');
    }
    return actors;
  }

  /**
   * Get teams from the store
   */
  public getTeamsFromStore(): TeamListItem[] {
    let teams: TeamListItem[];
    const subscription = this.store
      .select(getTeams)
      .subscribe(data => (teams = data));
    subscription.unsubscribe();
    // Security check
    if (isEmpty(teams)) {
      throw new Error('Teams cannot be select in store.');
    }
    return teams;
  }

  /**
   * Get the event list from database
   * @param matchId Optionnal. If not provided, the function will get it from the store
   */
  public getEventList(matchId?: number, analysisId?: number) {
    // If the params are not provided, get them from the store
    if (isEmpty(matchId)) {
      matchId = this.getMatchIdFromStore();
    }
    if (isEmpty(analysisId)) {
      analysisId = this.getAnalysisIdFromStore();
    }
    // Asynchronous load
    this.loadEventList(matchId, analysisId).then(eventList => {
      this.store.dispatch(new UpdateEventList({ eventList: eventList }));

      this.setVideoTime(eventList);

      // Autoplay on video after the events are loaded
      this.chronoService.play();
    });
  }

  /**
   * Set the video time from the last event or keypoint
   */
  private setVideoTime(eventList: EventListItem[]) {
    if (!eventList.length) {
      return;
    }
    const lastEvent = eventList[eventList.length - 1];
    if (lastEvent) {
      const time = get(
        lastEvent,
        'event.videoTime',
        get(lastEvent, 'keyPoint.startChrono.videoTime', 0)
      );
      this.tcVideoPlayerService.goTo(time / 1000 + 0.01);
    }
  }

  /**
   * Compile events and keypoints from database into one list
   */
  private async loadEventList(
    matchId: number,
    analystId: number
  ): Promise<EventListItem[]> {
    // Load all that we need to construct the event list
    const events = await this.eventService.getMatchEvents(matchId);
    const keypoints = await this.keyPointService.getMatchKeyPoints(matchId);
    const chronos = await this.chronoCrudService.getMatchChronos(matchId);

    // Result array
    let eventList: EventListItem[] = [];

    // Events
    if (events.length > 0) {
      for (const event of events) {
        // Compile the chrono
        const chrono = chronos.filter(item => item.id === event.id).shift();
        const chronoItem: ChronoItem = {
          id: chrono.id,
          videoTime: chrono.primaryVideoTime,
          matchTime: chrono.matchTime,
          round: chrono.round,
          isAdditionalTime: chrono.isAdditionalTime,
          eventType: event.type as EventTypeEnum
        };

        // Create list item for event
        const eventListItem: EventListItem = {
          // Make a uniqId from time, fixed number, id. Fixed number because keypoint and event id can be equal, time too.
          id: parseFloat(
            new Date().valueOf().toString() + '1' + chrono.id.toString()
          ),
          type: this.getEventTypeLabel(event.type as EventTypeEnum),
          teamId: null,
          teamPlayerId: null,
          teamPlayerInId: null,
          teamPlayerOutId: null,
          details: '',
          event: chronoItem
        };

        // Add list item to the array
        eventList.push(eventListItem);
      }
    }

    // Key points
    if (keypoints.length > 0) {

      const keyPointAnalyses = await this.keyPointAnalysisService.getKeyPointsAnalyses(analystId);
      const deficiencies = await this.keyPointAnalysisService.getDeficienciesByAnalysis(analystId);

      for (const keypoint of keypoints) {
        // Make a uniqId from time, fixed number, id. Fixed number because keypoint and event id can be equal, time too.
        const eventListId = parseFloat(
          new Date().valueOf().toString().substr(-8) + '2' + keypoint.id.toString()
        );

        // Get the chronos of the key points
        const startChrono = chronos
          .filter(item => item.id === keypoint.startChronoId)
          .shift();
        const endChrono = chronos
          .filter(item => item.id === keypoint.endChronoId)
          .shift();

        // Creating ChronoItems from Chronos for keyPointItem
        const startChronoItem: ChronoItem = {
          id: startChrono.id,
          videoTime: startChrono.primaryVideoTime,
          matchTime: startChrono.matchTime,
          round: startChrono.round,
          isAdditionalTime: startChrono.isAdditionalTime
        };
        const endChronoItem = {
          id: endChrono.id,
          videoTime: endChrono.primaryVideoTime,
          matchTime: endChrono.matchTime,
          round: endChrono.round,
          isAdditionalTime: endChrono.isAdditionalTime
        };

        // Get the key point analysis for the connected user if he exists
        let keyPointAnalysisItem: KeyPointAnalysisItem;
        const keyPointAnalysis = keyPointAnalyses.find(kpa => kpa.keyPointId === keypoint.id);

        if (keyPointAnalysis !== null) {
          const keyPointDeficienciesItems: DeficiencyItem[] = [];

          // Load analysis deficiencies
          const keyPointDeficiencies = deficiencies.filter(d => d.keyPointAnalyseId === keyPointAnalysis.id);

          // If they are deficiencies, we compile the item
          if (keyPointDeficiencies.length > 0) {
            // Load deficienies chrono
            const deficienciesChronoIds = keyPointDeficiencies.map(d => d.chronoId);
            const deficienciesChronos = chronos.filter(chrono => deficienciesChronoIds.includes(chrono.id));

            // Load actors from store
            const actors = this.getActorsFromStore();


            // Load teams from store
            const teams = this.getTeamsFromStore();

            // Compile deficiency item
            for (const deficiency of keyPointDeficiencies) {
              // Get the chrono of the deficiency
              const deficiencyChrono = deficienciesChronos.find(
                item => item.id === deficiency.chronoId
              );
              const deficiencyChronoItem = {
                id: deficiencyChrono.id,
                videoTime: deficiencyChrono.primaryVideoTime,
                matchTime: deficiencyChrono.matchTime,
                round: deficiencyChrono.round,
                isAdditionalTime: deficiencyChrono.isAdditionalTime
              };

              // Get the actor for deficiency
              let actor: ActorListItem;
              if (deficiency.refereeId) {
                actor = actors
                  .map(act => ({ ...act, id: act['refereeId'] }))
                  .find(
                    item =>
                      item['refereeId'] === deficiency.refereeId &&
                      item.type === ActorListTypeEnum.referee
                  );
              } else {
                actor = actors.find(
                  item =>
                    item.id === deficiency.teamPlayerId &&
                    item.type === ActorListTypeEnum.teamPlayer
                );
              }

              // Compile the deficiency item
              const deficiencyItem: DeficiencyItem = {
                uiId: deficiency.id,
                id: deficiency.id,
                chrono: deficiencyChronoItem,
                keyPointAnalysisId: deficiency.keyPointAnalyseId,
                deficiencyType: deficiency.deficiencyType as
                  | PlayerDeficiencyTypeEnum
                  | RefereeDeficiencyTypeEnum,
                actor: actor,
                var: deficiency.var,
                comment: deficiency.comment,
                invalid: deficiency.invalid,
                team: teams.find(t => t.id === deficiency.teamId),
                statut: deficiency.statut as DeficiencyStatusEnum
              };

              // Add the deficiency to the array
              keyPointDeficienciesItems.push(deficiencyItem);
            }
          }

          // Compile the key point analysis item
          keyPointAnalysisItem = {
            id: keyPointAnalysis.id,
            keyPointId: keypoint.id,
            analysisId: analystId,
            keyPointAnalysisStatus: keyPointAnalysis.keyPointAnalysisStatus as KeypointAnalysisStatusEnum,
            invalid: keyPointAnalysis.invalid,
            deficiencies: keyPointDeficienciesItems,
            var: keyPointAnalysis.var,
            reviewed: keyPointAnalysis.reviewed,
          };
        } else {
          // User has not analysis yet. We create a blank object to be saved if he edit a keyPoint
          keyPointAnalysisItem = {
            keyPointId: keypoint.id,
            analysisId: analystId,
            keyPointAnalysisStatus: KeypointAnalysisStatusEnum.Normal,
            invalid: false,
            deficiencies: [],
            var: false,
            reviewed: false,
          };
        }

        // Compile the key point item
        const keyPointItem: KeyPointItem = {
          id: keypoint.id,
          eventListId: eventListId,
          startChrono: startChronoItem,
          endChrono: endChronoItem,
          keyPointType: keypoint.keyPointType as KeyPointTypeEnum,
          keyPointTypeLabel: keypoint.label,
          code: keypoint.code,
          keyPointAnalysisId: keypoint.keyPointAnalysisId,
          invalid: keypoint.invalid,
          keyPointAnalysis: keyPointAnalysisItem,
          teamId: keypoint.teamId,
          teamPlayerInId: keypoint.teamPlayerInId,
          teamPlayerOutId: keypoint.teamPlayerOutId,
          teamPlayerId: keypoint.teamPlayerId,
        };

        // Create list item containing key point item
        const eventListItem: EventListItem = {
          id: eventListId,
          type: this.getKeyPointLabel(keyPointItem.keyPointType),
          details: keyPointItem.keyPointTypeLabel,
          teamId: keypoint.teamId,
          teamPlayerId: keypoint.teamPlayerId,
          teamPlayerInId: keypoint.teamPlayerInId,
          teamPlayerOutId: keypoint.teamPlayerOutId,
          keyPoint: keyPointItem
        };

        // Add list item to the array
        eventList.push(eventListItem);
      }
    }

    // At the end, sort the event list to reorder key points and events
    eventList = sortEventArray(eventList);

    // Return the full list with events and keyPoints
    return Promise.resolve(eventList);
  }

  /**
   * Get the actors list from database
   * @param matchId Optionnal. If not provided, the function will get it from the store
   */
  public async getActorsList(matchId?: number) {
    // If the match id is not provided, get it from the store
    if (isEmpty(matchId)) {
      matchId = this.getMatchIdFromStore();
    }

    const actorsList = await this.loadActorsList(matchId);

    this.store.dispatch(new UpdateActorsList({ actorsList: actorsList }));
  }

  /**
   * Get the teams from database
   * @param matchId Optionnal. If not provided, the function will get it from the store
   */
  public getTeams(matchId?: number) {
    // If the match id is not provided, get it from the store
    if (isEmpty(matchId)) {
      matchId = this.getMatchIdFromStore();
    }
    this.loadTeams(matchId).then(teams => {
      this.store.dispatch(new UpdateTeams({ teams }));
    });
  }

  /**
   * Get the videos list from database
   * @param matchId Optionnal. If not provided, the function will get it from the store
   */
  public getVideos(matchId?: number) {
    // If the match id is not provided, get it from the store
    if (isEmpty(matchId)) {
      matchId = this.getMatchIdFromStore();
    }

    this.loadVideosList(matchId).then(videos => {
      this.store.dispatch(new SetMatchVideos(videos));
    });
  }

  private getMatch(matchId?: number) {
    if (isEmpty(matchId)) {
      matchId = this.getMatchIdFromStore();
    }

    this.matchsService.get(matchId)
      .then(match => this.store.dispatch(new SetMatch(match)));
  }

  /**
   * Compile referees and teamPlayers from database into one list
   */
  private async loadActorsList(matchId: number): Promise<ActorListItem[]> {
    // Load all that we need to construct the actors list
    const referees = await this.matchRefereesService.getMatchReferees(matchId);
    const teamPlayers = await this.teamsService.getMatchTeamPlayers(matchId);

    const actorsList = referees.concat(teamPlayers);

    // Return the full list with referees and team players
    return Promise.resolve(actorsList);
  }

  /**
  * Compile teams from database into one list
  */
  private async loadTeams(matchId: number): Promise<TeamListItem[]> {
    const teams = await this.teamsService.getTeamsByMatch(matchId);
    return Promise.resolve(teams);
  }

  /**
   * Get videos from database and start getting video's chronos
   */
  private async loadVideosList(matchId: number): Promise<Video[]> {
    // Load all that we need to construct the videos list
    const videos = await this.matchVideosService.getMatchVideos(matchId);

    // Return the list of videos
    return Promise.resolve(videos);
  }

  /**
   * Get the event label for the list
   */
  private getEventTypeLabel(eventTypeEnum: EventTypeEnum) {
    // Loading labels from shared files
    const eventsLabels: EventTypeLabel[] = eventsTypes;
    const index = indexOfProperty(eventsLabels, 'value', eventTypeEnum);
    return eventsLabels[index]?.label;
  }

  /**
   * Get the keyPoint label for the list
   */
  private getKeyPointLabel(keyPointTypeEnum: KeyPointTypeEnum) {
    // Loading labels from shared files
    const keyPointsLabels: KeyPointLabel[] = keyPoints;
    const index = indexOfProperty(keyPointsLabels, 'value', keyPointTypeEnum);
    return keyPointsLabels[index]?.label;
  }

  /**
   * Save chrono into the database and return a promise of it with id inserted in DB
   */
  private async saveChronoItem(
    matchId: number,
    chronoItem: ChronoItem
  ): Promise<ChronoItem> {
    // DEBUG : decomment this line if you don't want to save in DB
    // return Promise.resolve(chronoItem);

    // Insertion or update of chrono
    let chrono: Chrono = {
      id: chronoItem.hasOwnProperty('id') ? chronoItem.id : null,
      isAdditionalTime: chronoItem.isAdditionalTime
        ? chronoItem.isAdditionalTime
        : false,
      matchId: matchId,
      matchTime: chronoItem.matchTime,
      primaryVideoTime: chronoItem.videoTime,
      round: chronoItem.round
    };
    if (chrono.id) {
      chrono = await this.chronoCrudService.update(chrono);
    } else {
      chrono = await this.chronoCrudService.create(chrono);
    }

    if (chrono.id) {
      // Delete event anyway and put it back if needed.
      await this.eventService.delete(chrono.id);
      if (chronoItem.hasOwnProperty('eventType')) {
        // Insertion of event
        let event: Event = {
          id: chrono.id,
          type: chronoItem.eventType
        };
        event = await this.eventService.insertEvent(event);
      }
    }
    return Promise.resolve({ ...chronoItem, id: chrono.id });
  }

  /**
   * Save key point into the database and return a promise of it with id inserted in DB
   */
  private async saveKeyPointItem(
    matchId: number,
    analystId: number,
    keyPointItem: KeyPointItem
  ): Promise<KeyPointItem> {
    // DEBUG : decomment this line if you don't want to save in DB
    // return Promise.resolve(keyPointItem);

    // Insert or update the chrono start and the chrono end
    const startChrono = await this.saveChronoItem(
      matchId,
      keyPointItem.startChrono
    );
    const endChrono = await this.saveChronoItem(
      matchId,
      keyPointItem.endChrono
    );

    // Insert tor update the key point
    let keyPoint: KeyPoint = {
      id: keyPointItem.hasOwnProperty('id') ? keyPointItem.id : null,
      startChronoId: startChrono.id,
      endChronoId: endChrono.id,
      keyPointType: keyPointItem.keyPointType,
      teamPlayerOutId: keyPointItem.teamPlayerOutId,
      teamPlayerInId: keyPointItem.teamPlayerInId,
      code: this.getKeyPointCode(keyPointItem),
      invalid: keyPointItem.invalid,
      teamId: keyPointItem.teamId,
      teamPlayerId: keyPointItem.teamPlayerId,
      label: keyPointItem.hasOwnProperty('keyPointTypeLabel')
        ? keyPointItem.keyPointTypeLabel
        : null
    };
    if (keyPoint.id) {
      // If we are in update, we keep the analystId who created the keypoint
      keyPoint.keyPointAnalysisId = keyPointItem.keyPointAnalysisId;
      keyPoint = await this.keyPointService.update(keyPoint);
    } else {
      keyPoint = await this.keyPointService.create(keyPoint);
    }

    // Handle the key point analysis. At this point, it should be defined. If not, that's not normal !
    let keyPointAnalysis: KeyPointAnalysis = {
      id: keyPointItem.keyPointAnalysis.hasOwnProperty('id')
        ? keyPointItem.keyPointAnalysis.id
        : null,
      keyPointId: keyPoint.id,
      analysisId: analystId,
      keyPointAnalysisStatus:
        keyPointItem.keyPointAnalysis.keyPointAnalysisStatus,
      invalid: keyPointItem.keyPointAnalysis.invalid,
      var: keyPointItem.keyPointAnalysis.var,
      reviewed: keyPointItem.keyPointAnalysis.reviewed,
    };
    if (keyPointAnalysis.id) {
      keyPointAnalysis = await this.keyPointAnalysisService.update(
        keyPointAnalysis
      );
    } else {
      keyPointAnalysis = await this.keyPointAnalysisService.create(
        keyPointAnalysis
      );
    }

    // If they are deficiencies for the analyst, delete them then resinsert with the ones in the store
    await this.keyPointAnalysisService.removeDeficiencies(keyPointAnalysis.id);
    const newDeficiencies = [];
    if (keyPointItem.keyPointAnalysis.deficiencies.length > 0) {
      for (const deficiency of keyPointItem.keyPointAnalysis.deficiencies) {
        // Adding deficency chrono to database
        const deficiencyChronoItem = { ...deficiency.chrono, id: null };
        const newDeficiencyChronoItem = await this.saveChronoItem(
          matchId,
          deficiencyChronoItem
        );
        // Adding deficiency to database
        let deficiencyToSave: Deficiency = {
          id: null,
          chronoId: newDeficiencyChronoItem.id,
          deficiencyType: deficiency.deficiencyType,
          keyPointAnalyseId: keyPointAnalysis.id,
          refereeId:
            deficiency.actor ? (deficiency.actor.type === ActorListTypeEnum.referee
              ? deficiency.actor.id
              : null) : null,
          teamPlayerId:
            deficiency.actor ? (deficiency.actor.type === ActorListTypeEnum.teamPlayer
              ? deficiency.actor.id
              : null) : null,
          var: deficiency.var,
          comment: deficiency.comment,
          invalid: deficiency.invalid,
          teamId: deficiency.team.id,
          statut: deficiency.statut
        };
        deficiencyToSave = await this.deficiencyService.create(
          deficiencyToSave
        );
        newDeficiencies.push({
          ...deficiency,
          id: deficiencyToSave.id,
          chrono: newDeficiencyChronoItem
        });
      }
    }

    // Set the new key point analysis data with all new deficiencies
    const newKeyPointAnalysis = {
      ...keyPointItem.keyPointAnalysis,
      id: keyPointAnalysis.id,
      deficiencies: newDeficiencies
    };

    // Return the full object with all id inside
    return Promise.resolve({
      ...keyPointItem,
      id: keyPoint.id,
      startChrono: startChrono,
      endChrono: endChrono,
      keyPointAnalysis: newKeyPointAnalysis
    });
  }

  /**
   * Compile the keypoint code from match time
   */
  private getKeyPointCode(keyPointItem: KeyPointItem): string {
    const matchtime = this.chronoService.diplayMatchTime(
      keyPointItem.startChrono
    );
    let code = keyPointItem.keyPointType + matchtime.time.replace(':', '');
    if (
      matchtime.hasOwnProperty('additionalTime') &&
      matchtime.additionalTime
    ) {
      code = code + '+' + matchtime.additionalTime.replace(':', '');
    }
    return code.trim();
  }
}
