import { createReducer, on } from '@ngrx/store';
import { initialBreadcrumbState } from './breadcrumb.state';
import { setBreadcrumb } from './breadcrumb.actions';

export const breadcrumbReducer = createReducer(
  initialBreadcrumbState,
  on(setBreadcrumb, (state, { payload }) => ({
    ...state,
    breadcrumbList: payload
  })),
);

