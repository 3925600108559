import { Injectable } from '@angular/core';
const { version, buildDate } = require('../../../../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class TcVersionService {
  public getVersion(): string {
    return version + ' - ' + buildDate;
  }
}
