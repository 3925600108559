import { createAction, props } from '@ngrx/store';
import { Stadium } from '@match-fix/shared';

export const upsertStadium = createAction(
    '[Stadiums] Upsert Stadium',
    props<{ stadium?: Stadium }>()
);

export const upsertStadiumSuccess = createAction(
    '[Stadiums] Upsert Success Stadium',
    props<{ stadium?: Stadium }>()
);
