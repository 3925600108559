import { Chrono, Match, ServerVideo } from '@match-fix/shared';

/**
 * Main interface for match creation submission
 */
export interface MatchState {
  match: MatchData;
  teams: {
    teamA: TeamData;
    teamB: TeamData;
  };
  referees: MatchReferee[];
  analysts: MatchAnalysts[];
  errors: MatchError[];
  videos: VideoWithChrono[];
  selectedMatch: Match;
  analystsUpdated: boolean;
  serverVideos: ServerVideo[];
}

/**
 * Interface for match data
 */
export interface MatchData {
  competitionId: number;
  stadiumId: number;
  date: string;
  time: string;
  var: boolean;
  weather?: string;
  wind?: string;
  rain?: string;
}

/**
 * Interface for match referees
 */
export interface MatchReferee {
  id: string;
  refereeId: number;
  name: string;
  role: string;
}

/**
 * Interface for match analysts
 */
export interface MatchAnalysts {
  id: string;
  userId: number;
  analystType: string;
}

/**
 * Interface for teams
 */
export interface TeamData {
  clubId: number;
  players: TeamPlayer[];
}

/**
 * Interface for team players
 */
export interface TeamPlayer {
  id: string;
  playerId: number;
  number?: number;
  titular: boolean;
  name: string
}

/**
 * Interface for match errors
 */
export interface MatchError {
  field: string;
  message: string;
  value?: any;
}

/**
 * Default object to register in match store with empty values
 */
export const defaultMatchState: MatchState = {
  match: {
    competitionId: null,
    stadiumId: null,
    date: null,
    time: null,
    var: false,
    weather: null,
    wind: null,
    rain: null
  },
  teams: {
    teamA: {
      clubId: null,
      players: []
    },
    teamB: {
      clubId: null,
      players: []
    }
  },
  referees: [],
  analysts: [],
  errors: [],
  videos: [],
  selectedMatch: null,
  analystsUpdated: false,
  serverVideos: [],
};

export interface VideoWithChrono {
  id: number;
  matchId: number;
  endChrono?: Chrono;
  startChrono?: Chrono;
  isPrimary: boolean;
  fileIdentifier: string;
}

export interface UpdateVideo {
  id: number;
  matchId: number;
  endChrono?: UpdateVideoChrono;
  startChrono?: UpdateVideoChrono;
  isPrimary: boolean;
  fileIdentifier: string;
}

export interface UpdateVideoChrono {
  id?: number;
  matchTime: number;
  round: number;
  isAdditionalTime?: boolean;
}
