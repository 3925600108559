import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss']
})
export class LoginMfaComponent extends TcFormComponent<any> implements OnInit {

  @Input() options: { access_token: string, code: string, image: string } = null;

  @Output() verify: EventEmitter<any> = new EventEmitter();

  public plainTextCode: string;
  public form: FormGroup;
  public codeCtrl: FormControl;
  public src = null;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService,
  ) {
    super(translate, elem);

    this.fields = [
      this.ngxInputField('code'),
    ];
  }

  ngOnInit() {
    this.model = { code: null };

    if (this.options?.code) {
      this.plainTextCode = `${this.translate.instant('login-mfa.plain-code')}\n${this.options?.code}`;
    }

    this.src = this.options?.image;
  }

  public submit() {
    if (this.form.valid) {
      this.verify.emit({ code: this.model.code, token: this.options.access_token });
    }
  }

  private ngxInputField(key: string): FormlyFieldConfig {
    const label = this.translate.instant(`login-mfa.labels.${key}`);

    return ({
      key: key,
      type: 'ngx-input',
      className: `input-${key}`,
      templateOptions: {
        label,
        modelKey: key,
        mask: '000 000',
        required: true,
      },
    });
  }

}
