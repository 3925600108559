export const ConfigKeys = {
  STORAGE_BASE_PATH: "STORAGE_BASE_PATH",
  API_BASE_PATH: "API_BASE_PATH",
  translate: {
    language: "translate.language",
  },
  logger: {
    level: "logger.level",
    serverLogLevel: "logger.serverLogLevel",
    serverLoggingUrl: "logger.serverLoggingUrl",
  },
  notifications: {
    closeButton: "notifications.closeButton",
    timeOut: "notifications.timeOut",
  },
};
