import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'chrono-input-cell-editor',
  templateUrl: './chrono-input-cell-editor.component.html',
  styleUrls: ['./chrono-input-cell-editor.component.scss'],
})
export class ChronoInputCellEditorComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  public value: string;
  public model: string;
  public params: ICellEditorParams;
  public maxlength = 6;

  ngAfterViewInit() {
    setTimeout(() => this.input.nativeElement.focus());
  }

  getValue() {
    return this.value;
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.value = params.data[params.colDef.field];
    this.model = params.data[params.colDef.field];
  }

  public onChange(value) {
    this.value = this.params.colDef.cellEditorParams(this.params).valueSetter((value || ''));
  }
}