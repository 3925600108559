import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { PlayerListComponent } from './components/smart/player-list/player-list.component';
import { PlayerDetailComponent } from './components/smart/player-detail/player-detail.component';
import { PlayersEffects } from './store/players.effects';
import { EffectsModule } from '@ngrx/effects';

const smartComponents = [PlayerListComponent, PlayerDetailComponent];

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([PlayersEffects])
  ],
  exports: [...smartComponents],
  entryComponents: [PlayerDetailComponent]
})
export class PlayersModule { }
