import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigKeys } from '../../modules/shared/interfaces/config.interface';
import { ConfigService } from '../../modules/shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: ConfigService,
  ) { }

  async getAnalysisFilters(): Promise<AnalysisFilters> {
    const url = `${this.config.get(ConfigKeys.API_BASE_PATH)}/filters/analysis`;

    return this.httpClient.get<AnalysisFilters>(url).toPromise();
  }

}

export interface AnalysisFilters {
  competitions: Filter[];
  dates: Filter[];
  teamA: Filter[];
  teamB: Filter[];
  supervisors: Filter[];
  operator1: Filter[];
  operator2: Filter[];
  experts: Filter[];
  referees: Filter[];
  matchStates: Filter[];
  matchAnalysisStates: Filter[];
  matchStatuses: Filter[];
}

export interface Filter {
  value: string | number;
  label: string;
}
