import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActorListItem, ActorListTypeEnum, ChronoItem, KeyPoint, KeypointAnalysisStatusEnum, NoteDeficiencyItem, allDeficiencyStatusesList } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcGenericFormComponent, TcNotificationService, TcTranslateService } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
import { DeficiencyService } from '../../../../services/business-services/deficiency-service';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { ChronoService } from '../../../match-analysis/services/chrono.service';
import { GetNoteKeyPoint } from '../../store/expert-analysis.actions';
import { AssembleState } from '../../store/expert-analysis.interfaces';

@Component({
  selector: 'app-assemble-key-points-details',
  templateUrl: './assemble-key-points-details.component.html',
  styleUrls: ['./assemble-key-points-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssembleKeyPointsDetailsComponent
  extends TcGenericFormComponent<any>
  implements OnInit, AfterViewInit {

  keyPoint;

  public rows: {
    key: string;
    operator1: {
      actor: ActorListItem;
      deficiency: NoteDeficiencyItem;
    };
    operator2: {
      actor: ActorListItem;
      deficiency: NoteDeficiencyItem;
    };
  }[] = [];

  constructor(
    readonly elem: ElementRef,
    readonly translate: TcTranslateService,
    readonly entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private readonly store$: Store<AssembleState>,
    private readonly chronoService: ChronoService,
    private readonly notification: TcNotificationService,
    private readonly deficiencyService: DeficiencyService,
    private readonly dialogRef: MatDialogRef<AssembleKeyPointsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssembleState & { keypoint: KeyPoint },
  ) {
    super('Assemble key point', entityCollectionServiceFactory, translate, elem);
  }

  async ngOnInit() {
    this.initForm();
    this.initListData();

    this.dialogRef.addPanelClass('assemble-key-point-popup');
  }

  ngAfterViewInit() {
    this.form.controls.timeline.valueChanges.subscribe(value => {
      this.form.controls.chronoStart.setValue(value, { emitEvent: false });
      this.model.chronoStart = value;
    });

    this.form.controls.chronoStart.valueChanges.subscribe(value => {
      this.form.controls.timeline.setValue(value, { emitEvent: false });
    });
  }

  public cancelAssemble() {
    this.onClose();
  }

  public async saveAssemble() {

    if (this.form.invalid) {
      this.notification.error('Complete all fields');
      return;
    }

    const data = this.buildForSave();

    await this.deficiencyService.manualAssembly(data);
    this.store$.dispatch(new GetNoteKeyPoint({ keyPointId: this.data.keyPointId }));
    this.onClose();
  }

  public onClose() {
    this.dialogRef.close();
  }

  public getMatchAnalysisStateColor(status: KeypointAnalysisStatusEnum) {
    switch (status) {
      case KeypointAnalysisStatusEnum.Abnormal:
        return '#f3a88a';
      case KeypointAnalysisStatusEnum.PotentiallyAbnormal:
        return '#f2df98';
      case KeypointAnalysisStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  public setCommentValue(value: string) {
    this.model = {
      ...this.model,
      comment: value,
    };
  }

  public getChronoValue(chrono: ChronoItem) {
    if (!chrono) {
      return '';
    }

    const displayMatchTime = this.chronoService.diplayMatchTime(chrono);

    return `${displayMatchTime.time}${displayMatchTime.additionalTime ? ` + ${displayMatchTime.additionalTime}` : ''}`
  }

  public getMatchTimeValue(chrono: ChronoItem) {
    if (!chrono?.videoTime) {
      return '-:-';
    }

    return convertToReadableFormat(chrono.videoTime);
  }

  public getActorLabel(item): string {
    let label = '';
    if (item.type === ActorListTypeEnum.teamPlayer) {
      label += `${item.number || '?'} - `;
    }
    label = `${label}${item.firstName}`;
    if (item.club) {
      label = `${label} (${item.club})`;
    }

    return label;
  }

  private buildForSave() {
    const actors = [this.data.deficiencies.operator1.actor, this.data.deficiencies.operator2.actor];

    const data = {
      deficiencyOp2: this.data.deficiencies.operator2.deficiency.deficiencyId,
      deficiencyOp1: this.data.deficiencies.operator1.deficiency.deficiencyId,
      expert: {
        id: this.data.deficiencies.expert.deficiency?.deficiencyId,
        chronoId: this.model.chronoStart,
        teamPlayerId: actors.filter(actor => actor.type === ActorListTypeEnum.teamPlayer).find(e => e.id === this.model.player)?.id || null,
        refereeId: actors.filter(actor => actor.type === ActorListTypeEnum.referee).find(e => e.id === this.model.player)?.id || null,
        comment: this.model.comment,
        type: this.model.deficiency,
        statut: this.model.statut,
        teamId: this.model.team,
        var: this.model.var,
      },
    };

    return data;
  }

  private initForm() {
    this.fields = this._fields;
  }

  private get _fields(): FormlyFieldConfig[] {
    return [
      this.selectField('team'),
      this.selectField('actor'),
      this.selectField('deficiency'),
      this.selectField('chronoStart'),
      this.selectField('timeline'),
      this.radioField('var'),
      this.inputField('comment'),
      this.selectField('statut'),
    ]
  }

  private initListData() {
    const keys = this.fields.map(({ key }) => key);

    this.rows = keys.map(key => ({
      key,
      label: this.translate.instant(`assemble-key-points-details.fields.${key}`),
      operator1: this.data.deficiencies.operator1,
      operator2: this.data.deficiencies.operator2,
    }));
  }

  private selectField(key: string): FormlyFieldConfig {
    let options = [];
    let value = null;

    const label = this.translate.instant(`assemble-key-points-details.fields.${key}`);

    const data = this.getNecessaryData(key as any);

    switch (key) {
      case 'team': {
        for (const option of data.filter(v => !!v.team).map(item => ({ value: item.team.id, label: item.team.code }))) {
          if (!options.length || !options.some(v => v.value === option.value)) {
            options.push(option);
          }
        }
        value = (this.data.deficiencies?.expert?.deficiency as any)?.team?.id;
        break;
      }

      case 'statut': {
        options = allDeficiencyStatusesList(this.translate);
        value = this.data.deficiencies?.expert?.deficiency?.statut;
        break;
      }

      case 'actor': {
        options = data.map(item => ({ value: item.id, label: this.getActorLabel(item) }));
        value = this.data.deficiencies?.expert?.actor?.id;
        break;
      }

      case 'deficiency': {
        options = data.map(item => ({ value: item.deficiencyType, label: this.translate.instant(`deficiencies.${item.deficiencyType}`) }));
        value = this.data.deficiencies?.expert?.deficiency?.deficiencyType;
        break;
      }

      case 'chronoStart': {
        options = data.map(item => ({ value: item.chrono.id, label: this.getChronoValue(item.chrono) }));

        if (this.data.deficiencies.expert && !options.find(o => o.value === this.data.deficiencies?.expert?.deficiency?.chrono?.id)) {
          options.push({
            value: this.data.deficiencies?.expert?.deficiency?.chrono?.id,
            label: this.getChronoValue(this.data.deficiencies?.expert?.deficiency?.chrono)
          });
        }

        options = options.filter(e => e?.value)
        value = this.data.deficiencies?.expert?.deficiency?.chrono?.id;
        break;
      }

      case 'timeline': {
        options = data.map(item => ({ value: item.chrono.id, label: this.getMatchTimeValue(item.chrono) }));

        if (this.data.deficiencies.expert && !options.find(o => o.value === this.data.deficiencies?.expert?.deficiency?.chrono?.id)) {
          options.push({
            value: this.data.deficiencies?.expert?.deficiency?.chrono?.id,
            label: this.getChronoValue(this.data.deficiencies?.expert?.deficiency?.chrono)
          });
        }

        options = options.filter(e => e?.value)
        value = this.data.deficiencies?.expert?.deficiency?.chrono?.id;
        break;
      }
    }

    return {
      key,
      type: 'select',
      defaultValue: value,
      templateOptions: {
        required: true,
        label,
        options,
      }
    }
  }

  private inputField(key: string): FormlyFieldConfig {
    const label = this.translate.instant(`assemble-key-points-details.fields.${key}`);

    return {
      key,
      type: 'input',
      defaultValue: this.data.deficiencies.expert.deficiency.comment,
      templateOptions: {
        required: true,
        label,
      }
    }
  }

  private radioField(key: string): FormlyFieldConfig {
    return {
      key,
      className: 'form--radio',
      type: 'radio',
      defaultValue: this.data.deficiencies.expert.deficiency.var,
      templateOptions: {
        required: true,
        label: this.translate.instant('note-key-point-details.labels.var'),
        options: [
          { value: true, label: this.translate.instant('note-key-point-details.labels.yes') },
          { value: false, label: this.translate.instant('note-key-point-details.labels.no') },
        ],
      },
    }
  }

  private getNecessaryData(key: string): any[] {

    switch (key) {

      case 'actor': {
        return [
          this.data.deficiencies.operator1.actor,
          this.data.deficiencies.operator2.actor,
        ];
      }

      default: {
        return [
          this.data.deficiencies.operator1.deficiency,
          this.data.deficiencies.operator2.deficiency,
        ];
      }
    };

  }

}
