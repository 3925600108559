import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { navigateToRoute } from './main.actions';
import { RouteEnum } from '../enums/routes.enum';

@Injectable()
export class MainEffects {
  constructor(
    private actions$: Actions,
    private router: Router
  ) { }

  navigateToRoute = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateToRoute),
      tap(({ route }) => {
        this.router.navigate([route === RouteEnum.Home ? '' : route]);
    })),
    { dispatch: false }
  );
}
