import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { getChronoTime } from '../../../../utils/getChronoTime';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { isChronoValide } from '../../../../utils/validateChrono';
import { ChronoService } from '../../services/chrono.service';
import { RealignService } from '../../services/realign.service';

@Component({
  selector: 'app-realign-component',
  templateUrl: './realign-popup.component.html',
  styleUrls: ['./realign-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealignComponent extends TcFormComponent<any> implements OnInit {

  private currentMatchTime: number;
  private newMatchTime: number;

  constructor(
    public elem: ElementRef,
    public translate: TcTranslateService,
    private readonly chronoService: ChronoService,
    private readonly realignService: RealignService,
    private readonly dialogRef: MatDialogRef<RealignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(translate, elem);

    this.fields = [
      {
        fieldGroup: [
          {
            key: 'chrono',
            type: 'chrono-input',
            templateOptions: {
              required: true,
              modelKey: 'chrono',
              setValue: (value) => {
                if (!isChronoValide(value)) {
                  return;
                }

                const time = getChronoTime(value).mmss;

                this.setChronoRealign(time);
              }
            },
            validators: {
              chrono: {
                expression: (c) => isChronoValide(c.value),
              },
            },
          },
        ],
      },
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    this.currentMatchTime = this.chronoService.getMatchTime(this.chronoService.getVideoTime())?.matchTime || 0;
    this.model.chrono = convertToReadableFormat(this.currentMatchTime, 'mm:ss');

    const values = this.model.chrono.split(':').map(v => parseInt(v, 10));
    this.newMatchTime = values[0] * 60 + values[1];
  }

  public cancelRealign() {
    this.chronoService.play();
    this.onClose();
  }

  public async saveRealign() {
    if (this.form.invalid) {
      return;
    }

    await this.realignService.realign(this.currentMatchTime, this.newMatchTime * 1000);

    this.onClose();
  }

  public onClose() {
    this.dialogRef.close();
  }

  private setChronoRealign(value) {
    const values = value.split(':').map(v => parseInt(v, 10));
    this.newMatchTime = values[0] * 60 + values[1];

    if (!this.newMatchTime) {
      return;
    }

    this.model.chrono = convertToReadableFormat(this.newMatchTime, 'mm:ss');
  }

}
