import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Analysis,
  AnalystTypeEnum, ClubPlayer,
  MatchAnalysisStateEnum,
  MatchStateEnum,
  MatchStatusEnum,
  Player,
  User
} from '@match-fix/shared';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcConfirmDialogComponent, TcNotificationService } from '@tc/core';
import { uniqBy } from 'lodash';
import * as moment from 'moment';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChronoService } from '../../../services/business-services/chrono-service';
import { ClubPlayerService } from '../../../services/business-services/club-player-service';
import { CustomPlayersService } from '../../../services/business-services/custom-player-service';
import { VideosService } from '../../../services/business-services/match-videos-service';
import { MatchsService } from '../../../services/business-services/matchs-service';
import { PlayersService } from '../../../services/business-services/players-service';
import { TeamPlayersService } from '../../../services/business-services/teamplayers-service';
import { getStartOfDay } from '../../../utils/startOfDay';
import { MatchDetailComponent } from '../components/smart/match-detail/match-detail.component';
import { ListRefreshService, ListRefreshType } from '../services/list-refresh.service';
import {
  AddMatchVideo,
  AddMatchVideoSuccess,
  AddNewPlayer,
  AddNewPlayerSuccess,
  CreateMatch,
  CreateMatchSuccess,
  DeleteMatch,
  DeleteMatchVideo,
  DeleteMatchVideoSuccess,
  GetClubPlayers,
  GetClubPlayersSuccess,
  GetMatchVideos,
  GetServerVideos,
  GoToMatchAnalysis,
  MatchsActionTypes,
  RemovePlayer,
  RemoveTeamPlayer,
  SelectServerVideo,
  SetMatch,
  SetMatchVideos,
  SetServerVideos,
  StartEditMatch,
  StartEditSuccess,
  StartMatchAnalysis,
  UpdateMatchData,
  UpdateMatchDataSuccess,
  UpdateMatchVideo,
  UpdateMatchVideoSuccess,
  upsertMatch
} from './matchs.actions';
import { UpdateVideo, VideoWithChrono } from './matchs.interfaces';
import { getMatch, getSelectedMatch, getTeams } from './matchs.selectors';

@Injectable()
export class MatchsEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly listRefreshService: ListRefreshService,
    private readonly matchService: MatchsService,
    private readonly playersService: PlayersService,
    private readonly router: Router,
    private readonly store: Store<any>,
    private readonly videosService: VideosService,
    private readonly chronoService: ChronoService,
    private readonly clubPlayerService: ClubPlayerService,
    private readonly customPlayersService: CustomPlayersService,
    private readonly notification: TcNotificationService,
    private readonly teamPlayersService: TeamPlayersService
  ) { }

  upsertMatch = createEffect(() => this.actions$.pipe(
    ofType(upsertMatch),
    tap(({ match }) => {
      this.dialog.open(MatchDetailComponent, {
        height: '90vh',
        maxWidth: '90vw',
        width: '90vw',
        data: match,
        disableClose: true,
        // position: { top: '17%', left: '40%' }
      });
    })),
    { dispatch: false }
  );

  @Effect({ dispatch: false })
  deleteMatch = this.actions$.pipe(
    ofType(MatchsActionTypes.DELETE_MATCH),
    tap((action: DeleteMatch) => {
      const dialog = this.dialog.open(TcConfirmDialogComponent, {
        data: {
          noText: 'match-list.dialog.delete.no',
          title: 'match-list.dialog.delete.title',
          yesText: 'match-list.dialog.delete.yes',
          message: 'match-list.dialog.delete.message',
        },
        panelClass: 'match-list-dialog-panel'
      });
      dialog.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.matchService.deleteAll(action.payload.id).then((r) => {
            this.listRefreshService.emitRefresh(ListRefreshType.Match);
          });
        }
      });
    })
  )

  @Effect({ dispatch: false })
  createMatch = this.actions$.pipe(
    ofType<CreateMatch>(MatchsActionTypes.CREATE_MATCH),
    tap(async (action: CreateMatch) => {

      const match = {
        competitionId: action.payload.match.competitionId,
        date: action.payload.match.date,
        rain: action.payload.match.rain,
        stadiumId: action.payload.match.stadiumId,
        weather: action.payload.match.weather,
        var: action.payload.match.var,
        wind: action.payload.match.wind,
        matchAnalysisState: MatchAnalysisStateEnum.NotStarted,
        matchState: MatchStateEnum.New,
        matchStatus: MatchStatusEnum.Normal,
      }

      const referees = uniqBy(action.payload.referees, 'refereeId');
      const matchReferees = referees.map((referee) => ({
        refereeId: referee.refereeId,
        matchRefereeRole: referee.role,
      }));

      const analysis = action.payload.analysts.map(analyst => ({
        userId: analyst.userId,
        analystType: analyst.analystType,
        isValidated: false
      }));

      const teamA = {
        clubId: action.payload.teams.teamA.clubId,
        order: 1
      };

      const teamB = {
        clubId: action.payload.teams.teamB.clubId,
        order: 2
      };

      const teamAPlayers = action.payload.teams.teamA.players.map(player => ({
        playerId: player.playerId,
        number: player.number ? Number(player.number) : null,
        titular: player.titular,
      }));

      const teamBPlayers = action.payload.teams.teamB.players.map(player => ({
        playerId: player.playerId,
        number: player.number ? Number(player.number) : null,
        titular: player.titular,
      }));

      const created = await this.matchService.createAll({
        match,
        matchReferees,
        analysis,
        teamA,
        teamB,
        teamAPlayers,
        teamBPlayers,
      });

      upsertMatch({});
      this.store.dispatch(new CreateMatchSuccess(created));
    }
    )
  );

  @Effect({ dispatch: false })
  updateMatchData = this.actions$.pipe(
    ofType<UpdateMatchData>(MatchsActionTypes.UPDATE_MATCH_DATA),
    map(async (action) => {
      const data = action.payload;

      await this.matchService.updateAll({
        match: data.match,
        matchReferees: uniqBy(data.referees.map((referee) => ({ refereeId: referee.refereeId, matchRefereeRole: referee.role })), 'refereeId'),
        analysis: data.analysts.map(analyst => (analyst ? { userId: analyst?.userId ?? (analyst as any)?.user?.id, analystType: analyst.analystType, isValidated: false } : null)).filter(v => !!v),
        teamA: { clubId: data.teams.teamA.clubId, order: 1 },
        teamB: { clubId: data.teams.teamB.clubId, order: 2 },
        teamAPlayers: data.teams.teamA.players.map(player => ({ playerId: player.playerId, number: player.number, titular: player.titular })),
        teamBPlayers: data.teams.teamB.players.map(player => ({ playerId: player.playerId, number: player.number, titular: player.titular })),
      });

      this.store.dispatch(new UpdateMatchDataSuccess());
    }),
  );

  @Effect({ dispatch: false })
  createMatchSuccess = this.actions$.pipe(
    ofType<CreateMatchSuccess>(MatchsActionTypes.CREATE_MATCH_SUCCESS),
    map(() => {
      this.dialog.closeAll();
      this.listRefreshService.emitRefresh(ListRefreshType.Match);
    }),
  );

  @Effect({ dispatch: false })
  startEditMatch = this.actions$.pipe(
    ofType<StartEditMatch>(MatchsActionTypes.START_EDIT_MATCH),
    map(async action => {

      const {
        match,
        clubA,
        clubB,
        competition,
        stadium,
        referees: refereesData,
        users,
        analysis,
        teamPlayers: matchPlayers
      } = await this.matchService.getAll(action.payload.id);

      const [playersA, playersB] = [[], []];
      for (const player of matchPlayers) {
        const body = {
          id: player.id,
          playerId: player.playerId,
          name: `${player.firstName} ${player.lastName}`,
          number: player.number,
          titular: player.titular,
        };

        if (player.club === clubA.code) {
          playersA.push(body);
        }

        if (player.club === clubB.code) {
          playersB.push(body);
        }
      }

      const analysts = await this.getAnalysisData(analysis, users);

      const referees = refereesData.map(referee => ({
        id: referee.id,
        refereeId: referee.refereeId,
        name: `${referee.firstName} ${referee.lastName}`,
        role: (referee as any).matchRefereeRole
      }));

      const data = {
        analysts,
        referees,
        match: { ...match, competition, stadium },
        teams: {
          teamA: {
            clubId: clubA.id,
            players: playersA,
            club: clubA,
          },
          teamB: {
            clubId: clubB.id,
            players: playersB,
            club: clubB,
          },
        },
        errors: [],
        analystsUpdated: false,
      } as any;

      this.store.dispatch(new StartEditSuccess(data));
    })
  );

  @Effect({ dispatch: false })
  startEditSuccess = this.actions$.pipe(
    ofType<StartEditSuccess>(MatchsActionTypes.START_EDIT_SUCCESS),
    map((action) => {
      this.store.dispatch(upsertMatch({ match: (action.payload as any).match }));
    }),
  );

  @Effect({ dispatch: false })
  updateMatchDataSuccess = this.actions$.pipe(
    ofType<UpdateMatchDataSuccess>(MatchsActionTypes.UPDATE_MATCH_DATA_SUCCESS),
    map(() => {
      this.dialog.closeAll();
      this.listRefreshService.emitRefresh(ListRefreshType.Match);
    }),
  );

  @Effect({ dispatch: false })
  getClubPlayer = this.actions$.pipe(
    ofType<GetClubPlayers>(MatchsActionTypes.GET_CLUB_PLAYERS),
    map((action) => {
      const team = action.payload.team;
      const subscription = this.store.select(getMatch).subscribe(data => {
        this.playersService.getClubPlayers(action.payload.clubId, data.date).then((players) => {
          this.store.dispatch(new GetClubPlayersSuccess(
            {
              team: team,
              players: players,
            }));
        });
      });
      subscription.unsubscribe();
    })
  );

  @Effect({ dispatch: false })
  GoToMatchAnalysis = this.actions$.pipe(
    ofType<GoToMatchAnalysis>(MatchsActionTypes.GO_TO_MATCH_ANALYSIS),
    map((action) => {
      this.store.dispatch(new SetMatchVideos([]));
      // TODO // Use action.payload.matchId to send it to the match-analysis page
      this.router.navigate(['match-analysis']);
    })
  );

  @Effect({ dispatch: false })
  deleteMatchVideo = this.actions$.pipe(
    ofType<DeleteMatchVideo>(MatchsActionTypes.DELETE_MATCH_VIDEO),
    map((action) => {
      this.videosService.delete(action.payload.id).then(() => {
        this.store.dispatch(new DeleteMatchVideoSuccess({ id: action.payload.id }));
      });
    }),
  );

  @Effect({ dispatch: false })
  updateMatchVideo = this.actions$.pipe(
    ofType<UpdateMatchVideo>(MatchsActionTypes.UPDATE_MATCH_VIDEO),
    map((action) => {
      this.saveMatchVideo(action.payload).then((video) => {
        this.store.dispatch(new UpdateMatchVideoSuccess(video));
      });
    }),
  );

  @Effect({ dispatch: false })
  addMatchVideo = this.actions$.pipe(
    ofType<AddMatchVideo>(MatchsActionTypes.ADD_MATCH_VIDEO),
    map(({ payload }) => {
      this.videosService.create({
        id: 42, // provide any value as backend will ignore it
        endChronoid: null,
        startChronoid: null,
        matchId: payload.matchId,
        isPrimary: payload.isPrimary,
        fileIdentifier: payload.file,
      }).then((video) => {

        if (!video?.id) {
          this.notification.error('Failed to upload video');
          return;
        }

        this.store.dispatch(new AddMatchVideoSuccess({
          id: video.id,
          fileIdentifier: video.fileIdentifier,
          isPrimary: video.isPrimary,
          matchId: video.matchId,
          startChrono: null,
          endChrono: null,
        }));
      });
    }),
  );

  @Effect({ dispatch: false })
  selectServerVideo = this.actions$.pipe(
    ofType<SelectServerVideo>(MatchsActionTypes.SELECT_SERVER_VIDEO),
    map(({ payload }) => {
      this.videosService.selectServerVideo({ name: payload.file }).then((file) => {

        if (!file.name) {
          this.notification.error('Failed to upload video');
          return;
        }
        this.store.dispatch(new AddMatchVideo({
          file: file.name,
          isPrimary: payload.isPrimary,
          matchId: payload.matchId,
        }));
      });
    }),
  );

  @Effect({ dispatch: false })
  getMatchVideos = this.actions$.pipe(
    ofType<GetMatchVideos>(MatchsActionTypes.GET_MATCH_VIDEOS),
    map(({ payload }) => {
      this.videosService.getMatchVideos(payload.matchId).then((videos) => {
        this.store.dispatch(new SetMatchVideos(videos));
      });;
    }),
  );

  @Effect({ dispatch: false })
  getServerVideos = this.actions$.pipe(
    ofType<GetServerVideos>(MatchsActionTypes.GET_SERVER_VIDEOS),
    map(() => {
      this.videosService.getServerVideos().then((videos) => {
        this.store.dispatch(new SetServerVideos(videos));
      });;
    }),
  );

  @Effect({ dispatch: false })
  startMatchAnalysisEffect = this.actions$.pipe(
    ofType<StartMatchAnalysis>(MatchsActionTypes.START_MATCH_ANALYSIS),
    withLatestFrom(this.store.select(getSelectedMatch)),
    map(([action, match]) => {
      if (match.matchAnalysisState !== MatchAnalysisStateEnum.NotStarted) {
        return;
      }

      const updatedMatch = {
        ...match,
        matchAnalysisState: MatchAnalysisStateEnum.InProgress,
      };

      this.matchService.update(updatedMatch).then(() => {
        this.store.dispatch(new SetMatch(updatedMatch));
      });
    }),
  );

  @Effect({ dispatch: false })
  addNewPlayer = this.actions$.pipe(
    ofType<AddNewPlayer>(MatchsActionTypes.ADD_NEW_PLAYER),
    withLatestFrom(this.store.select(getSelectedMatch)),
    tap(async ([action]) => {
      const teams = await this.store.select(getTeams).pipe(take(1)).toPromise();
      const team = teams[action.payload.team];

      const exists = team.players.some(pl => pl.playerId === action.payload.playerId);

      if (exists) {
        return;
      }

      const secondTeamKey = Object.keys(teams).find(t => t !== action.payload.team)
      const currentClubPlayer = await this.clubPlayerService.getClubPlayer(action.payload.playerId);
      const player = await this.playersService.get(action.payload.playerId);

      if (!currentClubPlayer?.clubId) {
        await this.updateClubPlayer(player, team);

        this.store.dispatch(new AddNewPlayerSuccess(action.payload));

      } else if (currentClubPlayer.clubId !== team.clubId) {

        const existsInOtherTeam = teams[secondTeamKey].players
          .some(e => e.playerId === action.payload.playerId);

        await this.transferPlayer(
          action.payload,
          currentClubPlayer,
          player,
          team.clubId,
          existsInOtherTeam ? secondTeamKey : null
        );
      } else {
        this.store.dispatch(new AddNewPlayerSuccess(action.payload));
      }
    }),
  );

  // @Effect({ dispatch: false })
  // removePlayer = this.actions$.pipe(
  //   ofType(MatchsActionTypes.REMOVE_PLAYER),
  //   tap((action: RemovePlayer) => {
  //     if(isNaN(action.payload.id)){
  //       this.store.dispatch(new RemoveTeamPlayer({ team: action.payload.team, id: action.payload.playerId }))
  //     } else {
  //       this.teamPlayersService.delete(action.payload.id).then((r) => {
  //         this.store.dispatch(new RemoveTeamPlayer({ team: action.payload.team, id: action.payload.playerId }))
  //       });
  //     }
  //   })
  // )

  private async transferPlayer(
    payload: any,
    currentClubPlayer: ClubPlayer,
    player: Player,
    clubId: number,
    removeFrom: string,
  ) {

    const dialog = this.dialog.open(TcConfirmDialogComponent, {
      data: {
        noText: 'club-player-dialog.transfer.no',
        title: 'club-player-dialog.transfer.title',
        yesText: 'club-player-dialog.transfer.yes',
        message: 'club-player-dialog.transfer.message',
      },
      panelClass: 'club-player-transfer-dialog-panel'
    });

    dialog.afterClosed().subscribe(async result => {
      if (result === 'yes') {
        await this.transfer(
          payload,
          currentClubPlayer,
          player,
          clubId,
          removeFrom,
        );
      }
    });
  }
  private async transfer(
    payload: any,
    current: ClubPlayer,
    player: Player,
    clubId: number,
    removeFrom: string,
  ) {
    const clubPlayer = await this.customPlayersService.update({
      id: current.playerId,
      firstName: player.firstName,
      lastName: player.lastName,
      birthDate: player.birthDate,

      clubId: clubId,
      startDate: getStartOfDay(moment()),
      endDate: null,
    });

    if (!!clubPlayer) {
      this.store.dispatch(new AddNewPlayerSuccess(payload));
    }

    if (removeFrom) {
      this.store.dispatch(new RemoveTeamPlayer({ id: current.id, team: removeFrom }))
    }
  }

  private async updateClubPlayer(player: Player, team: any) {
    return await this.customPlayersService.update({
      id: player.id,
      birthDate: player.birthDate,
      firstName: player.firstName,
      lastName: player.lastName,
      clubId: team.clubId,
      startDate: getStartOfDay(moment()),
    });
  }

  private async getAnalysisData(analysis: Analysis[], users: User[]) {
    const analysisByType = (type: AnalystTypeEnum) => analysis.find(a => a.analystType === type);
    const userById = (id: number) => users.find(user => user.id === id);

    const getAnalystByType = (type: AnalystTypeEnum) => {
      const userAnalysis = analysisByType(type)
      if (!userAnalysis) {
        return null;
      }

      return {
        id: type,
        analystId: userAnalysis.id,
        userId: userAnalysis.userId,
        analystType: type,
        user: userById(userAnalysis.userId),
      };
    }

    const analysts = [
      getAnalystByType(AnalystTypeEnum.Operator1),
      getAnalystByType(AnalystTypeEnum.Operator2),
      getAnalystByType(AnalystTypeEnum.Supervisor),
      getAnalystByType(AnalystTypeEnum.Expert),
    ];

    return analysts.filter(analyst => !!analyst);
  }

  private async saveMatchVideo(payload: UpdateVideo) {
    const { startChrono, endChrono, ...video } = payload;

    let start = null;
    if (startChrono) {
      start = await this.saveChronoItem(
        payload.matchId,
        startChrono
      );
      video['startChronoid'] = start.id;
    }

    let end = null;
    if (endChrono) {
      end = await this.saveChronoItem(
        payload.matchId,
        endChrono
      );
      video['endChronoid'] = end.id;
    }

    await this.videosService.update(video);

    const response: VideoWithChrono = {
      id: payload.id,
      fileIdentifier: payload.fileIdentifier,
      isPrimary: payload.isPrimary,
      matchId: payload.matchId,
    };

    if (startChrono) {
      response.startChrono = start;
    }
    if (endChrono) {
      response.endChrono = end;
    }

    return response;
  }

  private saveChronoItem(matchId: number, chrono: any) {
    if (chrono.id) {
      return this.chronoService.update({
        ...chrono,
        matchId,
        primaryVideoTime: 0,
      });
    }

    return this.chronoService.create({
      ...chrono,
      matchId,
      primaryVideoTime: 0,
    });
  }
}
