import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ActorListTypeEnum,
  allMatchStatusesList,
  AnalystTypeEnum,
  AssemblyTypeEnum,
  ChronoItem,
  Deficiency,
  KeyPoint,
  KeyPointAnalysis,
  KeypointAnalysisStatusEnum,
  keyPoints,
  KeyPointTypeEnum,
  NoteKeyPointState,
  UserRoleEnum,
  allUserStatusesList
} from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcFormComponent, TcMenuComponent, TcMenuItem, TcNotificationService, TcTranslateService } from '@tc/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { DeficiencyService } from '../../../../services/business-services/deficiency-service';
import { KeyPointAnalysisService } from '../../../../services/business-services/key-point-analysis-service';
import { KeyPointService } from '../../../../services/business-services/key-point-service';
import { PermissionsService } from '../../../../services/permissions/permissions.service';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { getAuthenticatedUser } from '../../../auth/store/auth.selectors';
import { ChronoService } from '../../../match-analysis/services/chrono.service';
import { LoadAssemble, SaveExpertKeyPoint, UpdateNoteKeyPointSuccess } from '../../store/expert-analysis.actions';
import { getExpertAnalysisReadonly, getNoteKeyPoint } from '../../store/expert-analysis.selectors';

@Component({
  selector: 'app-note-key-point-details',
  templateUrl: './note-key-point-details.component.html',
  styleUrls: ['./note-key-point-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoteKeyPointDetailsComponent extends TcFormComponent<any> implements OnInit, OnDestroy {

  public readonly assemblyTypes = AssemblyTypeEnum;
  public readonly analystTypeEnum = AnalystTypeEnum;
  public readonly actorListTypeEnum = ActorListTypeEnum;

  public time: string;
  public rows = [];
  public canAssemble = false;
  public keyPointType: KeyPointTypeEnum;

  public isReadOnly = true;

  private readOnlySubscription: Subscription;

  private noteKeyPointSubscription$;
  private noteKeyPoint: NoteKeyPointState;
  private selectedRows = [];

  keyPointAnalysisTypeMenu: TcMenuComponent;
  @ViewChild('keyPointAnalysisTypeMenu', { static: true }) set appKeyPointAnalysisTypeMenu(value: TcMenuComponent) {
    this.keyPointAnalysisTypeMenu = value;
  }

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService,
    private readonly store$: Store<any>,
    private readonly chrono: ChronoService,
    private readonly keyPointService: KeyPointService,
    private readonly deficiencyService: DeficiencyService,
    private readonly permissionsService: PermissionsService,
    private readonly notificationService: TcNotificationService,
    private readonly keyPointAnalysisService: KeyPointAnalysisService,
    private readonly dialogRef: MatDialogRef<NoteKeyPointDetailsComponent>,
  ) {
    super(translate, elem);
  }

  async ngOnInit() {
    const user = await this.store$.select(getAuthenticatedUser).pipe(take(1)).toPromise();

    this.canAssemble = user.role === UserRoleEnum.Administrator || user.role === UserRoleEnum.Expert;

    this.dialogRef.addPanelClass('note-key-point-details');

    this.listenNoteKeyPointData();
    this.initFormFields();
    this.setKeyPointAnalysisTypeMenu();

    this.readOnlySubscription = this.store$.select(getExpertAnalysisReadonly).subscribe(isReadOnly => {
      this.disableFields(this.fields, isReadOnly);
      this.isReadOnly = isReadOnly;
    });
  }

  private disableFields(fields: any[], isReadOnly: boolean) {
    fields.forEach(field => {
      if (field.templateOptions) {
        field.templateOptions.disabled = isReadOnly;
      }
      if (field.fieldGroup) {
        this.disableFields(field.fieldGroup, isReadOnly);
      }
    });
  }

  public assemble() {
    if (!this.checkCanAssemble()) {
      this.notificationService.error('Unable to assemble selected deficiencies. Please check that the selected deficiencies are from different operators and only two deficiencies can be assembled at a time.');
      return;
    }

    const deficienyId1 = (this.selectedRows[0].row.value[AnalystTypeEnum.Operator1] || this.selectedRows[0].row.value[AnalystTypeEnum.Operator2])?.deficiencyId;
    const deficienyId2 = (this.selectedRows[1].row.value[AnalystTypeEnum.Operator1] || this.selectedRows[1].row.value[AnalystTypeEnum.Operator2])?.deficiencyId;

    this.store$.dispatch(new LoadAssemble({ deficienyId1, deficienyId2, keyPointId: this.noteKeyPoint.keyPointId }));
  }

  public cancelKeyPoint() {
    this.dialogRef.close();
  }

  public invalidateKeyPoint() {
    this.setKeyPointValide(false);
  }

  public validateKeyPoint() {
    this.setKeyPointValide(true);
  }

  public edit(row) {
    const deficienyId1 = row.value[AnalystTypeEnum.Operator1]?.deficiencyId;
    const deficienyId2 = row.value[AnalystTypeEnum.Operator2]?.deficiencyId;
    const expertDeficiencyId = row.value[AnalystTypeEnum.Expert]?.deficiencyId;

    this.store$.dispatch(new LoadAssemble({ deficienyId1, deficienyId2, expertDeficiencyId, keyPointId: this.noteKeyPoint.keyPointId }));
  }

  private setKeyPointValide(valide: boolean) {
    this.store$.dispatch(new SaveExpertKeyPoint({
      keyPointId: this.noteKeyPoint.keyPointId,
      noteKeyPoint: {
        ...this.noteKeyPoint,
        keyPoint: {
          ...this.noteKeyPoint.keyPoint,
          invalid: !valide
        }
      }
    }));
  }

  public getMatchAnalysisStateColor(status: KeypointAnalysisStatusEnum) {
    switch (status) {
      case KeypointAnalysisStatusEnum.Abnormal:
        return '#f3a88a';
      case KeypointAnalysisStatusEnum.PotentiallyAbnormal:
        return '#f2df98';
      case KeypointAnalysisStatusEnum.Normal:
        return '#97e1d9';
      default:
        return '#2196f3';
    }
  }

  public getChronoValue(chrono: ChronoItem) {
    if (!chrono) {
      return '';
    }

    const displayMatchTime = this.chrono.diplayMatchTime(chrono);

    return `${displayMatchTime.time}${displayMatchTime.additionalTime ? ` + ${displayMatchTime.additionalTime}` : ''}`
  }

  public getMatchTimeValue(chrono: ChronoItem) {
    if (!chrono?.videoTime) {
      return '-:-';
    }

    return convertToReadableFormat(chrono.videoTime);
  }

  public setExpertComment(comment: string, expertDeficiencyId: number) {
    this.deficiencyService.update({ comment, id: expertDeficiencyId } as Deficiency);

    for (const row of this.rows) {
      if (row?.value?.[AnalystTypeEnum.Expert]?.deficiencyId === expertDeficiencyId) {
        row.value[AnalystTypeEnum.Expert].comment = comment;
      }
    }
  }

  public updateExpertComment(event: any, expertDeficiencyId: number) {
    this.setExpertComment(event.target.value, expertDeficiencyId);
  }

  public setDeficiencyValidation(event, rowIndex: number) {
    const row = this.getDeficiencyByRowIndex(rowIndex);

    this.deficiencyService.update({ id: row.value.Expert.deficiencyId, invalid: event.checked } as Deficiency);
  }

  public selectDeficiency(index: number, row) {
    const existIndex = this.selectedRows.findIndex(selected => selected.index === index);

    if (existIndex >= 0) {
      this.selectedRows.splice(existIndex, 1);
    } else {
      this.selectedRows.push({ index, row });
    }
  }

  private checkCanAssemble(): boolean {
    const haveTwoSelectedRows = this.selectedRows.length === 2;

    if (!haveTwoSelectedRows) {
      return false;
    }

    const diferentOperators = this.selectedRows[0].row.value[AnalystTypeEnum.Operator1] && this.selectedRows[1].row.value[AnalystTypeEnum.Operator2]
      || this.selectedRows[0].row.value[AnalystTypeEnum.Operator2] && this.selectedRows[1].row.value[AnalystTypeEnum.Operator1];
    const assemblySingle = this.selectedRows[0].row.value[AnalystTypeEnum.Expert].assemblyType === this.assemblyTypes.AutomaticSingle
      && this.selectedRows[1].row.value[AnalystTypeEnum.Expert].assemblyType === this.assemblyTypes.AutomaticSingle;

    return haveTwoSelectedRows && diferentOperators && assemblySingle;
  }

  private setKeyPointAnalysisTypeMenu() {
    this.keyPointAnalysisTypeMenu.items = keyPoints.map(keyPoint => ({ name: keyPoint.label, value: keyPoint.value, displayName: this.translate.instant(`key-point-analysis-type-menu.${keyPoint.label}`) }));
    this.keyPointAnalysisTypeMenu.onMenuItemClick = (item: TcMenuItem) => {
      this.noteKeyPoint = {
        ...this.noteKeyPoint,
        keyPoint: {
          ...this.noteKeyPoint.keyPoint,
          keyPointType: (item as any).value
        }
      };

      this.keyPointType = item.name as KeyPointTypeEnum;

      this.keyPointService.update({ id: this.noteKeyPoint.keyPointId, keyPointType: (item as any).value } as KeyPoint);
    };
  }

  private getDeficiencyByRowIndex(index: number) {
    return this.rows[index];
  }

  private listenNoteKeyPointData() {
    this.noteKeyPointSubscription$ = this.store$.select(getNoteKeyPoint)
      .pipe(skipWhile(v => !v))
      .subscribe((noteKeyPoint: NoteKeyPointState) => {
        if (!noteKeyPoint) {
          return;
        }

        this.noteKeyPoint = noteKeyPoint;

        const expertAnalysisStatus = noteKeyPoint.analysis?.[AnalystTypeEnum.Expert]?.keyPointAnalysisStatus;
        const expertAnalysisVar = noteKeyPoint.analysis?.[AnalystTypeEnum.Expert]?.var;

        this.model = {
          ...this.model,
          var: typeof expertAnalysisVar === 'boolean' ? expertAnalysisVar : undefined,
          status: expertAnalysisStatus || null,
        }

        this.time = noteKeyPoint?.startChrono?.primaryVideoTime + '' || '0';
        this.keyPointType = keyPoints.find(keyPoint => keyPoint.value === noteKeyPoint.keyPoint.keyPointType).label as KeyPointTypeEnum;

        this.setRowsData(noteKeyPoint);
      });
  }

  private async setRowsData(noteKeyPoint: NoteKeyPointState) {
    const deficiencies = cloneDeep(noteKeyPoint?.deficiencies || []);
    const implicationRow = {
      key: 'implication',
      value: {
        [AnalystTypeEnum.Operator1]: noteKeyPoint.implication?.[AnalystTypeEnum.Operator1],
        [AnalystTypeEnum.Operator2]: noteKeyPoint.implication?.[AnalystTypeEnum.Operator2],
        [AnalystTypeEnum.Expert]: noteKeyPoint.implication?.[AnalystTypeEnum.Expert],
      }
    };

    const keyPointAnalysisStatusRow = {
      key: 'keyPointAnalysisStatus',
      value: {
        [AnalystTypeEnum.Operator1]: noteKeyPoint.analysis?.[AnalystTypeEnum.Operator1]?.keyPointAnalysisStatus,
        [AnalystTypeEnum.Operator2]: noteKeyPoint.analysis?.[AnalystTypeEnum.Operator2]?.keyPointAnalysisStatus,
        [AnalystTypeEnum.Expert]: noteKeyPoint.analysis?.[AnalystTypeEnum.Expert]?.keyPointAnalysisStatus,
      }
    };

    const rows: any[] = [implicationRow];

    for (const deficiency of deficiencies) {
      rows.push({
        key: deficiency.actors[AnalystTypeEnum.Expert]?.type || deficiency.actors[AnalystTypeEnum.Operator1]?.type || deficiency.actors[AnalystTypeEnum.Operator2]?.type,
        value: {
          [AnalystTypeEnum.Operator1]: deficiency.actors[AnalystTypeEnum.Operator1],
          [AnalystTypeEnum.Operator2]: deficiency.actors[AnalystTypeEnum.Operator2],
          [AnalystTypeEnum.Expert]: deficiency.actors[AnalystTypeEnum.Expert],
        }
      });

      for (const def of deficiency.deficiencies) {
        rows.push({
          key: 'deficiency',
          value: {
            [AnalystTypeEnum.Operator1]: def[AnalystTypeEnum.Operator1],
            [AnalystTypeEnum.Operator2]: def[AnalystTypeEnum.Operator2],
            [AnalystTypeEnum.Expert]: def[AnalystTypeEnum.Expert],
          }
        });
      }
    }

    rows.push(keyPointAnalysisStatusRow);

    this.rows = rows;
  }

  private initFormFields() {
    this.fields = [
      {
        fieldGroupClassName: 'form--fields',
        fieldGroup: [
          {
            key: 'status',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('note-key-point-details.labels.status'),
              options: allUserStatusesList(this.translate),
              change: () => this.onUpdateForm(),
            }
          },
          {
            className: 'form--radio',
            key: 'var',
            type: 'radio',
            templateOptions: {
              label: this.translate.instant('note-key-point-details.labels.var'),
              options: [
                { value: true, label: this.translate.instant('note-key-point-details.labels.yes') },
                { value: false, label: this.translate.instant('note-key-point-details.labels.no') },
              ],
              change: () => this.onUpdateForm(),
            },
          }
        ],
      },
    ];
  }

  private onUpdateForm() {
    const expertKeyPointAnalysis = { id: this.noteKeyPoint?.analysis?.Expert?.keyPointAnalysisId } as KeyPointAnalysis;

    if (this.model.status) {
      expertKeyPointAnalysis.keyPointAnalysisStatus = this.model.status;
    }

    if (typeof this.model.var === 'boolean') {
      expertKeyPointAnalysis.var = this.model.var;
    }

    this.keyPointAnalysisService.update(expertKeyPointAnalysis).then(() => {
      this.store$.dispatch(new UpdateNoteKeyPointSuccess({
        ...expertKeyPointAnalysis,
        keyPointId: +this.noteKeyPoint.keyPointId,
      }));
    });
  }

  ngOnDestroy() {
    if (this.noteKeyPointSubscription$) {
      this.noteKeyPointSubscription$.unsubscribe();
    }

    if (this.readOnlySubscription) {
      this.readOnlySubscription.unsubscribe();
    }
  }

}
