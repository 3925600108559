
export function Retry(options: RetryOptions): Function {

  return function (target: Record<string, any>, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {
    const originalFn: Function = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      try {
        return await retryAsync.apply(this, [originalFn, args, options.maxRetryAttempts]);
      } catch (e) {
        if (e.message === 'maxAttempts') {
          e.code = '429';
          e.message = `Failed '${propertyKey}' for ${options.maxRetryAttempts} times.`;
          e.stack = null;
        }
        throw e;
      }
    };
    return descriptor;
  };

  async function retryAsync(fn: Function, args: any[], maxAttempts: number): Promise<any> {
    try {
      return await fn.apply(this, args);
    } catch (e) {
      if (--maxAttempts < 0) {
        throw new Error('maxAttempts');
      }

      return retryAsync.apply(this, [fn, args, maxAttempts]);
    }
  }
}

export interface RetryOptions {
  maxRetryAttempts: number;
}
