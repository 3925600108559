import { Injectable } from '@angular/core';
import { RouteEnum } from '../enums/routes.enum';
import { BreadcrumbModel } from '../models/breadcrumb.model';
import { elementAt } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbItems: BreadcrumbModel[] = [
    { route: RouteEnum.Home, parents: null },
    { route: RouteEnum.Organisations, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Competitions, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Matches, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Clubs, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Players, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Referees, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Stadiums, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.ExpertAnalysis, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Users, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.Reporting, parents: [{ route: RouteEnum.Home }] },
    {
      route: RouteEnum.MatchAnalysis,
      parents: [{ route: RouteEnum.Home }, { route: RouteEnum.Matches }]
    },
    {
      route: RouteEnum.NoteKeyPoint,
      parents: [{ route: RouteEnum.Home }, { route: RouteEnum.ExpertAnalysis }]
    },
    {
      route: RouteEnum.ExpertAnalysisConsultation,
      parents: [{ route: RouteEnum.Home }, { route: RouteEnum.ExpertAnalysis }]
    },
    { route: RouteEnum.Configuration, parents: [{ route: RouteEnum.Home }] },
    { route: RouteEnum.ReportView, parents: [{ route: RouteEnum.Home }, { route: RouteEnum.ExpertAnalysis }] },
  ];
  constructor() {}

  public getBreadcrumbList(route: RouteEnum, name?: string): BreadcrumbModel[] {
    const breadcrumbResult = [];

    const breadcrumb: BreadcrumbModel = this.breadcrumbItems.find(
      element => element.route === route
    );
    const clone = Object.assign({}, breadcrumb);
    if (breadcrumb.parents !== null) {
      breadcrumb.parents.forEach(item => {
        breadcrumbResult.push(item);
      });
    }
    if (name) {
      clone.name = name;
    }
    breadcrumbResult.push(clone);
    return breadcrumbResult;
  }
}
