import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { KeyPointConsultationItem, KeyPointTypeEnum } from '@match-fix/shared';
import { TcGenericListComponent, TcListFilterType, TcListSortType } from '@tc/core';
import { Subject } from 'rxjs';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';

@Component({
  selector: 'app-key-points-list',
  templateUrl: './key-points-list.component.html',
  styleUrls: ['./key-points-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KeyPointsListComponent implements OnInit, AfterViewInit {

  @Input() public keypoints: Subject<KeyPointConsultationItem[]>;

  keyPointList: TcGenericListComponent<any>;
  specificKeyPointList: TcGenericListComponent<any>;

  @ViewChild('keyPointList', { static: true }) set setKeyPointList(values: TcGenericListComponent<any>) {
    this.keyPointList = values;
  }
  @ViewChild('specificKeyPointList', { static: true }) set setSpecificKeyPointList(values: TcGenericListComponent<any>) {
    this.specificKeyPointList = values;
  }

  @ViewChild('typeTemplate', { static: true }) typeTemplate: TemplateRef<any>;
  @ViewChild('chronoStartTemplate', { static: true }) chronoStartTemplate: TemplateRef<any>;
  @ViewChild('chronoEndTemplate', { static: true }) chronoEndTemplate: TemplateRef<any>;
  @ViewChild('chronoVideoStartTemplate', { static: true }) chronoVideoStartTemplate: TemplateRef<any>;
  @ViewChild('chronoVideoEndTemplate', { static: true }) chronoVideoEndTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('varTemplate', { static: true }) varTemplate: TemplateRef<any>;
  @ViewChild('videoTemplate', { static: true }) videoTemplate: TemplateRef<any>;

  private readonly standartList$ = new Subject<any[]>();
  private readonly specificList$ = new Subject<any[]>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initStandartListComponent()
    this.initSpecificListComponent()

    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    const standartTypes = [
      KeyPointTypeEnum.Goal,
      KeyPointTypeEnum.Penalty,
      KeyPointTypeEnum.RedCard,
      KeyPointTypeEnum.FreeKick,
      KeyPointTypeEnum.YellowCard,
      KeyPointTypeEnum.Offside,
      KeyPointTypeEnum.CornerKick,
    ];
    const specificTypes = [
      KeyPointTypeEnum.GoalSituation,
      KeyPointTypeEnum.CounterAttack,
      KeyPointTypeEnum.Injury,
      KeyPointTypeEnum.FreeDeficiency,
      KeyPointTypeEnum.Other,
    ];

    this.keypoints.subscribe(list => {
      this.standartList$.next(list.filter(item => standartTypes.includes(item.type)));
      this.specificList$.next(list.filter(item => specificTypes.includes(item.type)));
    });
  }

  public readonly convertToReadableFormat = m => {
    return convertToReadableFormat(m, 'mm:ss')
  };

  private initStandartListComponent() {
    this.keyPointList.rows$ = this.standartList$;
    this.keyPointList.isFiltrable = false;
    this.keyPointList.filterType = TcListFilterType.Disabled;
    this.keyPointList.sortType = TcListSortType.Disabled;
    this.keyPointList.isPaged = false;
    this.keyPointList.hasFixedHeader = true;
    this.keyPointList.hasAddButton = false;
    this.keyPointList.onRowClick = () => { };

    this.keyPointList.columns = this.getTableColumns();
  }

  private initSpecificListComponent() {
    this.specificKeyPointList.rows$ = this.specificList$;
    this.specificKeyPointList.isFiltrable = false;
    this.specificKeyPointList.filterType = TcListFilterType.Disabled;
    this.specificKeyPointList.sortType = TcListSortType.Disabled;
    this.specificKeyPointList.isPaged = false;
    this.specificKeyPointList.hasFixedHeader = true;
    this.specificKeyPointList.hasAddButton = false;
    this.specificKeyPointList.onRowClick = () => { };

    this.specificKeyPointList.columns = this.getTableColumns();
  }

  private getTableColumns() {
    return [
      {
        propertyName: 'type',
        visible: true,
        htmlTemplate: this.typeTemplate,
      },
      {
        propertyName: 'teamCode',
        visible: true,
      },
      {
        propertyName: 'code',
        visible: true,
      },
      {
        propertyName: 'status',
        visible: true,
        htmlTemplate: this.statusTemplate,
      },
      {
        propertyName: 'var',
        visible: true,
        htmlTemplate: this.varTemplate,
      },
      {
        propertyName: 'chronoStart',
        visible: true,
        htmlTemplate: this.chronoStartTemplate,
      },
      {
        propertyName: 'chronoEnd',
        visible: true,
        htmlTemplate: this.chronoEndTemplate,
      },
      {
        propertyName: 'startVideo',
        visible: true,
        htmlTemplate: this.chronoVideoStartTemplate,
      },
      {
        propertyName: 'endVideo',
        visible: true,
        htmlTemplate: this.chronoVideoEndTemplate,
      },
      {
        propertyName: 'video',
        visible: true,
        htmlTemplate: this.videoTemplate,
      },
    ];
  }

}
