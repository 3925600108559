import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core/option';
import {
  allMatchRefereeRoles,
  AnalystTypeEnum,
  Club,
  Competition,
  EntitiesEnum,
  matchWeather,
  Player,
  Referee,
  Stadium,
  User
} from '@match-fix/shared';
import { Store } from '@ngrx/store';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { QlAutcompleteService } from 'apps/frontend/src/app/services/core/ql-autocomplete.service';
import { from, Observable, of, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { UserService } from '../../../../../services/users.service';
import { MatchFormAbstract } from '../../../abstract/match-form.abstract';
import {
  UpdateAnalyst,
  UpdateCurrentReferee,
  UpdateMatch
} from '../../../store/matchs.actions';
import { MatchState } from '../../../store/matchs.interfaces';
import { AutoCompleteOptions } from './types';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent extends MatchFormAbstract implements OnInit, AfterViewInit, OnDestroy {

  public id = null;

  public form: FormGroup;
  public matchSubscription: Subscription;

  formControl = new FormControl();
  filter: Observable<any>;
  filteredOptions: any[] = [];
  filteredOptionsSubscription: Subscription;
  model: any = {};

  @Input() readOnly = false;
  @Input() listValue: string;
  @Input() entity: string;
  @Input() name: string;
  @Input() label: string;
  @Input() hasTooltip: boolean;

  @Input() preselectedOptions: { value: any, label: string };
  @Input() options: AutoCompleteOptions;

  @Output() public handleSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild('valueRef') valueRef: ElementRef<any>;

  constructor(
    protected store: Store<MatchState>,
    private readonly usersService: UserService,
    private readonly qlAutocompleteService: QlAutcompleteService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    if(!this.hasTooltip){
      this.hasTooltip = false;
    }

    this.id = `${this.entity}_${this.name}`;

    // Setup form and formControl for the component : add form fields of the component here
    this.form = new FormGroup({});
    this.form.addControl(this.name, new FormControl(this.preselectedOptions ? this.preselectedOptions.value : ''));

    this.filteredOptionsSubscription = this.formControl.valueChanges
      .pipe(
        startWith(''),
        switchMap(term => this.filterItems(term ? term : null)),
      ).subscribe(s => {
        this.filteredOptions = s as any[];
      });

    // Call parent to set form
    super.SetForm(this.form);
    // Call the parent to setup error system
    super.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.preselectedOptions) {
      this.initPreselectedValue();
    }
  }

  public resetFormValue() {
    this.initPreselectedValue();
  }

  //auto complete
  filterItems(term) {
    switch (this.entity) {
      case 'competition':
        return this.qlAutocompleteService.getAutocompleteValues<Competition>(EntitiesEnum.Competitions, term, 'id', 'name,season',
          (item: any) => {
            const competitionName = item.name;
            const season = item.season;
            return `${competitionName} - ${season}`;
          });
      case 'stadium':
        return this.qlAutocompleteService.getAutocompleteValues<Stadium>(EntitiesEnum.Stadiums, term, 'id', 'name');
      case 'userAnalyst':
        return from(this.getAnalysts(term));
      case 'refereeUser':
        return this.qlAutocompleteService.getAutocompleteValues<Referee>(EntitiesEnum.Referees, term, 'id', 'firstName,lastName');
      case 'refereeRole':
        if (!isNaN(term)) {
          term = '';
        }
        const roles: any[] = [];
        allMatchRefereeRoles.forEach((key, index) => {
          if (key.toLowerCase().includes(term)) {
            roles.push({ value: index + 1, label: key });
          }
        });
        return of(roles);
      case 'weather':
        if (!isNaN(term)) {
          term = '';
        }
        const items: any[] = [];
        matchWeather.forEach((key, index) => {
          if (key.toLowerCase().includes(term)) {
            items.push({ value: index + 1, label: key });
          }
        });
        return of(items);
      case 'team':
        return this.qlAutocompleteService.getAutocompleteValues<Club>(EntitiesEnum.Clubs, term, 'id', 'name')
      case 'player':
        return this.qlAutocompleteService.getAutocompleteValues<Player>(EntitiesEnum.Players, term, 'id', 'firstName,lastName')
    }
  }

  displayWith(itemId): string | undefined {
    if (itemId && itemId > 0 && this.filteredOptions) {
      const item = this.filteredOptions.find(x => x.value === itemId);
      if (item) {
        return item.label;
      } else {
        return this.getInitialValue();
      }
    } else {
      this.setListValue(null);
      return null;
    }
  }

  selected(event: MatOptionSelectionChange, item: any) {
    if (event.source.selected) {
      this.setListValue(item);

      if (this.entity === 'team') {
        const team = this.filteredOptions.find(e => e.value === item.value)?.selectedItem;

        this.handleSelect.emit(team);
      }

      if (this.entity === 'player') {
        const player = this.filteredOptions.find(e => e.value === item.value);

        if (player) {
          const playerId = player.value;
          const playerTeam = this.name.replace('_playerId', '');

          this.handleSelect.emit(
            { team: playerTeam, playerId: playerId, titular: true, number: null, name: player.label }
          );
        }
      }

      let key = '';

      switch (this.entity) {
        case 'competition':
          key = 'competitionId';
          break;
        case 'stadium':
          key = 'stadiumId';
          break;
        case 'weather':
          key = 'weather';
          this.form.controls[this.name].setValue(item.value);
          this.store.dispatch(new UpdateMatch({ key: key, value: item.label }));
          return;
        case 'userAnalyst':
          const id = this.name.replace('_userId', '');
          const analystType = AnalystTypeEnum[id];
          const userId = item.value;

          this.store.dispatch(new UpdateAnalyst({ id: id, key: 'userId', value: userId }));
          this.store.dispatch(new UpdateAnalyst({ id: id, key: 'analystType', value: analystType }));
          return;
        case 'refereeUser':
          this.store.dispatch(new UpdateCurrentReferee({ refereeId: item.value, name: item.label, role: null }));
          return;
        case 'refereeRole':
          this.store.dispatch(new UpdateCurrentReferee({ refereeId: null, name: null, role: item.label }));
          return;
        case 'player':
        case 'team':
          return;
      }

      this.form.controls[this.name].setValue(item.value);
      this.store.dispatch(new UpdateMatch({ key: key, value: item.value }));
    }
  }

  handleEmptyInput(event: any) {
    if (event.target.value === '') {
      this.setListValue(null);

      const shouldResetValue = this.entity === 'userAnalyst';
      if (shouldResetValue) {
        this.selected({ source: { selected: true } } as any, { value: null, label: null });
      }
    }
  }

  private async getAnalysts(term): Promise<User[]> {
    if (typeof term === 'number') {
      return [];
    }

    if (!this.options?.competitionId) {
      return [];
    }

    const id = this.name.replace('_userId', '');
    const analystType = AnalystTypeEnum[id];

    let users = await this.usersService.get({
      competitionId: this.options?.competitionId,
      type: analystType,
      search: term,
    });

    if (analystType === AnalystTypeEnum.Operator1 && this.options?.operator2Id) {
      users = users.filter(user => user.id !== this.options?.operator2Id)
    }

    if (analystType === AnalystTypeEnum.Operator2 && this.options?.operator1Id) {
      users = users.filter(user => user.id !== this.options?.operator1Id)
    }

    return users.map(user => ({
      ...user,
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
  }

  private getInitialValue() {
    if (this.listValue) {
      const listValue = this.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        return this.model[values[0]] ? this.model[values[0]][values[1]] : null;
      }
      return this.model[listValue];
    }
  }

  private setListValue(item: any) {
    if (this.listValue) {
      const listValue = this.listValue;
      if (listValue.includes('.')) {
        const values = listValue.split('.');
        if (item === null) {
          this.model[values[0]] = Object.assign({});
        } else {
          this.model[values[0]] = Object.assign({}, { id: item.value, [values[1]]: item.label });
        }
      } else {
        if (item === null) {
          this.model[listValue] = null;
        } else {
          this.model[listValue] = item.label;
        }
      }
    }
  }

  private initPreselectedValue() {
    setTimeout(() => {
      this.valueRef.nativeElement.value = this.preselectedOptions.label;
    });
  }

  ngOnDestroy() {
    if (this.filteredOptionsSubscription) {
      this.filteredOptionsSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

}
