import { DefaultDataServiceFactory, DefaultDataService, HttpUrlGenerator, DefaultDataServiceConfig } from 'ngrx-data';
import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { QlServiceFactoryConfig } from './ql-service-factory-config';
import { TcListDataAdapter, TC_LIST_DEFAULT_NB_LINES } from '@tc/core';
import { QlListDataService } from './ql-list-data.service';

@Injectable({
  providedIn: 'root'
})
export class QlDefaultDataServiceFactory extends DefaultDataServiceFactory {

  constructor(
    private apollo: Apollo,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private qlServiceFactoryConfig: QlServiceFactoryConfig,
    @Inject(TC_LIST_DEFAULT_NB_LINES) private tcListDefaultNbLines: number,
    config?: DefaultDataServiceConfig
  ) {
    super(http, httpUrlGenerator, config);
  }

  create<T>(entityName: string): DefaultDataService<T> {
    if (this.qlServiceFactoryConfig.typesConfig[entityName]) {
      const metadata = this.qlServiceFactoryConfig.typesConfig[entityName];

      const service = new QlListDataService<T>(this.apollo, metadata.className);
      service.gqlColumns = metadata.gqlColumns as string[];
      if (metadata.gqlNodeMapper) {
        service.nodeMapper = metadata.gqlNodeMapper as (node: any) => T;
      }

      const adapter = new TcListDataAdapter<T>(entityName, service, this.http, this.httpUrlGenerator, this.tcListDefaultNbLines, this.config);
      return adapter;

    }

    return new DefaultDataService<T>(entityName, this.http, this.httpUrlGenerator, this.config);
  }
}


