import { TcService } from '@tc/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Subscriber, Observable } from 'rxjs';
import { QlServiceFactoryConfig } from './ql-service-factory-config';
import { EntitiesEnum } from '@match-fix/shared';

@Injectable({
  providedIn: 'root'
})
export class QlAutcompleteService extends TcService {

  defaultNbLines = 20; // TODO config nb lines to load
  first = this.defaultNbLines;  // number of items to load

  resObserver: Subscriber<any>;
  results$: Observable<any> = new Observable<any>(o => this.resObserver = o);

  constructor(private apollo: Apollo, private qlServiceFactoryConfig: QlServiceFactoryConfig) {
    super();
  }

  nodeMapper<T>(node: any) {
    node.id = node._id;
    delete node._id;
    delete node.__typename;
    return node as T;
  }

  columnSelector = (gqlColumns: string[]): string => `{ ${gqlColumns.join(',')}`;

  getAutocompleteValues<T>(entityName: EntitiesEnum, term: string, keyName: string, valueName: string, labelSelector?: (T) => string, orderByDesc?: boolean) {
    if (term && term !== '' && typeof (term) !== 'number') {

      let entity = entityName.slice(0, -1);

      if (this.qlServiceFactoryConfig.typesConfig[entityName]) {
        const metadata = this.qlServiceFactoryConfig.typesConfig[entityName];
        entity = metadata.className;
      }

      const columns = valueName.split(',');
      columns.push(keyName);
      columns.push('_id');

      const variables: any = { first: this.first };

      const query = gql`
        query genericEntityQuery($first: Int, $after: String, $orderBy: OrderBy, $filter: ${entity}FilterType){
            ${entity.toLowerCase()} (first: $first, after:$after, orderBy: $orderBy, filter: $filter){
              pageInfo{
                endCursor
              },
              total,
              edges {
                node ${this.columnSelector(columns)}
                }
              }
            }
          }
          `;

      variables.filter = { anyFieldContains: term };
      // variables.orderBy = orderBy;

      const resultsQuery$ = this.apollo.watchQuery({
        query,
        variables
      }).valueChanges;

      const subscription = resultsQuery$.subscribe(value => {
        const connectionResult = value.data[entity.toLowerCase()];
        const result = (connectionResult.edges as []).map(x => this.nodeMapper<T>((x as any).node));
        const resultMap = result.map(item => {
          return { value: item[keyName], label: labelSelector ? labelSelector(item) : this.setSelectLabel(item, valueName), selectedItem: item };
        });
        if (orderByDesc) {
          resultMap.sort((a, b) => (a.label < b.label) ? 1 : -1);
        } else {
          resultMap.sort((a, b) => (a.label > b.label) ? 1 : -1);
        }
        this.resObserver.next(resultMap);
        subscription.unsubscribe();
      });

    } else {
      return new Observable<any[]>();
    }

    return this.results$;
  }

  private setSelectLabel = (item: any, valueName: string): string => {
    const result = [];
    const values = valueName.split(',');

    values.forEach(value => {
      if (value.includes('{') && value.includes('}')) {
        result.push(item[value.substr(0, value.indexOf('{'))][value.substr(value.indexOf('{') + 1).replace('}', '')]);
      } else {
        result.push(item[value]);
      }
    });
    return result.join(' ');
  }


}
