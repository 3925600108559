import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/core';
import { mouseWheelZoom } from 'mouse-wheel-zoom';
import { Subscription, timer } from 'rxjs';
import { MatchVideosService, VideoLocal } from '../../../../services/match-videos.service';
import { TcVideoPlayerService } from '../../../../services/tc-video-player.service';
import { convertToReadableFormat } from '../../../../utils/milisecondsToReadableFormat';
import { normalizeTime } from '../../../../utils/normalizeTimeFormat';
import { SetVideoTime } from '../../store/analysis.actions';
import { MatchTimeChrono } from '../../store/analysis.interfaces';
import { getVideoTime } from '../../store/analysis.selectors';
import { ChronoService } from './../../services/chrono.service';
import { getMatchTime } from './../../store/chrono.selectors';

@Component({
  selector: 'app-tc-video-player',
  templateUrl: './tc-video-player.component.html',
  styleUrls: ['./tc-video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcVideoPlayerComponent implements AfterViewInit, OnDestroy {

  @ViewChild('media', { static: false }) public videoElement: ElementRef;

  @Input() public allowRemoteControl = true;
  @Input() public src: string;
  @Input() public preview = false;
  @Input() public set startTime(time: number) {
    this.timeToStartVideo = (time || 0);

    this.goToValue = convertToReadableFormat(this.timeToStartVideo).replace(':', '');
  }

  /**
   * Setup of store subscription and chrono match time
   */
  private matchTimeSubscription: Subscription;
  private timerSubscription: Subscription;
  public matchTime: MatchTimeChrono = { time: '--:--' };

  private zoom;
  private matchVideos: VideoLocal[];
  private timeToStartVideo = 0;
  private videoPaused = false;
  private videoCurrentTime = 0;
  private silentReloadIntervalTime = 20 * 1000; // 20 seconds
  private silentReloadInterval;
  private currentSrc: string;

  public muted = true;
  public goToValue = '000000';
  public currentVideo: VideoLocal;
  public secondaryVideos: VideoLocal[] = [];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly store$: Store<TcAppState>,
    private readonly chronoService: ChronoService,
    private readonly matchVideosService: MatchVideosService,
    private readonly tcVideoPlayerService: TcVideoPlayerService,
  ) { }

  async ngAfterViewInit() {
    await this.initVideos()
    await timer(100).toPromise();

    if (this.allowRemoteControl) {
      this.tcVideoPlayerService.init(this.video);
    }

    if (!this.preview) {
      this.initChrono();
      this.listenChangeVideoTime();
    }

    this.initZoom();
    if (this.timeToStartVideo) {
      this.videoCurrentTime = this.timeToStartVideo / 1000;
    }

    this.video.volume = 0;

    this.listenVideoEvents();
  }

  private listenVideoEvents() {
    this.video.addEventListener('play', () => {
      if (this.silentReloadInterval) {
        clearInterval(this.silentReloadInterval);
      }

      this.silentReloadInterval = setInterval(() => this.reload(), this.silentReloadIntervalTime);
    });

    this.video.addEventListener('pause', () => {
      if (this.silentReloadInterval) {
        clearInterval(this.silentReloadInterval);
      }
    });

    this.video.addEventListener('loadeddata', () => this.onVideoLoad(this.videoPaused, this.videoCurrentTime));
  }

  public selectVideo(video: VideoLocal) {
    this.video.pause();
    this.zoom.reset();

    if (this.currentVideo.isPrimary) {
      this.matchVideos.find(v => v.src === this.currentVideo.src).videoTime = this.video.currentTime;
    }
    this.currentVideo = video;
    this.currentSrc = video.src;
    this.video.load();

    this.video.currentTime = video.videoTime;
  }

  private async initVideos() {
    if (this.src) {
      this.currentVideo = { src: this.src } as VideoLocal;
      this.cdr.detectChanges();
    } else {
      this.matchVideos = await this.matchVideosService.getVideosFromStore();
      this.currentVideo = this.matchVideos.find(v => v.isPrimary);
    }

    this.currentSrc = this.currentVideo.src;
  }

  /**
   * Subscribe to the match time from the chrono store
   */
  private initChrono() {
    // Subscribe to the store data containing the match time
    this.store$.select(getMatchTime)
      .subscribe(matchTime => {
        this.matchTime = matchTime;

        this.checkSecondaryVideos();
      });

    // Declare the timer with 1 second of delay and call the method to refresh the chrono
    const timerObservable = timer(500, 500);
    const getCurrentTime = () => this.currentVideo.isPrimary
      ? this.video.currentTime
      : (this.currentVideo.startChrono.matchTime / 1000 + this.video.currentTime);

    let currentTime = this.video.currentTime;
    this.timerSubscription = timerObservable.subscribe(interval => {
      if (currentTime !== getCurrentTime()) {
        currentTime = getCurrentTime()

        // Get the time in milliseconds of the player
        const matchTime = parseInt(currentTime * 1000 as any, 10);
        this.chronoService.UpdateMatchTime(matchTime);
      }
    });
  }

  private checkSecondaryVideos() {
    const primaryVideo = this.matchVideos.find(v => v.isPrimary);
    const chronoTime = this.chronoService.getMatchTime((primaryVideo.videoTime * 1000) || this.chronoService.getVideoTime());
    if (!chronoTime) {
      return;
    }

    const showPrimary = (v: VideoLocal) => !this.currentVideo.isPrimary && v.isPrimary;

    this.secondaryVideos = this.matchVideos
      .filter(v => {
        return showPrimary(v) || (!!v.startChrono && !!v.endChrono);
      })
      .filter(v => {
        return showPrimary(v)
          || ((chronoTime.matchTime >= (v.startChrono.matchTime - 3000))
            && chronoTime.matchTime <= (v.endChrono.matchTime + 1000));
      })
      .filter(v => {
        return v.src !== this.currentVideo.src;
      })
      .sort((a, b) => a.isPrimary ? -1 : 1);
  }

  private listenChangeVideoTime() {
    this.store$.select(getVideoTime)
      .subscribe(time => {
        if (this.videoElement?.nativeElement && time !== undefined) {
          this.store$.dispatch(new SetVideoTime({ time: undefined }));
          this.video.currentTime = time / 1000;
        }
      });
  }

  private initZoom() {
    this.zoom = mouseWheelZoom({
      element: this.videoElement.nativeElement,
      zoomStep: .25
    });
    this.zoom.reset();
  }

  private get video() {
    return this.videoElement.nativeElement;
  }

  public setVolume(event) {
    this.video.volume = event.target.value;
  }

  public async reload(resetZoom = false) {
    const { paused, currentTime } = this.video;

    this.videoPaused = paused;
    this.videoCurrentTime = currentTime;

    if (resetZoom) {
      this.zoom.reset();
    }

    this.video.src = `${this.currentSrc}?t=${new Date().getTime()}`;
    this.video.load();
  }

  private onVideoLoad(paused, currentTime) {
    this.video.currentTime = currentTime;
    if (!paused) {
      this.video.play();
    }
  }

  public back() {
    this.video.currentTime = this.video.currentTime - 0.04;
  }

  public next() {
    this.video.currentTime = this.video.currentTime + 0.04;
  }

  public changeGoToValue(event) {
    this.goToValue = `${event.target.value}${event.target.value.length === 5 ? ':00' : ''}`;
  }

  public gotoTime() {
    const time = normalizeTime(this.goToValue.replace(':', ''));

    this.video.currentTime = time.seconds || 0;
  }

  ngOnDestroy(): void {
    // Destroy subscriptions/intervals
    if (this.matchTimeSubscription) {
      this.matchTimeSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.silentReloadInterval) {
      clearInterval(this.silentReloadInterval);
    }
  }

}