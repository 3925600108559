import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country, CustomEntitiesEnum, EntitiesEnum, Referee } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TcGenericFormComponent, TcTranslateService } from '@tc/core';
import { EntityCollectionServiceFactory } from 'ngrx-data';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { QlAutcompleteService } from '../../../../../services/core/ql-autocomplete.service';
import { upsertRefereeSuccess } from '../../../store/referees.actions';

@Component({
  selector: 'app-referee-detail',
  templateUrl: './referee-detail.component.html',
  styleUrls: ['./referee-detail.component.scss']
})
export class RefereeDetailComponent extends TcGenericFormComponent<Referee> implements OnInit {

  constructor(
    entityCollectionServiceFactory: EntityCollectionServiceFactory,
    translate: TcTranslateService,
    private store: Store<any>,
    private qlAutocompleteService: QlAutcompleteService,
    private dialogRef: MatDialogRef<RefereeDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Referee,
    elem: ElementRef) {

    super(CustomEntitiesEnum.RefereeWithMatch, entityCollectionServiceFactory, translate, elem);

    const [countryId, countryName] = [data?.countryId || null, (data as any)?.countryName || null];

    const preselected = countryId ? {
      value: countryId, label: countryName
    } : null;

    this.fields = [
      {
        fieldGroupClassName: 'form-display-grid',
        fieldGroup: [
          {
            key: 'firstName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'lastName',
            type: 'input',
            templateOptions: {
              type: 'text',
              required: true,
              maxLength: 100
            },
          },
          {
            key: 'birthDate',
            type: 'datepicker',
            templateOptions: {
              required: true,
              type: 'text',
              datepickerPopup: 'dd/MM/yyyy',
              datepickerOptions: {
                format: 'dd/MM/yyyy'
              }
            },
          },
          {
            key: 'countryId',
            type: 'autocomplete-list',
            templateOptions: {
              filter: (term) => this.qlAutocompleteService.getAutocompleteValues<Country>(EntitiesEnum.Countrys, term, 'id', 'code'),
              listValue: 'country.code',
              required: true,
              preselected,
            },
          },
        ]
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    this.afterSave = (model) => {
      this.store.dispatch(upsertRefereeSuccess({ referee: model }));
      this.onClose();
    }
  }
  onClose(event?) {
    if (event) {
      event.preventDefault();
    }
    this.dialogRef.close(null);
  }
}

