import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { User, CustomEntitiesEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import {
  TcAppState,
  TcGenericListComponent,
  TcListFilterType,
  TcListRowActionButtonsPosition,
  TcListSortType,
  TcSmartComponent,
} from '@tc/core';
import { Subscription } from 'rxjs';

import { OrganisationsService } from '../../../../../services/business-services/organisations-service';
import { ListRefreshService, ListRefreshType } from '../../../../match/services/list-refresh.service';
import { upsertUser, saveOrganisations, showResetUserAuthKeyDialog } from '../../../store/users.actions';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent extends TcSmartComponent implements OnInit, AfterViewInit, OnDestroy {

  private refreshSubscription: Subscription;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly listRefreshService: ListRefreshService,
    private readonly organisationsService: OrganisationsService,
  ) {
    super();
  }

  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

  usersList: TcGenericListComponent<any>;
  @ViewChild('usersList', { static: true }) set setUsersList(values: TcGenericListComponent<User>) {
    this.usersList = values;
    this.usersList.entityName = CustomEntitiesEnum.CustomUsers;
  }

  ngAfterViewInit() {
    this.refresh();
  }

  ngOnInit() {

    this.usersList.rowActionButtonsPosition = TcListRowActionButtonsPosition.AfterData;
    this.usersList.showTotalInActionsHeader = false;

    this.usersList.sortType = TcListSortType.Server;
    this.usersList.filterType = TcListFilterType.Server;
    this.usersList.hasFixedHeader = true;

    this.usersList.hasAddButton = false;
    this.usersList.addItemWhenKeyPresed = true;

    this.usersList.hasActionsLabel = true;
    this.usersList.isPaged = false;

    this.usersList.onScrollDown = () => {
      this.usersList.service.getAll();
    };

    this.usersList.columns = [
      {
        propertyName: 'firstName',
        visible: true
      },
      {
        propertyName: 'lastName',
        visible: true
      },
      {
        propertyName: 'organisationNames',
        visible: true
      },
      {
        propertyName: 'email',
        visible: true
      },
      {
        propertyName: 'role',
        visible: true
      },
      {
        propertyName: 'active',
        visible: true,
        htmlTemplate: this.statusTemplate
      },
    ];

    this.usersList.rowActions = [
      {
        actionName: 'edit',
        icon: 'edit',
        visible: true
      },
      {
        actionName: 'delete',
        icon: 'delete',
        visible: true
      },
      {
        actionName: 'restore_auth_key',
        icon: 'vpn_key',
        visible: true
      }
    ];

    this.usersList.onRowAction = (row: any, actionName: string) => {
      if (actionName === 'edit') {
        this.editUser(row);
      }
      if (actionName === 'restore_auth_key') {
        this.store$.dispatch(showResetUserAuthKeyDialog({ user: row }));
      }
    };

    this.usersList.onRowClick = (row: any) => {
      this.editUser(row);
    };

    this.initListeners();
    this.initOrgs();
  }

  addItem() {
    this.store$.dispatch(upsertUser({}));
  }

  private refresh() {
    this.usersList.service.clearCache();
    this.usersList.service.getWithQuery({ initial: 'true' });
  }

  private initListeners() {
    this.refreshSubscription = this.listRefreshService.subject
      .pipe(skipWhile((type) => type !== ListRefreshType.User))
      .subscribe(() => this.refresh());
  }

  private editUser(row: any) {
    this.store$.dispatch(upsertUser({ user: row }));
  }

  private async initOrgs() {
    const orgs = await this.organisationsService.getAll();
    this.store$.dispatch(saveOrganisations({ orgs }));
  }

  public getStatusLabel(status: boolean) {
    if (status) {
      return 'active'
    } else {
      return 'inactive'
    }
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

}
