import { Injectable } from '@angular/core';
import { allPlayerDeficiencyList, allRefereeDeficiencyList, KeypointAnalysisStatusEnum, keyPoints as allKeyPoints, KeyPointTypeEnum, MatchAnalysisStateEnum, MatchStatusEnum, NoteKeyPointListItem, NoteKeyPointListItemDeficiency, specificKeyPointTypes, UserAnalysisStatusEnum } from '@match-fix/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState } from '@tc/core';
import { uniqBy } from 'lodash';
import { map, skipWhile, take } from 'rxjs/operators';
import { AnalystService } from '../../../services/business-services/analyst-service';
import { convertToReadableFormat } from '../../../utils/milisecondsToReadableFormat';
import { getNoteKeyPointList, getNoteMatchId } from '../store/expert-analysis.selectors';

@Injectable({
  providedIn: 'root'
})
export class ExpertAnalysisService {

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly translate: TranslateService,
    private readonly analysisService: AnalystService
  ) { }

  public async getFilters(status: MatchAnalysisStateEnum) {
    const keyPoints = await this.getNoteKeyPointList(status);
    const isSpecific = (type: KeyPointTypeEnum) => {
      return specificKeyPointTypes.indexOf(type) !== -1;
    };
    const keyPointTypes = allKeyPoints.map(keyPoint => ({ ...keyPoint, label: `${keyPoint.label} ${this.translate.instant(isSpecific(keyPoint.value) ? 'note-key-point-list.labels.specific' : 'note-key-point-list.labels.standard')}` }));
    const defs = (keyPoints.map(({ deficiencies }) => (deficiencies)) as any).flat();

    return {
      startPeriod: '00:00',
      endPeriod: this.getEndPeriod(keyPoints),
      teams: this.getTeams(defs),
      players: this.getPlayers(defs),
      referees: this.getReferees(defs),
      statuses: this.getStatuses(defs),
      implications: [{ value: 'Simple' }, { value: 'Multiple' }],
      deficiencies: [...allPlayerDeficiencyList(this.translate), ...allRefereeDeficiencyList(this.translate)],
      keyPoints: keyPointTypes,
    };
  }

  public getNoteKeyPointList(analysisState: MatchAnalysisStateEnum): Promise<NoteKeyPointListItem[]> {
    return this.store$.select(getNoteKeyPointList).pipe(
      skipWhile(v => !v),
      take(1),
      map(list => this.sort(list, analysisState)),
    ).toPromise();
  }

  private getEndPeriod(keyPoints: NoteKeyPointListItem[]) {
    const matchTimes = keyPoints
      .map(keyPoint => keyPoint.chronoEnd.matchTime)
      .sort((a, b) => b - a);

    return convertToReadableFormat(matchTimes[0] + 1000, 'mm:ss');
  }

  private getTeams(deficiencies: NoteKeyPointListItemDeficiency[]): { id: number; name: string; }[] {
    return deficiencies
      .filter(def => !!def.clubId)
      .map(def => ({ id: def.clubId, name: def.clubCode }))
      .reduce((accumulator, currentValue) =>
        (accumulator || [])
          .some(value => value.id === currentValue.id) ? accumulator : [...accumulator, currentValue], []);
  }

  private getPlayers(deficiencies: NoteKeyPointListItemDeficiency[]): { id: number; name: string; }[] {
    return deficiencies
      .filter(def => !!def.playerId)
      .map(def => ({ id: def.playerId, name: `${def.playerFirstName[0]}. ${def.playerLastName}` }))
      .reduce((accumulator, currentValue) =>
        (accumulator || [])
          .some(value => value.id === currentValue.id) ? accumulator : [...accumulator, currentValue], []);
  }

  private getReferees(deficiencies: NoteKeyPointListItemDeficiency[]): { id: number; role: string; }[] {
    return deficiencies
      .filter(def => !!def.refereeId)
      .map(def => ({ id: def.refereeId, role: def.matchRefereeRole }))
      .reduce((accumulator, currentValue) =>
        (accumulator || [])
          .some(value => value.id === currentValue.id) ? accumulator : [...accumulator, currentValue], []);
  }

  private getStatuses(deficiencies: NoteKeyPointListItemDeficiency[]): string[] {
    return deficiencies
      .filter(def => !!def.status)
      .map(def => def.status)
      .reduce((accumulator, currentValue) =>
        (accumulator || [])
          .includes(currentValue) ? accumulator : [...accumulator, currentValue], []);
  }

  public async getAllStatuses(): Promise<any> {
    const matchId = await this.getMatchId();

    return await this.analysisService.getAllStatuses(matchId);
  }

  public async getMatchStatus(): Promise<any> {
    const matchId = await this.getMatchId();
    return await this.analysisService.getMatchStatus(matchId);
  }

  public async getTeamAStatus(): Promise<any> {
    const matchId = await this.getMatchId();

    return await this.analysisService.getTeamAStatus(matchId);
  }

  public async getTeamBStatus(): Promise<any> {
    const matchId = await this.getMatchId();

    return await this.analysisService.getTeamBStatus(matchId);
  }

  public async getRefereeStatus(): Promise<any> {
    const matchId = await this.getMatchId();

    return await this.analysisService.getRefereeStatus(matchId);
  }

  public async getMatchId(): Promise<number> {
    return await this.store$.select(getNoteMatchId).pipe(take(1)).toPromise();
  }

  public async setMatchStatus(matchStatus: MatchStatusEnum): Promise<void> {
    const matchId = await this.getMatchId();

    await this.analysisService.setMatchStatus(matchId, matchStatus);
  }

  public async setMatchStatusByAdmin(matchStatus: MatchStatusEnum, matchId: number): Promise<void> {

    await this.analysisService.setMatchStatus(matchId, matchStatus);
  }

  public async setRefereeStatus(refereeStatus: UserAnalysisStatusEnum): Promise<void> {
    const matchId = await this.getMatchId();

    await this.analysisService.setRefereeStatus(matchId, refereeStatus);
  }

  public async setTeamStatus(teamStatus: UserAnalysisStatusEnum, type: string): Promise<void> {
    const matchId = await this.getMatchId();

    await this.analysisService.setTeamStatus(matchId, teamStatus, type);
  }

  public async analysisForExpertIsValidated(): Promise<any> {
    const matchId = await this.getMatchId();

    return await this.analysisService.analysisForExpertIsValidated(matchId);
  }

  private sort(list: NoteKeyPointListItem[], analysisState: MatchAnalysisStateEnum): NoteKeyPointListItem[] {
    if (analysisState !== MatchAnalysisStateEnum.Classified) {
      return [...list].sort((a, b) => a?.chronoStart?.primaryVideoTime > b?.chronoStart?.primaryVideoTime ? 1 : -1);
    }

    const [abnormal, potentially, normal] = [
      KeypointAnalysisStatusEnum.Abnormal,
      KeypointAnalysisStatusEnum.PotentiallyAbnormal,
      KeypointAnalysisStatusEnum.Normal,
    ].map(s => list.filter(({ status }) => s === status));

    return [
      ...(abnormal.length > 1 ? this.sortByDeficienciesCount(abnormal) : abnormal),
      ...(potentially.length > 1 ? this.sortByDeficienciesCount(potentially) : potentially),
      ...(normal.length > 1 ? this.sortByDeficienciesCount(normal) : normal),
    ];
  }

  private sortByDeficienciesCount(list: NoteKeyPointListItem[]): NoteKeyPointListItem[] {
    const result = [];
    const lengths = new Set<number>();

    for (const item of list) {
      lengths.add(item.deficiencies.length);
    }

    const groups = Array.from(lengths).sort((a, b) => b - a);

    for (const group of groups) {
      const items = list.filter(item => item.deficiencies.length === group);

      result.push(
        ...(items.length > 1 ? this.sortByType(items) : items)
      );
    }

    return result;
  }

  private sortByType(list: NoteKeyPointListItem[]): NoteKeyPointListItem[] {
    const result = [];

    for (const type of this.orderTypes) {
      const items = list.filter(item => item.type === type);

      result.push(
        ...(items.length > 1 ? this.sortByImplication(items) : items)
      );
    }

    return result;
  }

  private sortByImplication(list: NoteKeyPointListItem[]): NoteKeyPointListItem[] {
    const data = [];

    for (const item of list) {
      const players = uniqBy(item.deficiencies.filter(e => !!e.playerId), 'playerId').length;
      const referees = uniqBy(item.deficiencies.filter(e => !!e.refereeId), 'refereeId').length;

      data.push({ players, referees, id: item.id });
    }

    const playersSorted = [
      ...data.filter(e => e.players > 1).filter(e => e.players >= e.referees).sort((a, b) => a.players > b.players ? -1 : 1),
      ...data.filter(e => e.referees > 1).filter(e => e.referees > e.players).sort((a, b) => a.referees > b.referees ? -1 : 1),
      ...data.filter(e => e.players === 1 && e.referees <= e.players),
      ...data.filter(e => e.referees === 1 && !e.players),
      ...data.filter(e => !e.referees && !e.players),
    ];

    const finalSort = playersSorted.sort((a, b) => {
      return a.players === b.players
        ? a.referees > b.referees ? -1 : 1
        : 0;
    });

    const result = finalSort.map(e => list.find(item => item.id === e.id));

    return result;
  }

  private get orderTypes(): KeyPointTypeEnum[] {
    return [
      KeyPointTypeEnum.Goal,
      KeyPointTypeEnum.Penalty,
      KeyPointTypeEnum.RedCard,
      KeyPointTypeEnum.FreeKick,
      KeyPointTypeEnum.YellowCard,
      KeyPointTypeEnum.Offside,
      KeyPointTypeEnum.CornerKick,
      KeyPointTypeEnum.Substitution,
      KeyPointTypeEnum.GoalSituation,
      KeyPointTypeEnum.CounterAttack,
      KeyPointTypeEnum.Injury,
      KeyPointTypeEnum.FreeDeficiency,
      KeyPointTypeEnum.Other,
    ];
  }

}
