import { Chrono } from '..';

export class ChronoType {
  static className = 'Chrono';
  static gqlRootQueryName = 'chrono';
  static gqlColumns: string[] = [
      '_id',
      'matchId',
      'primaryVideoTime',
      'matchTime',
      'round',
      'isAdditionalTime',
  ];

  static gqlNodeMapper = (node: any): Chrono => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Chrono;
    }

}
