import { Referee } from '..';

export class RefereeType {
  static className = 'Referee';
  static gqlRootQueryName = 'referee';
  static gqlColumns: string[] = [
      '_id',
      'firstName',
      'lastName',
      'birthDate',
      'countryId',
  ];

  static gqlNodeMapper = (node: any): Referee => {
        node.id = node._id;
        delete node._id;
        delete node.__typename;
        return node as Referee;
    }

}
