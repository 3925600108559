import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { AppFormlyAutocompleteComponent } from './autocomplete.component';
import { ImagePreviewComponent } from './components/dumb/image-preview/image-preview.component';
import { LogoComponent } from './components/dumb/logo/logo.component';
import { AddNewMenuComponent } from './components/smart/add-new-menu/add-new-menu.component';
import { BreadcrumbComponent } from './components/smart/breadcrumb/breadcrumb.component';
import { FiltersComponent } from './components/smart/filters/filters.component';
import { AppFormlyUploadComponent } from './form-upload.component';
import { BreadcrumbEffects } from './store/breadcrumb/breadcrumb.effects';
import { breadcrumbReducer } from './store/breadcrumb/breadcrumb.reducer';
import { ListEffects } from './store/list.effects';
import { MainEffects } from './store/main.effects';

const smartComponents = [
  ImagePreviewComponent,
  AddNewMenuComponent,
  LogoComponent,
  AppFormlyAutocompleteComponent,
  FiltersComponent,
  BreadcrumbComponent,
  AppFormlyUploadComponent
]

@NgModule({
  declarations: [...smartComponents],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([ListEffects, BreadcrumbEffects, MainEffects]),
    StoreModule.forFeature('breadcrumb', breadcrumbReducer)
  ],
  exports: [...smartComponents]
})
export class MainModule { }
