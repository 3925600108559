import { Organisation } from '@match-fix/shared';

export default class UsersState {
  organisations: Array<Organisation & { add?: boolean }>;
}

export const initializeState = (): UsersState => {
  return {
    organisations: [],
  };
};